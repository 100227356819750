import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./MyConnections.css";
import MySQL from "../../assets/images/DatabaseIcons/mysql.svg";
import AzureDataLake from "../../assets/images/DatabaseIcons/AzureDataLake.webp";
import Azureblob from "../../assets/images/DatabaseIcons/Azureblob.svg";
import Clickhouse from "../../assets/images/DatabaseIcons/Clickhouse.svg";
import PostgreSQL from "../../assets/images/DatabaseIcons/postgre.svg";
import MicrosoftSQL from "../../assets/images/DatabaseIcons/MicrosoftSQL.svg";
import Oracle from "../../assets/images/DatabaseIcons/Oracle.svg";
import Redshift from "../../assets/images/DatabaseIcons/Redshift.svg";
import SalesForce from "../../assets/images/DatabaseIcons/Salesforce.png";
import Dremio from "../../assets/images/DatabaseIcons/dreamio.svg";
import Snowflake from "../../assets/images/DatabaseIcons/snowflake.svg";
import SFTP from "../../assets/images/DatabaseIcons/SFTC.png";

import { Col, Row } from "react-bootstrap";
// import { Switch } from "antd";
import { MARTBUILDER_API } from "../../serverConfig";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../msalConfig";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { MdOutlineDelete, MdDelete } from "react-icons/md";
import { Box, Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import DeleteCrawlModal from "../../components/NewComponents/DeleteCrawlModal";
import BreadcrumbMUI from "../../components/NewComponents/BreadcrumbMUI";

const ConnectionDetail = (props) => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.datacraft.scopes,
    account: account,
  };
  const { dcerror, dctoken, getDatacraftToken, getToken, token } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const [connectionCrawlDetails, setConnectionCrawlDetails] = useState([]);
  const [dbetails, setDBDetails] = useState({});
  const [connectionNameAlt, setConnectionNameAlt] = useState();

  //below usestate for blinker
  const [loadingRunStatus, setLoadingRunStatus] = useState(false);
  const [runningStatuses, setRunningStatuses] = useState({});
  const [openRunStatusModal, setOpenRunStatusModal] = useState(false);

  const location = useLocation();

  const { connfqn } = params || {};
  const {
    connectionName,
    createdAt,
    datasourceType,
    connectionFqn,
    description,
  } = location?.state || {};
  const {
    hostname,
    port,
    database,
    tenantId,
    accountName,
    clientId,
    // clientSecret,
  } = dbetails || {};

  const [deleteIconFill, setDeleteIconFill] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [moreData, setMoreData] = useState(null);

  const handleMoreClick = (event, crawl) => {
    // setAnchorEl(event.currentTarget);
    // console.log("crawl", crawl);
    setMoreData(crawl);
    setOpenDeleteModal(!openDeleteModal);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleDeleteCrawl = useCallback(
    (crawl) => {
      // console.log(crawl);
      let metadataConfigId = crawl.id;
      // console.log("metadataConfigId ID to delete:", metadataConfigId);
      if (metadataConfigId) {
        const ourRequest = axios.CancelToken.source();
        const deleteMetadataConfig = async () => {
          try {
            let response = await axios.delete(
              `${MARTBUILDER_API}/metadatacrawl/configs/${metadataConfigId}`,
              {
                headers: {
                  // Define your custom headers here
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                CancelToken: ourRequest.token,
              }
            );
            console.log(response.data);
            callapiwithtoken(
              async (token) => await getConnectionCrawlDetails(token)
            );
          } catch (err) {
            if (axios.isCancel(err)) {
              return;
            } else {
              console.error(err);
            }
          }
        };
        deleteMetadataConfig();
      }
      setOpenDeleteModal(false);
      // setAnchorEl(null);
    }
    // [tableData]
  );

  const callapiwithtoken = async (callback) => {
    await getToken();
    if (token) {
      await callback(token);
    }
    if (dcerror) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getConnectionCrawlDetails = async (token) => {
    console.log("getConnectionCrawlDetails");
    let username = account.username;
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await axios.get(
        // `https://datasculpt-api.gleafink.com/metadatacrawl/configs/all`,
        `${MARTBUILDER_API}/metadatacrawl/crawls/${connfqn}`,
        // `${MARTBUILDER_API}/metadatacrawl/crawls/${connfqn}?username=${username}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          cancelToken: ourRequest.token,
        }
      );
      console.log("response.data : ", response.data);
      setConnectionCrawlDetails(response.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const getDbDetails = async (token) => {
    // setConnLoading(true);
    // let username = account.username;
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await axios.get(
        `${MARTBUILDER_API}/datasources/${connfqn}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          cancelToken: ourRequest.token,
        }
      );
      console.log("response.data : ", response.data.connectionName);
      setDBDetails(JSON.parse(response?.data.connectionCreds));
      setConnectionNameAlt(response?.data?.connectionName);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const fetchRunningStatus = async (token, dagName) => {
    try {
      const response = await axios.get(
        `${MARTBUILDER_API}/metadatacrawl/taskStatus/${dagName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRunningStatuses((prevStatuses) => ({
        ...prevStatuses,
        [dagName]: response?.data,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    callapiwithtoken(async (token) => {
      await getConnectionCrawlDetails(token);
      await getDbDetails(token);
    });
  }, [token, connfqn]);

  // const calculateDays = (date) => {
  //     console.log("date : ", date)
  //     let d = "2024-06-25";
  //     var today = new Date();
  //     var date_to_reply = new Date(d);
  //     var timeinmilisec = date_to_reply.getTime() - today.getTime();
  //     console.log(Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)));
  //     return Math.abs(Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)));
  // }

  // Crawl status hitting every 5 secs-------
  // useEffect(() => {
  //   if (connectionCrawlDetails.length > 0) {
  //     const intervalId = setInterval(() => {
  //       connectionCrawlDetails.forEach((crawl) => {
  //         const runningStatus = runningStatuses[crawl.fqn];
  //         if (
  //           !(
  //             (runningStatus && runningStatus.status === "COMPLETED")
  //             // && runningStatus.tasksCompleted === 0
  //           )
  //         ) {
  //           fetchRunningStatus(token, crawl.fqn);
  //         }
  //       });
  //     }, 5000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [connectionCrawlDetails, token, runningStatuses]);

  const calculateDays = (d1, d2) => {
    var ndays;
    var tv1 = d1.valueOf(); // msec since 1970
    var tv2 = d2.valueOf();

    ndays = (tv2 - tv1) / 1000 / 86400;
    ndays = Math.round(ndays - 0.5);
    return ndays;
  };
  // let d = "2024-06-28";
  // console.log("--------------", calculateDays1(new Date(d), new Date()));

  const databaseImages = {
    MySQL,
    AzureDataLake,
    Azureblob,
    Clickhouse,
    PostgreSQL,
    MicrosoftSQL,
    Oracle,
    Redshift,
    SalesForce,
    Dremio,
    Snowflake,
    SFTP,
  };

  const getBlinkerClass = (status) => {
    switch (status) {
      case "NOT_STARTED":
        return "round-blinker not-started";
      case "RUNNING":
        return "round-blinker running";
      case "COMPLETED":
        return "round-blinker completed";
      default:
        return "round-blinker";
    }
  };

  // const handleRunStatusModalToggle = () => {
  //   setOpenRunStatusModal(!openRunStatusModal);
  // };

  return (
    <>
      <div className="px-31">
        <BreadcrumbMUI
          path1={"/myconnections"}
          name1={"My Connections"}
          last={connectionName ? connectionName : connectionNameAlt}
        />
      </div>

      <div className="wrapper-conn-details p30">
        <div className="w-100">
          <div className="header-conn-detail">
            <div>
              <div className="myConName">
                {connectionName ? connectionName : connectionNameAlt}
              </div>
            </div>
            <div className="imageText-conn-detail">
              <img
                src={databaseImages[datasourceType]}
                alt={datasourceType}
                style={{ width: "30px" }}
              />
            </div>
          </div>
          <div className="py-1">
            <p className="myConTimeDetails">
              Created {calculateDays(new Date(createdAt), new Date())} days ago
            </p>
          </div>
          <div className="py-1 pt-3">
            {/* <Row>
                        <Col>
                            <p className="fw-bold ps-1">Product: Maestro 2.0</p>
                        </Col>
                    </Row> */}
          </div>
          <div className="w-75">
            <Row>
              <Col className="col-4">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {datasourceType === "AzureDataLake" ? (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#000000",
                        padding: "0.2rem",
                      }}
                    >
                      <span>Account Name : </span>
                      {accountName || "N/A"}
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#000000",
                        padding: "0.2rem",
                      }}
                    >
                      <span>Host Name : </span>
                      {hostname || "N/A"}
                    </p>
                  )}
                  {datasourceType === "AzureDataLake" ? (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#000000",
                        padding: "0.2rem",
                      }}
                    >
                      <span>Tenant ID: </span>
                      {tenantId || "N/A"}
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#000000",
                        padding: "0.2rem",
                      }}
                    >
                      <span>Port Number: </span>
                      {port || "N/A"}
                    </p>
                  )}
                  {datasourceType === "AzureDataLake" ? (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#000000",
                        padding: "0.2rem",
                      }}
                    >
                      <span>Client ID: </span>
                      {clientId?.replace(/.(?=.{4})/g, "X") || "N/A"}
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#000000",
                        padding: "0.2rem",
                      }}
                    >
                      <span>Database : </span>
                      {database || "N/A"}
                    </p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="text-dark maersk-font-family">
                  {description}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <hr />

        {/* <div className="myConPageContent"> */}
        <div className="metacrawl-header-conn-detail">
          <div className="d-flex">
            <div className="myConPageHeader pe-2">
              <h4>Metadata Crawl</h4>
              <div className="px-2">
                <p className="count-conn-detais px-2">
                  {connectionCrawlDetails?.length}
                </p>
              </div>
            </div>

            {/* <div className="pe-5 mt-1">
              <p className="count-conn-detais">
                {connectionCrawlDetails?.length}
              </p>
            </div> */}
            <div>
              <button
                className="nar-wid1 job-btn round-btn"
                onClick={() => {
                  // navigate("/publishMetadata/" + connectionFqn);
                  navigate("/metadataCrawl/" + connfqn);
                  // /publishmetadata
                }}
              >
                + New Metadata Crawl
              </button>
            </div>
          </div>
        </div>

        <div className="cardWrapper">
          {connectionCrawlDetails && connectionCrawlDetails?.length > 0 ? (
            connectionCrawlDetails?.map((crawl) => {
              const runningStatus = runningStatuses[crawl.fqn] || {};
              return (
                <div className="myConPageContent-conn-detail" key={crawl.fqn}>
                  <div className="card-conn-details">
                    <Row>
                      <Col>
                        <div style={{ marginBottom: "14px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p className="myConName">
                              {crawl.name || "Name N/A"}
                            </p>

                            {/* <div
                              style={{
                                textAlign: "right",
                                marginBottom: "20px",
                              }}
                            >
                              {loadingRunStatus ? (
                                <>
                                  <div>
                                    Fetching data please wait...{" "}
                                    <CircularLoader />
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{
                                    border: "0px solid red",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignContent: "center",
                                  }}
                                >
                                  <div className={getBlinkerClass(runningStatus.status)}>
                                    <span className="tooltip-text">
                                      Task Completed:{" "}
                                      {runningStatus.tasksCompleted || 0}
                                    </span>
                                  </div>
                                              {runningStatus.status !== "RUNNING" && (    
                                                <button
                                                    onClick={() => handleRunStatusModalToggle()}
                                                    style={{
                                                    border: "1px solid grey",
                                                    padding: "3px 5px",
                                                    borderRadius: "5px",
                                                    }}
                                                >
                                                    <span className="crawlImage" style={{ marginRight: "5px" }}>
                                                    Crawl Now
                                                    </span>
                                                </button>
                                                )} 
                                </div>
                              )}
                            </div> */}
                            <div
                              onMouseEnter={() =>
                                setDeleteIconFill(crawl?.name)
                              }
                              onMouseLeave={() => setDeleteIconFill(null)}
                            >
                              {deleteIconFill === crawl?.name ? (
                                <MdDelete
                                  size={18}
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={(event) =>
                                    handleMoreClick(event, crawl)
                                  }
                                />
                              ) : (
                                <MdOutlineDelete
                                  size={18}
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={(event) =>
                                    handleMoreClick(event, crawl)
                                  }
                                />
                              )}
                              {/* <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem onClick={handleClose}>Edit</MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    setOpenDeleteModal(!openDeleteModal)
                                  }
                                >
                                  Delete
                                </MenuItem>
                              </Menu> */}
                            </div>
                          </div>
                          <p className="myConTimeDetails">
                            Created{" "}
                            {crawl.createdAt ? (
                              <span>
                                {calculateDays(
                                  new Date(crawl.createdAt),
                                  new Date()
                                )}{" "}
                                days ago
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        {/* Disbaled for now, will be enabled in future */}
                        {/* <div className="myConPageHeader1 float-end">
                                        <div className="imageText">
    
                                        </div>
                                    </div> */}
                      </Col>
                    </Row>

                    {/* <Row className="py-2">
                      <div className="d-flex">
                        <div className="pe-3 label-con-detail">Auto Crawl</div>
                        <div className="px-3">
                          <Switch size="small" checked={crawl.isAutoCrawl} />
                        </div>
                        {crawl.isAutoCrawl ? (
                          <p className="ps-2">Enabled</p>
                        ) : (
                          <p className="ps-2">disabled</p>
                        )}
                      </div>
                    </Row> */}

                    {datasourceType === "AzureDataLake" ? (
                      <Row className="py-2">
                        <Col>
                          <div className="label-con-detail">
                            Storage Account Name
                          </div>
                          {crawl.adlsStorageAccountName?.length > 0 ? (
                            <div>{crawl.adlsStorageAccountName}</div>
                          ) : (
                            "N/A"
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Row className="py-2">
                        <Col>
                          <div className="label-con-detail">
                            Schema Filter Pattern
                          </div>
                          {crawl.schemaExclusiveFilterPatterns?.length > 0 ? (
                            <div>{crawl.schemaExclusiveFilterPatterns}</div>
                          ) : (
                            "N/A"
                          )}
                        </Col>
                      </Row>
                    )}

                    {datasourceType === "AzureDataLake" ? (
                      <Row className="py-2">
                        <Col>
                          <div className="label-con-detail">Container Name</div>
                          {/* <div>Select table filter pattern</div> */}
                          {crawl.adlsContainerName?.length > 0 ? (
                            <div>{crawl.adlsContainerName}</div>
                          ) : (
                            "N/A"
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Row className="py-2">
                        <Col>
                          <div className="label-con-detail">
                            Table Filter Pattern
                          </div>
                          {/* <div>Select table filter pattern</div> */}
                          {crawl.tableExclusiveFilterPatterns?.length > 0 ? (
                            <div>{crawl.tableExclusiveFilterPatterns}</div>
                          ) : (
                            "N/A"
                          )}
                        </Col>
                      </Row>
                    )}

                    {datasourceType === "AzureDataLake" && (
                      <Row className="py-2">
                        <Col>
                          <div className="label-con-detail">Dataset Path</div>
                          <div>
                            {/* <Flex gap="small" vertical> */}
                            {/* <Progress percent={100} /> */}
                            {/* <span>N/A</span> */}
                            {/* </Flex> */}
                            {crawl.adlsDatasetPath?.length > 0 ? (
                              <div>{crawl.adlsDatasetPath}</div>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}

                    {datasourceType === "AzureDataLake" && (
                      <Row className="py-2">
                        <Col>
                          <div className="label-con-detail">File Format</div>
                          {/* <div>Select table filter pattern</div> */}
                          {crawl.adlsFileFormat?.length > 0 ? (
                            <div>{crawl.adlsFileFormat}</div>
                          ) : (
                            "N/A"
                          )}
                        </Col>
                      </Row>
                    )}

                    <Row className="py-2">
                      <Col>
                        <div className="label-con-detail">Crawl Status</div>
                        <div>
                          {loadingRunStatus ? (
                            <>
                              <div>
                                Fetching data please wait...{" "}
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    color: "#d3d3d3",
                                  }}
                                >
                                  <CircularProgress
                                    color="inherit"
                                    sx={{ margin: "10% auto" }}
                                  />
                                </Box>
                              </div>
                            </>
                          ) : (
                            <div>
                              <div
                                className={getBlinkerClass(
                                  runningStatus.status
                                )}
                              >
                                <span className="tooltip-text">
                                  Task Completed:{" "}
                                  {runningStatus.tasksCompleted || 0}
                                </span>
                              </div>
                              {runningStatus.status}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <div>
                <Row>
                  <Col className="mt-5 fs-5 text-center">
                    <div>No records found. </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
      <DeleteCrawlModal
        handleDeleteCrawl={handleDeleteCrawl}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        crawl={moreData}
      />
    </>
  );
};

export default ConnectionDetail;
