import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AuthContext } from "../../../AuthContext"
import { useMsal } from "@azure/msal-react"
import { protectedResources } from "../../../msalConfig"
import axios from "axios"
import { Card } from "antd"
import {
  Box,
  CardActionArea,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material"
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

function Utilization() {
  const { getToken, token, error } = useContext(AuthContext)
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  }
  const { datasetFQN } = useParams()

  const TELEMETRY_API = `https://telemetry-api.gleafink.com:443/metainsights/telemetry/util/users`
  // postgres.postgres-db-svc.postgres.svc.cluster.local.5432.adventureworks.purchasing.vendor

  const [isFetching, setIsFetching] = useState(false)
  const [telemetryCountData, setTelemetryCountData] = useState()
  const [telemetryHistoricalData, setTelemetryHistoricalData] = useState([])
  const [telemetryDatasetsData, setTelemetryDatatsetsData] = useState([])
  const [highestUser, setHighestUser] = useState("")

  const getTelemetryCountdata = async (token) => {
    setIsFetching(true)
    axios
      .get(
        `${TELEMETRY_API}/count/{datasetId}?datasetId=${datasetFQN.toLowerCase()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        let maindata = res.data
        // console.log("Utilization::", maindata);
        setTelemetryCountData(maindata)
        setIsFetching(false)
      })
      .catch((error) => {
        console.log("Telemetry count fetch error", error)
        setIsFetching(false)
      })
  }

  const getTelemetryHistorydata = async (token) => {
    setIsFetching(true)
    axios
      .get(
        `${TELEMETRY_API}/count/historical/{datasetId}?datasetId=${datasetFQN.toLowerCase()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        let maindata = res.data
        for (let i = 0; i < maindata.length; i++) {
          let cuurDay = new Date(maindata[i].day)
          maindata[i]["day"] = cuurDay.toUTCString().slice(5, 11)
        }
        // console.log("Utilization::", maindata);
        setTelemetryHistoricalData(maindata)
        setIsFetching(false)
      })
      .catch((error) => {
        console.log("Telemetry history fetch error", error)
        setIsFetching(false)
      })
  }

  const getTelemetryDatasetdata = async (token) => {
    setIsFetching(true)
    axios
      .get(
        `${TELEMETRY_API}/datasets/{datasetId}?datasetId=${datasetFQN.toLowerCase()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        let maindata = res.data
        // console.log("Utilization::", maindata);
        setTelemetryDatatsetsData(maindata)
        let countHigh = maindata[0].count
        setHighestUser(maindata[0].user)
        for (let i = 0; i < maindata.length; i++) {
          if (maindata[i].count > countHigh) {
            setHighestUser(maindata[i].user)
          }
        }
        setIsFetching(false)
      })
      .catch((error) => {
        console.log("Telemetry datsets fetch error", error)
        setIsFetching(false)
      })
  }

  const callapiwithtoken = async (callback) => {
    ;(async () => await getToken())()
    if (token) {
      await callback(token)
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken
        await callback(rtoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (datasetFQN) {
      callapiwithtoken(getTelemetryCountdata)
      callapiwithtoken(getTelemetryHistorydata)
      callapiwithtoken(getTelemetryDatasetdata)
    }
  }, [datasetFQN])

  return (
    <div style={{ height: "58vh", overflow: "auto" }}>
      {isFetching ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            {telemetryCountData === 0 && (
              <Card>
                <CardActionArea>
                  <CardContent>
                    {/* <Typography variant="h5" component="div">
                      {telemetryCountData}
                    </Typography> */}
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Utilization data is not available.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            )}
            {telemetryCountData !== 0 && (
              <Card>
                <Typography variant="h5" component="div">
                  {telemetryCountData}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  User Query in Last 1 Month
                </Typography>
              </Card>
            )}
            {highestUser !== "" && (
              <Card>
                <Typography variant="h5" component="div">
                  {highestUser}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Frequently Queried User
                </Typography>
              </Card>
            )}
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ height: "340px", width: "45%" }}>
              {telemetryCountData !== 0 && (
                <div
                  style={{
                    textAlign: "left",
                    margin: "2rem 0 0 2rem",
                    padding: "0.4rem 0 0 0.8rem",
                    height: "80px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    User Query Trend
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Showing User Query Count for last 2 weeks
                  </Typography>
                </div>
              )}
              {telemetryCountData !== 0 && (
                <ResponsiveContainer
                  width="80%"
                  height="100%"
                  style={{ display: "flex" }}
                >
                  <AreaChart
                    width={500}
                    height={400}
                    data={telemetryHistoricalData}
                    margin={{
                      top: 15,
                      right: 5,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="count"
                      stroke="#1890ff"
                      fill="#E6F4FF"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </div>
            <div style={{ height: "340px", width: "45%" }}>
              {telemetryCountData !== 0 && (
                <div
                  style={{
                    textAlign: "left",
                    margin: "2rem 0 0 2rem",
                    padding: "0.4rem 0 0 0.8rem",
                    height: "80px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Top Users
                  </Typography>
                </div>
              )}
              {telemetryCountData !== 0 && (
                <ResponsiveContainer
                  width="100%"
                  height="100%"
                  style={{ display: "flex" }}
                >
                  <BarChart
                    width={500}
                    height={300}
                    data={telemetryDatasetsData}
                    layout="horizontal"
                    margin={{
                      top: 15,
                      right: 5,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="user" tick={false} />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                      dataKey="count"
                      fill="#E6F4FF"
                      activeBar={<Rectangle fill="#1890ff" stroke="#E6F4FF" />}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Utilization
