import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
} from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
// import CircularLoader from "../../components/NewComponents/Atoms/CircularLoader";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";
import PauseCircleOutlineTwoToneIcon from "@mui/icons-material/PauseCircleOutlineTwoTone";
// import { data } from "../../components/reactTable/makeData";
import { userRequestMartbuilder } from "../../serverConfig";
import { Link } from "react-router-dom";
import { AddCircleOutline } from "@mui/icons-material";
// import DashBoardTabs from "../dashboard/DashBoardTabs";
import { useValueDCT } from "../../context/ContextProviderDCT";
import DeleteModal from "../../components/NewComponents/Atoms/DeleteModal";
// import StatusModal from "../../components/statusModal/StatusModal";
import { AuthContext } from "../../AuthContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MetaDataCrawlInfoModal from "../../components/NewComponents/MetaDataCrawl/metadata_Crawl_Info/MetaDataCrawlInfoModal";
import useWindowDimensions from "../../components/NewComponents/Atoms/useWindowDimensions";

const ManageMetadataCrawls = () => {
  const [jobs, setJobs] = useState([]);
  const [tableData, setTableData] = useState(() => jobs);
  const [rowData, setRowData] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //show-hide metadata crawl info modal
  const [showMetaDataCrawlsInfo, setShowMetaDataCrawlsInfo] = useState(false);
  const [metaDataCrawlsData, setMetaDataCrawlsData] = useState();
  const navigate = useNavigate();
  const { getToken, token, error } = useContext(AuthContext);

  const { height, width } = useWindowDimensions();
  const rowValue = height > 570 ? 10 : 5;

  const {
    state: { editMetaDataCrawlId, editMetaDataCrawlFqn },
    dispatch,
  } = useValueDCT();
  // console.log("Current context state:", editMetaDataCrawlId)
  useEffect(() => {
    // (async () => await getToken())();
  }, []);

  // Fetching Jobs
  const fetchJobs = async () => {
    dispatch({ type: "START_CIRCULAR_LOADING" });
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/metadatacrawl/configs/all`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setJobs(response.data);
      // console.log(response.data);
      dispatch({ type: "END_CIRCULAR_LOADING" });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchJobs();
    return () => {
      ourRequest.cancel();
    };
  };

  // Fetching single metaDataCrawls for info modal
  const fetchSingleMetaDataCrawlsInfo = async (id) => {
    dispatch({ type: "START_CIRCULAR_LOADING" });
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/metadatacrawl/configs/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setMetaDataCrawlsData(response.data);
      // console.log(response.data, "setMetaDataCrawlsData");
      dispatch({ type: "END_CIRCULAR_LOADING" });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    (async () => await getToken())();
    let timeoutId;
    const fetchDataWithDelay = () => {
      timeoutId = setTimeout(() => {
        fetchJobs();
      }, 900);
    };
    fetchDataWithDelay();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate, tableData, token]);

  const handleEditMetaData = (editMetaDataCrawlRowValue) => {
    if (editMetaDataCrawlRowValue) {
      const { id, fqn } = editMetaDataCrawlRowValue;

      if (id !== undefined && fqn !== undefined) {
        localStorage.setItem("SET_EDIT_METADATACRAWL_ID", String(id));
        localStorage.setItem("SET_EDIT_METADATACRAWL_FQN", String(fqn));
        dispatch({
          type: "SET_EDIT_METADATACRAWL_ID",
          payload: id,
        });
        dispatch({
          type: "SET_EDIT_METADATACRAWL_FQN",
          payload: fqn,
        });
      } else {
        console.warn(
          "ID or FQN is undefined, not storing in localStorage nor navigating"
        );
      }
    } else {
      console.warn("editMetaDataCrawlRowValue is undefined");
    }
  };

  const handleDeleteModalToggle = (row) => {
    // console.log(row);
    setOpenDeleteModal(!openDeleteModal);
    setRowData(row);
  };

  const handleInfoModal = (id) => {
    // console.log(id);
    fetchSingleMetaDataCrawlsInfo(id);
    setShowMetaDataCrawlsInfo(!showMetaDataCrawlsInfo);
  };

  const handleDeleteRow = useCallback(
    (row) => {
      // console.log(row.original);
      let metadataConfigId = row.original.id;
      // console.log("metadataConfigId ID to delete:", metadataConfigId);
      if (metadataConfigId) {
        const ourRequest = axios.CancelToken.source();
        const deleteMetadataConfig = async () => {
          try {
            let response = await userRequestMartbuilder.delete(
              `/metadatacrawl/configs/${metadataConfigId}`,
              {
                headers: {
                  // Define your custom headers here
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                CancelToken: ourRequest.token,
              }
            );
            setTableData((prevData) => {
              return prevData.filter(
                (item) => item.metadataConfigId !== metadataConfigId
              );
            });
            // console.log(response.data)
            // dispatch({ type: "END_CIRCULAR_LOADING" })
          } catch (err) {
            if (axios.isCancel(err)) {
              return;
            } else {
              console.error(err);
            }
          }
        };
        deleteMetadataConfig();
      }
      setOpenDeleteModal(false);
    },
    [tableData]
  );

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      header: "Crawl Name",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: "datasourceConnectionFqn",
      header: "Datasource Fqn",
      size: 140,
    },
    {
      accessorKey: "datasourceType",
      header: "Source Type",
      size: 140,
    },
    {
      accessorKey: "crawlType",
      header: "Crawl Type",
      size: 80,
    },
    {
      accessorKey: "cronSchedule",
      header: "Schedule",
      size: 40,
    },
    // {
    //   accessorKey: "dagName",
    //   header: "Dag Name",
    //   size: 80,
    // },
    // {
    //   accessorKey: "status",
    //   header: "Run Status",
    //   size: 70,
    // Cell: ({ cell }) => {
    //   const [runs, setRuns] = useState();
    //   const [isLoading, setIsLoading] = useState(false);
    //   const [openStatusModal, setOpenStatusModal] = useState(false);
    //   const [status, setStatus] = useState();
    //   const handleOpenStatusModal = (item) => {
    //     // console.log(item);
    //     setStatus(item);
    //     setOpenStatusModal(!openStatusModal);
    //   };
    //   const dag_Name = [cell.row.original.dagName];
    //   // console.log(cell.row.original);
    //   useEffect(() => {
    //     const ourRequest = axios.CancelToken.source();
    //     setIsLoading(true);
    //     const fetchJobs = async () => {
    //       try {
    //         let response = await userRequestMartbuilder.post(
    //           `/jobs/dag/runs`,
    //           dag_Name,
    //           {
    //             headers: {
    //               // Define your custom headers here
    //               Authorization: `Bearer ${token}`,
    //               "Content-Type": "application/json",
    //             },
    //             CancelToken: ourRequest.token,
    //           }
    //         );
    //         setRuns(response.data[dag_Name].dagRuns?.dag_runs);
    //         // console.log(response.data, "cc");
    //         setIsLoading(false);
    //       } catch (err) {
    //         if (axios.isCancel(err)) {
    //           return;
    //         } else {
    //           console.error(err);
    //         }
    //       }
    //     };
    //     fetchJobs();
    //     return () => {
    //       ourRequest.cancel();
    //     };
    //   }, []);
    //   if (isLoading) {
    //     return (
    //       <>
    //         <CircularLoader size={25} />
    //       </>
    //     );
    //   }
    //   return (
    //     <>
    //       {runs?.map((item) =>
    //         item.state === "success" ? (
    //           <Tooltip key={item.dag_run_id} title={item.execution_date}>
    //             <CheckCircle
    //               color="success"
    //               sx={{
    //                 p: "0px 0.6px",
    //                 cursor: "pointer",
    //               }}
    //               onClick={() => handleOpenStatusModal(item)}
    //             />
    //           </Tooltip>
    //         ) : (
    //           <Tooltip key={item.dag_run_id} title={item.execution_date}>
    //             <Cancel
    //               color="error"
    //               sx={{ p: "0px 0.6px", cursor: "pointer" }}
    //               onClick={() => handleOpenStatusModal(item)}
    //             />
    //           </Tooltip>
    //         )
    //       )}
    //       {openStatusModal && (
    //         <StatusModal
    //           onOpen={handleOpenStatusModal}
    //           boolean={openStatusModal}
    //           Item={status}
    //         />
    //       )}
    //     </>
    //   );
    // },
    // },
  ]);

  return (
    <>
      {/* <DashBoardTabs /> */}
      <div
        style={{
          width: "80%",
          margin: "0px auto",
          paddingTop: "70px",
        }}
      >
        <h2 style={{ marginBottom: "20px", textAlign: "left" }}>
          Metadata Crawls
        </h2>
        {/* <Link to={"/metadataCrawls/new"}> */}
        {/* <Button
            variant="outlined"
            size="small"
            sx={{
              color: "#247ba0",
              borderColor: "#247ba0",
              fontSize: "9.5px",
              marginBottom: "20px",
              // position: "relative",
            }}
            startIcon={<AddCircleOutline />}
          >
            Add Crawl
          </Button> */}
        <button
          className="rounded-md job-btn btn nar-wid mar-bottom"
          style={{ marginBottom: "1.4rem" }}
          // variant="outlined"
          // size="small"
          // sx={{
          //   color: "#247ba0",
          //   borderColor: "#247ba0",
          //   fontSize: "9.5px",
          //   marginBottom: "20px",
          // position: "relative",
          // }}
        >
          <Link to={"/publishMetadata"} style={{ color: "#ffffff" }}>
            <AddCircleOutline />
            <span>Add Crawl</span>
          </Link>
        </button>
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
                sx: { padding: "0" },
              },
              muiTableBodyCellProps: {
                sx: { padding: "0" },
              },
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              paddingLeft: "2.8rem",
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              paddingLeft: "2.8rem",
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: rowValue, pageIndex: 0 },
          }}
          columns={columns}
          data={jobs}
          positionActionsColumn="last"
          editingMode="modal"
          enableColumnOrdering
          enableEditing
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex" }}>
              <Tooltip arrow placement="left" title="Info">
                <Link to={`/metadataCrawls/edit`}>
                  <IconButton onClick={() => handleEditMetaData(row?.original)}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Link>
              </Tooltip>
              {/* <Tooltip arrow placement="left" title="Info">
                <IconButton onClick={() => handleInfoModal(row?.original?.id)}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip arrow placement="right" title="Pause">
                <IconButton
                // onClick={() => handleDeleteRow(row)}
                >
                  <PauseCircleOutlineTwoToneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  // onClick={() => handleDeleteRow(row)}
                  onClick={() => handleDeleteModalToggle(row)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
      <DeleteModal
        handleDeleteRow={handleDeleteRow}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        row={rowData}
      />
      {showMetaDataCrawlsInfo && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <MetaDataCrawlInfoModal
            data={metaDataCrawlsData}
            onOpen={() => setShowMetaDataCrawlsInfo(false)}
            boolean={showMetaDataCrawlsInfo}
          />
        </Box>
      )}
    </>
  );
};

export default ManageMetadataCrawls;
