import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  TextField,
} from "@mui/material";
import axios from "axios";
import { DATABOOK_API } from "../../serverConfig";
import { AuthContext } from "../../AuthContext";

const LikeDislikeModal = ({ open, onClose, like, data, getallcolumndata }) => {
  const { getToken, token, error } = useContext(AuthContext);

  const [updateLikeReason, setUpdateLikeReason] = useState("");

  const putLikeDislikeInteraction = (token, likeDislike) => {
    if (updateLikeReason !== "") {
      var requestbody = {
        datasetFqn: data.fqn,
        interactionType: likeDislike,
        reason: updateLikeReason,
      };
    }

    axios
      .patch(`${DATABOOK_API}/datasets/user/interaction`, requestbody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        getallcolumndata(token)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      {like === "like" && (
        <DialogTitle id="alert-dialog-title">
          {`Please tell us what you ${like}d this dataset "${data?.name}"`}
        </DialogTitle>
      )}
      {like === "dislike" && (
        <DialogTitle id="alert-dialog-title">
          {`Please tell us what can be improved in "${data?.name}"`}
        </DialogTitle>
      )}
      <DialogContent>
        {like === "dislike" && (
          <Box mt={2}>
            {/* <Typography variant="h6" align="center">
            Maestro - Unified In-House Data and Analytics Platform
          </Typography> */}
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Usefulness")}
            >
              Usefulness
            </p>
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Informative")}
            >
              Informative
            </p>
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Structure")}
            >
              Structure
            </p>
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Details and precision")}
            >
              Details and precision
            </p>
            <Box mt={2}>
              <TextField
                id="outlined-multiline-static"
                // label="Reason"
                placeholder="Tell us more about how this asset can be improved."
                multiline
                rows={4}
                defaultValue={updateLikeReason && updateLikeReason}
                style={{ margin: "0", fontSize: "14px", width: "96%" }}
                onChange={(e) => {
                  setUpdateLikeReason(e.target.value);
                }}
              />
            </Box>
          </Box>
        )}
        {like === "like" && (
          <Box mt={2}>
            {/* <Typography variant="h6" align="center">
            Maestro - Unified In-House Data and Analytics Platform
          </Typography> */}
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Ease of use")}
            >
              Ease of use
            </p>
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Informative")}
            >
              Informative
            </p>
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Well structured")}
            >
              Well structured
            </p>
            <p
              className="dhc-header-message-classi m-1"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setUpdateLikeReason("Detailed and precise")}
            >
              Detailed and precise
            </p>
            <Box mt={2}>
              <TextField
                id="outlined-multiline-static"
                // label="Reason"
                placeholder="Tell us more about how this asset can be improved."
                multiline
                rows={4}
                defaultValue={updateLikeReason && updateLikeReason}
                style={{ margin: "0", fontSize: "14px", width: "96%" }}
                onChange={(e) => {
                  setUpdateLikeReason(e.target.value);
                }}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            putLikeDislikeInteraction(token, like);
            onClose();
            setUpdateLikeReason("");
          }}
          style={{ color: "#1890ff" }}
        >
          Accept
        </Button>
        <Button
          onClick={() => {
            onClose();
            setUpdateLikeReason("");
          }}
          style={{ color: "rgba(0,0,0,0.87)" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LikeDislikeModal;
