import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TELEMETRY_API } from "../../../serverConfig";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../../../AuthContext";
import { protectedResources } from "../../../msalConfig";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

function Activities() {
  const { datasetFQN } = useParams();
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const ACTIVITIY_API = `https://telemetry-api.gleafink.com:443/metainsights/telemetry/dataasset/auditlogs/{datasetId}?dataAssetFqn=`;
  const [isFetching, setIsFetching] = useState(false);
  const [activityData, setActivityData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getActivityData = async (token) => {
    setIsFetching(true);
    axios
      .get(ACTIVITIY_API + `${datasetFQN}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data;
        for (let i = 0; i < maindata.length; i++) {
          let formatedDay = new Date(maindata[i].modifiedAt);
          maindata[i]["modifiedAt"] = formatedDay.toUTCString();
        }
        // console.log("Actitvity data", maindata);
        setActivityData(maindata);
      })
      .catch((error) => {
        console.log("Activities Error", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      (async () => await getToken())();
      if (token) {
        await getActivityData(token);
      }
      if (error) {
        try {
          const rtoken = (await instance.acquireTokenRedirect(request))
            .accessToken;
          await getActivityData(rtoken);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [getToken, token, datasetFQN]);
  return (
    <div style={{ height: "56vh" }}>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: "0.7rem",
          minWidth: 650,
          maxHeight: "56vh",
          overflow: "auto",
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#d3d3d3" }}>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Modified On</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Resource</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityData?.map((act, idx) => (
              <TableRow
                hover
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {act.user}
                </TableCell>
                <TableCell>{act.modifiedAt}</TableCell>
                <TableCell>{act.action}</TableCell>
                <TableCell>{act.resource}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={activityData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default Activities;
