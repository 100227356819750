import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  TextField,
} from "@mui/material";
import { DATABOOK_API } from "../../serverConfig";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Select } from "antd";
import { AuthContext } from "../../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../msalConfig";

const DDSchemaTagsEditModal = ({ open, onClose, getallcolumndata }) => {
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const [updatedTags, setUpdatedTags] = useState([]);
  const [columnsTag, setColumnTags] = useState();

  const handleChange = (value) => {
    console.log(value)
    let output = [];
    value.map((item) => {
      output.push(...columnsTag.filter((x) => x.value === item));
    });
    setUpdatedTags(
      output.map((item) => ({ tagName: item.label, fqn: item.value }))
    );
  };

  const fetchAllTags = (token) => {
    axios
      .get(`${DATABOOK_API}/tag-classification/tags`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let result = res.data?.tags.map((item) => {
          if (item.fqn !== null) {
            return { value: item.fqn, label: item.tagName };
          }
        });
        setColumnTags(result);
      });
  };

  const columnTagsFielsdSave = (token) => {
    // if (selectedrow?.columnid !== "description") {
    //   if (validateFields() &&
    //     rsSelectedSensitivity?.length === 0) {
    //     toast.info("Security classificaton or sensitivity cannot be blank. ", {
    //       position: "top-right",
    //       autoClose: 6000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     return false;
    //   }
    // }

    // handleDataTypeForEmptyValue(selectedrow?.columnid);
    // const inprogresstoast = toast.info("Updating description. Please wait...");
    let requestbody = "";
    // if (selectedrow?.columnid === "description") {
    requestbody = {
      id: open.dataset_id,
      columns: [
        {
          name: open.name,
          tags: updatedTags,
        },
      ],
    };
    // } else {
    //   requestbody = {
    //     id: selectedrow?.rowdata.dataset_id,
    //     columns: [
    //       {
    //         name: open?.name,
    //         securityClassification: rsSelectedClassification?.value === undefined ? "" : rsSelectedClassification?.value,
    //         sensitivity: rsSelectedSensitivity?.map((item) => item.value)
    //       },
    //     ],
    //   }
    // }

    axios
      .put(`${DATABOOK_API}/datasets/dataset-columns`, requestbody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        getallcolumndata(token);
        // toast.update(inprogresstoast, {
        //   render: "Column field updated successfully.",
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 6000,
        //   closeButton: true,
        //   isLoading: false
        // });
        onClose();
      })
      .catch((error) => {
        if (error.response.status == 401) {
          // toast.update(inprogresstoast, {
          //   render: error.response.data,
          //   type: toast.TYPE.ERROR,
          //   autoClose: 6000,
          //   closeButton: true,
          //   isLoading: false
          // });
          onClose();
        } else {
          // toast.update(inprogresstoast, {
          //   render: "Some went wrong while updating columns field.",
          //   type: toast.TYPE.ERROR,
          //   autoClose: 6000,
          //   closeButton: true,
          //   isLoading: false
          // });
        }
      });
    onClose();
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };


  useEffect(() => {
    callapiwithtoken(fetchAllTags);
  }, [instance, open]);
console.log(open)
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        {`Tags for "${open.name}"`}
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          {/* <TextField
            id="outlined-multiline-static"
            label="Tags"
            multiline
            rows={4}
            defaultValue={
              open?.tags && open?.tags
            }
            style={{ margin: "0", fontSize: "14px", width: "100%" }}
            onChange={(e) => {
              setUpdatedTags(e.target.value);
            }}
          /> */}
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            backdrop="static"
            keyboard={false}
            placeholder="Please select tags."
            // defaultValue={props.fieldDefaultValues}
            defaultValue={open?.tags?.map((item) => ({
              label: item.tags,
              value: item.fqn,
            }))}
            onChange={handleChange}
            options={columnsTag}
            dropdownStyle={{ zIndex: 99999 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CheckIcon
          color="primary"
          style={
            updatedTags !== ""
              ? { cursor: "pointer", margin: "0.4rem 1rem" }
              : { cursor: "not-allowed", margin: "0.4rem 1rem" }
          }
          onClick={() => {
            updatedTags !== "" && columnTagsFielsdSave(token);
          }}
        />
        <CloseIcon
          color="action"
          style={{ cursor: "pointer", margin: "0.4rem 1rem" }}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DDSchemaTagsEditModal;
