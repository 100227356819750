import React from "react";
import GlossaryDesc from "./GlossaryDesc";
import { Row, Col } from "react-bootstrap";
import { Button } from "antd";
import GlossaryGuide from "../GlossaryGuide";

const GlossaryDescHeader = (props) => {
  const {
    descriptionheader,
    individualdatafetch,
    handleDeleteGlossaryShow,
    glossaryselect,
    handleCreatetagShow,
  } = props;
  return (
    <>
      {" "}
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <GlossaryDesc
            descriptionheader={descriptionheader}
            individualdatafetch={individualdatafetch}
          />{" "}
        </Col>
        <Col>
          <div className="form__view">
            <div className="tags__btn py-3">
              <GlossaryGuide />
              {glossaryselect?.length > 0 ? (
                <Button className="enabled_btn" onClick={handleCreatetagShow}>
                  Add Term
                </Button>
              ) : (
                <Button disabled>Add Term</Button>
              )}
              {/* {glossaryselect?.length > 0 ? (
                <Button
                  className="deleted__btn"
                  onClick={handleDeleteGlossaryShow}
                >
                  Delete Glossary
                </Button>
              ) : (
                <Button disabled>Delete Glossary</Button>
              )} */}
            </div>{" "}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GlossaryDescHeader;
