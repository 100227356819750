import React from "react";
import PieGraph from "../Charts/PieGraph";
import { Card } from "antd";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const DataQualityPieCard = ({ transformPieData, transformPieTableData }) => {
  return (
    <Card style={{ width: 300, height: 390 }}>
      {transformPieData && (
        <>
          <p style={{ fontSize: "16px", fontWeight: "600" }}>
            {transformPieData[0].name}
          </p>
          <PieGraph data={transformPieData} />
        </>
      )}

      {transformPieTableData && (
        // <div>
        //   {/* <p style={{ padding: "0px", margin: "0px" }}>Attribute : DQ%</p> */}
        //   {Object.entries(transformPieTableData.metricsGroup).map(
        //     ([key, value]) => (
        //       <p style={{ padding: "0px", margin: "0px" }}>
        //         {key}: {JSON.stringify(value)}
        //       </p>
        //     )
        //   )}
        // </div>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: "100%" }}
        >
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "#E6F4FF" }}>
              <TableRow>
                <TableCell>Attribute</TableCell>
                <TableCell>DQ %</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(transformPieTableData.metricsGroup).map(
                ([key, value]) => (
                  <TableRow
                    key={key}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  );
};

export default DataQualityPieCard;
