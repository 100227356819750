import React, { useCallback, useEffect, useContext, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import "./MIModalsTable.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
// import MaerskModalTableEdit from "../ManageProjectEditModal/MaerskModelTableEdit";
import MIModalTableEdit from "../ManageProductEditModal/MIModalTableEdit";
import ProductTableEdit from "../ManageProductEditModal/ProductTableEdit";
// import ApplicationTableEdit from "../ManageProductEditModal/ApplicationTableEdit";
// import ResourceTableEdit from "../ManageProductEditModal/ResourceTableEdit";
// import PermissionTableEdit from "../ManageProductEditModal/PermissionTableEdit";
// import RoleTableEdit from "../ManageProductEditModal/RoleTableEdit";
// import UserTableEdit from "../ManageProductEditModal/UserTableEdit";
// import RoleToUserTableEdit from "../ManageProductEditModal/RoleToUserTableEdit";
// import UserDetailsModal from "../manageAuthorizationModal/UserDetailsModal";
// import MIModal from "./MIModal/MIModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Delete, Edit, Padding } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteModal from "../Atoms/DeleteModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../Atoms/useWindowDimensions";
import { AuthContext } from "../../../AuthContext";
import { userRequestMartbuilder } from "../../../serverConfig";
import WarningPopUp from "../Atoms/WarningPopUp";

const MIModalsTable = ({
  data,
  tableType,
  columns,
  prop,
  fetchMaerskModalData,
  fetchBusinessTableData,
  fetchDomainTableData,
  fetchProductTableData,
  fetchPermissionsData,
  fetchUserTableData,
  fetchRolesData,
  fetchApplicationsData,
  fetchResourcesData,
  fetchUserRolesData,
}) => {
  const { getToken, token, error } = useContext(AuthContext);
  const navigate = useNavigate();

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openProductUpdateModal, setOpenProductUpdateModal] = useState(false);

  const [openApplicationUpdateModal, setOpenApplicationUpdateModal] =
    useState(false);
  const [openResourceUpdateModal, setOpenResourceUpdateModal] = useState(false);
  const [openPermissionUpdateModal, setOpenPermissionUpdateModal] =
    useState(false);
  const [openRoleUpdateModal, setOpenRoleUpdateModal] = useState(false);
  const [openUserUpdateModal, setOpenUserUpdateModal] = useState(false);
  const [openRoleToUserUpdateModal, setOpenRoleToUserUpdateModal] =
    useState(false);
  const [selectedAuthRow, setSelectedAuthRow] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // row number controller
  const { height, width } = useWindowDimensions();
  const rowValue = height > 570 ? 10 : 5;

  // for delete modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  // for user permission details modal
  const [updModal, setUPDModal] = useState(false);
  const [emailName, setEmailName] = useState({});

  //related to toastify notification
  const notify = () =>
    toast("Foriegn key constraint violated in the Product tabled.");
  const notifyProduct = () =>
    toast("Datasource Connection with selected Product exists");

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data, openDeleteModal, tableData]);

  //delete the row
  const handleDeleteModalToggle = (row, tableType) => {
    setOpenDeleteModal(!openDeleteModal);
    setRowData(row);
  };

  //edit a row
  const handleEdit = (row, tableType) => {
    console.log("Tabletype", tableType);
    if (tableType === "Products") {
      setOpenProductUpdateModal(!openProductUpdateModal); // Open the ProductTableEdit modal
      setSelectedRow(row); // Set the selected row for editing
      return;
    }
    if (tableType === "ApplicationData") {
      setOpenApplicationUpdateModal(!openApplicationUpdateModal); // Open the PermissionTableEdit modal
      setSelectedAuthRow(row); // Set the selected row for editing
      setSelectedRow(null);
      return;
    }
    if (tableType === "ResourceData") {
      setOpenResourceUpdateModal(!openResourceUpdateModal); // Open the PermissionTableEdit modal
      setSelectedAuthRow(row); // Set the selected row for editing
      setSelectedRow(null);
      return;
    }
    if (tableType === "PermissionData") {
      setOpenPermissionUpdateModal(!openPermissionUpdateModal); // Open the PermissionTableEdit modal
      setSelectedAuthRow(row); // Set the selected row for editing
      setSelectedRow(null);
      return;
    }
    if (tableType === "RoleData") {
      setOpenRoleUpdateModal(!openRoleUpdateModal); // Open the RoleTableEdit modal
      setSelectedAuthRow(row); // Set the selected row for editing
      setSelectedRow(null);
      // navigate("/manageAuthorization/editRole")
      return;
    }
    if (tableType === "UserData") {
      setOpenUserUpdateModal(!openUserUpdateModal); // Open the UserTableEdit modal
      setSelectedAuthRow(row); // Set the selected row for editing
      setSelectedRow(null);
      return;
    }
    if (tableType === "UserToRoleData") {
      setOpenRoleToUserUpdateModal(!openRoleToUserUpdateModal); // Open the RoleToUserTableEdit modal
      setSelectedAuthRow(row); // Set the selected row for editing
      setSelectedRow(null);
      return;
    } else {
      setOpenUpdateModal(!openUpdateModal); // Open the regular update modal
      setSelectedRow(row); // Set the selected row for editing
      return;
    }
  };

  //DELETE API call for deleting the data from the maersk  modal table
  const deleteMaerskModalTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/orgmodel/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchMaerskModalData();
      } else {
        alert("Foriegn key constraint violated in the Product table");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        notify();
        setShowWarningPopup(true);
        console.error(err);
      }
    }
  };

  //DELETE API call for deleting the data from the business table
  const deleteBusinessTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/business/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchBusinessTableData();
      } else {
        alert("Foriegn key constraint violated in the Product table");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        notify();
        setShowWarningPopup(true);
        console.error(err);
      }
    }
  };

  //DELETE API call for deleting the data from the domain table
  const deleteDomainTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(`/platform/${dataId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchDomainTableData();
      } else {
        alert("Foriegn key constraint violated in the Product table");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        notify();
        setShowWarningPopup(true);
        console.error(err);
      }
    }
  };

  //DELETE API call for deleting the data from the product table
  const deleteProductTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(`/product/${dataId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchProductTableData();
      } else {
        alert("Datasource Connection with selected Product exists");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        notifyProduct();
        setShowWarningPopup(true);
        console.error(err);
      }
    }
  };

  //DELETE API call for deleting the data from the applicationTable
  const deleteApplicationTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/aatapplications/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchApplicationsData();
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };
  
  //DELETE API call for deleting the data from the applicationTable
  const deleteResourceTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/aatapplications/resources/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchResourcesData();
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  //DELETE API call for deleting the data from the permissionTable
  const deletePermissionTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/aatpermissions/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchPermissionsData();
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  //DELETE API call for deleting the data from the roleTable
  const deleteRoleTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/aatroles/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchRolesData();
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  //DELETE API call for deleting the data from the userTable
  const deleteUserTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/aatusers/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchUserTableData();
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };
  
  //DELETE API call for deleting the data from the userTable
  const deleteRoleToUserTableData = async (row, dataId) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/aatusers/roles/${dataId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      const { status, data } = response;
      if (status === 200) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => {
            return item.id !== dataId;
          })
        );
        fetchUserRolesData();
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  const handleDeleteRow = useCallback(
    async (row, tableType) => {
      let dataId = row?.original?.id;

      try {
        switch (tableType) {
          case "MaerskModels":
            deleteMaerskModalTableData(row, dataId);
            break;
          case "Business":
            await deleteBusinessTableData(row, dataId);
            break;
          case "Domain":
            await deleteDomainTableData(row, dataId);
            break;
          case "Products":
            await deleteProductTableData(row, dataId);
            break;
          case "ApplicationData":
            await deleteApplicationTableData(row, dataId);
            break;
          case "ResourceData":
            await deleteResourceTableData(row, dataId);
            break;
          case "PermissionData":
            await deletePermissionTableData(row, dataId);
            break;
          case "RoleData":
            await deleteRoleTableData(row, dataId);
            break;
          case "UserData":
            await deleteUserTableData(row, dataId);
            break;
          case "UserToRoleData":
            await deleteRoleToUserTableData(row, dataId);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error("Error deleting:", error);
        setShowWarningPopup(true);
      }
      setOpenDeleteModal(false);
    },
    [tableData]
  );

    // Fetching values for users dropdown
    const fetchUsersPermissionData = async (email) => {
      const ourRequest = axios.CancelToken.source();
      try {
        let response = await userRequestMartbuilder.get(`/aatusers`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        const emailname = response?.data.filter(
          (data) => data.emailId === email
        );
        setEmailName(emailname);
        setUPDModal(true)
        // console.log(response?.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
      return () => {
        ourRequest.cancel();
      };
    };

  return (
    <div style={{ marginBottom: "35px" }}>
      {openUpdateModal && selectedRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <MIModalTableEdit
            onToggle={handleEdit}
            boolean={openUpdateModal}
            row={selectedRow} // Pass the selected row here
            tableType={tableType}
            fetchMaerskModalData={fetchMaerskModalData}
            fetchBusinessTableData={fetchBusinessTableData}
            fetchDomainTableData={fetchDomainTableData}
            fetchProductTableData={fetchProductTableData}
          />
        </Box>
      )}
      {/* {openApplicationUpdateModal && selectedAuthRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <ApplicationTableEdit
            onToggle={handleEdit}
            boolean={openApplicationUpdateModal}
            row={selectedAuthRow} // Pass the selected row here
            tableType={tableType}
            fetchApplicationsData={fetchApplicationsData}
          />
        </Box>
      )} */}
      {/* {openResourceUpdateModal && selectedAuthRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <ResourceTableEdit
            onToggle={handleEdit}
            boolean={openResourceUpdateModal}
            row={selectedAuthRow} // Pass the selected row here
            tableType={tableType}
            fetchResourcesData={fetchResourcesData}
          />
        </Box>
      )} */}
      {/* {openPermissionUpdateModal && selectedAuthRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <PermissionTableEdit
            onToggle={handleEdit}
            boolean={openPermissionUpdateModal}
            row={selectedAuthRow} // Pass the selected row here
            tableType={tableType}
            fetchPermissionsData={fetchPermissionsData}
          />
        </Box>
      )} */}
      {/* {openRoleUpdateModal && selectedAuthRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <RoleTableEdit
            onToggle={handleEdit}
            boolean={openRoleUpdateModal}
            row={selectedAuthRow} // Pass the selected row here
            tableType={tableType}
            fetchRolesData={fetchRolesData}
          />
        </Box>
      )} */}
      {/* {openUserUpdateModal && selectedAuthRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <UserTableEdit
            onToggle={handleEdit}
            boolean={openUserUpdateModal}
            row={selectedAuthRow} // Pass the selected row here
            tableType={tableType}
            fetchUserTableData={fetchUserTableData}
          />
        </Box>
      )} */}
      {/* {openRoleToUserUpdateModal && selectedAuthRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <RoleToUserTableEdit
            onToggle={handleEdit}
            boolean={openRoleToUserUpdateModal}
            row={selectedAuthRow} // Pass the selected row here
            tableType={tableType}
            fetchUserRolesData={fetchUserRolesData}
          />
        </Box>
      )} */}
      {!selectedAuthRow && selectedRow && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <ProductTableEdit
            onToggle={handleEdit}
            boolean={openProductUpdateModal}
            row={selectedRow}
            tableType="Products"
            fetchProductTableData={fetchProductTableData}
          />
        </Box>
      )}
      {/* </div> */}
      {tableData && tableType !== "auditLogData" && tableType !== "UserToRoleData" && (
        <MaterialReactTable
          muiTableHeadCellProps={{
            // align: "center",
            sx: {
              paddingLeft: "28px",
            },
          }}
          muiTableBodyCellProps={{
            // align:"center",
            sx: {
              paddingLeft: "28px",
            },
          }}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
                sx: {
                  padding: "0",
                },
              },
              muiTableBodyCellProps: {
                align: "center",
                sx: {
                  padding: "0",
                },
              },
              size: 80,
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: 5, pageIndex: 0 },
            columnVisibility: { address: false },
          }}
          columns={columns}
          data={tableData}
          enableColumnFilters={false}
          enableColumnSearch={false}
          enableDensityToggle={false}
          editingMode="modal" //default
          enableColumnOrdering
          enableEditing
          positionActionsColumn="last"
          // onEditingRowSave={handleSaveRowEdits}
          // onEditingRowCancel={handleCancelRowEdits}
          renderRowActions={({ row, table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                paddingLeft: "70px",
              }}
            >
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={() => handleEdit(row, tableType)}
                  className="edit-icon"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="gray"
                  onClick={() => handleDeleteModalToggle(row, tableType)} // Call the handleDeleteRow function
                  className="delete-icon"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      )}
      
      {tableData && tableType === "UserToRoleData" && (
        <MaterialReactTable
          muiTableHeadCellProps={{
            // align: "center",
            sx: {
              paddingLeft: "28px",
            },
          }}
          muiTableBodyCellProps={{
            // align:"center",
            sx: {
              paddingLeft: "28px",
            },
          }}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
                sx: {
                  padding: "0",
                },
              },
              muiTableBodyCellProps: {
                align: "center",
                sx: {
                  padding: "0",
                },
              },
              size: 80,
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: 5, pageIndex: 0 },
            columnVisibility: { address: false },
          }}
          columns={columns}
          data={tableData}
          enableColumnFilters={false}
          enableColumnSearch={false}
          enableDensityToggle={false}
          editingMode="modal" //default
          enableColumnOrdering
          enableEditing
          positionActionsColumn="last"
          // onEditingRowSave={handleSaveRowEdits}
          // onEditingRowCancel={handleCancelRowEdits}
          renderRowActions={({ row, table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                paddingLeft: "70px",
              }}
            >
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={() => {
                    fetchUsersPermissionData(row.original.emailId)
                  }}
                  className="edit-icon"
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={() => handleEdit(row, tableType)}
                  className="edit-icon"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="gray"
                  onClick={() => handleDeleteModalToggle(row, tableType)} // Call the handleDeleteRow function
                  className="delete-icon"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      )}

      {tableData && tableType === "auditLogData" && (
        <MaterialReactTable
          muiTableHeadCellProps={{
            // align: "center",
            sx: {
              paddingLeft: "28px",
            },
          }}
          muiTableBodyCellProps={{
            // align:"center",
            sx: {
              paddingLeft: "28px",
            },
          }}
          // displayColumnDefOptions={{
          //   "mrt-row-actions": {
          //     muiTableHeadCellProps: {
          //       align: "center",
          //       sx: {
          //         padding: "0",
          //       },
          //     },
          //     muiTableBodyCellProps: {
          //       align: "center",
          //       sx: {
          //         padding: "0",
          //       },
          //     },
          //     size: 80,
          //   },
          // }}
          initialState={{
            density: "compact",
            pagination: { pageSize: rowValue, pageIndex: 0 },
            columnVisibility: { address: false },
          }}
          columns={columns}
          data={tableData}
          enableColumnFilters={true}
          enableColumnSearch={false}
          enableDensityToggle={false}
          editingMode="modal" //default
          enableColumnOrdering
          // enableEditing
          // positionActionsColumn="last"
          // onEditingRowSave={handleSaveRowEdits}
          // onEditingRowCancel={handleCancelRowEdits}
          // renderRowActions={({ row, table }) => (
          //   <Box
          //     // sx={{
          //       // display: "flex",
          //       // gap: "10px",
          //       // paddingLeft: "70px",
          //     // }}
          //   >
          //     <Tooltip arrow placement="left" title="Edit">
          //       <IconButton
          //         onClick={() => handleEdit(row, tableType)}
          //         className="edit-icon"
          //       >
          //         <Edit />
          //       </IconButton>
          //     </Tooltip>
          //     <Tooltip arrow placement="right" title="Delete">
          //       <IconButton
          //         color="error"
          //         onClick={() => handleDeleteModalToggle(row, tableType)} // Call the handleDeleteRow function
          //         className="delete-icon"
          //       >
          //         <Delete />
          //       </IconButton>
          //     </Tooltip>
          //   </Box>
          // )}
        />
      )}
      <DeleteModal
        handleDeleteRow={handleDeleteRow}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        row={rowData}
        tableType={tableType}
      />
      {showWarningPopup && <WarningPopUp />}

      {/* {updModal && (
        <UserDetailsModal
          boolean={updModal}
          onOpen={() => setUPDModal(!updModal)}
          email={emailName[0]?.id}
        />
      )} */}
    </div>
  );
};

export default MIModalsTable;
