import React from "react";
import { Form, Modal, Button } from "react-bootstrap";

const DeleteTagForm = (props) => {
  const { deltagshow, handleDelTagClose, handleSubmitDeletetags, tagid } =
    props;
  return (
    <Modal show={deltagshow} onHide={handleDelTagClose} centered>
      <Modal.Header className="del__msg">
        {" "}
        <Modal.Title>
          <span>
            Are you sure you want to delete tag <b>"{tagid}"</b>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form__view" onSubmit={handleSubmitDeletetags}>
          <div className="button_save_close">
            <Button variant="secondary pop__btn" onClick={handleDelTagClose}>
              Close
            </Button>
            <Button type="submit" className="pop__btn enabled_btn">
              Yes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteTagForm;
