import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "antd";
import GuideInfoModal from "./GuideInfoModal";

const Guide = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="secondary" onClick={handleShow} className="enabled_btn1">
        Info
      </Button>
      <GuideInfoModal
        open={show}
        onClose={handleClose}
      />
    </>
  );
};

export default Guide;
