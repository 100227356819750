import React, { useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box, CircularProgress } from "@mui/material";

const Dremio = ({
  connectionData,
  onSubmitFun,
  testConnectionSuccess,
  submissionSuccess,
  showLoading,
  editConnectionCred,
  isEditFlag,
  updatedatasource,
}) => {
  const [connectionCreds, setConnectionCreds] = useState({
    hostname: "",
    personalAccessToken: "",
  });

  useEffect(() => {
    const { hostname, personalAccessToken } = editConnectionCred || {};
    if (isEditFlag) {
      setConnectionCreds({ hostname, personalAccessToken });
    }
  }, [editConnectionCred]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionCreds((prevCreds) => ({
      ...prevCreds,
      [name]: value,
    }));
  };

  const handleSubmit = (isTestConnection) => {
    const formData = {
      ...connectionData,
      connectionCreds,
    };
    if (onSubmitFun) {
      onSubmitFun(formData, isTestConnection);
    }
  };
  const updateDS = (isTestConnection) => {
    const formData = {
      ...connectionData,
      connectionCreds,
    };

    if (isEditFlag) {
      console.log("UPDATE datasource");
      updatedatasource(formData, isTestConnection);
    }
  };

  return (
    <div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="hostname">
            Host Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="hostname"
            name="hostname"
            placeholder="Enter Host Name"
            value={connectionCreds.hostname}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="personalAccessToken">
            Personal Access Token <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="personalAccessToken"
            name="personalAccessToken"
            placeholder="Personal Access Token"
            value={connectionCreds.personalAccessToken}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        {showLoading ? (
          
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "#d3d3d3",
              }}
            >
              <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
            </Box>
        ) : (
          <div className="valign_bottom">
            <button
              className="job-btn btn nar-wid1"
              onClick={() => handleSubmit(true)}
              disabled={testConnectionSuccess}
              // disabled={!testConnectionSuccess || submissionSuccess}
            >
              Check Connection
            </button>
          </div>
        )}
      </div>

      {submissionSuccess && (
        <div className="individualRow align-right">
          <p id="successPara" style={{ paddingLeft: "60%" }}>
            <VerifiedIcon color="primary" />
             Success! Connection to
            the Database is established.
          </p>
        </div>
      )}

      <hr></hr>

      <div className="individualRow" style={{ marginTop: "20px" }}>
        {!testConnectionSuccess && isEditFlag ? (
          <button
            className="job-btn btn"
            type="submit"
            // onSubmit={handleSubmit}
            onClick={() => updateDS(false)}
            // disabled={!testConnectionSuccess}
            disabled={!testConnectionSuccess}
          >
            Update
          </button>
        ) : (
          ""
        )}
        {!testConnectionSuccess && !isEditFlag ? (
          <button
            className="job-btn btn"
            type="submit"
            // onSubmit={handleSubmit}
            onClick={() => handleSubmit(false)}
            // disabled={!testConnectionSuccess}
            disabled={!testConnectionSuccess}
          >
            Update
          </button>
        ) : (
          ""
        )}

        {/* <button
          className="job-btn btn nar-wid"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button> */}
      </div>
    </div>
  );
};

export default Dremio;
