import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";

export const CLASSI_TAG_GUIDE_CONST = {
  CLASSI_HEADER:
    " This page basically consists of two different sections Tag & Classification by clicking on any Classification the Description and Classification Name with the number of tags associated will be seen above the Tabular View.",
  CLASSI_STEPS: [
    {
      list: "Create a Classification by clicking on Create Classification button in the left Classification Name is mandatory.",
    },
    {
      list: " All the Classification will be listed on the left after creating it.",
    },
    {
      list: "Edit a Classification by clicking on the icon next to the Classification Name.",
    },
    {
      list: " For Deleting a classification click on the Button Delete Classification on top right corner.",
    },
  ],
  TAG_STEPS: [
    {
      list: "To create a Tag, click on Add Tag Button on the right where you can choose classification name from the drop down where (Tag Name) is mandatory.",
    },
    {
      list: "The tags will be listed on the table.",
    },
    {
      list: "For Edit & Delete of a Tag need to Select the icons present in the Actions Column in the Table.",
    },
  ],
};

const GuideInfoModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        {"Classification & Tags Creation Guide"}
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          {/* <Typography variant="h6" align="center">
            Maestro - Unified In-House Data and Analytics Platform
          </Typography> */}
          <p style={{ fontSize: "1rem" }}>
            <span>{CLASSI_TAG_GUIDE_CONST.CLASSI_HEADER}</span></p>
          <p style={{ fontSize: "1rem" }}>
            <span>Classification Steps</span> -<br />
            <ul>
            {CLASSI_TAG_GUIDE_CONST.CLASSI_STEPS.map((classi, idx) => (
              <li key={idx}>
                <span>{classi.list}</span>
              </li>
            ))}
          </ul>
          </p>
          <p style={{ fontSize: "1rem" }}>
            <span>Tags Steps</span> -<br />
            <ul>
            {CLASSI_TAG_GUIDE_CONST.TAG_STEPS.map((tag, idx) => (
              <li key={idx}>
                <span>{tag.list}</span>
              </li>
            ))}
          </ul>
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GuideInfoModal;
