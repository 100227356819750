import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";

const Snowflake = ({
  connectionData,
  onSubmitFun,
  testConnectionSuccess,
  submissionSuccess,
  showLoading,
}) => {
  const [connectionCreds, setConnectionCreds] = useState({
    accountName: "",
    role: "",
    warehouse: "",
    database: "",
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionCreds((prevCreds) => ({
      ...prevCreds,
      [name]: value,
    }));
  };

  const handleSubmit = (isTestConnection) => {
    const formData = {
      ...connectionData,
      connectionCreds,
    };
    if (onSubmitFun) {
      onSubmitFun(formData, isTestConnection);
    }
  };

  return (
    <div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="accountName">
            Account Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="accountName"
            name="accountName"
            placeholder="Account Name"
            value={connectionCreds.accountName}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="role">
            Role <span style={{ color: "red" }}>*</span>
          </label>{" "}
          <br />
          <input
            type="text"
            id="role"
            name="role"
            placeholder="Role"
            value={connectionCreds.role}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="warehouse">
            Warehouse <span style={{ color: "red" }}>*</span>
          </label>{" "}
          <br />
          <input
            type="text"
            id="warehouse"
            name="warehouse"
            placeholder="Warehouse"
            value={connectionCreds.warehouse}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="database">
            Database <span style={{ color: "red" }}>*</span>
          </label>{" "}
          <br />
          <input
            type="text"
            id="database"
            name="database"
            placeholder="Database"
            value={connectionCreds.database}
            onChange={handleChange}
            required
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>

      <div className="individualRow">
        <div className="">
          <label className="labelStyle" htmlFor="userName">
            User Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="userName"
            name="userName"
            placeholder="Enter User Name"
            value={connectionCreds.username}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="passwordWrapper">
          <div>
            <label className="labelStyle" htmlFor="password">
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter Password"
              value={connectionCreds.password}
              onChange={handleChange}
              style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
            />
          </div>
        </div>

        {showLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "#d3d3d3",
            }}
          >
            <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
          </Box>
        ) : (
          <div className="valign_bottom">
            <button
              className="job-btn btn nar-wid1"
              onClick={() => handleSubmit(true)}
              disabled={testConnectionSuccess}
              // disabled={!testConnectionSuccess || submissionSuccess}
            >
              Check Connection
            </button>
          </div>
        )}
      </div>
      <hr></hr>

      <div className="individualRow" style={{ marginTop: "20px" }}>
        <button
          className="job-btn btn"
          type="submit"
          // onSubmit={handleSubmit}
          onClick={() => handleSubmit(false)}
          disabled={!testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Submit
        </button>
        {/* <button
          className="job-btn btn nar-wid"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button> */}
      </div>
    </div>
  );
};

export default Snowflake;
