import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

const Oracle = ({
  connectionData,
  onSubmitFun,
  testConnectionSuccess,
  submissionSuccess,
  showLoading,
  isEditFlag,
  editConnectionCred,
}) => {
  const [connectionCreds, setConnectionCreds] = useState({
    database: "",
    hostname: "",
    portName: "",
    userName: "",
    password: "",
    schema: "",
  });
  useEffect(() => {
    const { database, hostname, portName, userName, password, schema } =
      editConnectionCred || {};
    if (isEditFlag) {
      setConnectionCreds({
        database,
        hostname,
        portName,
        userName,
        password,
        schema,
      });
    }
  }, [editConnectionCred]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionCreds((prevCreds) => ({
      ...prevCreds,
      [name]: value,
    }));
  };

  const handleSubmit = (isTestConnection) => {
    const formData = {
      ...connectionData,
      connectionCreds,
    };
    if (onSubmitFun) {
      onSubmitFun(formData, isTestConnection);
    }
  };

  return (
    <div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="hostname">
            Host Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="hostname"
            name="hostname"
            placeholder="Enter Host Name"
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="portName">
            Port Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="portName"
            name="portName"
            placeholder="Enter Port Name"
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="database">
            Database <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="database"
            name="database"
            placeholder="Enter Database Name"
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="schema">
            Schema <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="schema"
            name="schema"
            placeholder="Enter Schema Name"
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>
      <div className="individualRow">
        <div className="">
          <label className="labelStyle" htmlFor="userName">
            User Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="userName"
            name="userName"
            placeholder="Enter User Name"
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="passwordWrapper">
          <div>
            <label className="labelStyle" htmlFor="Password">
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="password"
              id="Password"
              name="Password"
              placeholder="Enter Password"
              onChange={handleChange}
              style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
            />
          </div>
        </div>
        {showLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "#d3d3d3",
            }}
          >
            <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
          </Box>
        ) : (
          <div className="valign_bottom">
            <button
              className="job-btn btn nar-wid1"
              onClick={() => handleSubmit(true)}
              disabled={testConnectionSuccess}
              // disabled={!testConnectionSuccess || submissionSuccess}
            >
              Check Connection
            </button>
          </div>
        )}
      </div>
      <hr></hr>

      <div className="individualRow" style={{ marginTop: "20px" }}>
        <button
          className="job-btn btn"
          type="submit"
          // onSubmit={handleSubmit}
          onClick={() => handleSubmit(false)}
          disabled={!testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Submit
        </button>
        {/* <button
          className="job-btn btn nar-wid"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button> */}
      </div>
    </div>
  );
};

export default Oracle;
