import React, { useEffect, useState } from "react";
import "../components/NewComponents/Glossary/Glossary.css";
import "../components/NewComponents/Glossary/Glossary.scss";
import { Container, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import BreadcrumbMUI from "../components/NewComponents/BreadcrumbMUI";
import GlossaryDataFetchMethods from "../components/NewComponents/Glossary/GlossaryDataFetchMethods";
import TagsClassificationData from "../components/NewComponents/TagsClassification/TagsClassificationData";
import { useNavigate } from "react-router-dom";

const TagsClassificationGlossary = () => {
  const [toggleTagsGlossary, setToggleTagsGlossary] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/glossary") {
      setToggleTagsGlossary(false);
    }
  });
  return (
    <>
      <BreadcrumbMUI last={"Manage Classification and Glossary"} />
      {/* <Container fluid className="tags__container p-5"> */}
      <Container fluid className="p30">
        <Row>
          <div className="TagsPageHeader">
            <h3
              style={{
                fontWeight: "500",
                lineHeight: "1.2",
                marginBottom: "0.5rem",
                marginTop: "0",
              }}
            >
              Manage Classification and Glossary
            </h3>
          </div>
        </Row>
        <Row>
          <>
            <div className="TagContainerTop">
              <p
                className={toggleTagsGlossary && "TagContainerTopActivep"}
                style={{ margin: "0" }}
                // className={
                //   window.location.pathname === "/tagsclassifcation" &&
                //   "TagContainerTopActivep"
                // }
                onClick={() => {
                  navigate("/tagsclassifcation");
                  setToggleTagsGlossary(true);
                }}
              >
                Classification
              </p>
              <p
                className={!toggleTagsGlossary && "TagContainerTopActivep"}
                style={{ margin: "0" }}
                // className={
                //   window.location.pathname === "/databook/glossary" &&
                //   "TagContainerTopActivep"
                // }
                onClick={() => setToggleTagsGlossary(false)}
              >
                Glossary
              </p>
            </div>
            {toggleTagsGlossary && (
              <div className="TagContainer" style={{ width: "100%" }}>
                <TagsClassificationData />
              </div>
            )}
            {!toggleTagsGlossary && (
              <div className="GlossaryContainer">
                <GlossaryDataFetchMethods />
              </div>
            )}
          </>
        </Row>
      </Container>
    </>
  );
};

export default TagsClassificationGlossary;
