
import Modal from 'react-bootstrap/Modal';
import React from "react";
import { RxCross2 } from "react-icons/rx";
import { GiCheckMark } from "react-icons/gi";
import Select from 'react-select';

const DDSchemaClasSensEditModal = (props) => {
    const { columnid, rowdata } = props.row;

    let editcolum = (<div></div>);
    switch (columnid) {

        case "description":
            editcolum = (
                <div className="mb-3">
                    <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Description for {rowdata.name}</label>
                    <textarea className="form-control" id="descID" rows="3" defaultValue={props.descValue} name="description" onChange={(e) => props.modalHandler(null, e, e.target.name)}></textarea>
                </div>)
            break;

        case "securityClassification": case "sensitivity":
            editcolum = (
                <div>
                    <div className='mb-3'>
                        <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Security Classification for {rowdata.name}</label>
                        <Select
                            value={props.rsSelectedClassification}
                            onChange={props.rsclassificationHandler}
                            options={props.rsClassificationList}
                            isClearable={true}
                            styles={{
                                menu: base => ({
                                    ...base,
                                    width: "100%"
                                })
                            }}
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Sensitivity for {rowdata.name}</label>
                        <Select
                            value={props.rsSelectedSensitivity}
                            onChange={props.rsSensitivityHandler}
                            options={props.rsSensitivityList}
                            isClearable={true}
                            isMulti
                            styles={{
                                // control: (baseStyles, state) => ({
                                //     ...baseStyles,
                                //     // minHeight: '25px',
                                //     width: "50%"
                                // }),
                                // dropdownIndicator: (base) => ({
                                //     ...base,
                                //     width: "50%"
                                // }),
                                // menuList: (base) => ({
                                //     ...base,
                                //     // fontSize: '12px',
                                //     width: "50%"
                                // }),
                                // valueContainer: (base) => ({
                                //     ...base,
                                //     width: "50%"
                                // }),
                                // option: (base) => ({
                                //     ...base,
                                //     width: "50%"
                                // }),
                                menu: base => ({
                                    ...base,
                                    width: "100%"
                                })
                            }}
                        />
                    </div>
                </div>
            );
            break;
        default:
            editcolum = (<div></div>)
    }

    return (
        <>
            <div>
                <Modal
                    size="lg"
                    show={props.show}
                    onHide={() => props.setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-modal-sizes-title-lg"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div>
                            {editcolum}
                        </div>
                        <div className="">
                            <div className=" text-end">
                                <button type="button" className="model-save mx-3 shadow-sm  rounded" onClick={props.closeModal}>
                                    <RxCross2 />
                                </button>
                                <button type="submit" className="model-save  shadow-sm  rounded" onClick={props.updateColumnsFields} >
                                    <GiCheckMark />
                                </button>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}



export default DDSchemaClasSensEditModal;
