import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
} from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { Delete, Edit, CheckCircle, Cancel } from "@mui/icons-material"; 
import { userRequestMartbuilder } from "../../serverConfig";
import { useValueDCT } from "../../context/ContextProviderDCT";
import { useDispatch } from "react-redux";
// import StatusModal from "../../statusModal/StatusModal";
import CircularLoader from "../../components/NewComponents/Atoms/CircularLoader";
import { AuthContext } from "../../AuthContext"
import DeleteModal from "../../components/NewComponents/Atoms/DeleteModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import RunStatusModal from "./RunStatusModal";

const MainUITable = ({ columns,  data, dropdownValue}) => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState(data);
  const [tableData, setTableData] = useState(() => data);
  //handle delete notification modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //show hide job Details modal
  const [showJobDetails, setShowJobDetails] = useState(false)
  const [tableRowData, setTableRowData] = useState()
  const [rowData, setRowData] = useState(null);
  const [Dag, setDag] = useState();
  //showhide run status modal
  const [showRunStatus, setShowRunStatus] = useState(false);
  //for sending as a prop single row data
  const [singleRowData, setSingleRowData] = useState();

  const { getToken, token, error } = useContext(AuthContext);

  const {
    state: { martBuilderEditJobId, toggleEditButton },
    dispatch,
  } = useValueDCT();


  useEffect(() => {
    setJobs(data);
  }, [data]);  
  


  return (
    <>
      <div
        style={{
          width: "100%", 
        }}
      >
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: 10, pageIndex: 0 },
          }}
          columns={columns}
          data={jobs}
          editingMode="modal" //defaulta
          positionActionsColumn="last"
          enableColumnOrdering
          // enableEditing
          // renderRowActions={({ row, table }) => (
          //   <Box sx={{ display: "flex", gap: "1rem" }}>
          //     <Tooltip arrow placement="left" title="Edit">
          //       <IconButton onClick={() => handleJobViewClick(row?.original)}>
          //         <Edit />
          //       </IconButton>
          //     </Tooltip>
          //     <Tooltip arrow placement="left" title="Info">
          //       <IconButton onClick={() => handleInfoModal(row?.original)}>
          //         <InfoOutlinedIcon />
          //       </IconButton>
          //     </Tooltip>
          //     <Tooltip arrow placement="right" title="Delete">
          //       <IconButton
          //         color="error"
          //         onClick={() => handleDeleteModalToggle(row)}
          //       >
          //         <Delete />
          //       </IconButton>
          //     </Tooltip>
          //   </Box>
          // )}
        />
      </div>
     
      {showRunStatus && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <RunStatusModal
            singleRowData={singleRowData}
            onOpen={() => setShowRunStatus(false)}
            boolean={showRunStatus}
          />
        </Box>
      )}

    </>
  );
};

export default MainUITable;
