import React, { useMemo } from "react";
// import ReactTable from "react-table";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { Delete, Edit } from "@mui/icons-material";
// import "react-table/react-table.css";
import editicon from "../../../../assets/images/pencil.svg";
import deleteicon from "../../../../assets/images/trash.svg";
import { Button } from "react-bootstrap";

const TagsTable = ({
  tagsdata,
  istagsFetching,
  handleEditTags,
  handleDeleteTags,
}) => {
  const columns = useMemo(() => [
    {
      accessorKey: "tagName",
      header: "Tag Name",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 140,
    },
  ]);

  return (
    <>
      {istagsFetching ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "#d3d3d3",
          }}
        >
          <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
        </div>
      ) : (
        // <ReactTable
        //   data={tagsdata}
        //   sortable={true}
        //   size="sm"
        //   showPagination={true}
        //   columns={[
        //     // {
        //     //   Header: () => (
        //     //     <div className="column__table__header">Classification Name</div>
        //     //   ),
        //     //   accessor: "classificationName",
        //     //   width: 220,
        //     // },
        //     {
        //       Header: () => (
        //         <div className="column__table__header">Tag Name</div>
        //       ),
        //       accessor: "tagName",
        //       width: 300,
        //     },
        //     {
        //       Header: () => (
        //         <div className="column__table__header">Description</div>
        //       ),
        //       accessor: "description",
        //       width: 480,
        //     },
        //     {
        //       Header: () => (
        //         <div className="column__table__header">Actions</div>
        //       ),
        //       width: 80,
        //       Cell: ({ row }) => {
        //         const fqndata = row._original.fqn;
        //         const decdata = row._original.description;
        //         const tagname = row._original.tagName;
        //         return (
        //           <>
        //             <div className="action__icon" style={{gap:"8px"}}>
        //               <Button
        //                 onClick={() =>
        //                   handleEditTags(fqndata, decdata, tagname)
        //                 }
        //               >
        //                 <img src={editicon} alt="Edit Tag" />
        //               </Button>
        //               <Button
        //                 onClick={() => handleDeleteTags(fqndata, tagname)}
        //               >
        //                 <img src={deleteicon} alt="Delete Tag" />
        //               </Button>{" "}
        //             </div>
        //           </>
        //         );
        //       },
        //     },
        //   ]}
        //   defaultPageSize={10}
        // />
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                // align: "center",
                sx: { paddingLeft: "3.6rem", backgroundColor: "#ddd" },
              },
              muiTableBodyCellProps: {
                // align: "center",
                sx: { paddingLeft: "2.8rem" },
              },
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              backgroundColor: "#ddd",
              paddingLeft: "2.8rem",
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              paddingLeft: "2.8rem",
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: 10, pageIndex: 0 },
          }}
          columns={columns}
          data={tagsdata}
          positionActionsColumn="last"
          editingMode="modal"
          enableColumnOrdering
          enableEditing
          renderRowActions={({ row }) => {
            const fqndata = row?.original.fqn;
            const decdata = row?.original.description;
            const tagname = row?.original.tagName;
            return (
              <Box sx={{ display: "flex" }}>
                <Tooltip arrow placement="left" title="Info">
                  <IconButton
                    onClick={() => handleEditTags(fqndata, decdata, tagname)}
                  >
                    {/* <Edit /> */}
                    <img
                      src={editicon}
                      alt="Edit Tag"
                      style={{
                        cursor: "pointer",
                        height: "36px",
                        width: "36px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    color="error"
                    // onClick={() => handleDeleteRow(row)}
                    onClick={() => handleDeleteTags(fqndata, tagname)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

export default TagsTable;
