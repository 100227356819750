import React, { useState, useContext } from "react";
import { DATABOOK_API } from "../../../serverConfig";
import { AuthContext } from "../../../AuthContext";
import { protectedResources } from "../../../msalConfig";
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { toast } from "react-toastify";
import axios from "axios";

const DownloadTemplate = ({ datasetid, filename }) => {
  const [download, setDownload] = useState("");

  const DOWNLOADFILEAPI = `${DATABOOK_API}/bulk/template/download`;

  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const tokenfetch = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const downloadfetch = (token) => {
    const inprogresstoast = toast.info(
      <span>Downloading {filename} datasets Please wait...</span>
    );
    let reqdata = JSON.stringify({
      datasetNames: datasetid,
    });

    axios
      .post(DOWNLOADFILEAPI, reqdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        const linkSource = `data:application/xlsm;base64,${res.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `${filename}.xlsm`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setDownload(linkSource);
        toast.update(inprogresstoast, {
          render: "File downloaded successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 6000,
          closeButton: true,
          isLoading: false,
        });
        // window.location.href = "/bulkupload";
      })
      .catch((error) => {
        if (error.response.status == 401) {
          toast.update(inprogresstoast, {
            render: error.response.data,
            type: toast.TYPE.ERROR,
            autoClose: 6000,
            closeButton: true,
            isLoading: false
          });
        } else {
          toast.update(inprogresstoast, {
            render: "Something went wrong while downloading file.",
            type: toast.TYPE.ERROR,
            autoClose: 6000,
            closeButton: true,
            isLoading: false,
          });
        }

      });
  };

  const downloadfile = () => {
    tokenfetch(downloadfetch);
  };

  return (
    <>
      {datasetid?.length > 0 ? (
        <Button
          onClick={() => downloadfile()}
          className="bulk__btn enabled_btn"
        >
          Download Template
        </Button>
      ) : (
        <Button className="bulk__btn disabled_btn" disabled>
          Download Template
        </Button>
      )}
    </>
  );
};

export default DownloadTemplate;
