import React, { useState, useEffect, useContext } from "react";
import { DATABOOK_API } from "../../../serverConfig";
import { AuthContext } from "../../../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../../msalConfig";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AllTagsClassificationView from "./AllTagsClassificationView";
import _ from "lodash";
import { toast } from "react-toastify";

const TagsClassificationData = () => {
  const [tagsdata, settagsData] = useState([]);
  const [classificationdata, setclassificationData] = useState([]);
  const [classificationName, setClassificationName] = useState("");
  const [description, setDescription] = useState("");
  const [classidesc, setclassidesc] = useState("");
  const [classsiselect, sethandleclassiSelect] = useState([]);
  const [individualdatafetch, setindividualdatafetch] = useState([]);
  const [descriptionheader, setDescriptionheader] = useState([]);
  const [istagsFetching, setIstagsFetching] = useState(false);
  const [isclassificationFetching, setIsclassificationFetching] =
    useState(false);
  const [createshow, setCreateShow] = useState(false);
  const handleCreateClose = () => setCreateShow(false);
  const handleCreateShow = () => setCreateShow(true);
  const [editshow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);
  const [deleteclassificationshow, setDeleteclassificationShow] =
    useState(false);
  const handleDeleteClassificationClose = () =>
    setDeleteclassificationShow(false);
  const handleDeleteClassificationShow = () =>
    setDeleteclassificationShow(true);

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.title,
      type: alert.type,
      icon: alert.icon,
      text: alert.text,
      html: alert.html,
      allowOutsideClick: false,
      confirmButtonColor: "#42b0d5",
      customClass: {
        htmlContainer: "sw_classi__text",
        text: "sw_classi__text",
      },
    }).then(function () {
      window.location = "/tagsclassifcation";
    });
  };

  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };
  console.log("classsiselect : ", classsiselect);
  //Getting classification name on selection//
  const handleClassificationSelect = (selectedclassi) => {
    console.log("classsification selected : ", selectedclassi);
    const tabledata = _.filter(
      tagsdata,
      (m) => m.classificationName === selectedclassi
    );

    const descriptiondata = _.filter(
      classificationdata,
      (m) => m.classificationName === selectedclassi
    );

    setDescriptionheader(descriptiondata);
    setindividualdatafetch(tabledata);
    sethandleclassiSelect(selectedclassi);
  };

  const TAGCALL = `${DATABOOK_API}/tag-classification/tags`;
  const CLASSIFICATIONLISTCALL = `${DATABOOK_API}/tag-classification`;
  const CREATECLASSIFICATION = `${DATABOOK_API}/tag-classification?classificationName=${classificationName}&description=${description}`;

  const DELETECLASSI = `${DATABOOK_API}/tag-classification/${classsiselect}`;

  const tokenfetch = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //Fetching all classification//
  const getallclassification = (token) => {
    setIsclassificationFetching(true);
    axios
      .get(CLASSIFICATIONLISTCALL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data;
        let classificationdata = maindata.tagClassifications;
        setclassificationData(classificationdata);
        setIsclassificationFetching(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Getting all tags//

  const getalltags = (token) => {
    setIstagsFetching(true);
    axios
      .get(TAGCALL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log("tag all :", res.data);
        let maindata = res.data;
        let tagsdata = maindata.tags;
        settagsData(tagsdata);
        setIstagsFetching(false);
      });
  };

  //Creating  classification//

  const classificationcreation = (token) => {
    axios
      .post(CREATECLASSIFICATION, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          // sweetAlertHandler({
          //   text: "Classification is Created!",
          //   type: "success",
          //   icon: "success",
          // });
          getallclassification(token);
          toast.success("Classification is Created!. ", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // sweetAlertHandler({
          //   title: "Error while fetching the data. ",
          //   icon: "info",
          // });
          toast.error("Error while creating classification. ", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const handleSubmitCreateclassification = (e) => {
    console.log("ev :: ", e);
    e.preventDefault();
    tokenfetch(classificationcreation);
    setCreateShow(false);
  };

  //Edit Classification i.e only Description can be edited//

  // const classificationedit = (token) => {
  //   axios
  //     .put(EDITCLASSIFICATIONCALL, null, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //     })

  //     .then((response) => {
  //       if (response.status === 200) {
  //         sweetAlertHandler({
  //           html: `"${classsiselect}" Classification Description is updated!`,
  //           type: "success",
  //           icon: "success",
  //         });
  //       } else {
  //         sweetAlertHandler({
  //           title: "Error while fetching the data. ",
  //           icon: "info",
  //         });
  //       }
  //     });
  // };

  const handleEditClassification = (values) => {
    // e.preventDefault();
    const EDITCLASSIFICATIONCALL = `${DATABOOK_API}/tag-classification/${values.classificationName}?description=${values.description}`;
    axios
      .put(EDITCLASSIFICATIONCALL, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 200) {
          // getalltags(token);
          getallclassification(token);
          toast.success(
            `"${classsiselect}" Classification Description is updated!`,
            {
              position: "top-right",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          // sweetAlertHandler({
          //   html: `"${classsiselect}" Classification Description is updated!`,
          //   type: "success",
          //   icon: "success",
          // });
        } else {
          toast.success(
            `Error while editing classification "${classsiselect}"`,
            {
              position: "top-right",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          // sweetAlertHandler({
          //   title: "Error while fetching the data. ",
          //   icon: "info",
          // });
        }
      });
    setEditShow(false);
  };

  //Deleteing a classification //

  const handleDeleteClassification = (token) => {
    axios
      .delete(DELETECLASSI, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 200) {
          // sweetAlertHandler({
          //   html: `"${classsiselect}" Classification is deleted!`,
          //   type: "success",
          //   icon: "success",
          // });
          toast.success(`"${classsiselect}" Classification is deleted!`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const classidel = _.filter(
            classificationdata,
            (m) => m.classificationName !== classsiselect
          );
          setclassificationData(classidel);
          let selectedclassi = classificationdata[0]?.classificationName;
          sethandleclassiSelect(selectedclassi);
          handleClassificationSelect(selectedclassi);
        } else {
          toast.success(`Error while deleting "${classsiselect}"`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // sweetAlertHandler({
          //   title: "Error while fetching the data. ",
          //   icon: "info",
          // });
        }
      });
  };

  const handleDeleteClassi = (e) => {
    e.preventDefault();
    tokenfetch(handleDeleteClassification);
    setDeleteclassificationShow(false);
  };

  useEffect(() => {
    const fetchtags = async () => {
      (async () => await getToken())();
      if (token) {
        getalltags(token);
        getallclassification(token);
      }
      if (error) {
        try {
          const rtoken = (await instance.acquireTokenRedirect(request))
            .accessToken;
          getalltags(rtoken);
          getallclassification(rtoken);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchtags();
  }, [getToken, token]);

  return (
    <>
      {" "}
      <AllTagsClassificationView
        tagsdata={tagsdata}
        classificationdata={classificationdata}
        isclassificationFetching={isclassificationFetching}
        handleCreateShow={handleCreateShow}
        istagsFetching={istagsFetching}
        createshow={createshow}
        handleCreateClose={handleCreateClose}
        handleSubmitCreateclassification={handleSubmitCreateclassification}
        classificationName={classificationName}
        description={description}
        setClassificationName={setClassificationName}
        setDescription={setDescription}
        handleClassificationSelect={handleClassificationSelect}
        classsiselect={classsiselect}
        individualdatafetch={individualdatafetch}
        descriptionheader={descriptionheader}
        editshow={editshow}
        handleEditShow={handleEditShow}
        handleEditClose={handleEditClose}
        handleEditClassification={handleEditClassification}
        setclassidesc={setclassidesc}
        classidesc={classidesc}
        deleteclassificationshow={deleteclassificationshow}
        handleDeleteClassificationClose={handleDeleteClassificationClose}
        handleDeleteClassificationShow={handleDeleteClassificationShow}
        handleDeleteClassi={handleDeleteClassi}
        settagsData={settagsData}
        setDescriptionheader={setDescriptionheader}
        setindividualdatafetch={setindividualdatafetch}
        sethandleclassiSelect={sethandleclassiSelect}
        getalltags={getalltags}
      />
    </>
  );
};

export default TagsClassificationData;
