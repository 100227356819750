import { useMsal } from "@azure/msal-react";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import ShieldIcon from "@mui/icons-material/Shield";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { FaTags } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { AuthContext } from "../AuthContext";
import useWindowDimensions from "../components/NewComponents/Atoms/useWindowDimensions";
import BreadcrumbMUI from "../components/NewComponents/BreadcrumbMUI";
import ReusableCertifiedIcon from "../components/NewComponents/ReusableCertifiedIcon";
import { protectedResources } from "../msalConfig";
import { DATABOOK_API } from "../serverConfig";
import PostgresSVG from "../assets/images/DatabaseIcons/postgre.svg";
import "../styles/SearchResults.styles.css";
import { truncateText } from "../utils/functions";
import LikeDislikeModal from "../components/NewComponents/LikeDislikeModal";

function SearchResults() {
  const { criteria, searchTerm, categoryName, subCategoryName } = useParams();
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };
  const { height, width } = useWindowDimensions();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [resultCount, setResultCount] = useState();
  const [filters, setFilters] = useState({});
  const [filterCount, setFilterCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [clipSnackbar, setClipSnackbar] = useState(false);
  const [advancedFilters, setAdvancedFilter] = useState({
    // datasources: [],
    layers: [],
    schemas: [],
    domains: [],
    datasetSchemas: [],
    // orgModels: [],
    businessUnits: [],
    classifications: [],
    // platforms: [],
    owner: "",
    products: [],
    certifications: null,
    refreshFrequencies: 0,
    sensitivities: [],
    assetTypes: [],
    formats: [],
    subDomains: [],
  });

  const [openLikeDislikeDialog, setOpenLikeDislikeDialog] = useState("");
  const [likeData, setLikeData] = useState();

  const [page, setPage] = useState(pageIndex + 1);
  const datasetperpage = 10;
  const datasets = (page - 1) * datasetperpage;
  const totalRecords = resultCount;
  const totalPages = Math.ceil(totalRecords / datasetperpage);
  let pageNumber = totalRecords === 0 ? 0 : datasets + 1;
  let recordPerPage = Math.min(datasets + datasetperpage, totalRecords);

  if (totalRecords === 0) {
    pageNumber = 0;
    recordPerPage = 0;
  }

  const changePage = ({ selected }) => {
    setPageIndex(selected);
    setPage(selected + 1);
  };

  const classificationType = (classification) => {
    let confidenceFlag = classification?.indexOf("confidential") > -1;
    let internalFlag = classification?.indexOf("internal") > -1;
    let publicFlag = classification?.indexOf("public") > -1;

    if (confidenceFlag) {
      return "sensitivity-confidential";
    } else if (internalFlag) {
      return "sensitivity-internal";
    } else if (publicFlag) {
      return "sensitivity-public";
    } else {
      return "bg-white";
    }
  };

  const metadataScoreType = (modaldata) => {
    if (modaldata.metadataDQScore == null) {
      return "mtScore-gold";
    } else if (modaldata.metadataDQScore >= 90) {
      return "mtScore-gold";
    } else if (
      modaldata.metadataDQScore < 90 ||
      modaldata.metadataDQScore > 70
    ) {
      return "mtScore-silver";
    } else if (modaldata.metadataDQScore <= 70) {
      return "mtScore-bronze";
    }
    // setSensitivityType("#008000")
  };

  const handleSingleSelectChange = (option, selectId) => {
    const { name } = selectId;
    setAdvancedFilter((prevFilters) => ({
      ...prevFilters,
      [name]: option ? option.value : null,
    }));
  };

  const handleChange = (option, selectId) => {
    const { name } = selectId;
    setAdvancedFilter((prevFilters) => ({
      ...prevFilters,
      [name]: option.map((item) => item.value),
    }));
  };

  const getdatasets = async (token) => {
    // if (
    //   (searchTerm !== "" && criteria !== "") ||
    //   (categoryName !== "" &&
    //     subCategoryName !== "" &&
    //     advancedFilters.businessUnits.length)
    // ) {
    setIsFetching(true);

    let reqdata = "";
    let filters = { ...advancedFilters };
    reqdata = JSON.stringify({
      criteria: criteria,
      pageIndex: pageIndex + 1,
      pageSize: 10,
      term: searchTerm.trim(),
      ...filters,
    });
    axios
      .post(`${DATABOOK_API}/search`, reqdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let updateddata = [];
        // setResponseFilterObj(res.data?.filters);
        // if (initialresponseFilterFlag && params.category?.length > 0) {
        //   setInitialresponseFilter(res.data?.filters);
        //   setInitialresponseFilterFlag(false);
        // }
        // if (prevterm !== term) {
        //   setInitialresponseFilter(res.data?.filters);
        //   setPreviousTerm(term);
        // }

        res.data.results?.forEach((item) => {
          if (item.description?.length > 130) {
            let alerted_desc = item.description.slice(0, 130);
            updateddata.push({
              ...item,
              alerted_desc,
              show: true,
              descCount: item.description.length,
            });
          } else {
            updateddata.push({
              ...item,
              alerted_desc: "",
              show: false,
              descCount: item.description?.length,
            });
          }
        });
        setResultCount(res.data?.totalRecords);
        setData(updateddata);
        // setCertifiedDetails(
        //   res.data?.certification !== null
        //     ? res.data?.certification
        //     : {
        //         certificationDate: "",
        //         certifiedBy: "",
        //         certifiedFlag: false,
        //       }
        // );
        // console.log("updatedData>>", nonEmptyFilterCount);
        // setFilteredData(updateddata);
        setIsFetching(false);
      });
    // } else {
    //   // clearAllFilter();
    //   // setInitialresponseFilter([]);
    // }
  };

  const getdatasetsFilters = async (token) => {
    // if (searchTerm || criteria) {
    setIsFetching(true);

    let reqdata = "";
    let filters = { ...advancedFilters };
    reqdata = JSON.stringify({
      criteria: criteria,
      pageIndex: pageIndex + 1,
      pageSize: 10,
      term: searchTerm.trim(),
      ...filters,
    });

    axios
      .post(`${DATABOOK_API}/search/filter`, reqdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let updateddata = [];
        // console.log(res.data)
        setFilters(res.data);
        const nonEmptyFilterCount = countNonEmptyFilters(res.data);
        setFilterCount(nonEmptyFilterCount);
        // setCertifiedDetails(
        //   res.data?.certification !== null
        //     ? res.data?.certification
        //     : {
        //         certificationDate: "",
        //         certifiedBy: "",
        //         certifiedFlag: false,
        //       }
        // );
        // console.log("updatedData>>", nonEmptyFilterCount);
        // setFilteredData(updateddata);
        setIsFetching(false);
      });
    // } else {
    //   // clearAllFilter();
    //   // setInitialresponseFilter([]);
    // }
  };

  const fetchData = async () => {
    if (!token && !error) {
      await getToken();
    }

    if (token) {
      await getdatasets(token);
      await getdatasetsFilters(token);
    } else if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await getdatasets(rtoken);
        await getdatasetsFilters(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const countNonEmptyFilters = (obj) => {
    // Filter and count keys where the value is not null, undefined, or an empty string
    return Object.keys(obj || {}).filter(
      (key) =>
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key] !== "" &&
        obj[key]?.length !== 0
    ).length;
  };

  const formatCertificationOption = (option) => {
    if (option)
      return {
        value: option,
        label: option.toString(),
      };
  };

  const formatOptions = (options, arrayType = "") => {
    if (!Array.isArray(options) || options.length === 0) {
      return [];
    }
    if (arrayType === "certifications") {
      return options.map((option) => ({
        value: option,
        label: option.toString(),
      }));
    } else if (
      arrayType === "classifications" ||
      arrayType === "sensitivities"
    ) {
      return options.map((option) => ({
        value: option,
        label: option.replace("securityclassification.", ""),
      }));
    } else {
      return options.map((option) => ({
        value: option,
        label: option,
      }));
    }
  };

  useEffect(() => {
    let updateAdvancedFilter = { ...advancedFilters };
    if (categoryName !== undefined && subCategoryName !== undefined) {
      if (categoryName === "Platform") {
        updateAdvancedFilter.platforms = [subCategoryName];
        // setPlatforms([...platforms, subCategoryName])
        // setPlatforms([subCategoryName]);
      } else if (categoryName === "By Org") {
        updateAdvancedFilter.orgModels = [subCategoryName];
        // setOrgModels([...orgModels, subCategoryName])
        // setOrgModels([subCategoryName]);
      } else if (categoryName === "Business Units") {
        updateAdvancedFilter.businessUnits = [subCategoryName];
        // setOrgModels([...orgModels, subCategoryName])
        // setBusinesses([subCategoryName]);
      } else if (categoryName === "Domains") {
        updateAdvancedFilter.domains = [subCategoryName];
        // setOrgModels([...orgModels, subCategoryName])
        // setBusinesses([subCategoryName]);
      } else if (categoryName === "Sub Domains") {
        updateAdvancedFilter.subDomains = [subCategoryName];
        // setOrgModels([...orgModels, subCategoryName])
        // setBusinesses([subCategoryName]);
      } else if (categoryName === "Data Assets") {
        updateAdvancedFilter.assetTypes = [subCategoryName];
        // setOrgModels([...orgModels, subCategoryName])
        // setBusinesses([subCategoryName]);
      } else if (categoryName === "Products") {
        updateAdvancedFilter.products = [subCategoryName];
        // setOrgModels([...orgModels, subCategoryName])
        // setBusinesses([subCategoryName]);
      }
      // setAdvancedFilter((ev) => ({
      //   ...ev,
      //   [categoryName]: subCategoryName,
      // }));
      setAdvancedFilter(updateAdvancedFilter);
    }
  }, []);

  useEffect(() => {
    if (
      (searchTerm.trim() !== "" && criteria !== "") ||
      (categoryName !== "" &&
        subCategoryName !== "" &&
        (advancedFilters.businessUnits.length ||
          advancedFilters.domains.length ||
          advancedFilters.subDomains.length ||
          advancedFilters.products.length ||
          advancedFilters.assetTypes.length))
    ) {
      fetchData();
    }
  }, [
    token,
    pageIndex,
    advancedFilters,
    searchTerm,
    criteria,
    categoryName,
    subCategoryName,
  ]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          // height: "auto",
          backgroundColor: "#ffffff",
          position: "sticky",
          top: "4rem",
          padding: "0.4rem 0",
        }}
      >
        <BreadcrumbMUI last="Search Results" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            margin: "1.4rem",
          }}
        >
          <Typography
            // sx={{ mb: 1.5 }}
            color="text.secondary"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            Showing results for:{" "}
          </Typography>
          <Typography
            // sx={{ mb: 1.5 }}
            variant="p"
            component="div"
            style={{ display: "flex", alignItems: "center", gap: "1rem" }}
          >
            {subCategoryName ? subCategoryName : searchTerm}
          </Typography>
        </div>
        {isFetching ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "#d3d3d3",
            }}
          >
            <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
          </Box>
        ) : (
          <>
            <div className="search-filters-wrapper">
              <span>Filter Options: {filterCount === 0 && "N/A"}</span>
              <div className="filters-dropdown">
                {filters.assetTypes?.length !== 0 &&
                  filters.assetTypes !== null && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.assetTypes.length !== 0 &&
                              "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isMulti
                        value={formatOptions(advancedFilters.assetTypes)}
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        name="assetTypes"
                        placeholder="Asset Types"
                        options={formatOptions(filters.assetTypes)}
                      />
                    </div>
                  )}
                {filters.businessUnits?.length !== 0 &&
                  filters.businessUnits !== null && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.businessUnits.length !== 0 &&
                              "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isMulti
                        value={formatOptions(advancedFilters.businessUnits)}
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        name="businessUnits"
                        placeholder="Business Units"
                        options={formatOptions(filters.businessUnits)}
                        // options={[
                        //   { value: "ocean", label: "Ocean" },
                        //   { value: "blue", label: "Blue" },
                        // ]}
                      />
                    </div>
                  )}
                {filters.certifications?.length !== 0 &&
                  filters.certifications !== null && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.certifications && "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        // isMulti
                        value={formatCertificationOption(
                          advancedFilters.certifications
                        )}
                        onChange={handleSingleSelectChange}
                        isClearable={true}
                        isSearchable={true}
                        name="certifications"
                        placeholder="Certifications"
                        options={formatOptions(
                          filters.certifications,
                          "certifications"
                        )}
                      />
                    </div>
                  )}

                {filters.datasetSchemas?.length !== 0 &&
                  filters.datasetSchemas !== null && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.datasetSchemas?.length !== 0 &&
                              "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        isMulti
                        value={formatOptions(advancedFilters.datasetSchemas)}
                        // defaultValue={colourOptions[0]}
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        name="datasetSchemas"
                        placeholder="DatasetSchemas"
                        options={formatOptions(filters.datasetSchemas)}
                      />
                    </div>
                  )}
                {filters.fileFormats?.length !== 0 &&
                  filters.fileFormats !== null && (
                    <div>
                      <Select
                        // styles={{
                        //   control: (baseStyles, state) => ({
                        //     ...baseStyles,
                        //     backgroundColor:
                        //       advancedFilters.fileFormats.length !== 0 && "#E6F4FF",
                        //   }),
                        // }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isMulti
                        value={formatOptions(advancedFilters.fileFormats)}
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        // TODO formats
                        name="fileFormats"
                        placeholder="FileFormats"
                        options={formatOptions(filters.fileFormats)}
                      />
                    </div>
                  )}
                {filters.layers?.length !== 0 && filters.layers !== null && (
                  <div>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor:
                            advancedFilters.layers.length !== 0 && "#E6F4FF",
                        }),
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      // defaultValue={colourOptions[0]}
                      isMulti
                      value={formatOptions(advancedFilters.layers)}
                      onChange={handleChange}
                      isClearable={true}
                      isSearchable={true}
                      options={formatOptions(filters.layers)}
                      name="layers"
                      placeholder="Layers"
                    />
                  </div>
                )}

                {filters.domains?.length !== 0 && filters.domains !== null && (
                  <div>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor:
                            advancedFilters.domains.length !== 0 && "#E6F4FF",
                        }),
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      // defaultValue={colourOptions[0]}
                      isMulti
                      value={formatOptions(advancedFilters.domains)}
                      onChange={handleChange}
                      isClearable={true}
                      isSearchable={true}
                      name="domains"
                      placeholder="Domains"
                      options={formatOptions(filters.domains)}
                    />
                  </div>
                )}

                {filters.subDomains?.length !== 0 &&
                  filters.subDomains !== null && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.subDomains.length !== 0 &&
                              "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isMulti
                        value={formatOptions(advancedFilters.subDomains)}
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        name="subDomains"
                        placeholder="Sub Domains"
                        options={formatOptions(filters.subDomains)}
                      />
                    </div>
                  )}
                {filters.products?.length !== 0 &&
                  filters.products !== null && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.products.length !== 0 &&
                              "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isMulti
                        value={formatOptions(advancedFilters.products)}
                        placeholder="Products"
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        options={formatOptions(filters.products)}
                        name="products"
                      />
                    </div>
                  )}

                {filters.classifications?.length !== 0 &&
                  filters.classifications !== null &&
                  advancedFilters && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.classifications.length !== 0 &&
                              "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isMulti
                        value={formatOptions(
                          advancedFilters.classifications,
                          "classifications"
                        )}
                        placeholder="Classifications"
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        name="classifications"
                        options={formatOptions(
                          filters.classifications,
                          "classifications"
                        )}
                      />
                    </div>
                  )}
                {filters.sensitivities?.length !== 0 &&
                  filters.sensitivities !== null && (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor:
                              advancedFilters.sensitivities.length !== 0 &&
                              "#E6F4FF",
                          }),
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        isMulti
                        value={formatOptions(
                          advancedFilters.sensitivities,
                          "sensitivities"
                        )}
                        placeholder="Sensitivities"
                        onChange={handleChange}
                        isClearable={true}
                        isSearchable={true}
                        name="sensitivities"
                        options={formatOptions(
                          filters.sensitivities,
                          "sensitivities"
                        )}
                      />
                    </div>
                  )}
              </div>
              {/* {showAllFilters ? (
              <span
                style={{
                  borderBottom: "1px solid",
                  cursor: "pointer",
                  margin: "0 1rem",
                }}
                onClick={() => setShowAllFilters(false)}
              >
                {filterCount !== 0 && "Show few"}
              </span>
            ) : (
              <span
                style={{
                  borderBottom: "1px solid",
                  cursor: "pointer",
                  margin: "0 1rem",
                }}
                onClick={() => setShowAllFilters(true)}
              >
                {filterCount !== 0 && "Show all"}
              </span>
            )} */}
            </div>

            {data.length !== 0 && (
              <div
                style={{
                  textAlign: "left",
                  margin: "0.8rem 10%",
                }}
              >
                <Typography
                  // sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  Showing <span>{pageNumber}</span> to{" "}
                  <span>{recordPerPage}</span> of <span>{totalRecords}</span>{" "}
                  results
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "1rem auto",
          padding: "1rem auto",
          // height: "52vh",
          overflowY: "auto",
        }}
      >
        {data.length !== 0 &&
          data?.map((indi) => (
            <Link
              href={`/DatasetDetails/${indi.fqn}`}
              underline="none"
              style={{ width: "80%" }}
              key={indi.fqn}
            >
              <Card
                className="search_card"
                sx={{ minWidth: "100%" }}
                variant="outlined"
                // key={indi.fqn}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "38px",
                    }}
                  >
                    {/* <BsDatabaseFillExclamation
                      style={{
                        height: "30px",
                        width: "20px",
                        // paddingBottom: "8px",
                        color: "#1890ff",
                        marginRight: "10px",
                      }}
                    /> */}
                    <h5>{indi.name || "Name not available"}</h5>
                    {indi?.certification && (
                      <ReusableCertifiedIcon
                        certifieddata={
                          indi?.certification !== null
                            ? indi?.certification
                            : {
                                certificationDate: "",
                                certifiedBy: "",
                                certifiedFlag: false,
                              }
                        }
                      />
                    )}
                    {indi.metadataDQScore && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span>Metadata Score: {indi.metadataDQScore}%</span>
                          </Tooltip>
                        }
                      >
                        <span className="px-2">
                          <MilitaryTechIcon
                            className={metadataScoreType(indi)}
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                    {indi?.securityClassification &&
                      indi.securityClassification?.length > 0 && (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span>
                                {indi.securityClassification
                                  ?.map((item) => {
                                    return item?.replace(
                                      "securityclassification.",
                                      ""
                                    );
                                  })
                                  .toString()}
                              </span>
                            </Tooltip>
                          }
                        >
                          <p
                            className={
                              "dhc-header-line-lock-tag " +
                              classificationType(indi.securityClassification[0])
                            }
                          >
                            {/* <MdLockOpen size={14} color="white" /> */}
                            <ShieldIcon />
                          </p>
                        </OverlayTrigger>
                      )}
                    <div
                      style={{
                        marginLeft: "auto",
                        height: "34px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "1.8rem",
                          color: "#d6d6d6",
                          marginBottom: "0.2rem",
                        }}
                      >
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span>FQN: {indi.fqn}</span>
                            </Tooltip>
                          }
                        >
                          <div className="fqn-search-card-envelop">
                            <p>{truncateText(indi.fqn)}</p>
                            {indi.fqn && (
                              <ContentCopyIcon
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                                color="info"
                                onClick={(event) => {
                                  event.preventDefault();
                                  window.navigator.clipboard.writeText(
                                    indi.fqn
                                  );
                                  setClipSnackbar(true);
                                }}
                              />
                            )}
                          </div>
                        </OverlayTrigger>
                        <ThumbUpIcon
                          style={{ color: "#d6d6d6", cursor: "pointer" }}
                          onClick={(event) => {
                            event.preventDefault();
                            setOpenLikeDislikeDialog("like");
                            setLikeData(indi);
                          }}
                        />
                        <ThumbDownIcon
                          style={{ color: "#d6d6d6", cursor: "pointer" }}
                          onClick={(event) => {
                            event.preventDefault();
                            setOpenLikeDislikeDialog("dislike");
                            setLikeData(indi);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="second-line-meta-info-search-card">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="card-data-info">
                        <BsDatabaseFillExclamation
                          style={{
                            height: "30px",
                            width: "20px",
                            // paddingBottom: "8px",
                            color: "#1890ff",
                            marginRight: "10px",
                          }}
                        />
                        {indi.datasourceType === "Postgres" && (
                          <img
                            src={PostgresSVG}
                            alt="postgres"
                            className="database-image"
                          />
                        )}

                        <p>
                          Published By: <span>{indi.publishedBy}</span>
                        </p>
                      </div>
                      {indi.owner && (
                        <div className="owner-heading">
                          <p>Owner</p>
                        </div>
                      )}
                    </div>
                    {indi.owner && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          gap: "0.2rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            gap: "0.4rem",
                            marginLeft: "auto",
                          }}
                        >
                          {indi.owner.slice(0, 2).map((own) => (
                            <>
                              <p
                                key={own}
                                className="dhc-header-message-classi"
                                style={{
                                  fontSize: "14px",
                                  margin: "0",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.4rem",
                                }}
                              >
                                <CiUser
                                  size={14}
                                  style={{ color: "#1890ff" }}
                                />
                                {own}
                              </p>
                            </>
                          ))}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {indi.owner.length > 2 && (
                            <OverlayTrigger
                              placement="left-start"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltipOwner">
                                  {indi.owner.slice(2).map((item, index) => (
                                    <p
                                      key={index}
                                      style={{
                                        fontSize: "14px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.4rem",
                                        backgroundColor: "rgb(199, 199, 199)",
                                      }}
                                      className="fqn-search-card-envelop"
                                    >
                                      <CiUser
                                        size={14}
                                        style={{ color: "#1890ff" }}
                                      />
                                      {item}
                                    </p>
                                  ))}
                                </Tooltip>
                              }
                            >
                              <p className="show-more-owner">{`+ ${
                                indi.owner.slice(2).length
                              } More`}</p>
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    )}
                    {/* {indi.matchScore && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span>Match Score: {indi.matchScore}</span>
                          </Tooltip>
                        }
                      >
                        <Typography
                          sx={{ mb: 1.5, mt: 1.5 }}
                          variant="subtitle2"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: "0.8rem",
                          }}
                        >
                          {indi.matchScore && indi.matchScore}
                        </Typography>
                      </OverlayTrigger>
                    )} */}
                  </div>
                  {/* Description start */}
                  <Typography
                    sx={{ mb: 1.5 }}
                    // color="text.secondary"
                    variant="subtitle2"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    {indi.alerted_desc !== ""
                      ? indi.alerted_desc
                      : indi.description}
                  </Typography>
                  {/* meta info chips  */}
                  <div
                    style={
                      width > 768
                        ? {
                            display: "flex",
                            gap: "8px",
                          }
                        : {
                            display: "grid",
                            gap: "0.4rem",
                            padding: "0",
                            gridTemplateColumns: "repeat(3, auto)",
                            // gridTemplateColumns: "repeat(3, 7rem)",
                          }
                    }
                  >
                    <div>
                      {indi.businessUnits?.length !== 0 && (
                        <div>
                          {/* <FcOrganization size={14} /> */}
                          {/* <Typography variant="body2">Business Units:</Typography> */}
                          {indi.businessUnits?.map((biz) => (
                            <OverlayTrigger
                              key={biz}
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  <span>Business Units: {biz}</span>
                                </Tooltip>
                              }
                            >
                              <div
                                className="business-unit-capsule-search-page"
                                key={biz}
                              >
                                <p>{biz}</p>
                              </div>
                            </OverlayTrigger>
                          ))}
                        </div>
                      )}
                    </div>
                    <div>
                      {/* <GrDomain size={14} style={{ color: "#1890ff" }} /> */}
                      {/* <Typography variant="body2">Domains:</Typography> */}
                      {indi.domains?.map((dom) => (
                        <OverlayTrigger
                          key={dom}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span>Domains: {dom}</span>
                            </Tooltip>
                          }
                        >
                          <div className="domain-capsule-search-page">
                            <p>{dom}</p>
                          </div>
                        </OverlayTrigger>
                      ))}
                    </div>

                    <div>
                      {/* <MdOutlineSchema size={14} style={{ color: "#1890ff" }} /> */}
                      {/* <Typography variant="body2">Sub Domains:</Typography> */}
                      {indi.subDomains?.map((subDom) => (
                        <OverlayTrigger
                          key={subDom}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span>Sub Domains: {subDom}</span>
                            </Tooltip>
                          }
                        >
                          <div className="sub-domain-capsule-search-page">
                            <p>{subDom}</p>
                          </div>
                        </OverlayTrigger>
                      ))}
                    </div>

                    <div>
                      {/* <MdOutlineSchema size={14} style={{ color: "#1890ff" }} /> */}
                      {/* <Typography variant="body2">Products:</Typography> */}
                      {/* {indi.product?.map((prod) => ( */}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span>Products: {indi.product}</span>
                          </Tooltip>
                        }
                      >
                        <div className="product-capsule-search-page">
                          <p>{indi.product}</p>
                        </div>
                      </OverlayTrigger>
                      {/* ))} */}
                    </div>

                    <div>
                      {/* <FaDatabase size={14} style={{ color: "#1890ff" }} /> */}

                      {/* <Typography variant="body2">Database:</Typography> */}
                      {indi.database?.map((db) => (
                        <OverlayTrigger
                          key={db}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span>Database: {db}</span>
                            </Tooltip>
                          }
                        >
                          <img
                            src={PostgresSVG}
                            alt="Postgres"
                            width={"18px"}
                            height={"18px"}
                          />
                          <p key={db} style={{ fontSize: "14px", margin: "0" }}>
                            {db}
                          </p>
                        </OverlayTrigger>
                      ))}
                    </div>
                    {/* end */}
                  </div>
                  {/* Tags Start */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={
                        width > 768
                          ? {
                              display: "grid",
                              gap: "1rem",
                              padding: "0",
                              gridTemplateColumns: "repeat(6, auto)",
                              // gridTemplateColumns: "repeat(6, 7rem)",
                              marginTop: "0.8rem",
                            }
                          : {
                              display: "grid",
                              gap: "1rem",
                              padding: "0",
                              gridTemplateColumns: "repeat(3, auto)",
                              // gridTemplateColumns: "repeat(3, 7rem)",
                              marginTop: "0.8rem",
                            }
                      }
                    >
                      {indi?.tags?.length !== 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "0.2rem",
                          }}
                        >
                          {indi.tags?.map((tag) => (
                            <OverlayTrigger
                              key={tag}
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  <span>Tags: {tag}</span>
                                </Tooltip>
                              }
                            >
                              {/* <Typography variant="body2">Tags:</Typography> */}
                              <p
                                key={tag}
                                style={{
                                  fontSize: "14px",
                                  margin: "0",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.4rem",
                                }}
                                className="dhc-header-message-classi m-1"
                              >
                                <FaTags size={14} />
                                {tag}
                              </p>
                            </OverlayTrigger>
                          ))}
                        </div>
                      )}
                    </div>
                    {indi.matchScore && (
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          variant="body2"
                          // style={{ margin: "0 0 0 auto" }}
                        >
                          Match Score:
                        </Typography>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltipOwner">
                              <div
                                style={{
                                  margin: "0",
                                }}
                              >
                                Match Fields:
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.4rem",
                                    margin: "0 auto",
                                  }}
                                >
                                  {indi.matchFields?.map((field) => (
                                    <p
                                      key={field}
                                      style={{
                                        backgroundColor: "rgb(199, 199, 199)",
                                        fontSize: "14px",
                                      }}
                                      className="fqn-search-card-envelop"
                                    >
                                      {field}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </Tooltip>
                          }
                        >
                          <p
                            className="dhc-header-message-classi m-1"
                            style={{
                              fontSize: "14px",
                              margin: "0",
                              padding: "0 1.8rem",
                              display: "flex",
                              alignItems: "center",
                              gap: "0.4rem",
                            }}
                          >
                            {/* {indi.matchScore && indi.matchScore} */}
                            {Math.round(indi.matchScore * 100) / 100}
                          </p>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Link>
          ))}
        {data.length === 0 && (
          <Typography
            color="text.secondary"
            variant="h5"
            component="div"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            No results found.
          </Typography>
        )}
      </div>

      <div
        style={{
          // position: "fixed",
          // bottom: "0",
          width: "100%",
          margin: "0.7rem auto",
          padding: "2rem 0",
          // display: "flex",
          // justifyContent: "center",
        }}
      >
        {/* <Pagination count={4} variant="outlined" shape="rounded" /> */}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={totalPages}
          onPageChange={changePage}
          containerClassName={"navigationButtons"}
          previousLinkClassName={"previousButton"}
          nextLinkClassName={"nextButton"}
          disabledClassName={"navigationDisabled"}
          activeClassName={"navigationActive"}
          renderOnZeroPageCount={null}
          forcePage={page - 1}
        />
      </div>
      <LikeDislikeModal
        open={openLikeDislikeDialog}
        onClose={() => setOpenLikeDislikeDialog("")}
        like={openLikeDislikeDialog}
        data={likeData}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={6000}
        open={clipSnackbar}
        onClose={() => setClipSnackbar(false)}
        message="Text copied to clipboard"
        // key={indi.fqn}
      />
    </div>
  );
}

export default SearchResults;
