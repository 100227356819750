import React, { useState, useContext, useEffect } from "react";
import "./NewDataSourceConnection.scss"
import AzureDataLakeImg from "../../assets/images/DatabaseIcons/AzureDataLake.webp";
import ClickHouseImg from "../../assets/images/DatabaseIcons/Clickhouse.svg";
import DremioImg from "../../assets/images/DatabaseIcons/dreamio.svg";
import MicrosoftSQLImg from "../../assets/images/DatabaseIcons/MicrosoftSQL.svg";
import MySQLImg from "../../assets/images/DatabaseIcons/mysql.svg";
import OracleImg from "../../assets/images/DatabaseIcons/Oracle.svg";
import PostgreImg from "../../assets/images/DatabaseIcons/postgre.svg";
// import RedshiftImg from "../../assets/images/DatabaseIcons/Redshift.svg";
// import SalesForceImg from "../../assets/images/DatabaseIcons/Salesforce.png";
import SnowflakeImg from "../../assets/images/DatabaseIcons/snowflake.svg";
// import SFTCImg from "../../assets/images/DatabaseIcons/SFTC.png";
import Postgre from "../../components/NewComponents/DatabaseForm/Postgre";
import Snowflake from "../../components/NewComponents/DatabaseForm/Snowflake";
import AzureDataLake from "../../components/NewComponents/DatabaseForm/AzureDataLake";
import Oracle from "../../components/NewComponents/DatabaseForm/Oracle";
import Dremio from "../../components/NewComponents/DatabaseForm/Dremio";
import Redshift from "../../components/NewComponents/DatabaseForm/Redshift";
import SalesForce from "../../components/NewComponents/DatabaseForm/SalesForce";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import { Container, Breadcrumb, Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../msalConfig";
import { MARTBUILDER_API } from "../../serverConfig";
import BreadcrumbMUI from "../../components/NewComponents/BreadcrumbMUI";

// import {useLocation} from "react-router-dom";
import { toast } from "react-toastify";
import NewDatasourceConnModal from "../../components/NewComponents/NewDatasourceConnModal";

const imagesData = [
  { name: "PostgreSQL", image: PostgreImg },
  { name: "AzureDataLake", image: AzureDataLakeImg },
  { name: "Dremio", image: DremioImg },
  { name: "MicrosoftSQL", image: MicrosoftSQLImg },
  { name: "MySQL", image: MySQLImg },
  // { name: "Oracle", image: OracleImg },
  // { name: "Snowflake", image: SnowflakeImg },
  // { name: "SalesForce", image: SalesForceImg },
  // { name: "Redshift", image: RedshiftImg },
  // { name: "ClickHouse", image: ClickHouseImg },
  // { name: "SFTC", image: SFTCImg },
];

const NewDataSourceConnection = () => {
  const { dcerror, dctoken, getDatacraftToken, token, getToken } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.datacraft.scopes,
    account: account,
  };
  const navigate = useNavigate();

  const [selectedDatabase, setSelectedDatabase] = useState("PostgreSQL");
  const [productTableData, setProductTableData] = useState([]);
  const [connectionFqnValue, setConnectionFqnValue] = useState("");

  const [openNewProdDialog, setOpenNewProdDialog] = useState(false);

  // to show hide submit btn on success of test connection
  const [testConnectionSuccess, setTestConnectionSuccess] = useState(false);
  const [toggleCheckMetadataBtn, setToggleCheckMetadataBtn] = useState(false);
  // const [testConnectionSuccess, setTestConnectionSuccess] = useState(
  //   JSON.parse(localStorage.getItem("testConnectionSuccess")) || false
  // );
  //to show hide checkmetadata now btn on success of submit
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  //useState to show loading for test connection btn
  const [showLoading, setShowLoading] = useState(false);

  //below useState for handling the multiple select dropdown for cronSchedule
  const [newConnectionData, setNewConnectionData] = useState({
    connectionId: "",
    connectionFqn: "",
    connectionName: "",
    datasourceType: selectedDatabase,
    product: { id: "" },
    description: "",
    vaultKey: "",
  });
  console.log("newConnectionData : ", newConnectionData);
  const [editConnectionCred, setEditConnectionCred] = useState("");
  const location = useLocation();
  const params = useParams();

  const { connection_id } = params || {};
  const { actionType } = location?.state || {};
  let isEditFlag = actionType === "EDIT" ? true : false;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "product") {
      setNewConnectionData((prevState) => ({
        ...prevState,
        product: { id: value },
      }));
    } else if (
      ["hostName", "portName", "userName", "password", "database"].includes(
        name
      )
    ) {
      setNewConnectionData((prevState) => ({
        ...prevState,
        connectionCreds: {
          ...prevState.connectionCreds,
          [name]: value,
        },
      }));
    } else {
      console.log("name : ", name, " - value : ", value);

      setNewConnectionData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    // callapiwithtoken(fetchProductDropDownData);
    callapiwithtoken(fetchProductDropDownData);
    if (actionType === "EDIT") {
      callapiwithtoken(fetchConnectionDetails);
    }
  }, [token, dcerror]);

  // const handleSubmit = async (isTestConnection = false) => {
  const handleSubmit = async (formdataPayload, isTestConnection) => {
    if (!validateForm()) {
      return;
    }

    callapiwithtoken((token) =>
      handlePostAPINewConnection(token, formdataPayload, isTestConnection)
    );
  };

  const fetchConnectionDetails = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await axios.get(
        `${MARTBUILDER_API}/datasources/${connection_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // setProductTableData(response?.data);
      console.log("connection_id", response);
      let conndata = response.data;
      setNewConnectionData({
        connectionId: conndata.connectionId,
        connectionFqn: conndata.connectionFqn,
        connectionName: conndata.connectionName,
        datasourceType: conndata.datasourceType,
        description: conndata.description,
        product: conndata.product,
        vaultKey: conndata.vaultKey,
      });
      setEditConnectionCred(JSON.parse(conndata.connectionCreds));
      setSelectedDatabase(response?.data.datasourceType);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };
  const validateForm = () => {
    const { connectionName, product } = newConnectionData;
    if (!connectionName || !product.id) {
      alert(
        "Please fill all required fields: Connection Name, Product, and Description."
      );
      return false;
    }
    return true;
  };

  // const navigateMetaDataCrawl = () => {
  //   navigate(`/metadataCrawl/${connectionFqnValue}`);
  //   console.log("connectionFqnValue : ", connectionFqnValue)
  // };

  const handleDatabaseSelect = (databaseName) => {
    setSelectedDatabase(databaseName);
    setNewConnectionData((prevState) => ({
      ...prevState,
      datasourceType: databaseName,
    }));
    setTestConnectionSuccess(false);
    setSubmissionSuccess(false);
  };

  //for fetching the product data fro dropdown
  const fetchProductDropDownData = async (token) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await axios.get(`${MARTBUILDER_API}/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });

      let sortingProduct = response?.data?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setProductTableData(sortingProduct);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (dcerror) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const updatedatasource = async (formdataPayload, isTestConnection) => {
    console.log("formdataPayload :", formdataPayload);
    const payload = {
      ...newConnectionData,
      connectionCreds: JSON.stringify(formdataPayload.connectionCreds),
      product: { id: formdataPayload?.product?.id },
    };
    const selectedProduct = productTableData.find(
      (product) => product.id === payload.product.id
    );
    const productName = selectedProduct ? selectedProduct.name : "";

    const storageData = {
      connectionName: payload.connectionName,
      datasourceType: payload.datasourceType,
      productId: payload.product.id,
      productName: productName,
    };
    localStorage.setItem("connectionData", JSON.stringify(storageData));
    console.log("payload : ", payload);

    await axios
      .put(MARTBUILDER_API + "/datasources/" + payload.connectionId, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res : ", res);
        navigate("/myconnections");
      })
      .catch((error) => {
        console.log("ERROR : ", error);
      });
  };

  //below we have created a function for handling custom payload
  const handlePostAPINewConnection = (
    token,
    formdataPayload,
    isTestConnection
  ) => {
    if (isTestConnection) {
      setShowLoading(true);
    }
    const payload = {
      ...newConnectionData,
      connectionCreds: JSON.stringify(formdataPayload.connectionCreds),
    };
    const selectedProduct = productTableData.find(
      (product) => product.id === payload.product.id
    );
    const productName = selectedProduct ? selectedProduct.name : "";

    const storageData = {
      connectionName: payload.connectionName,
      datasourceType: payload.datasourceType,
      productId: payload.product.id,
      productName: productName,
    };
    localStorage.setItem("connectionData", JSON.stringify(storageData));
    const ourRequest = axios.CancelToken.source();
    const sendJobData = async () => {
      try {
        let endpoint = isTestConnection
          ? "/metadatacrawl/test/"
          : "/datasources";

        let response = await axios.post(
          `${MARTBUILDER_API}${endpoint}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setConnectionFqnValue(response?.data?.connectionFqn);

        if (response.status === 200 && isTestConnection) {
          setShowLoading(false);
          setSubmissionSuccess(true);
          setTestConnectionSuccess(true);
          localStorage.setItem("testConnectionSuccess", JSON.stringify(true));
          // alert(`Test connection successful.`);
          toast.success("Data source connection tested successfully. ", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setTimeout(() => {
          //   setSubmissionSuccess(false);
          // }, 5000);
        } else if (
          (response.status === 200 || response.status === 201) &&
          !isTestConnection
        ) {
          // setSubmissionSuccess(true);
          // setTestConnectionSuccess(false);
          // setTestConnectionSuccess(true);
          setToggleCheckMetadataBtn(true);
          toast.success("Data source connection created successfully. ", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // alert(`Data submitted successfully.`);
          navigate(
            `/metadataCrawl/${response?.data?.connectionFqn}`,

            {
              state: {
                connectionName: response?.data?.connectionName,
                createdAt: response?.data?.createdAt,
                datasourceType: response?.data?.datasourceType,
                connectionFqn: response?.data?.connectionFqn,
                description: response?.data?.description,
              },
            }
          );
        }
        //  else if (response.status === 200) {
        //   alert(`Data submitted successfully.`);
        // }
        //  else if (response.status === 201) {
        //   alert(`Data submitted successfully.`);
        // }
        else {
          alert(`Success with status ${response.status}: ${response.data}`);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else if (err.response) {
          alert(`Error ${err.response.status}: ${err.response.data}`);
        } else if (err.request) {
          console.log(err.request);
          alert("The request was made but no response was received");
        } else {
          console.log("Error", err.message);
          alert("Error: " + err.message);
        }
      } finally {
        if (isTestConnection) {
          setShowLoading(false);
          // navigate("/manageDatasources");
        }
      }
    };
    sendJobData();
  };
  return (
    <div className="" style={{textAlign:"left"}}>
      <BreadcrumbMUI
        name1={"My Connections"}
        path1={"/myConnections"}
        last={"Create New Data Connection"}
      />
      <h3 className="pageHeading">Create New Data Connection</h3>
      <div className="mainDSwrapper">
        <div className="individualRow">
          <div>
            <label className="labelStyle" htmlFor="connectionName">
              Connection Name <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              id="connectionName"
              name="connectionName"
              placeholder="Enter Connection Name"
              onChange={handleChange}
              style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
              value={newConnectionData.connectionName}
            />
          </div>
          <div>
            <label className="labelStyle" htmlFor="product">
              Products <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <select
              id="product"
              name="product"
              onChange={handleChange}
              value={newConnectionData.product.id || ""}
            >
              <option value="">None</option>
              {productTableData.map((product, index) => (
                <option key={index} value={product.id}>
                  {product.name}
                </option>
              ))}
            </select>
          </div>
          <p style={{alignSelf:"center", marginTop:"1rem"}} onClick={()=>setOpenNewProdDialog(true)}>
            <a href={"javascript:void(0)"} style={{textDecoration:"none"}}>Cannot find your product?</a>
          </p>
        </div>
        <div className="individualRow">
          <div className="inputWrapper">
            <label className="labelStyle" htmlFor="description">
              Description <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <textarea
              id="description"
              name="description"
              rows="4"
              cols="50"
              placeholder="Enter Description"
              onChange={handleChange}
              value={newConnectionData.description}
            ></textarea>
          </div>
        </div>
        <hr></hr>
        <div className="individualRow">
          <div>
            <label
              className="labelStyle"
              htmlFor="description"
              id="sub-heading"
            >
              Select Database
            </label>
            <div className="imageWrapper">
              {imagesData.map((data, index) => (
                <>
                  <div
                    className={`databaseIconsImage ${
                      selectedDatabase === data.name ? "selectedImage" : ""
                    }  ${
                      isEditFlag
                        ? selectedDatabase === data.name
                          ? ""
                          : "disabledbutton"
                        : ""
                    }`}
                    key={index}
                    onClick={() => handleDatabaseSelect(data.name)}
                  >
                    <img src={data.image} alt={data.name} />
                    <p>{data.name}</p>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        {selectedDatabase === "PostgreSQL" && (
          <Postgre
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            showLoading={showLoading}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
            updatedatasource={updatedatasource}
          />
        )}

        {selectedDatabase === "MySQL" && (
          <Postgre
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            showLoading={showLoading}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
            updatedatasource={updatedatasource}
          />
        )}
        {selectedDatabase === "MicrosoftSQL" && (
          <Postgre
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            showLoading={showLoading}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
            updatedatasource={updatedatasource}
          />
        )}
        {selectedDatabase === "Oracle" && (
          <Oracle
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            showLoading={showLoading}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
          />
        )}
        {selectedDatabase === "Redshift" && (
          <Redshift
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
          />
        )}
        {selectedDatabase === "Snowflake" && (
          <Snowflake
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            showLoading={showLoading}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
          />
        )}
        {selectedDatabase === "Dremio" && (
          <Dremio
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            showLoading={showLoading}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
            updatedatasource={updatedatasource}
          />
        )}
        {selectedDatabase === "AzureDataLake" && (
          <AzureDataLake
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
          />
        )}
        {selectedDatabase === "SalesForce" && (
          <SalesForce
            connectionData={newConnectionData}
            handleChange={handleChange}
            onSubmitFun={handleSubmit}
            testConnectionSuccess={testConnectionSuccess}
            submissionSuccess={submissionSuccess}
            showLoading={showLoading}
            editConnectionCred={editConnectionCred}
            isEditFlag={isEditFlag}
          />
        )}
        <div></div>
      </div>
      {/* <div className="btnWrapper">
        <button
          className="job-btn btn"
          style={{ marginLeft: "11px" }}
          onClick={navigateMetaDataCrawl}
          disabled={!toggleCheckMetadataBtn}
        >
          Crawl Metadata Now
        </button>
      </div> */}
      <NewDatasourceConnModal open={openNewProdDialog} onClose={()=>setOpenNewProdDialog(false)} />
    </div>
  );
};

export default NewDataSourceConnection;
