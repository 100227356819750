import CloseIcon from "@mui/icons-material/Close"
import { DialogActions, Grid, TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import axios from "axios"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import { toast } from "react-toastify"
import { AuthContext } from "../../../../AuthContext"
import { useValueDCT } from "../../../../context/ContextProviderDCT"
import { userRequestMartbuilder } from "../../../../serverConfig"
import WarningPopUp from "../../Atoms/WarningPopUp"

const AddProduct = () => {
  const { getToken, token, error } = useContext(AuthContext)

  const {
    state: {
      maerskModalTableData = [],
      businessData = [],
      domainData = [],
      productData = [],
    },
    dispatch,
  } = useValueDCT()

  const [openModal, setOpenModal] = useState(false)
  const [maerskModel, setMaerskModel] = useState([])
  const [business, setBusiness] = useState([])
  const [platform, setPlatform] = useState([])

  const [values, setValues] = useState({
    name: "",
    businessUnits: [],
    domains: [],
    subDomains: [],
  })

  const AddProductTableData = async (values) => {
    console.log(values, "<==payload")
    const ourRequest = axios.CancelToken.source()
    try {
      let response = await userRequestMartbuilder.post(`/product`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      })
      if (response.status === 200 || response.status === 201) {
        // alert("Sucess - Status Code 200/201");
        toast.success("Product created successfully.", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
        setTimeout(() => {
          navigate("/manageProducts")
        }, 6000)
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
    }
    return () => {
      ourRequest.cancel()
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const updatedValues = { ...values, [name]: value }
    setValues(updatedValues)
  }

  const handleModalToggle = () => {
    setOpenModal(!openModal)
  }

  const handleSubmit = () => {
    handleModalToggle()
    if (!values.name.trim()) {
      toast.error("Name cannot be empty")
      return
    }
    if (maerskModel.length === 0) {
      toast.error("Please select at least one Business Unit")
      return
    }
    if (business.length === 0) {
      toast.error("Please select at least one Domain")
      return
    }
    if (platform.length === 0) {
      toast.error("Please select at least one Syb Domain")
      return
    }

    AddProductTableData(values)
  }

  const navigate = useNavigate()

  const maerskModelOptions = Array.isArray(maerskModalTableData)
    ? maerskModalTableData.map((model) => ({
        value: model.id,
        label: model.name,
      }))
    : []

  const businessOptions = Array.isArray(businessData)
    ? businessData.map((business) => ({
        value: business.id,
        label: business.name,
      }))
    : []

  const domainOptions = Array.isArray(domainData)
    ? domainData.map((domain) => ({
        value: domain.id,
        label: domain.name,
      }))
    : []

  const handleMaerskModelChange = (selectedOptions) => {
    setMaerskModel(selectedOptions)
    setValues((prevValues) => ({
      ...prevValues,
      orgModel: selectedOptions.map((option) => ({ id: option.value })),
    }))
  }

  const handleBusinessChange = (selectedOptions) => {
    setBusiness(selectedOptions)
    setValues((prevValues) => ({
      ...prevValues,
      business: selectedOptions.map((option) => ({ id: option.value })),
    }))
  }

  const handlePlatformChange = (selectedOptions) => {
    setPlatform(selectedOptions)
    setValues((prevValues) => ({
      ...prevValues,
      platform: selectedOptions.map((option) => ({ id: option.value })),
    }))
  }

  return (
    <div style={{ height: "100%", marginTop: "60px" }}>
      <div
        style={{
          padding: "20px",
          maxWidth: "740px",
          height: "auto",
          margin: "0px auto",
          border: "1px solid lightgray",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
        }}
      >
        <div>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h4" component="h4">
                Product Details
              </Typography>
            </Grid>

            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => navigate("/manageProducts")}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 10px",
          }}
        >
          <Typography style={{ flex: 1, fontWeight: 600, textAlign: "left" }}>
            Name
          </Typography>
          <TextField
            margin="dense"
            label="Enter name"
            name="name"
            value={values.name}
            onChange={handleChange}
            style={{ width: "54.5%" }}
            size="small"
            // inputProps={{ style: { height: "20px", padding: "10px" } }}
            InputLabelProps={{ style: { fontSize: "16px", fontWeight: "500" } }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 10px",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
              textAlign: "left",
            }}
          >
            Business Units
          </Typography>
          <Select
            id="maersk-model"
            placeholder="Select Business Units..."
            aria-label="maersk-model"
            options={maerskModelOptions}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={maerskModel}
            onChange={handleMaerskModelChange}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "54.5%",
                textAlign: "left",
              }),
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 10px",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
              textAlign: "left",
            }}
          >
            Domains
          </Typography>
          <Select
            id="business"
            placeholder="Select Domains..."
            aria-label="business"
            options={businessOptions}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={business}
            onChange={handleBusinessChange}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "54.5%",
                textAlign: "left",
              }),
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 10px",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
              textAlign: "left",
            }}
          >
            Sub Domains
          </Typography>
          <Select
            id="platform"
            placeholder="Select Sub Domains..."
            aria-label="platform"
            options={domainOptions}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={platform}
            onChange={handlePlatformChange}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "54.5%",
                textAlign: "left",
              }),
            }}
          />
        </div>

        <Box sx={{ pb: 2, px: 2, m: 4 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              className="rounded-md job-btn btn width-72p"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
        {openModal && <WarningPopUp />}
      </div>
    </div>
  )
}

export default AddProduct
