import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { AuthContext } from "../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../msalConfig";
import axios from "axios";
import WordCloud from "react-d3-cloud";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import DataQualityPieCard from "../components/NewComponents/DatasetDetails/DataQualityPieCard";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import { TELEMETRY_API } from "../serverConfig";

function UserInsights(props) {
  const navigate = useNavigate();
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const [isFetching, setIsFetching] = useState(false);
  const [termsCount, setTermsCount] = useState([]);
  const [monthlyVisits, setMonthlyVisits] = useState();
  const [topUsers, setTopUsers] = useState([]);
  const [visitsTrend, setVisitsTrend] = useState([]);

  const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

  const TELEMETRY_API = `https://telemetry-api.gleafink.com:443/metainsights/`;

  const fetchAllTermsCount = (token) => {
    setIsFetching(true);
    axios
      .get(`${TELEMETRY_API}insights/search/terms_count`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let result = res.data?.map((item) => {
          if (item.keyword !== null) {
            return { text: item.keyword, value: item.count };
          }
        });
        setTermsCount(result);
      })
      .catch((error) => {
        console.log("UserInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchTotalOneMonthVisit = (token) => {
    setIsFetching(true);
    axios
      .get(`${TELEMETRY_API}user/total_visits_one_month`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setMonthlyVisits(res.data);
      })
      .catch((error) => {
        console.log("UserInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchTopUsers = (token) => {
    setIsFetching(true);
    axios
      .get(`${TELEMETRY_API}user/hits_count`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setTopUsers(res.data);
      })
      .catch((error) => {
        console.log("UserInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchVisitsTrend = (token) => {
    setIsFetching(true);
    axios
      .get(`${TELEMETRY_API}user/trend`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data;
        for (let i = 0; i < maindata.length; i++) {
          let formatedDay = new Date(maindata[i].day);
          maindata[i]["day"] = formatedDay.toUTCString();
        }
        setVisitsTrend(maindata);
      })
      .catch((error) => {
        console.log("UserInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    callapiwithtoken(fetchAllTermsCount);
    callapiwithtoken(fetchTotalOneMonthVisit);
    callapiwithtoken(fetchTopUsers);
    callapiwithtoken(fetchVisitsTrend);
  }, [token, instance]);

  return (
    <div style={{ width: "100%" }}>
      {isFetching ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "6rem",
          }}
        >
          <Skeleton variant="rounded" width={"70%"} height={390} />
          <Skeleton variant="rounded" width={"70%"} height={390} />
        </div>
      ) : (
        <>
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // margin: "4rem auto",

              // border: "1px solid #d8d8d8",
              // borderRadius: "7px",
              width: "80%",
              // height: "310px",
              margin: "6rem auto",
            }}
          >
            <Card style={{ width: "100%", height: "auto" }}>
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Search Cloud
              </h3>
              <div
                style={{
                  margin: "2rem",
                  width: "100%",
                  height: "21rem",
                  // display: "grid",
                  // gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                  padding: "0",
                }}
              >
                <WordCloud
                  data={termsCount}
                  rotate={(term) => 0}
                  fontSize={(term) => Math.sqrt(term.value) * 2}
                  width={320}
                  height={100}
                  fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
                />
              </div>
            </Card>
          </div>
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // margin: "4rem auto",

              // border: "1px solid #d8d8d8",
              // borderRadius: "7px",
              width: "80%",
              // height: "310px",
              margin: "6rem auto",
            }}
          >
            <Card style={{ width: "100%", height: "auto" }}>
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                User Click Stream Analytics
              </h3>
              <div
                style={{
                  margin: "2rem",
                  marginTop: "4rem",
                  width: "100%",
                  height: "21rem",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              >
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                  <div style={{ width: "50%", cursor: "pointer" }}>
                    <Typography
                      sx={{ mb: 1.5, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Total Visitors in Last One Month
                    </Typography>
                    <div className="pie hollow">
                      <span>{monthlyVisits}</span>
                    </div>
                  </div>
                {/* </Card> */}
                  {/* <Card style={{ width: "50%", height: "auto" }}> */}
                <div style={{ width: "50%", cursor: "pointer" }}>
                    <Typography
                      sx={{ mb: 1.5, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Top Users
                    </Typography>
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                      style={{ display: "flex" }}
                    >
                      <BarChart
                        width={500}
                        height={300}
                        data={topUsers}
                        layout="horizontal"
                        margin={{
                          top: 15,
                          right: 5,
                          left: 5,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="userName" tick={false} />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Bar
                          dataKey="noOfHits"
                          // fill="#E6F4FF"
                          fill="#22d3ee"
                          activeBar={<Rectangle fill="#1890ff" stroke="#E6F4FF" />}
                          // activeBar={
                          //   <Rectangle fill="#2dd4bf" stroke="#E6F4FF" />
                          // }
                        />
                      </BarChart>
                    </ResponsiveContainer>
                </div>
                  {/* </Card> */}
              </div>
              <div
                style={{
                  margin: "2rem",
                  marginTop: "4rem",
                  width: "100%",
                  height: "21rem",
                  display: "flex",
                  padding: "0",
                }}
              >
                <div style={{ width: "100%", cursor: "pointer" }}>
                  {/* <Card style={{ width: "100%", height: "auto" }}> */}
                    <Typography
                      sx={{ mb: 1.5, textAlign: "left" }}
                      color="text.secondary"
                    >
                      User Visits Trend
                    </Typography>
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                      style={{ display: "flex" }}
                    >
                      <BarChart
                        width={500}
                        height={300}
                        data={visitsTrend}
                        margin={{
                          top: 15,
                          right: 5,
                          left: 5,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" tick={false} />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Bar
                          dataKey="noOfHits"
                          // fill="#E6F4FF"
                          fill="#22d3ee"
                          activeBar={<Rectangle fill="#1890ff" stroke="#E6F4FF" />}
                          // activeBar={
                          //   <Rectangle fill="#2dd4bf" stroke="#E6F4FF" />
                          // }
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  {/* </Card> */}
                </div>
              </div>
            </Card>
          </div>
        </>
      )}
    </div>
  );
}

export default UserInsights;
