import { Button } from "antd";
import React from "react";
import { OverlayTrigger, Badge, Tooltip } from "react-bootstrap";

const GlossaryDesc = (props) => {
  const { descriptionheader, individualdatafetch } = props;
  const glossarytooltiprender = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Total glossary terms associated
    </Tooltip>
  );
  return (
    <>
      {" "}
      {descriptionheader &&
        descriptionheader.map((d, idx) => (
          <div className="header__desc" key={idx}>
            <h2>
              {d.classificationName}{" "}
              {individualdatafetch?.length > 0 ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={glossarytooltiprender}
                >
                  <Button className="enabled_btn1">
                    {" "}
                    {individualdatafetch.length}
                  </Button>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </h2>
            {d.description === null ? (
              <p>No description</p>
            ) : (
              <p>{d.description}</p>
            )}
          </div>
        ))}
    </>
  );
};

export default GlossaryDesc;
