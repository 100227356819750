import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const AzureDataLakeForm = ({ azureData, setAzureData, disableFieldProp }) => {
  const [pathContainsTable, setPathContainsTable] = useState(
    azureData.adlsDoesPathContainTables || false
  );

  useEffect(() => {
    setPathContainsTable(azureData.adlsDoesPathContainTables || false);
  }, [azureData.adlsDoesPathContainTables]);

  const handleInputChange = (e) => {
    setAzureData({
      ...azureData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePathContainsTableChange = (e) => {
    const isChecked = e.target.checked;
    setPathContainsTable(isChecked);
    setAzureData({
      ...azureData,
      adlsDoesPathContainTables: isChecked,
    });
  };

  return (
    <div style={{ marginTop: "5px", maxWidth: "28rem" }}>
      {/* <div
        className="arrayItem"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography 
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "146px",
            minWidth: "max-content",
            textAlign: "left",
          }}
        >
          Storage Account Name
        </Typography>
        <TextField
          sx={{ minWidth: "220px" }}
          size="small"
          margin="dense"
          name="adlsStorageAccountName"
          value={azureData.adlsStorageAccountName}
          onChange={handleInputChange} 
          disabled={disableFieldProp}
        />
      </div> */}
      <div className="grid-item">
        <label className="labelStyle" htmlFor="connectionName">
          Storage Account Name
        </label>
        <input
          style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          type="text"
          id="adlsStorageAccountName"
          name="adlsStorageAccountName"
          placeholder="Storage Account Name"
          value={azureData.adlsStorageAccountName}
          onChange={handleInputChange}
        />
      </div>
      {/* <div
        className="arrayItem"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "193px",
            minWidth: "max-content",
            textAlign: "left",
          }}
        >
          Container Name
        </Typography>
        <TextField
          sx={{ minWidth: "220px" }}
          size="small"
          margin="dense"
          name="adlsContainerName"
          value={azureData.adlsContainerName}
          onChange={handleInputChange}
          disabled={disableFieldProp}
        />
      </div> */}
      <div className="grid-item">
        <label className="labelStyle" htmlFor="connectionName">
          Container Name
        </label>
        <input
          style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          type="text"
          id="adlsContainerName"
          name="adlsContainerName"
          placeholder="Container Name"
          value={azureData.adlsContainerName}
          onChange={handleInputChange}
        />
      </div>
      {/* <div
        className="arrayItem"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "216px",
            minWidth: "max-content",
            textAlign: "left",
          }}
        >
          Dataset Path
        </Typography>
        <TextField
          sx={{ minWidth: "220px" }}
          size="small"
          margin="dense"
          name="adlsDatasetPath"
          value={azureData.adlsDatasetPath}
          onChange={handleInputChange}
          disabled={disableFieldProp}
        />
      </div> */}
      <div className="grid-item">
        <label className="labelStyle" htmlFor="connectionName">
          Dataset Path
        </label>
        <input
          style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          type="text"
          id="adlsDatasetPath"
          name="adlsDatasetPath"
          placeholder="Dataset Path"
          value={azureData.adlsDatasetPath}
          onChange={handleInputChange}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "center",
          width: "900px",
          marginTop: "20px",
        }}
      >
        <Typography
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "14px",
          }}
        >
          Does Path Contains Tables
        </Typography>
        <input
          style={{ width: "232px" }}
          type="checkbox"
          id="autoCrawl"
          name="adlsDoesPathContainTables" 
          onChange={handlePathContainsTableChange}
          checked={pathContainsTable} 
        />
      </div> */}
      {pathContainsTable && (
        // <div
        //   className="arrayItem"
        //   style={{
        //     display: "flex",
        //     alignItems: "center",
        //   }}
        // >
        //   <Typography
        //     style={{
        //       color: "black !important",
        //       fontWeight: 550,
        //       marginRight: "205px",
        //       minWidth: "max-content",
        //       textAlign: "left",
        //     }}
        //   >
        //     Schema Name
        //   </Typography>
        //   <TextField
        //     sx={{ minWidth: "220px" }}
        //     size="small"
        //     margin="dense"
        //     name="adlsDatasetSchemaName"
        //     value={azureData.adlsDatasetSchemaName}
        //     onChange={handleInputChange}
        //     disabled={disableFieldProp}
        //   />
        // </div>
        <div className="grid-item">
          <label className="labelStyle" htmlFor="connectionName">
            Schema Name
          </label>
          <input
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
            type="text"
            id="adlsDatasetSchemaName"
            name="adlsDatasetSchemaName"
            placeholder="Schema Name"
            value={azureData.adlsDatasetSchemaName}
            onChange={handleInputChange}
          />
        </div>
      )}
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "center",
          width: "900px",
        }}
      >
        <Typography
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "228px",
          }}
        >
          File Format
        </Typography>
        <FormControl>
          <Select
            sx={{ width: "220px", marginTop: "8px" }}
            size="small"
            name="adlsFileFormat"
            value={azureData.adlsFileFormat}
            onChange={handleInputChange}
            id="file-format-select"
            labelId="file-format-label"
            disabled={disableFieldProp}
          >
            <MenuItem value="parquet">parquet</MenuItem>
            <MenuItem value="orc">orc</MenuItem>
            <MenuItem value="avro">avro</MenuItem>
            <MenuItem value="json">json</MenuItem>
            <MenuItem value="csv">csv</MenuItem>
          </Select>
        </FormControl>
      </div> */}
      <div className="grid-item">
        <label className="labelStyle" htmlFor="connectionName">
          File Format
        </label>
        <select
          name="adlsFileFormat"
          value={azureData.adlsFileFormat}
          onChange={handleInputChange}
          id="file-format-select"
          // labelId="file-format-label"
          // disabled={disableFieldProp}
        >
          <option value="">File Format</option>
          <option value="parquet">parquet</option>
          <option value="orc">orc</option>
          <option value="avro">avro</option>
          <option value="json">json</option>
          <option value="csv">csv</option>
        </select>
      </div>
    </div>
  );
};

export default AzureDataLakeForm;
