import React, { useContext, useEffect, useState, useMemo } from "react";
import MySQL from "../../assets/images/DatabaseIcons/mysql.svg";
import AzureDataLake from "../../assets/images/DatabaseIcons/AzureDataLake.webp";
import Azureblob from "../../assets/images/DatabaseIcons/Azureblob.svg";
import Clickhouse from "../../assets/images/DatabaseIcons/Clickhouse.svg";
import PostgreSQL from "../../assets/images/DatabaseIcons/postgre.svg";
import MicrosoftSQL from "../../assets/images/DatabaseIcons/MicrosoftSQL.svg";
import Oracle from "../../assets/images/DatabaseIcons/Oracle.svg";
import Redshift from "../../assets/images/DatabaseIcons/Redshift.svg";
import SalesForce from "../../assets/images/DatabaseIcons/Salesforce.png";
import Dremio from "../../assets/images/DatabaseIcons/dreamio.svg";
import Snowflake from "../../assets/images/DatabaseIcons/snowflake.svg";
import SFTP from "../../assets/images/DatabaseIcons/SFTC.png";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../msalConfig";
import axios from "axios";
import { MARTBUILDER_API } from "../../serverConfig";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CronInfoModal from "../../components/NewComponents/MetaDataCrawl/CronInfoModal";
import { toast } from "react-toastify";
import AzureDataLakeForm from "../../components/NewComponents/MetaDataCrawl/azureDataLakeForm/AzureDataLakeForm";
import BreadcrumbMUI from "../../components/NewComponents/BreadcrumbMUI";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const databaseImages = {
  MySQL,
  AzureDataLake,
  Azureblob,
  Clickhouse,
  PostgreSQL,
  MicrosoftSQL,
  Oracle,
  Redshift,
  SalesForce,
  Dremio,
  Snowflake,
  SFTP,
};

const MetadataCrawl = () => {
  const location = useLocation();
  const { connectionName, createdAt, datasourceType, description } =
    location?.state || {};
  // console.log("connectionName MetadataCrawl : ", connectionName);
  const { dcerror, dctoken, getDatacraftToken, getToken, token } =
    useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.datacraft.scopes,
    account: account,
  };
  const params = useParams();
  const { connectionFqn } = params || {};

  const navigate = useNavigate();
  const [showCrawlProgress, setShowCrawlProgress] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [crawlName, setCrawlName] = useState("");
  const [selectedConnection, setSelectedConnection] = useState("");
  const [currentValue, setCurrentValue] = useState("");

  const [productName, setProductName] = useState("");
  const [datasourceTypeState, setDatasourceType] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();
  const [connectionNameState, setConnectionName] = useState("");
  const [connWarning, setConnWarning] = useState(false);
  const [connNameWarning, setConnNameWarning] = useState(false);
  const [dataSourceName, setDataSourceName] = useState("");
  const [cronSchedule, setCronSchedule] = useState("");
  //for show hide the dialog box
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedValues, setSelectedValues] = useState({
    minute: "",
    hour: "",
    dayOfMonth: "",
    month: "",
    dayOfWeek: "",
  });

  // State variables for toggling visibility
  const [showSchemaPatterns, setShowSchemaPatterns] = useState(false);
  const [showTablePatterns, setShowTablePatterns] = useState(false);
  const [showCronScheduleField, setShowCronScheduleField] = useState(false);

  // State for managing schema inclusive patterns
  const [schemaInclusivePatterns, setSchemaInclusivePatterns] = useState([]);
  const [schemaInclusivePatternInput, setSchemaInclusivePatternInput] =
    useState("");
  // State for managing schema exclusive patterns
  const [schemaExclusivePatterns, setSchemaExclusivePatterns] = useState([]);
  const [schemaExclusivePatternInput, setSchemaExclusivePatternInput] =
    useState("");
  // State for managing table exclusive patterns
  const [tableInclusivePatterns, setTableInclusivePatterns] = useState([]);
  const [tableInclusivePatternInput, setTableInclusivePatternInput] =
    useState("");
  // State for managing table exclusive patterns
  const [tableExclusivePatterns, setTableExclusivePatterns] = useState([]);
  const [tableExclusivePatternInput, setTableExclusivePatternInput] =
    useState("");

  // Azure Data Lake State
  const [AzureDataLakeState, setAzureDataLakeState] = useState(false);
  const [azureData, setAzureData] = useState({
    adlsContainerName: "",
    adlsDatasetPath: "",
    adlsDatasetSchemaName: "",
    adlsDoesPathContainTables: true,
    adlsFileFormat: "",
    adlsStorageAccountName: "",
  });

  const azurePayload = {
    ...azureData,
    // name: crawlPayload.name,
    // product: {
    //   id: selectedProduct,
    // },
    // datasourceConnectionFqn: dataSourceName,
    // id: crawlPayload.id,
    // isAutoCrawl: isAutoCrawlSelected,
    // cronSchedule: crawlPayload.cronSchedule,
  };

  const redirectToNewPage = () => {
    navigate("/new-page-path");
  };

  // const handleCrawlProgress = () => {
  //   if (
  //     window.location.pathname.toLowerCase() == "/publishmetadata" &&
  //     !selectedConnection
  //   ) {
  //     setConnWarning(true);
  //     return;
  //   }
  //   setShowCrawlProgress(!showCrawlProgress);
  // };

  const fetchDataSource = async (token) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await axios.get(`${MARTBUILDER_API}/datasources/`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      // console.log("Datasources::::", response.data);
      const filteredResult = response.data.filter(
        (item) => item.product?.createdBy === account.username
      );
      setDataSource(response.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const callapiwithtoken = async (callback, ...args) => {
    await getToken();
    if (token) {
      await callback(token, ...args);
    } else if (dcerror) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken, ...args);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // let connectionData = JSON.parse(localStorage.getItem("connectionData"));
  let connectionData = JSON.parse(localStorage.getItem("connectionData")) || {};

  // console.log(connectionData, "<==LS");

  useEffect(() => {
    callapiwithtoken(fetchDataSource);
    if (
      window.location.pathname.toLowerCase() !== "/publishmetadata"
    ) {
      const pathSegments = location.pathname.split("/");
      const connectionFqn = pathSegments[pathSegments.length - 1];
      // handleDataSource = (event, connectionFqn)
      setSelectedConnection(connectionFqn);
    }
  }, [token, dcerror, location.pathname]);

  useEffect(() => {
    if (location.pathname === "/publishmetadata") {
      setSelectedConnection("");
      setConnectionName("");
      setProductName("");
      setDatasourceType("");
      return;
    }
    if (selectedConnection && dataSource.length > 0) {
      const selectedDataSource = dataSource.find(
        (item) => item.connectionFqn === selectedConnection
      );
      setSelectedProduct(selectedDataSource);
      setConnectionName(selectedDataSource?.connectionName || "");
      setProductName(selectedDataSource?.product?.name || "");
      setDatasourceType(selectedDataSource?.datasourceType || "");
      if (selectedDataSource?.datasourceType === "AzureDataLake") {
        setAzureDataLakeState(true);
      } else {
        setAzureDataLakeState(false);
      }
    }
  }, [selectedConnection, dataSource, connectionNameState, productName]);

  const handleAddSchemaInclusivePattern = () => {
    if (schemaInclusivePatternInput.trim()) {
      setSchemaInclusivePatterns([
        ...schemaInclusivePatterns,
        schemaInclusivePatternInput.trim(),
      ]);
      setSchemaInclusivePatternInput("");
    }
  };

  const handleAddSchemaExclusivePattern = () => {
    if (schemaExclusivePatternInput.trim()) {
      setSchemaExclusivePatterns([
        ...schemaExclusivePatterns,
        schemaExclusivePatternInput.trim(),
      ]);
      setSchemaExclusivePatternInput("");
    }
  };

  const handleAddTableExclusivePattern = () => {
    if (tableExclusivePatternInput.trim()) {
      setTableExclusivePatterns([
        ...tableExclusivePatterns,
        tableExclusivePatternInput.trim(),
      ]);
      setTableExclusivePatternInput("");
    }
  };

  const handleAddTableInclusivePattern = () => {
    if (tableInclusivePatternInput.trim()) {
      setTableInclusivePatterns([
        ...tableInclusivePatterns,
        tableInclusivePatternInput.trim(),
      ]);
      setTableInclusivePatternInput("");
    }
  };

  const handleRemoveSchemaInclusivePattern = (index) => {
    setSchemaInclusivePatterns(
      schemaInclusivePatterns.filter((_, i) => i !== index)
    );
  };

  const handleRemoveSchemaExclusivePattern = (index) => {
    setSchemaExclusivePatterns(
      schemaExclusivePatterns.filter((_, i) => i !== index)
    );
  };

  const handleRemoveTableInclusivePattern = (index) => {
    setTableInclusivePatterns(
      tableInclusivePatterns.filter((_, i) => i !== index)
    );
  };

  const handleRemoveTableExclusivePattern = (index) => {
    setTableExclusivePatterns(
      tableExclusivePatterns.filter((_, i) => i !== index)
    );
  };

  const handleDataSource = (event, newValue) => {
    if (newValue) {
      setSelectedConnection(newValue.value);
      const selectedDataSource = dataSource.find(
        (item) => item.connectionFqn === newValue.value
      );
      setSelectedProduct(selectedDataSource);
      if (selectedDataSource?.datasourceType === "AzureDataLake") {
        setAzureDataLakeState(true);
      } else {
        setAzureDataLakeState(false);
      }
      setConnectionName(selectedDataSource?.connectionName || "");
      setProductName(selectedDataSource?.product?.name || "");
      setDatasourceType(selectedDataSource?.datasourceTypeState || "");
      setConnWarning(false);
    }
    if (newValue === null) {
      setSelectedConnection("");
      setSelectedProduct(null);
    }
  };

  const dataSourceOptions = useMemo(
    () =>
      dataSource?.map((ds) => ({
        label: ds.connectionFqn,
        value: ds.connectionFqn,
      })) || [],
    [dataSource]
  );

  const createPayload = (selectedValues) => {
    if (
      window.location.pathname.toLowerCase() === "/publishmetadata" &&
      !selectedConnection
    ) {
      setConnWarning(true);
      return;
    }
    if (!crawlName) {
      setConnNameWarning(true);
      return;
    }
    callapiwithtoken(metaDataCrawlPostReq, selectedValues);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setCurrentValue(value);
  };

  const runCrawlAPI = async (token, fqn, selectedConnection) => {
    // console.log("runCrawlAPI");
    const ourRequest = axios.CancelToken.source();
    console.log(selectedConnection, "<===response value");
    try {
      let response = await axios.get(
        `${MARTBUILDER_API}/metadatacrawl/run/${fqn}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      console.log("runCrawlAPI REsponse::::", response.data);

      if (response.status === 200 || response.status === 202) {
        // alert(response.data);
        // navigate(
        //   `/connectiondetail/${selectedConnection}`,
        navigate(
          `/manageMetadatacrawls`,

          {
            state: {
              connectionName: connectionName,
              createdAt: createdAt,
              datasourceType: datasourceType,
              connectionFqn: connectionFqn,
              description: description,
            },
          }
        );
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const metaDataCrawlPostReq = async (token, selectedValues = {}) => {
    console.log("trigggered");
    // dispatch({ type: "START_BACKDROP_LOADING" });
    const ourRequest = axios.CancelToken.source();

    const { minute, hour, dayOfMonth, month, dayOfWeek } = selectedValues;
    // const minuteDisplay = minute || "*";
    const minuteDisplay =
      minute === "" ? "*" : minute === "00" || "0" ? "*" : minute;
    const hourDisplay = hour || "*";
    const dayOfMonthDisplay = dayOfMonth || "*";
    const monthDisplay = month || "*";
    const dayOfWeekDisplay = dayOfWeek || "*";

    const cronScheduler = `${minuteDisplay} ${hourDisplay} ${dayOfMonthDisplay} ${monthDisplay} ${dayOfWeekDisplay}`;

    if (window.location.pathname.toLowerCase() == "/publishmetadata") {
      if (AzureDataLakeState) {
        var payload = {
          ...azureData,
          name: crawlName,
          product: {
            id: selectedProduct.product?.id,
          },
          datasourceConnectionFqn: selectedConnection,
          id: "",
          isAutoCrawl: false,
          cronSchedule: cronScheduler,
        };
      } else {
        var payload = {
          cronSchedule: cronScheduler,
          datasourceConnectionFqn: selectedConnection,
          name: crawlName,
          product: {
            id: selectedProduct.product?.id,
          },
          id: "",
          isAutoCrawl: false,
          schemaInclusiveFilterPatterns: schemaInclusivePatterns,
          schemaExclusiveFilterPatterns: schemaExclusivePatterns,
          tableInclusiveFilterPatterns: tableInclusivePatterns,
          tableExclusiveFilterPatterns: tableExclusivePatterns,
        };
      }
    }
    if (
      window.location.pathname.toLowerCase() ==
      `/metadatacrawl/${selectedConnection}`
    ) {
      if (AzureDataLakeState) {
        var payload = {
          ...azureData,
          name: crawlName,
          product: {
            id: connectionData.productId,
          },
          datasourceConnectionFqn: selectedConnection,
          id: "",
          isAutoCrawl: false,
          cronSchedule: cronScheduler,
        };
      } else {
        console.log(connectionData, "connectionData");
        var payload = {
          id: "",
          name: crawlName,
          datasourceConnectionFqn: selectedConnection,
          product: {
            id: connectionData.productId,
          },
          schemaInclusiveFilterPatterns: schemaInclusivePatterns,
          schemaExclusiveFilterPatterns: schemaExclusivePatterns,
          tableInclusiveFilterPatterns: tableInclusivePatterns,
          tableExclusiveFilterPatterns: tableExclusivePatterns,
          isAutoCrawl: false,
          cronSchedule: cronScheduler,
        };
      }
    }
    console.log(payload, "payload");
    try {
      let response = await axios.post(
        `${MARTBUILDER_API}/metadatacrawl/`,
        payload,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      if (response.status === 200 || response.status === 201) {
        // alert("Successful!");

        console.log(response.data, "response.body");
        runCrawlAPI(token, response?.data, selectedConnection);
        toast.success("Crawl started successfully !. ", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
        if (err.response.status === 200) {
          alert(err.message);
        } else if (err.response.status === 500) {
          alert(err.message);
        } else if (err.response.status === 404) {
          alert(err.message);
        }
      }
    } finally {
      // dispatch({ type: "END_BACKDROP_LOADING" });
      // navigate("/manageMetadatacrawls");
    }
  };

  const options = [];
  for (let i = 0; i < 60; i++) {
    options.push(
      <option key={i} value={i}>
        {i} Min
      </option>
    );
  }

  // const hours = [];
  // for (let i = -1; i < 24; i++) {
  //   if (i == -1) {
  //     hours.push(
  //       <option key={i} value={""}>
  //         Every Hour
  //       </option>
  //     );
  //   } else {
  //     hours.push(
  //       <option key={i} value={i}>
  //         {i} Hour
  //       </option>
  //     );
  //   }
  // }

  const hours = [];
  for (let i = -1; i < 24; i++) {
    if (i === -1) {
      hours.push(<option key={i} value=""></option>);
    } else if (i === 0) {
      hours.push(
        <option key={i} value="*">
          Every Hour
        </option>
      );
    } else {
      hours.push(
        <option key={i} value={i}>
          {i} Hour
        </option>
      );
    }
  }

  // const days = [];
  // for (let i = 0; i < 32; i++) {
  //   if (i == 0) {
  //     days.push(
  //       <option key={i} value={i}>
  //         Everyday
  //       </option>
  //     );
  //   } else {
  //     days.push(
  //       <option key={i} value={i}>
  //         {i} Day
  //       </option>
  //     );
  //   }
  // }

  const days = [];
  for (let i = -1; i < 32; i++) {
    if (i === -1) {
      days.push(<option key={i} value=""></option>);
    } else if (i === 0) {
      days.push(
        <option key={i} value={"*"}>
          Everyday
        </option>
      );
    } else {
      days.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
  }

  const months = [
    { value: "", label: "" },
    { value: "*", label: "Every Month" },
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const weekDays = [
    { value: "", label: "" },
    { value: "*", label: "Everyday" },
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];

  const handleClear = () => {
    setSelectedValues({
      minute: "",
      hour: "",
      dayOfMonth: "",
      month: "",
      dayOfWeek: "",
    });
    setCurrentValue("");
  };

  const handleBlur = () => {
    const intValue = parseInt(currentValue, 10);
    if (intValue > 59) {
      alert("Minute should be lower than 60");
      setCurrentValue(60);
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{ textAlign: "left" }}>
      {window.location.pathname.toLowerCase() ==
        `/metadatacrawl/${selectedConnection}` && (
        <BreadcrumbMUI
          path1={"/myconnections"}
          name1={"My Connections"}
          path2={`/connectiondetail/${selectedConnection}`}
          name2={connectionNameState}
          last={selectedConnection}
        />
      )}

      {window.location.pathname.toLowerCase() ==
        "/publishmetadata" && (
        <BreadcrumbMUI
          path1={"/manageMetadatacrawls"}
          name1={"Manage Metadata"}
          last={"Publish Metadata"}
        />
      )}
      {window.location.pathname.toLowerCase() ==
        `/metadatacrawl/${selectedConnection}` && (
        <h3 className="pageHeading">Metadata Crawl</h3>
      )}
      {window.location.pathname.toLowerCase() ==
        "/publishmetadata" && (
        <h3 className="pageHeading">Add New Metadata Crawl</h3>
      )}
      <div className="grid-container mainDSwrapper">
        <div className="grid-item">
          <label className="labelStyle" htmlFor="crawlName">
            Crawl Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
            id="crawlName"
            name="crawlName"
            placeholder="Enter Crawl Name"
            value={crawlName}
            onChange={(e) => setCrawlName(e.target.value)}
            required
          />
          {connNameWarning && (
            <p style={{ color: "red" }}>Please enter the crawl name.</p>
          )}
        </div>
        <div className="grid-item center">
          <label className="labelStyle" htmlFor="connectionName">
            Connection Name{" "}
          </label>
          <div className="subRo">
            {window.location.pathname.toLowerCase() ==
              `/metadatacrawl/${selectedConnection}` && (
              <h5> {connectionNameState} </h5>
            )}
            {window.location.pathname.toLowerCase() ==
              "/publishmetadata" && (
              // <>
              //   <select
              //     name="connectionName"
              //     id="connectionName"
              //     placeholder="Connection Name"
              //     required
              //     onChange={(e) => {
              //       setSelectedConnection(e.target.value);
              //       setConnWarning(false);
              //       const productFilter = dataSource.filter(
              //         (item) => item.connectionName === e.target.value
              //       );
              //       setSelectedProduct(productFilter);
              //     }}
              //   >
              //     <option value="" selected="selected">
              //       Select Connection Name
              //     </option>
              //     {dataSource?.map((item) => (
              //       <option value={item.connectionName}>
              //         {item.connectionName}
              //       </option>
              //     ))}
              //   </select>
              //   {connWarning && (
              //     <p style={{ color: "red" }}>
              //       Please select the Connection Name
              //     </p>
              //   )}
              // </>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                {/* {selectedProduct?.map((item) => ( */}
                <h5>{selectedProduct?.connectionName}</h5>
                {/* ))} */}
              </div>
            )}
          </div>
        </div>
        <div className="grid-item center">
          <label className="labelStyle" htmlFor="connectionName">
            Product Name
          </label>
          {window.location.pathname.toLowerCase() ==
            `/metadatacrawl/${selectedConnection}` && (
            <h5> {productName} </h5>
          )}
          {window.location.pathname.toLowerCase() ==
            "/publishmetadata" && (
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              {/* {selectedProduct?.map((item) => ( */}
              <h5>{selectedProduct?.product?.name}</h5>
              {/* ))} */}
            </div>
          )}
        </div>

        {window.location.pathname.toLowerCase() ==
          `/metadatacrawl/${selectedConnection}` && (
          <div className="grid-item center">
            <label className="labelStyle" htmlFor="connectionName">
              Selected Database
            </label>
            <div style={{ display: "flex" }}>
              <img
                src={databaseImages[datasourceTypeState]}
                alt={datasourceTypeState}
                style={{ width: "30px" }}
              />
              <h5> {datasourceTypeState} </h5>
            </div>
          </div>
        )}
        {window.location.pathname.toLowerCase() ==
          "/publishmetadata" && (
          <div className="grid-item center">
            <label className="labelStyle" htmlFor="connectionName">
              Selected Database
            </label>
            {/* {!selectedProduct && <h5> </h5>} */}
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <img
                src={databaseImages[selectedProduct?.datasourceType]}
                alt={selectedProduct?.datasourceType}
                style={{ width: "30px" }}
              />
              <h5> {selectedProduct?.datasourceType} </h5>
            </div>
          </div>
        )}

        {window.location.pathname.toLowerCase() ==
          `/metadatacrawl/${selectedConnection}` && (
          <div className="grid-item">
            <label className="labelStyle" htmlFor="connectionName">
              Choose Datasource Connection{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ padding: "0px" }}
              options={dataSourceOptions}
              sx={{ width: "auto", padding: "0px" }}
              onChange={handleDataSource}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              value={
                dataSourceOptions.find(
                  (option) => option.value === selectedConnection
                ) || null
              }
              disabled={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Datasource"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "0px 6px",
                      height: "40px",
                      lineHeight: "30px",
                      fontSize: "14px",
                    },
                  }}
                />
              )}
            />
          </div>
        )}
        {window.location.pathname.toLowerCase() ==
          "/publishmetadata" && (
          <div className="grid-item">
            <label className="labelStyle" htmlFor="connectionName">
              Choose Datasource Connection{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ padding: "0px" }}
              options={dataSourceOptions}
              sx={{ width: "auto", padding: "0px", backgroundColor: "#ffffff" }}
              onChange={handleDataSource}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Datasource"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: "0px 6px",
                      height: "40px",
                      lineHeight: "30px",
                      fontSize: "14px",
                    },
                  }}
                />
              )}
            />
            {connWarning && (
              <p style={{ color: "red" }}>
                Please select the Datasource Connection.
              </p>
            )}
          </div>
        )}

        <div className="grid-item"> </div>
        <div className="grid-item"> </div>
        <div className="grid-item"> </div>
        {AzureDataLakeState && (
          <AzureDataLakeForm
            azureData={azureData}
            setAzureData={setAzureData}
          />
        )}
        {!AzureDataLakeState && (
          <>
            <div className="grid-item side-by-side">
              <label className="labelStyle" htmlFor="connectionName">
                Schema Filter Pattern
              </label>
              <input
                type="checkbox"
                name="schemaFilterPattern"
                id="filterCheckbox"
                style={{height:"40px"}}
                onChange={(e) => setShowSchemaPatterns(e.target.checked)}
              />
            </div>
            <div className="grid-item left-align"> </div>
            <div className="grid-item"> </div>
            <div className="grid-item"> </div>

            {showSchemaPatterns && (
              <>
                <div className="grid-item">
                  <label className="labelStyle" htmlFor="connectionName">
                    Schema Inclusive Pattern
                  </label>
                  <input
                    style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                    type="text"
                    id="schemaFilterPattern"
                    name="schemaFilterPattern"
                    placeholder="Schema Inclusive Pattern"
                    value={schemaInclusivePatternInput}
                    onChange={(e) =>
                      setSchemaInclusivePatternInput(e.target.value)
                    }
                  />
                </div>

                <div className="grid-item">
                  <button
                    className="job-btn btn"
                    onClick={handleAddSchemaInclusivePattern}
                  >
                    Add
                  </button>
                </div>
                <div
                  className={`grid-item ${
                    schemaInclusivePatterns.length > 0 ? "grey-border" : ""
                  }`}
                >
                  {schemaInclusivePatterns.map((pattern, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        flexWrap: "wrap",
                        width: "max-content",
                      }}
                    >
                      <p>{pattern}</p>
                      <p
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() =>
                          handleRemoveSchemaInclusivePattern(index)
                        }
                      >
                        x
                      </p>
                    </div>
                  ))}
                </div>
                <div className="grid-item"> </div>

                <div className="grid-item">
                  <label className="labelStyle" htmlFor="connectionName">
                    Schema Exclusive Pattern
                  </label>
                  <input
                    style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                    type="text"
                    id="tableFilterPattern"
                    name="tableFilterPattern"
                    placeholder="Schema Exclusive Pattern"
                    value={schemaExclusivePatternInput}
                    onChange={(e) =>
                      setSchemaExclusivePatternInput(e.target.value)
                    }
                  />
                </div>
                <div className="grid-item">
                  <button
                    className="job-btn btn"
                    onClick={handleAddSchemaExclusivePattern}
                  >
                    Add
                  </button>
                </div>

                <div
                  className={`grid-item ${
                    schemaExclusivePatterns.length > 0 ? "grey-border" : ""
                  }`}
                >
                  {schemaExclusivePatterns.map((pattern, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        flexWrap: "wrap",
                        width: "max-content",
                      }}
                    >
                      <p>{pattern}</p>
                      <p
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() =>
                          handleRemoveSchemaExclusivePattern(index)
                        }
                      >
                        x
                      </p>
                    </div>
                  ))}
                </div>

                <div className="grid-item"> </div>
              </>
            )}

            <div className="grid-item side-by-side">
              <label className="labelStyle" htmlFor="connectionName">
                Table Filter Pattern
              </label>
              <input
                type="checkbox"
                style={{height:"40px"}}
                name=""
                id="filterCheckbox"
                onChange={(e) => setShowTablePatterns(e.target.checked)}
              />
            </div>
            <div className="grid-item"> </div>
            <div className="grid-item"> </div>
            <div className="grid-item"> </div>

            {showTablePatterns && (
              <>
                <div className="grid-item">
                  <label className="labelStyle" htmlFor="tableInclusivePattern">
                    Table Inclusive Pattern
                  </label>
                  <input
                    style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                    type="text"
                    id="tableInclusivePattern"
                    name="tableInclusivePattern"
                    placeholder="Table Inclusive Pattern"
                    value={tableInclusivePatternInput}
                    onChange={(e) =>
                      setTableInclusivePatternInput(e.target.value)
                    }
                  />
                </div>
                <div className="grid-item">
                  <button
                    className="job-btn btn"
                    onClick={handleAddTableInclusivePattern}
                  >
                    Add{" "}
                  </button>
                </div>
                {/* <div className="grid-item">15</div> */}
                <div
                  className={`grid-item ${
                    tableInclusivePatterns.length > 0 ? "grey-border" : ""
                  }`}
                >
                  {tableInclusivePatterns.map((pattern, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        flexWrap: "wrap",
                        width: "max-content",
                      }}
                    >
                      <p>{pattern}</p>
                      <p
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => handleRemoveTableInclusivePattern(index)}
                      >
                        x
                      </p>
                    </div>
                  ))}
                </div>
                <div className="grid-item"> </div>

                <div className="grid-item">
                  <label className="labelStyle" htmlFor="tableExclusivePattern">
                    Table Exclusive Pattern
                  </label>
                  <input
                    style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                    type="text"
                    id="tableExclusivePattern"
                    name="tableExclusivePattern"
                    placeholder="Table Exclusive Pattern"
                    value={tableExclusivePatternInput}
                    onChange={(e) =>
                      setTableExclusivePatternInput(e.target.value)
                    }
                  />
                </div>
                <div className="grid-item">
                  <button
                    className="job-btn btn"
                    onClick={handleAddTableExclusivePattern}
                  >
                    Add{" "}
                  </button>
                </div>
                {/* <div className="grid-item">15</div> */}

                <div
                  className={`grid-item ${
                    tableExclusivePatterns.length > 0 ? "grey-border" : ""
                  }`}
                >
                  {tableExclusivePatterns.map((pattern, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        flexWrap: "wrap",
                        width: "max-content",
                      }}
                    >
                      <p>{pattern}</p>
                      <p
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => handleRemoveTableExclusivePattern(index)}
                      >
                        x
                      </p>
                    </div>
                  ))}
                </div>

                <div className="grid-item"> </div>
              </>
            )}

            <div className="grid-item side-by-side">
              {/* <label className="labelStyle"  htmlFor="connectionName" >

            Is Auto Crawl
          </label>
          <input
            type="checkbox"
            name=""
            id="filterCheckbox"
            onChange={(e) => setShowCronScheduleField(e.target.checked)}
          /> */}
            </div>
          </>
        )}
        <div className="grid-item"></div>
        <div className="grid-item"></div>
        <div className="grid-item"></div>

        {/* {showCronScheduleField && (
          <> */}

        <div className="grid-item">
          <hr style={{ margin: "5px 0px" }} />
          <label htmlFor="tableExclusivePattern" className="labelStyle py-3">
            Crawl Schedule
          </label>
          {/* <label htmlFor="tableExclusivePattern">Cron Schedule Field</label>
              <input
                style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                type="text"
                id="cronScheduleField"
                name="cronScheduleField"
                placeholder="Cron Schedule Field"
                value={cronSchedule}
                onChange={(e) => setCronSchedule(e.target.value)}
              /> */}
          <div className="" style={{ display: "flex", marginBottom: "20px" }}>
            <div className="lastRow">
              <div>
                <div>
                  <label className="labelStyle" htmlFor="dayOfMonthSelect">
                    Day of Month
                  </label>
                </div>
                <div>
                  <select
                    name="dayOfMonth"
                    id="dayOfMonthSelect"
                    placeholder=""
                    value={selectedValues.dayOfMonth}
                    onChange={handleChange}
                  >
                    {days}
                  </select>
                </div>
              </div>

              <div>
                <div>
                  <label className="labelStyle" htmlFor="monthSelect">
                    Month
                  </label>
                </div>

                <div>
                  <select
                    name="month"
                    id="monthSelect"
                    className="month-select"
                    value={selectedValues.month}
                    onChange={handleChange}
                  >
                    {months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <div>
                  <label className="labelStyle" htmlFor="dayOfWeekSelect">
                    Day (Week)
                  </label>
                </div>
                <div>
                  <select
                    name="dayOfWeek"
                    id="dayOfWeekSelect"
                    placeholder=""
                    value={selectedValues.dayOfWeek}
                    onChange={handleChange}
                  >
                    {weekDays.map((weekday) => (
                      <option key={weekday.value} value={weekday.value}>
                        {weekday.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <div>
                  <label className="labelStyle" htmlFor="hourSelect">
                    Hour
                  </label>
                </div>
                <div>
                  <select
                    name="hour"
                    id="hourSelect"
                    placeholder=""
                    value={selectedValues.hour}
                    onChange={handleChange}
                  >
                    {hours}
                  </select>
                </div>
              </div>

              <div>
                <div>
                  <label className="labelStyle" htmlFor="minuteSelect">
                    Minute
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    id="minuteSelect"
                    name="minute"
                    placeholder="00"
                    min="0"
                    max="59"
                    value={selectedValues.minute}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                  />
                  {/* <select
                        name="minute"
                        id="minuteSelect"
                        placeholder="Min"
                        value={selectedValues.minute}
                        onChange={handleChange}
                      >
                        {options}
                      </select> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-item side-by-side-left">
          <button
            className="job-btn-clear btn nar-wid"
            style={{ background: "#FC4100", marginBottom: "18px", border:"none", borderRadius:"4px" }}
            onClick={handleClear}
          >
            Clear
          </button>
          <InfoOutlinedIcon
            style={{
              marginBottom: "23px",
              marginLeft: "5px",
              cursor: "pointer",
            }}
            onClick={handleDialogOpen}
          />
        </div>
        <div className="grid-item"> </div>
        <div className="grid-item"> </div>
        {/* </>
        )} */}
      </div>
      <CronInfoModal open={openDialog} onClose={handleDialogClose} />
      <div className="btnWrapper">
        <button
          className="job-btn btn"
          onClick={() => createPayload(selectedValues)}
        >
          Submit & Start Crawl
        </button>
      </div>
    </div>
  );
};

export default MetadataCrawl;
