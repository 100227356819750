import { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularLoader from "../../components/NewComponents/Atoms/CircularLoader";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { Delete, Edit, CheckCircle, Cancel } from "@mui/icons-material";
import axios from "axios";
import { userRequestMartbuilder } from "../../serverConfig";
import { AuthContext } from "../../AuthContext";
import StatusModal from "./statusModal/StatusModal";

function RunStatusModal({ boolean, onOpen, singleRowData }) {
  const [runs, setRuns] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [status, setStatus] = useState();
  //setting the jobType to pass as a prop and used in Status modal API as a query
  const [jobType, setJobType] = useState();
  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);
  //array for pushing jobname and utilized in run status api
  const [batchIngJobs, setBatchIngJobs] = useState([]);
  const [martBuilderJobs, setMartBuilderJobs] = useState([]);
  //below usestate to store response for batch ingestion
  const [batchData, setBatchData] = useState();
  //below useState for storing three batch data seprately
  const [ingestionData, setIngestionData] = useState();
  const [activeData, setActiveData] = useState();
  const [cleansedData, setCleansedData] = useState();
  //to show loader
  const [isLoadingIngestion, setIsLoadingIngestion] = useState(false);

  const handleOpenStatusModal = (item) => {
    setStatus(item);
    setOpenStatusModal(!openStatusModal);
  };

  const dag_Name = [singleRowData?.jobName];

  useEffect(() => {
    if (singleRowData) {
      setJobType(singleRowData?.jobType);
    }
  }, [singleRowData]);

  //below code for adding suffix and removing star
  useEffect(() => {
    if (boolean && singleRowData) {
      const { jobType, jobName } = singleRowData;
      const modifiedJobName = jobName.replace(/\*/g, "_");
      if (jobType === "BATCH_INGESTION") {
        setBatchIngJobs([modifiedJobName]);
      } else if (jobType === "MART_BUILDER") {
        setMartBuilderJobs([jobName]);
      }
    }
  }, [boolean, singleRowData]);

  // useEffect(() => {
  //   if (boolean && singleRowData) {
  //     const { jobType, jobName } = singleRowData;
  //     if (jobType === "BATCH_INGESTION") {
  //       const suffixes = ["_active", "_cleansed", "_ingestion"];
  //       const updatedBatchIngJobs = suffixes.map(
  //         (suffix) => `${jobName.replace(/\*/g, "_")}${suffix}`
  //       );
  //       setBatchIngJobs((prevBatchIngJobs) => [
  //         ...prevBatchIngJobs,
  //         updatedBatchIngJobs,
  //       ]);
  //     } else if (jobType === "MART_BUILDER") {
  //       setMartBuilderJobs((prevMartBuilderJobs) => [
  //         ...prevMartBuilderJobs,
  //         jobName,
  //       ]);
  //     }
  //   }
  // }, [boolean, singleRowData]);

  //api call for run status
  const runStatusPayload = {
    BATCH_INGESTION: batchIngJobs,
    MART_BUILDER: martBuilderJobs,
  };

  useEffect(() => {
    if (
      runStatusPayload?.BATCH_INGESTION.length > 0 ||
      runStatusPayload?.MART_BUILDER.length > 0
    ) {
      const ourRequest = axios.CancelToken.source();

      const fetchJobs = async () => {
        setIsLoading(true);
        try {
          let response = await userRequestMartbuilder.post(
            `/jobs/dag/runs`,
            runStatusPayload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              CancelToken: ourRequest.token,
            }
          );

          if (response?.data && response.data[dag_Name]) {
            setRuns(response?.data[dag_Name].dagRuns?.dag_runs);
          }
          setIsLoading(false);
          setBatchData(response?.data);
        } catch (err) {
          if (axios.isCancel(err)) {
            return;
          } else {
            console.error(err);
          }
        }
      };
      fetchJobs();
      return () => {
        ourRequest.cancel();
      };
    }
  }, [singleRowData, batchIngJobs, martBuilderJobs]);

  useEffect(() => {
    setIngestionData(true);
    if (batchData) {
      const jobTypes = {
        ingestion: batchIngJobs[0],
        active: batchIngJobs[1],
        cleansed: batchIngJobs[2],
      };
      setIngestionData(batchData[jobTypes.ingestion]?.dagRuns?.dag_runs);
      setActiveData(batchData[jobTypes.active]?.dagRuns?.dag_runs);
      setCleansedData(batchData[jobTypes.cleansed]?.dagRuns?.dag_runs);
    }
    setIsLoadingIngestion(false);
  }, [batchData, batchIngJobs, ingestionData, activeData, cleansedData]);

  if (isLoading) {
    return (
      <Dialog open={boolean} onClose={onOpen} maxWidth={"md"} fullWidth>
        <DialogContent
          style={{
            height: "360px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularLoader />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Run Status
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          {jobType === "BATCH_INGESTION" && (
            <>
              {/* <Typography>Run Status</Typography> */}
              {ingestionData && ingestionData.length > 0 ? (
                ingestionData.map((item) =>
                  item.state === "success" ? (
                    <Tooltip key={item.dag_run_id} title={item.execution_date}>
                      <CheckCircle
                        color="success"
                        sx={{
                          p: "0px 0.6px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenStatusModal(item)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip key={item.dag_run_id} title={item.execution_date}>
                      <Cancel
                        color="error"
                        sx={{ p: "0px 0.6px", cursor: "pointer" }}
                        onClick={() => handleOpenStatusModal(item)}
                      />
                    </Tooltip>
                  )
                )
              ) : (
                <Typography>No Data Found</Typography>
              )}
              <br />

              {/* <Typography>Active Run Status</Typography>
              {activeData && activeData.length > 0 ? (
                activeData.map((item) =>
                  item.state === "success" ? (
                    <Tooltip key={item.dag_run_id} title={item.execution_date}>
                      <CheckCircle
                        color="success"
                        sx={{
                          p: "0px 0.6px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenStatusModal(item)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip key={item.dag_run_id} title={item.execution_date}>
                      <Cancel
                        color="error"
                        sx={{ p: "0px 0.6px", cursor: "pointer" }}
                        onClick={() => handleOpenStatusModal(item)}
                      />
                    </Tooltip>
                  )
                )
              ) : (
                <Typography>No Data Found</Typography>
              )}

              <br /> */}
              {/* <Typography>Cleansed Run Status</Typography>
              {cleansedData && cleansedData.length > 0 ? (
                cleansedData.map((item) =>
                  item.state === "success" ? (
                    <Tooltip key={item.dag_run_id} title={item.execution_date}>
                      <CheckCircle
                        color="success"
                        sx={{
                          p: "0px 0.6px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenStatusModal(item)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip key={item.dag_run_id} title={item.execution_date}>
                      <Cancel
                        color="error"
                        sx={{ p: "0px 0.6px", cursor: "pointer" }}
                        onClick={() => handleOpenStatusModal(item)}
                      />
                    </Tooltip>
                  )
                )
              ) : (
                <Typography>No Data Found</Typography>
              )} */}
            </>
          )}

          {jobType === "MART_BUILDER" && (
            <>
              {runs && runs.length > 0
                ? runs.map((item) =>
                    item.state === "success" ? (
                      <Tooltip
                        key={item.dag_run_id}
                        title={item.execution_date}
                      >
                        <CheckCircle
                          color="success"
                          sx={{
                            p: "0px 0.6px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenStatusModal(item)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key={item.dag_run_id}
                        title={item.execution_date}
                      >
                        <Cancel
                          color="error"
                          sx={{ p: "0px 0.6px", cursor: "pointer" }}
                          onClick={() => handleOpenStatusModal(item)}
                        />
                      </Tooltip>
                    )
                  )
                : jobType === "MART_BUILDER" && (
                    <Typography>No Data Found</Typography>
                  )}
            </>
          )}
          {openStatusModal && (
            <StatusModal
              jobType={jobType}
              onOpen={handleOpenStatusModal}
              boolean={openStatusModal}
              Item={status}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RunStatusModal;
