import React from "react";
import "../../../styles/Linear-fill-bar.style.css";

const LinearFillBar = ({ percentage, color = "#4caf50" }) => {
  return (
    <div className="progress-bar">
      <div className="progress-fill" style={{ width: percentage, backgroundColor: color }}>
        <span className="progress-text">{percentage}</span>
      </div>
    </div>
  );
};

export default LinearFillBar;