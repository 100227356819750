import React, { useState, createContext, useEffect, useCallback } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { protectedResources } from "./msalConfig";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const [error, setError] = useState();
  const [token, setToken] = useState(null);
  const [dctoken, setDCToken] = useState(null);
  const [dcerror, setDCError] = useState();

  const getToken = useCallback(async () => {
    // if (isAuthenticated) {
      try {
        const request = {
          scopes: protectedResources.databook.scopes,
          account: account,
        };
        const tokenRes = await instance.acquireTokenSilent(request);
        setToken(tokenRes.accessToken);
        console.log(tokenRes.accessToken)
      } catch (err) {
        setError(err);
      }
    // }
  }, [instance, isAuthenticated, account]);

  const getDatacraftToken = useCallback(async () => {
    // if (isAuthenticated) {
      try {
        const request = {
          scopes: protectedResources.datacraft.scopes,
          account: account,
        };
        const tokenRes = await instance.acquireTokenSilent(request);
        setDCToken(tokenRes.accessToken);
      } catch (err) {
        setDCError(err);
      }
    // }
  }, [instance, isAuthenticated, account]);
// console.log("::")
  return (
    <AuthContext.Provider value={{ error, token, getToken, dcerror, dctoken, getDatacraftToken, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
