import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

const EditTagForm = (props) => {
  const {
    handleEditTagClose,
    edittagshow,
    handleSubmitedittags,
    tagdata,
    descdata,
    setTagiddesc,
    tagname,
  } = props;

  const schema = yup.object().shape({
    description: yup.string().required(),
  });
  return (
    <>
      <Modal show={edittagshow} onHide={handleEditTagClose} centered>
        <Modal.Header className="title__header">
          <Modal.Title>
            <h4>Edit Tag</h4>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={console.log}
            initialValues={{
              description: "",
            }}
          >
            {({ errors }) => (
              <Form className="form__view" onSubmit={handleSubmitedittags}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="label__header__name">
                    <span>Tag Name</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="tagname"
                    readOnly
                    disabled
                    // defaultValue={tagdata}
                    defaultValue={tagname}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="label__header__name">
                    <span>Description</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    isInvalid={!!errors.description}
                    defaultValue={descdata}
                    onChange={(e) => setTagiddesc(e.target.value)}
                  />
                </Form.Group>
                <div className="button_save_close">
                  <Button
                    variant="secondary pop__btn"
                    onClick={handleEditTagClose}
                  >
                    Close
                  </Button>
                  <Button type="submit" className="pop__btn enabled_btn">
                    Save Changes
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTagForm;
