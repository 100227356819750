import React, { useEffect, useState } from "react";

const AzureDataLake = ({
  connectionData,
  onSubmitFun,
  testConnectionSuccess,
  submissionSuccess,
  showLoading,
  editConnectionCred,
  isEditFlag,
}) => {
  const [connectionCreds, setConnectionCreds] = useState({
    tenantId: "",
    accountName: "",
    clientId: "",
    clientSecret: "",
  });
  useEffect(() => {
    const { tenantId, accountName, clientId, clientSecret } =
      editConnectionCred || {};
    if (isEditFlag) {
      setConnectionCreds({ tenantId, accountName, clientId, clientSecret });
    }
  }, [editConnectionCred]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionCreds((prevCreds) => ({
      ...prevCreds,
      [name]: value,
    }));
  };

  const handleSubmit = (isTestConnection) => {
    console.log("HI");
    const formData = {
      ...connectionData,
      connectionCreds,
    };
    if (onSubmitFun) {
      console.log(formData, "formData");
      console.log(isTestConnection, "isTestConnection");
      onSubmitFun(formData, isTestConnection);
    }
  };

  return (
    <div className="form-container">
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="tenantId">
            Tenant Id <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="tenantId"
            name="tenantId"
            placeholder="Tenant Id"
            // value={connectionData.tenantId}
            value={connectionCreds.tenantId}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="accountName">
            Account Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="accountName"
            name="accountName"
            placeholder="Account Name"
            value={connectionCreds.accountName}
            // value={connectionData.accountName}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>

      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="clientId">
            Client Id <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="clientId"
            name="clientId"
            placeholder="Client Id"
            value={connectionCreds.clientId}
            // value={connectionData.clientId}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="clientSecret">
            Client Secret <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="password" // Using type "password" to hide input for security
            id="clientSecret"
            name="clientSecret"
            placeholder="Client Secret"
            value={connectionCreds.clientSecret}
            // value={connectionData.clientSecret}
            onChange={handleChange}
            required
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="valign_bottom">
          <button
            className="job-btn btn nar-wid1"
            onClick={() => handleSubmit(true)}
            disabled={testConnectionSuccess}
            // disabled={!testConnectionSuccess || submissionSuccess}
          >
            Check Connection
          </button>
        </div>
      </div>
      <hr></hr>
      <div className="individualRow" style={{ marginTop: "20px" }}>
        {!isEditFlag && !testConnectionSuccess ? (
          <button
            className="job-btn btn"
            type="submit"
            // onSubmit={handleSubmit}
            onClick={() => handleSubmit(false)}
            disabled={!testConnectionSuccess}
          >
            Submit
          </button>
        ) : (
          ""
        )}
        {console.log("edEit : ", isEditFlag)}
        {console.log("testConnectionSuccess : ", testConnectionSuccess)}
        {isEditFlag && testConnectionSuccess ? (
          <button
            className="job-btn btn"
            type="submit"
            // onSubmit={handleSubmit}
            onClick={() => handleSubmit(false)}
            disabled={!testConnectionSuccess}
          >
            Submit
          </button>
        ) : (
          ""
        )}
        {/* <button
          className="job-btn btn"
          type="submit"
          // onSubmit={handleSubmit}
          onClick={() => handleSubmit(false)}
          disabled={!testConnectionSuccess}
        >
          Submit
        </button> */}
        {/* <button
          className="job-btn btn nar-wid"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button> */}
      </div>
    </div>
  );
};

export default AzureDataLake;
