import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import { NavBarAndLandingPageContext } from "../NavBarContextAndLandingPageContext"
import Banner from "../components/NewComponents/Banner"
import { truncateText } from "../utils/functions"
import { OverlayTrigger } from "react-bootstrap"

function LandingPage(props) {
  const navigate = useNavigate()

  const { browseData, browseFetching, recentSearch } = useContext(
    NavBarAndLandingPageContext
  )

  const [criteria, setCriteria] = useState("dataset-name")
  const [searchTerm, setSearchTerm] = useState(" ")

  const handleChange = (event) => {
    setCriteria(event.target.value)
  }

  const handleCardClick = (subCategory, category) => {
    navigate(
      `/SearchResults/${criteria}/${searchTerm}/${category.name}/${subCategory.name}`
    )
  }

  return (
    <div style={{ width: "100%" }}>
      {/* <Navbar /> */}
      <Banner />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            width: "58rem",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "4rem auto 0 auto",
          }}
        >
          <FormControl sx={{ m: 0, minWidth: 150 }} size="small">
            <InputLabel id="demo-select-small-label">Category</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={criteria}
              label="Category"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    padding: "0.6rem",
                    height: "18rem",
                  },
                },
              }}
            >
              <ListSubheader
                sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
              >
                <span>Dataset</span>
              </ListSubheader>
              <MenuItem value={"dataset-name"}>Name</MenuItem>
              <MenuItem value={"dataset-desc"}>Description</MenuItem>
              <MenuItem value={"dataset-tag"}>Tag</MenuItem>
              <MenuItem value={"dataset-classification"}>
                Classification
              </MenuItem>
              <MenuItem value={"dataset-sensitivity"}>Sensitivity</MenuItem>
              <ListSubheader
                sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
              >
                <span>Column</span>
              </ListSubheader>
              <MenuItem value={"column-name"}>Name</MenuItem>
              <MenuItem value={"column-desc"}>Description</MenuItem>
              <MenuItem value={"column-tag"}>Tag</MenuItem>
              <MenuItem value={"column-classification"}>
                Classification
              </MenuItem>
              <MenuItem value={"column-sensitivity"}>Sensitivity</MenuItem>
              <ListSubheader
                sx={{ padding: "0", lineHeight: "1.4", position: "static" }}
              >
                <span>Others</span>
              </ListSubheader>
              <MenuItem value={"all"}>All</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <FormControl
              sx={{ m: 0, width: 590 }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-search">
                Search here
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    navigate(`/SearchResults/${criteria}/${searchTerm}`)
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        backgroundColor: "#d8d8d8",
                        borderRadius: "4px",
                      }}
                      aria-label="SearchIcon"
                      onClick={() => {
                        navigate(`/SearchResults/${criteria}/${searchTerm}`)
                      }}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search here"
              />
            </FormControl>
            {recentSearch.length !== 0 && (
              <Typography
                color="text.secondary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Recent searches:{"  "}
                {recentSearch?.slice(0, 6).map((rec, idx) => (
                  <p
                    key={idx}
                    className="dhc-header-message-classi m-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/SearchResults/${rec.searchedCriteria}/${rec.searchedKeyword}`
                      )
                    }}
                  >
                    {rec.searchedKeyword}
                  </p>
                ))}{" "}
              </Typography>
            )}
          </div>
        </div>
        {/* <div style={{ alignSelf: "flex-start", marginLeft: "30%" }}></div> */}
      </div>
      {browseFetching ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "6rem",
          }}
        >
          <Skeleton variant="rounded" width={"70%"} height={140} />
          <Skeleton variant="rounded" width={"70%"} height={140} />
        </div>
      ) : (
        <>
          {/* {browseData.catalogs[0]?.categories?.map((category) => ( */}
          {browseData?.map((category) => (
            <div
              key={category.name}
              style={{
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                // margin: "4rem auto",

                // border: "1px solid #d8d8d8",
                // borderRadius: "7px",
                width: "80%",
                // height: "310px",
                margin: "6rem auto",
              }}
            >
              <h5
                key={category.name}
                style={{ textAlign: "left", padding: "0 2rem" }}
              >
                {category.name}
                {" (" + category.subCategories.length + ")"}
              </h5>
              <div
                style={{
                  margin: "0 2rem",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                  gap: "1rem",
                  padding: "2rem",
                  backgroundColor: "#E6F4FF",
                  // borderRadius: "1rem",
                  height: "11rem",
                  overflow: "auto",
                }}
              >
                {category.subCategories?.map((subCategory, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="bottom"
                    delay={{ show: 150, hide: 280 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <span>{subCategory.name}</span>
                      </Tooltip>
                    }
                  >
                    <Card
                      sx={{
                        minWidth: 260,
                        height: 100,
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                      // TODO
                      onClick={() => handleCardClick(subCategory, category)}
                    >
                      <CardActionArea sx={{ height: "100%" }}>
                        <CardContent
                          key={subCategory.name}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <Avatar
                              sx={{ bgcolor: "#87CEFA", margin: "0 auto" }}
                              variant="rounded"
                            >
                              {subCategory.name.substring(0, 1)}
                            </Avatar>
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              // justifyContent: "flex-start",
                            }}
                          >
                            <Typography
                              variant="p"
                              component="div"
                              style={{ width: "60%" }}
                            >
                              {truncateText(subCategory.name)}
                              {/* {subCategory.name} */}
                            </Typography>
                            <Typography color="text.secondary">
                              {subCategory.count} assets
                            </Typography>
                            {/* <Typography variant="body2"> */}
                            {/* sub Additional info */}
                            {/* </Typography> */}
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </OverlayTrigger>
                ))}
              </div>
              {/* <Card className="w-[80%] h-[394px]">
          <CardHeader>
            <CardTitle>Organisation</CardTitle> */}
              {/* <CardDescription>Deploy your new project in one-click.</CardDescription> */}
              {/* </CardHeader> */}
              {/* <CardContent className='flex justify-start grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]'> */}
              {/* <ScrollArea className="h-[220px] p-4">
            <CardContent className="grid gap-2 p-0 grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr]">
              <Link href="/SearchResults">
                <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_1</Button>
              </Link>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_2</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_3</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_4</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_5</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_6</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_7</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_8</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_9</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_10</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_5</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_6</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_7</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_8</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_9</Button>
              <Button className="w-[9.4rem] h-[160px]" variant="outline">Org_10</Button>
            </CardContent>
          </ScrollArea> */}
              {/* <CardFooter className="flex justify-between">
            <Button variant="outline">Cancel</Button>
            <Button>Deploy</Button>
          </CardFooter> */}
              {/* </Card> */}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default LandingPage
