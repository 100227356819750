import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
 
const CreateTerm = (props) => {
  const {
    handleCreateTerm,
    setDescription,
    settermname,
    createtagshow,
    handleCreatetagClose,
    description,
    parentterm,
    termname,
    glossaryselect,
  } = props;
 
  const [showAlert, setShowAlert] = useState(false);
 
  const handleTermNameChange = (e) => {
    const value = e.target.value;
    if (value.includes(".")) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
    settermname(value);
  };
 
  const handleClose = () => {
    settermname("");
    setDescription("");
    handleCreatetagClose();
  };
 
  return (
    <>
      <Modal show={createtagshow} onHide={handleClose} centered>
        <Modal.Header className="title__header">
          <Modal.Title>
            <h4>Add Term</h4>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form__view" onSubmit={handleCreateTerm}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label__header__name__required">
                <span>Glossary Name</span>
              </Form.Label>
              <Form.Control
                placeholder="Glossary Name"
                type="invalid"
                disabled
                readOnly
                name="glossaryName"
                defaultValue={glossaryselect}
                autoFocus
              />
            </Form.Group>
 
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="label__header__name__required">
                <span>Term Name</span>
              </Form.Label>
 
              <Form.Control
                placeholder="Term Name"
                type="invalid"
                required
                value={termname}
                name="description"
                // onChange={(e) => settermname(e.target.value)}
                onChange={handleTermNameChange}
              />
              {showAlert && (
                <p style={{ color: "red" }}>Term name cannot contain "."</p>
              )}
            </Form.Group>
 
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="label__header__name">
                <span>Description</span>
              </Form.Label>
              <Form.Control
                placeholder="Description"
                type="invalid"
                value={description}
                name="description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
 
            <div className="button_save_close">
              <Button variant="secondary pop__btn" onClick={handleClose}>
                Close
              </Button>
              <Button
                type="submit"
                className="pop__btn enabled_btn"
                disabled={showAlert}
              >
                Save Changes
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
 
export default CreateTerm;