import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  linearProgressClasses,
  OutlinedInput,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { AuthContext } from "../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../msalConfig";
import axios from "axios";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LinearFillBar from "../components/NewComponents/Atoms/LinearFillBar";

function MetadataInsights(props) {
  const navigate = useNavigate();
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const [isFetching, setIsFetching] = useState(false);
  const [dataAssetHealth, setDataAssetHealth] = useState([]);
  const [businessUComplLeader, setBusinessUComplLeader] = useState([]);
  const [businessUComplWDesc, setBusinessUComplWDesc] = useState([]);
  const [filterBusinessUComplWDesc, setFilterBusinessUComplWDesc] = useState(
    []
  );
  const [domainsComplLeader, setDomainsComplLeader] = useState([]);
  const [domainsComplWDesc, setDomainsComplWDesc] = useState([]);
  const [filterDomainsComplWDesc, setFilterDoaminsComplWDesc] = useState([]);

  const [searchDesc, setSearchDesc] = useState("");
  const [searchDescDom, setSearchDescDom] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const metadataRankType = (val) => {
    if (val.rank > 3) {
      return "";
    } else if (val.rank === 1) {
      return "mtScore-gold";
    } else if (val.rank === 2) {
      return "mtScore-silver";
    } else if (val.rank === 3) {
      return "mtScore-bronze";
    }
  };

  function getRandomColor() {
    // var letters = '0123456789ABCDEF';
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;

    var colors = [
      "#0369a1",
      "#7dd3fc",
      "#1d4ed8",
      "#93c5fd",
      "#3b82f6",
      "#0ea5e9",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: getRandomColor(),
      ...theme.applyStyles("dark", {
        backgroundColor: getRandomColor(),
      }),
    },
  }));

  const fetchOrganizationDataAssetHealth = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/cards`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setDataAssetHealth(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchBusinessUnitsComplianceLeaderboard = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/business_unit/leaderboard`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setBusinessUComplLeader(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchBusinessUnitsComplianceWDesc = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/business_unit`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setBusinessUComplWDesc(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchDomainsComplianceLeaderboard = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/domain/leaderboard`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setDomainsComplLeader(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchDomainsComplianceWDesc = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/domain`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setDomainsComplWDesc(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (searchDesc === "") {
      setFilterBusinessUComplWDesc(businessUComplWDesc);
    } else if (searchDesc !== "") {
      const filteredData = businessUComplWDesc.filter((colValue) =>
        colValue.dimension.toLowerCase().includes(searchDesc.toLowerCase())
      );
      setFilterBusinessUComplWDesc(filteredData);
    }
  }, [searchDesc, businessUComplWDesc]);

  useEffect(() => {
    if (searchDescDom === "") {
      setFilterDoaminsComplWDesc(domainsComplWDesc);
    } else if (searchDescDom !== "") {
      const filteredData = domainsComplWDesc.filter((colValue) =>
        colValue.dimension.toLowerCase().includes(searchDescDom.toLowerCase())
      );
      setFilterDoaminsComplWDesc(filteredData);
    }
  }, [searchDescDom, domainsComplWDesc]);

  useEffect(() => {
    callapiwithtoken(fetchOrganizationDataAssetHealth);
    callapiwithtoken(fetchBusinessUnitsComplianceLeaderboard);
    callapiwithtoken(fetchBusinessUnitsComplianceWDesc);
    callapiwithtoken(fetchDomainsComplianceLeaderboard);
    callapiwithtoken(fetchDomainsComplianceWDesc);
  }, [token, instance]);

  return (
    <div style={{ width: "100%" }}>
      {isFetching ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "6rem",
          }}
        >
          <Skeleton variant="rounded" width={"70%"} height={450} />
          <Skeleton variant="rounded" width={"70%"} height={450} />
        </div>
      ) : (
        <>
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // margin: "4rem auto",

              // border: "1px solid #d8d8d8",
              // borderRadius: "7px",
              width: "80%",
              // height: "310px",
              margin: "6rem auto",
            }}
          >
            {/* <Card style={{ width: "100%", height: "auto" }}> */}
            <h3
              style={{
                textAlign: "left",
                padding: "0 2rem",
                fontWeight: "600",
              }}
            >
              Organisation Data Asset Health at a Glance
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: "1rem",
                marginTop: "1.4rem",
              }}
            >
              {dataAssetHealth.totalDatasets !== 0 && (
                <Card sx={{ p: 1 }}>
                  <Typography variant="h5" component="div">
                    {dataAssetHealth.totalDatasets}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Total Data Assets
                  </Typography>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithDescription !== "" && (
                <Card sx={{ p: 1 }}>
                  <Typography variant="h5" component="div">
                    {Math.trunc(
                      dataAssetHealth.averagePercentageWithDescription
                    )}{" "}
                    %
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Data Assets w Description
                  </Typography>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithOwner !== "" && (
                <Card sx={{ p: 1 }}>
                  <Typography variant="h5" component="div">
                    {Math.trunc(dataAssetHealth.averagePercentageWithOwner)} %
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Data Assets w Owner
                  </Typography>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithClassification !== "" && (
                <Card sx={{ p: 1 }}>
                  <Typography variant="h5" component="div">
                    {Math.trunc(
                      dataAssetHealth.averagePercentageWithClassification
                    )}{" "}
                    %
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Data Assets w Classification
                  </Typography>
                </Card>
              )}
            </div>
            {/* </Card> */}
          </div>
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // margin: "4rem auto",

              // border: "1px solid #d8d8d8",
              // borderRadius: "7px",
              width: "80%",
              // height: "310px",
              margin: "6rem auto",
            }}
          >
            <Card style={{ width: "100%", height: "auto" }}>
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Compliance Insights By Business Unit Leaderboard
              </h3>
              <div
                style={{
                  margin: "2rem",
                  marginTop: "4rem",
                  width: "100%",
                  // height: "21rem",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              >
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                {/* <div style={{ width: "20%", cursor: "pointer" }}>
                  <Typography
                    sx={{ mt: 2.8, textAlign: "left" }}
                    color="text.secondary"
                  >
                    Leaderboard
                  </Typography>
                </div> */}
                {/* </Card> */}
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                <div style={{ width: "72%", cursor: "pointer" }}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      marginTop: "0.7rem",
                      minWidth: 650,
                      maxHeight: "56vh",
                      overflow: "auto",
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead sx={{ backgroundColor: "#d3d3d3" }}>
                        <TableRow>
                          <TableCell>Rank</TableCell>
                          <TableCell>Business Unit</TableCell>
                          <TableCell>Compliance %</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {businessUComplLeader?.map((val, idx) => (
                          <TableRow
                            hover
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {(val.rank === 1 ||
                                val.rank === 2 ||
                                val.rank === 3) && (
                                <MilitaryTechIcon
                                  className={metadataRankType(val)}
                                />
                              )}
                              {val.rank > 3 && val.rank}
                            </TableCell>
                            <TableCell>{val.dimension}</TableCell>
                            <TableCell>
                              {Math.trunc(val.averageGlossaryPercentage)} %
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={businessUComplLeader.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </div>
                {/* </Card> */}
              </div>

              <div
                style={{
                  margin: "2rem",
                  marginTop: "4rem",
                  width: "100%",
                  // height: "21rem",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              >
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                {/* <div style={{ width: "20%", cursor: "pointer" }}>
                  <Typography
                    sx={{ mt: 2.8, textAlign: "left" }}
                    color="text.secondary"
                  >
                    Charts
                  </Typography>
                </div> */}
                {/* </Card> */}
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                <div
                  style={{
                    width: "80%",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2.8rem",
                  }}
                >
                  {/* Compliance Insights By Business Unit with Description */}
                  <div className="compliance-chart-box">
                    {/* <Typography
                      sx={{ mb: 1.4, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Compliance Insights By Business Unit with Description
                    </Typography> */}
                    <h5
                      style={{
                        textAlign: "left",
                        // padding: "0 2rem",
                        fontWeight: "600",
                      }}
                    >
                      Compliance Insights By Business Unit with Description
                    </h5>
                    <FormControl
                      sx={{
                        mb: 1.8,
                        width: 450,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-search">
                        Search
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchDesc}
                        onChange={(e) => setSearchDesc(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            {searchDesc !== "" && (
                              <IconButton
                                // style={{ backgroundColor: "#d8d8d8", borderRadius: "4px" }}
                                aria-label="CloseIcon"
                                onClick={() => {
                                  setSearchDesc("");
                                }}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label="Search Schema"
                      />
                    </FormControl>
                    {filterBusinessUComplWDesc.map((val) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "1rem",
                          margin: "1rem 0",
                          width: "90%",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "auto",
                            marginBottom: "0",
                            textAlign: "left",
                          }}
                        >
                          {val.dimension}
                        </p>
                        <div style={{ width: "70%" }}>
                          {/* <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        /> */}
                          <LinearFillBar
                            percentage={
                              Math.trunc(val.percentageWithDescription) + "%"
                            }
                            color={getRandomColor()}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/*  Compliance Insights By Business Unit with Owner */}
                  <div>
                    {/* <Typography
                      sx={{ mb: 1.4, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Compliance Insights By Business Unit with Owner
                    </Typography> */}
                    <h5
                      style={{
                        textAlign: "left",
                        // padding: "0 2rem",
                        fontWeight: "600",
                      }}
                    >
                      Compliance Insights By Business Unit with Owner
                    </h5>
                    <FormControl
                      sx={{
                        mb: 1.8,
                        width: 450,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-search">
                        Search
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchDesc}
                        onChange={(e) => setSearchDesc(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            {searchDesc !== "" && (
                              <IconButton
                                // style={{ backgroundColor: "#d8d8d8", borderRadius: "4px" }}
                                aria-label="CloseIcon"
                                onClick={() => {
                                  setSearchDesc("");
                                }}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label="Search Schema"
                      />
                    </FormControl>
                    {filterBusinessUComplWDesc.map((val) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "1rem",
                          margin: "1rem 0",
                          width: "90%",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "auto",
                            marginBottom: "0",
                            textAlign: "left",
                          }}
                        >
                          {val.dimension}
                        </p>
                        <div style={{ width: "70%" }}>
                          {/* <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        /> */}
                          <LinearFillBar
                            percentage={
                              Math.trunc(val.percentageWithOwner) + "%"
                            }
                            color={getRandomColor()}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Compliance Insights By Business Unit with Security Classification */}
                  <div>
                    {/* <Typography
                      sx={{ mb: 1.4, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Compliance Insights By Business Unit with Security
                      Classification
                    </Typography> */}
                    <h5
                      style={{
                        textAlign: "left",
                        // padding: "0 2rem",
                        fontWeight: "600",
                      }}
                    >
                      Compliance Insights By Business Unit with Security
                      Classification
                    </h5>
                    <FormControl
                      sx={{
                        mb: 1.8,
                        width: 450,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-search">
                        Search
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchDesc}
                        onChange={(e) => setSearchDesc(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            {searchDesc !== "" && (
                              <IconButton
                                // style={{ backgroundColor: "#d8d8d8", borderRadius: "4px" }}
                                aria-label="CloseIcon"
                                onClick={() => {
                                  setSearchDesc("");
                                }}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label="Search Schema"
                      />
                    </FormControl>
                    {filterBusinessUComplWDesc.map((val) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "1rem",
                          margin: "1rem 0",
                          width: "90%",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "auto",
                            marginBottom: "0",
                            textAlign: "left",
                          }}
                        >
                          {val.dimension}
                        </p>
                        <div style={{ width: "70%" }}>
                          {/* <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        /> */}
                          <LinearFillBar
                            percentage={
                              Math.trunc(val.percentageWithClassification) + "%"
                            }
                            color={getRandomColor()}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* </Card> */}
              </div>
            </Card>
          </div>
          {/* Compliance Insights By Domain */}
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // margin: "4rem auto",

              // border: "1px solid #d8d8d8",
              // borderRadius: "7px",
              width: "80%",
              // height: "310px",
              margin: "6rem auto",
            }}
          >
            <Card style={{ width: "100%", height: "auto" }}>
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Compliance Insights By Domain Leaderboard
              </h3>
              <div
                style={{
                  margin: "2rem",
                  marginTop: "4rem",
                  width: "100%",
                  // height: "21rem",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              >
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                {/* <div style={{ width: "20%", cursor: "pointer" }}>
                  <Typography
                    sx={{ mt: 2.8, textAlign: "left" }}
                    color="text.secondary"
                  >
                    Leaderboard
                  </Typography>
                </div> */}
                {/* </Card> */}
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                <div style={{ width: "72%", cursor: "pointer" }}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      marginTop: "0.7rem",
                      minWidth: 650,
                      maxHeight: "56vh",
                      overflow: "auto",
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead sx={{ backgroundColor: "#d3d3d3" }}>
                        <TableRow>
                          <TableCell>Rank</TableCell>
                          <TableCell>Business Unit</TableCell>
                          <TableCell>Compliance %</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {domainsComplLeader?.map((val, idx) => (
                          <TableRow
                            hover
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {(val.rank === 1 ||
                                val.rank === 2 ||
                                val.rank === 3) && (
                                <MilitaryTechIcon
                                  className={metadataRankType(val)}
                                />
                              )}
                              {val.rank > 3 && val.rank}
                            </TableCell>
                            <TableCell>{val.dimension}</TableCell>
                            <TableCell>
                              {Math.trunc(val.averageGlossaryPercentage)} %
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={domainsComplLeader.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </div>
                {/* </Card> */}
              </div>

              <div
                style={{
                  margin: "2rem",
                  marginTop: "4rem",
                  width: "100%",
                  // height: "21rem",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              >
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                {/* <div style={{ width: "20%", cursor: "pointer" }}>
                  <Typography
                    sx={{ mt: 2.8, textAlign: "left" }}
                    color="text.secondary"
                  >
                    Charts
                  </Typography>
                </div> */}
                {/* </Card> */}
                {/* <Card style={{ width: "50%", height: "auto" }}> */}
                <div
                  style={{
                    width: "80%",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2.8rem",
                  }}
                >
                  {/* Compliance Insights By Domain with Description */}
                  <div className="compliance-chart-box">
                    {/* <Typography
                      sx={{ mb: 1.4, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Compliance Insights By Domain with Description
                    </Typography> */}
                    <h5
                      style={{
                        textAlign: "left",
                        // padding: "0 2rem",
                        fontWeight: "600",
                      }}
                    >
                      Compliance Insights By Domain with Description
                    </h5>
                    <FormControl
                      sx={{
                        mb: 1.8,
                        width: 450,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-search">
                        Search
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchDescDom}
                        onChange={(e) => setSearchDescDom(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            {searchDescDom !== "" && (
                              <IconButton
                                // style={{ backgroundColor: "#d8d8d8", borderRadius: "4px" }}
                                aria-label="CloseIcon"
                                onClick={() => {
                                  setSearchDescDom("");
                                }}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label="Search Schema"
                      />
                    </FormControl>
                    {filterDomainsComplWDesc.map((val) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "1rem",
                          margin: "1rem 0",
                          width: "90%",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "auto",
                            marginBottom: "0",
                            textAlign: "left",
                          }}
                        >
                          {val.dimension}
                        </p>
                        <div style={{ width: "70%" }}>
                          {/* <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        /> */}
                          <LinearFillBar
                            percentage={
                              Math.trunc(val.percentageWithDescription) + "%"
                            }
                            color={getRandomColor()}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/*  Compliance Insights By Business Unit with Owner */}
                  <div>
                    {/* <Typography
                      sx={{ mb: 1.4, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Compliance Insights By Domain with Owner
                    </Typography> */}
                    <h5
                      style={{
                        textAlign: "left",
                        // padding: "0 2rem",
                        fontWeight: "600",
                      }}
                    >
                      Compliance Insights By Domain with Owner
                    </h5>
                    <FormControl
                      sx={{
                        mb: 1.8,
                        width: 450,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-search">
                        Search
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchDescDom}
                        onChange={(e) => setSearchDescDom(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            {searchDescDom !== "" && (
                              <IconButton
                                // style={{ backgroundColor: "#d8d8d8", borderRadius: "4px" }}
                                aria-label="CloseIcon"
                                onClick={() => {
                                  setSearchDescDom("");
                                }}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label="Search Schema"
                      />
                    </FormControl>
                    {filterDomainsComplWDesc.map((val) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "1rem",
                          margin: "1rem 0",
                          width: "90%",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "auto",
                            marginBottom: "0",
                            textAlign: "left",
                          }}
                        >
                          {val.dimension}
                        </p>
                        <div style={{ width: "70%" }}>
                          {/* <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        /> */}
                          <LinearFillBar
                            percentage={
                              Math.trunc(val.percentageWithOwner) + "%"
                            }
                            color={getRandomColor()}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Compliance Insights By Business Unit with Security Classification */}
                  <div>
                    {/* <Typography
                      sx={{ mb: 1.4, textAlign: "left" }}
                      color="text.secondary"
                    >
                      Compliance Insights By Domain with Security Classification
                    </Typography> */}
                    <h5
                      style={{
                        textAlign: "left",
                        // padding: "0 2rem",
                        fontWeight: "600",
                      }}
                    >
                      Compliance Insights By Domain with Security Classification
                    </h5>
                    <FormControl
                      sx={{
                        mb: 1.8,
                        width: 450,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-search">
                        Search
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchDescDom}
                        onChange={(e) => setSearchDescDom(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            {searchDescDom !== "" && (
                              <IconButton
                                // style={{ backgroundColor: "#d8d8d8", borderRadius: "4px" }}
                                aria-label="CloseIcon"
                                onClick={() => {
                                  setSearchDescDom("");
                                }}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label="Search Schema"
                      />
                    </FormControl>
                    {filterDomainsComplWDesc.map((val) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "1rem",
                          margin: "1rem 0",
                          width: "90%",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "auto",
                            marginBottom: "0",
                            textAlign: "left",
                          }}
                        >
                          {val.dimension}
                        </p>
                        <div style={{ width: "70%" }}>
                          {/* <BorderLinearProgress
                          variant="determinate"
                          value={50}
                        /> */}
                          <LinearFillBar
                            percentage={
                              Math.trunc(val.percentageWithClassification) + "%"
                            }
                            color={getRandomColor()}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* </Card> */}
              </div>
            </Card>
          </div>
        </>
      )}
    </div>
  );
}

export default MetadataInsights;
