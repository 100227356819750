import React, { useState, useContext } from "react";
import { DATABOOK_API } from "../../../../serverConfig";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../../../msalConfig";
import { AuthContext } from "../../../../AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import _ from "lodash";
import CreateTagForm from "./TagForms/CreateTagForm";
import EditTagForm from "./TagForms/EditTagForm";
import DeleteTagForm from "./TagForms/DeleteTagForm";
import TagsTable from "./TagsTable";
import { toast } from "react-toastify";

const TagDataFetchMethods = (props) => {
  const [tagName, setTagName] = useState("");
  const [descriptiontags, setDescriptiontags] = useState("");
  const [tagiddesc, setTagiddesc] = useState("");
  const [tagdata, setTagdata] = useState("");
  const [descdata, setDescdata] = useState("");
  const [tagid, setTagid] = useState("");
  const [val, setVal] = useState([]);
  const [tagname, settagname] = useState("");
  const [deltagname, setdeltagname] = useState("");

  const {
    classsiselect,
    tagsdata,
    handletagaddClose,
    addtagshow,
    individualdatafetch,
    istagsFetching,
    settagsData,
    classificationdata,
    setaddtagShow,
    getalltags,
    handleClassificationSelect
  } = props;

  const [edittagshow, setedittagShow] = useState(false);
  const handleEditTagClose = () => setedittagShow(false);
  const handleEditTagShow = () => setedittagShow(true);
  const [deltagshow, setdeltagShow] = useState(false);
  const handleDelTagClose = () => setdeltagShow(false);
  const handleDelTagShow = () => setdeltagShow(true);

  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.title,
      type: alert.type,
      icon: alert.icon,
      html: alert.html,
      text: alert.text,
      customClass: {
        htmlContainer: "sw_classi__text",
        text: "sw_classi__text",
      },
      confirmButtonColor: "#42b0d5",
      allowOutsideClick: false,
    }).then(function () {
      window.location = "/tagsclassifcation";
    });
  };

  const slclassi = [];
  _.map(classificationdata, (c) => {
    return slclassi.push({
      value: c.classificationName,
      label: c.classificationName,
    });
  });

  const handleChange = (value) => {
    const valrecv = [value];
    const clval = _.map(valrecv, (m) => {
      return m.value;
    });
    setVal(clval);
  };

  const classifname = classsiselect?.length > 0 ? classsiselect : val;



  const DELETETAGCALL = `${DATABOOK_API}/tag-classification/tags/${tagid}`;

  //Create tag//

  const tokenfetch = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const tagscreation = (token) => {
    const CREATETAGCALL = `${DATABOOK_API}/tag-classification/tags?classificationName=${classifname}&tagName=${tagName}&description=${descriptiontags}`;
    axios
      .post(CREATETAGCALL, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 201) {
          // sweetAlertHandler({
          //   text: `Tag is created`,
          //   type: "success",
          //   icon: "success",
          // });
          getalltags(token);
          // handleClassificationSelect(classifname);
          toast.success("Tag created successfully. ", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // sweetAlertHandler({
          //   title: "Error while fetching the data. ",
          //   icon: "info",
          // });
          toast.error("Error while creating tag. ", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const handleSubmitcreatetag = (e) => {
    e.preventDefault();
    tokenfetch(tagscreation);
    setaddtagShow(false);
  };

  //Edit tag only desc can be edited//

  const handleSubmitedittags = (e) => {
    e.preventDefault();
    tokenfetch(tagsedit);
    setedittagShow(false);
  };
  const tagsedit = (token) => {
    const EDITTAGCALL = `${DATABOOK_API}/tag-classification/tags/${tagdata}?description=${tagiddesc}`;
    axios
      .put(EDITTAGCALL, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 201) {
          // sweetAlertHandler({
          //   text: `"${tagname}" Tag is Updated`,
          //   type: "success",
          //   icon: "success",
          // });
          getalltags(token);
          // handleClassificationSelect()
          toast.success(`"${tagname}" Tag is Updated`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success("Error while fetching the data", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // sweetAlertHandler({
          //   title: "Error while fetching the data. ",
          //   icon: "info",
          // });
        }
      });
  };

  const handleEditTags = (fqndata, decdata, tagnamedata) => {
    handleEditTagShow();
    setTagdata(fqndata);
    setDescdata(decdata);
    settagname(tagnamedata);
  };

  //Delete tag //

  const handleDeleteTags = (fqndata, tagname) => {
    handleDelTagShow();
    setTagid(fqndata);
    setdeltagname(tagname);
  };

  const handleDeleteTag = (token) => {
    axios
      .delete(DELETETAGCALL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 200) {
          // sweetAlertHandler({
          //   text: `"${deltagname}" Tag is deleted!`,
          //   type: "success",
          //   icon: "success",
          // });
          toast.success(`"${deltagname}" Tag is Updated`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const posts = _.filter(tagsdata, (m) => m.fqn !== tagid);
          settagsData(posts);
        } else {
          // sweetAlertHandler({
          //   title: "Error while fetching the data. ",
          //   icon: "info",
          // });
          toast.success(`Error while deleting the"${deltagname}"`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const handleSubmitDeletetags = (e) => {
    e.preventDefault();
    tokenfetch(handleDeleteTag);
    setdeltagShow(false);
  };

  return (
    <>
      <CreateTagForm
        handletagaddClose={handletagaddClose}
        addtagshow={addtagshow}
        setTagName={setTagName}
        tagName={tagName}
        descriptiontags={descriptiontags}
        setDescriptiontags={setDescriptiontags}
        handleSubmitcreatetag={handleSubmitcreatetag}
        classsiselect={classsiselect}
        classificationdata={classificationdata}
        slclassi={slclassi}
        handleChange={handleChange}
      />
      <EditTagForm
        handleEditTagClose={handleEditTagClose}
        edittagshow={edittagshow}
        handleSubmitedittags={handleSubmitedittags}
        tagdata={tagdata}
        tagname={tagname}
        descdata={descdata}
        setTagiddesc={setTagiddesc}
      />
      <DeleteTagForm
        deltagshow={deltagshow}
        handleDelTagClose={handleDelTagClose}
        handleSubmitDeletetags={handleSubmitDeletetags}
        tagid={tagid}
      />
      {classsiselect?.length > 0 ? (
        <TagsTable
          tagsdata={individualdatafetch}
          istagsFetching={istagsFetching}
          handleEditTags={handleEditTags}
          handleDeleteTags={handleDeleteTags}
        />
      ) : (
        <TagsTable
          tagsdata={tagsdata}
          istagsFetching={istagsFetching}
          handleEditTags={handleEditTags}
          handleDeleteTags={handleDeleteTags}
        />
      )}
    </>
  );
};

export default TagDataFetchMethods;
