// import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const i = isIE + isEdge + isFirefox;
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    // clientId: "7cad4f2b-01ce-4e15-9e47-fbe64cffe775"
    clientId: "eccd4653-2930-4077-be40-8e73fd4855e6"
    // clientId: (process.env.NODE_ENV === "development" &&
    //   process.env.REACT_APP_RUN_ENV === "development")
    //   ? "bd384c6c-d925-4099-a56f-83567cdb7ab7"
    //   : (process.env.NODE_ENV === "preprod" &&
    //     process.env.REACT_APP_RUN_ENV === "preprod")
    //     ? "7cad4f2b-01ce-4e15-9e47-fbe64cffe775"
    //     : "0cf34413-09df-4101-a909-6455c03c70b4"
    ,
    authority:
      "https://login.microsoftonline.com/b02b023f-1855-427a-8002-288a6252bf79",
    redirectUri: "/",
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },

  /*  system: {
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //       default:
    //         return;
    //     }
    //   },
    // },
    // iframeHashTimeout: 10000,
  }, */
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
// const resourceUri =
//   process.env.NODE_ENV === "development" &&
//   process.env.REACT_APP_RUN_ENV === "development"
//     ? "https://databook-api-dev.maestro.maersk.com/databook/"
//     : "https://databook-api.maestro.maersk.com/databook/";

// const resourceUri = "https://databook-api-preprod.maersk-digital.net/databook/";
const resourceUri = "https://metainsights-api.gleafink.com/metainsights/";
// const resourceUri = (process.env.NODE_ENV === "development" &&
//   process.env.REACT_APP_RUN_ENV === "development")
//   ? "https://databook-api-dev.maestro.maersk.com/databook/"
//   : (process.env.NODE_ENV === "preprod" &&
//     process.env.REACT_APP_RUN_ENV === "preprod")
//     ? "https://databook-api-preprod.maersk-digital.net/databook/"
//     : "https://databook-api.maestro.maersk.com/databook/";

// const resourceScope =
//   process.env.NODE_ENV === "development" &&
//   process.env.REACT_APP_RUN_ENV === "development"
//     ? "api://bd384c6c-d925-4099-a56f-83567cdb7ab7/Users.RW"
//     : "api://0cf34413-09df-4101-a909-6455c03c70b4/Users.RW";

// const resourceScope = "api://7cad4f2b-01ce-4e15-9e47-fbe64cffe775/Users.RW";

const resourceScope = "api://eccd4653-2930-4077-be40-8e73fd4855e6/Users.RW";
// const resourceScope = (process.env.NODE_ENV === "development" &&
//   process.env.REACT_APP_RUN_ENV === "development")
//   ? "api://bd384c6c-d925-4099-a56f-83567cdb7ab7/Users.RW"
//   : (process.env.NODE_ENV === "preprod" &&
//     process.env.REACT_APP_RUN_ENV === "preprod")
//     ? "api://7cad4f2b-01ce-4e15-9e47-fbe64cffe775/Users.RW"
//     : "api://0cf34413-09df-4101-a909-6455c03c70b4/Users.RW";
const resourceScopeDatacraft = "api://eccd4653-2930-4077-be40-8e73fd4855e6/.default";

const resourceUriDatacraft = "https://datasculpt-api.gleafink.com";
export const loginRequest = {
  scopes: [],
};
export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  databook: {
    endpoint: resourceUri,
    scopes: [resourceScope],
  },
  datacraft: {
    endpoint: resourceUriDatacraft,
    scopes: [resourceScopeDatacraft],
  },
};


