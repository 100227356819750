import React, { useState } from "react";

const Redshift = (showLoading) => {
  const [credentials, setCredentials] = useState({
    sfdcPassword: "",
    sfdcSecurityToken: "",
    sfUsername: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="sfdcPassword">
            SFDC Password <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="password"
            id="sfdcPassword"
            name="sfdcPassword"
            placeholder="SFDC password"
            value={credentials.sfdcPassword}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="sfdcSecurityToken">
            SFDC Security Token <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="sfdcSecurityToken"
            name="sfdcSecurityToken"
            placeholder="SFDC Security Token"
            value={credentials.sfdcSecurityToken}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="individualRow">
        <div className="inputWrapper">
          <label className="labelStyle" htmlFor="sfUsername">
            SF Username <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="sfUsername"
            name="sfUsername"
            placeholder="SF Username"
            value={credentials.sfUsername}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Redshift;
