import React from "react";
import { Form, Modal, Button } from "react-bootstrap";

const DeleteClassificationForm = (props) => {
  const {
    classsiselect,
    handleDeleteClassi,
    handleDeleteClassificationClose,
    deleteclassificationshow,
  } = props;
  return (
    <>
      <Modal
        show={deleteclassificationshow}
        onHide={handleDeleteClassificationClose}
        centered
      >
        <Modal.Header className="del__msg">
          {" "}
          <Modal.Title>
            <span>
              {" "}
              Are you sure you want to delete classification{" "}
              <b>"{classsiselect}"</b>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form__view" onSubmit={handleDeleteClassi}>
            <div className="button_save_close">
              <Button
                variant="secondary pop__btn"
                onClick={handleDeleteClassificationClose}
              >
                Close
              </Button>
              <Button className="pop__btn enabled_btn" type="submit">
                Yes
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>{" "}
    </>
  );
};

export default DeleteClassificationForm;
