import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

const CreateClassificationForm = (props) => {
  const {
    createshow,
    handleCreateClose,
    handleSubmitCreateclassification,
    setDescription,
    setClassificationName,
    classificationName,
    description,
  } = props;

  const schema = yup.object().shape({
    classificationName: yup.string().required(),
    description: yup.string().required(),
  });
  return (
    <Modal show={createshow} onHide={handleCreateClose} centered>
      <Modal.Header className="title__header">
        <Modal.Title>
          <h4>Add Classification</h4>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            console.log("FORMIK******* ::", values);
            resetForm({ values: "" });
          }}
          initialValues={{
            classificationName: "",
            description: "",
          }}
        >
          {({ errors }) => (
            <Form
              className="form__view"
              onSubmit={handleSubmitCreateclassification}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label__header__name__required">
                  <span>Classification Name</span>
                </Form.Label>
                <Form.Control
                  placeholder="Classification Name"
                  type="invalid"
                  required
                  value={classificationName}
                  isInvalid={!!errors.classificationName}
                  name="classiname"
                  onChange={(e) => setClassificationName(e.target.value)}
                  autoFocus
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.classificationName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="label__header__name">
                  <span>Description</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="invalid"
                  rows={3}
                  value={description}
                  name="description"
                  isInvalid={!!errors.description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="button_save_close">
                <Button
                  variant="secondary pop__btn"
                  onClick={handleCreateClose}
                >
                  Close
                </Button>
                <Button className="pop__btn enabled_btn" type="submit">
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateClassificationForm;
