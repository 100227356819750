import { Box, CardContent } from "@mui/material";
import { Divider, Typography } from "antd";
import { MaterialReactTable } from "material-react-table";
import React, { useState } from "react";
import { Card } from "react-bootstrap";

const SideDrawerList = ({ buzzBox, columns, ownedAssets, subsAssets }) => {
  const [openTabs, setOpenTabs] = useState({ 0: true });

  // Sample content for each tab
  const tabs = [
    {
      name: "Buzz Box",
      content: (
        <Card
          sx={{
            // margin: "7px",
            width: "100%",
            height: "31.5%",
            overflow: "auto",
            cursor: "pointer",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              padding: "0.2rem 0.4rem",
              backgroundColor: "#e6f4ff",
              height: "410px",
              overflowY: "auto",
            }}
          >
            {buzzBox.length !== 0 ? (
              <>
                {buzzBox?.map((buzz, idx) => (
                  <p
                    className="dhc-header-message-classi m-1"
                    key={idx}
                    style={{
                      width: "98%",
                      fontWeight: "400",
                      fontSize: "0.875rem",
                      wordBreak: "break-all",
                      backgroundColor: "#ffffff",
                      border: "1px solid #1890ff",
                      // lineHeight: "1.43",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        justifyContent: "center",
                        fontWeight: "550",
                      }}
                    >
                      {buzz.dataAssetFqn}'s
                    </span>{" "}
                    {buzz.resource}{" "}
                    <span
                      style={{
                        display: "inline-block",
                        justifyContent: "center",
                        padding: "1px 6px",
                        borderRadius: "10px",
                        fontWeight: "normal",
                        // backgroundColor: "GrayText",
                        backgroundColor: "#D8BFD8",
                      }}
                    >
                      {buzz.action}d
                    </span>
                  </p>
                ))}
              </>
            ) : (
              <p
                className="dhc-header-message-classi m-1"
                style={{
                  width: "100%",
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  // lineHeight: "1.43",
                }}
              >
                {"There is no notifications to display :)"}
              </p>
            )}
          </div>
        </Card>
      ),
    },
    {
      name: "Owned Assets",
      content: (
        <Card
          style={{
            width: "100%",
            height: "31.5%",
            cursor: "pointer",
            overflow: "auto",
            padding: "0.6rem 0.8rem ",
            backgroundColor: "#e6f4ff",
          }}
        >
          {/* <CardActionArea sx={{ height: "100%" }}> */}
          <MaterialReactTable
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "center",
                  sx: { padding: "0", width: "98%" },
                },
                muiTableBodyCellProps: {
                  sx: { padding: "0", width: "98%" },
                },
              },
            }}
            muiTablePaperProps={{
              sx: { border: "1px solid #1890ff" },
            }}
            muiTableHeadCellProps={{
              sx: {
                paddingLeft: "0.6rem",
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                paddingLeft: "0.6rem",
              },
            }}
            initialState={{
              density: "compact",
              pagination: { pageSize: 5, pageIndex: 0 },
            }}
            columns={columns}
            data={ownedAssets}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableHiding={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableKeyboardShortcuts={false}
            enablePagination={false}
            enableSorting={false}
          />
          {/* </CardActionArea> */}
        </Card>
      ),
    },
    {
      name: "Subscribed Assets",
      content: (
        <Card
          style={{
            margin: "7px",
            width: "100%",
            height: "31.5%",
            cursor: "pointer",
            // borderRadius: "8px",
            overflow: "auto",
            padding: "0.6rem 0.8rem ",
            backgroundColor: "#e6f4ff",
          }}
        >
          {/* <CardActionArea sx={{ height: "100%" }}> */}

          <MaterialReactTable
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "center",
                  sx: { padding: "0" },
                },
                muiTableBodyCellProps: {
                  sx: { padding: "0" },
                },
              },
            }}
            muiTablePaperProps={{
              sx: { border: "1px solid #1890ff" },
            }}
            muiTableHeadCellProps={{
              sx: {
                paddingLeft: "0.6rem",
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                paddingLeft: "0.6rem",
              },
            }}
            initialState={{
              density: "compact",
              pagination: { pageSize: 5, pageIndex: 0 },
            }}
            columns={columns}
            data={subsAssets}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableHiding={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableKeyboardShortcuts={false}
            enablePagination={false}
            enableSorting={false}
          />
          {/* </CardActionArea> */}
        </Card>
      ),
    },
  ];
  const toggleTab = (index) => {
    setOpenTabs((prevOpenTabs) => ({
      ...prevOpenTabs,
      [index]: !prevOpenTabs[index],
    }));
  };

  return (
    <div>
      {/* <Divider /> */}

      {/* <Divider /> */}

      <Box sx={{ width: 450, height: "100%" }} role="presentation">
        {tabs.map((tab, index) => (
          <div key={index} style={{ marginBottom: "1rem" }}>
            <div
              style={{
                cursor: "pointer",
                fontWeight: openTabs[index] ? "bold" : "normal",
                borderBottom: "1px solid #ddd",
                padding: "0.5rem",
              }}
              onClick={() => toggleTab(index)}
            >
              {tab.name}
            </div>
            {openTabs[index] && (
              <div style={{ padding: "0.5rem", border: "1px solid #ddd" }}>
                {tab.content}
              </div>
            )}
          </div>
        ))}
      </Box>
    </div>
  );
};

export default SideDrawerList;
