import React from "react";
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from "@mui/material";

const BreadcrumbMUI = (props) => {
  const navigate = useNavigate();

  return (
    <div style={{ margin: "0.4rem 1.4rem" }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        {props.path1 && (
          <Link underline="hover" color="inherit" href={props.path1}>
            {props.name1}
          </Link>
        )}
        {props.path2 && (
          <Link underline="hover" color="inherit" href={props.path2}>
            {props.name2}
          </Link>
        )}
        {props.goBack && (
          <Link underline="hover" color="inherit" style={{cursor:"pointer"}} onClick={() => navigate(-1)}>
            {props.goBackName}
          </Link>
        )}
        {props.last && (
          <Typography color="text.primary">{props.last}</Typography>
        )}
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbMUI;
