import React from "react";
import { Modal, Form, Button } from "react-bootstrap";

const DeleteTerm = (props) => {
  const { deltermshow, handleDelTermClose, handleSubmitDeleteterms, tagiddel } =
    props;
  return (
    <>
      {" "}
      <Modal show={deltermshow} onHide={handleDelTermClose} centered>
        <Modal.Header className="del__msg">
          {" "}
          <Modal.Title>
            <span>
              Are you sure you want to delete term <b>"{tagiddel}"</b>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form__view" onSubmit={handleSubmitDeleteterms}>
            <div className="button_save_close">
              <Button variant="secondary pop__btn" onClick={handleDelTermClose}>
                Close
              </Button>
              <Button type="submit" className="pop__btn enabled_btn">
                Yes
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteTerm;
