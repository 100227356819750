import React, { useState } from "react";
import "./Glossary.css";
import GlossaryDescHeader from "./GlossaryList/GlossaryDescHeader";
import { Container, Row, Col } from "react-bootstrap";
import GlossaryGroupingView from "./GlossaryList/GlossaryGroupingView";
import CreateGlossary from "./GlossaryList/GlossaryForms/CreateGlossary";
import EditGlossary from "./GlossaryList/GlossaryForms/EditGlossary";
import DeleteGlossary from "./GlossaryList/GlossaryForms/DeleteGlossary";
import TermDataFetchMethods from "./Terms/TermDataFetchMethods";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

const AllGlossaryViews = (props) => {
  const navigate = useNavigate();
  const [createtagshow, settagCreateShow] = useState(false);
  const handleCreatetagClose = () => settagCreateShow(false);
  const handleCreatetagShow = () => settagCreateShow(true);
  const {
    glossary,
    glossaryfetch,
    classificationName,
    setClassificationName,
    description,
    setDescription,
    setglossarydesc,
    glossaryselect,
    individualdatafetch,
    descriptionheader,
    createshow,
    handleCreateClose,
    handleCreateShow,
    editshow,
    handleEditClose,
    handleEditShow,
    deleteglossaryshow,
    handleDeleteGlossaryClose,
    handleDeleteGlossaryShow,
    handleGlossarySelect,
    handleSubmitCreateglossary,
    handleEditGlossary,
    handleDeleteglossary,
    terms,
    setterms,
    getallterms,
    setDescriptionheader,
    setindividualdatafetch,
    sethandleglossarySelect
  } = props;
  return (
    <>
      {/* <Container fluid className="tags__container p-5">
        <div className="TagsPageHeader">
          <h3>Manage Classisfication and Glossary</h3>
        </div>
        <Row> */}
      <>
        {/* <div className="GlossaryContainerTop">
              <p
                className={
                  window.location.pathname === "/tagsclassifcation" &&
                  "TagContainerTopActivep"
                }
                onClick={() => navigate("/tagsclassifcation")}
              >
                Classification
              </p>
              <p
                className={
                  window.location.pathname === "/glossary" &&
                  "GlossaryContainerTopActivep"
                }
                onClick={() => navigate("/glossary")}
              >
                Glossary
              </p>
            </div> */}
        {glossaryfetch ? (
          // <div className="justify-content-md-center1">
          //   {" "}
          //   <Spinner />
          // </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "#d3d3d3",
            }}
          >
            <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
          </Box>
        ) : (
          // <div className="GlossaryContainer">
          <>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={3}
              xl={3}
              style={{
                padding: "1rem",
                borderRight: "1px solid #dfdfdf",
              }}
            >
              <div className="list__items classification__view py-3">
                <GlossaryGroupingView
                  onItemSelect={handleGlossarySelect}
                  glossary={glossary}
                  terms={terms}
                  selectedItem={glossaryselect}
                  handleCreateShow={handleCreateShow}
                  handleEditShow={handleEditShow}
                  handleDeleteGlossaryShow={handleDeleteGlossaryShow}
                  // individualdatafetch={individualdatafetch}
                  setDescriptionheader={setDescriptionheader}
                  setindividualdatafetch={setindividualdatafetch}
                  sethandleglossarySelect={sethandleglossarySelect}
                />
              </div>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={8}
              lg={9}
              xl={9}
              style={{
                padding: "1rem",
                // border: "1px solid #dfdfdf",
                borderLeft: "none",
                // backgroundColor: "#f4f5f7",
                backgroundColor: "#E6F4FF",
              }}
            >
              <GlossaryDescHeader
                descriptionheader={descriptionheader}
                individualdatafetch={individualdatafetch}
                handleDeleteGlossaryShow={handleDeleteGlossaryShow}
                glossaryselect={glossaryselect}
                handleSubmitCreateglossary={handleSubmitCreateglossary}
                handleCreatetagShow={handleCreatetagShow}
              />

              <TermDataFetchMethods
                glossaryselect={glossaryselect}
                createtagshow={createtagshow}
                handleCreatetagClose={handleCreatetagClose}
                terms={terms}
                individualdatafetch={individualdatafetch}
                setterms={setterms}
                getallterms={getallterms}
                settagCreateShow={settagCreateShow}
              />
            </Col>
          </>
          // </div>
        )}
      </>
      {/* </Row>{" "} */}
      <CreateGlossary
        createshow={createshow}
        handleCreateClose={handleCreateClose}
        handleSubmitCreateglossary={handleSubmitCreateglossary}
        classificationName={classificationName}
        description={description}
        setClassificationName={setClassificationName}
        setDescription={setDescription}
      />
      <EditGlossary
        handleEditClose={handleEditClose}
        editshow={editshow}
        handleEditGlossary={handleEditGlossary}
        descriptionheader={descriptionheader}
        setglossarydesc={setglossarydesc}
        glossaryselect={glossaryselect}
      />
      <DeleteGlossary
        handleDeleteGlossaryClose={handleDeleteGlossaryClose}
        deleteglossaryshow={deleteglossaryshow}
        handleDeleteglossary={handleDeleteglossary}
        glossaryselect={glossaryselect}
      />
      {/* </Container> */}
    </>
  );
};

export default AllGlossaryViews;
