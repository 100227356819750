import React, { useEffect, useState } from "react";
import { Button } from "antd";
import editicon from "../../../../assets/images/pencil.svg";
import trashIcon from "../../../../assets/images/trash.svg";
import arrowRight from "../../../../assets/images/arrow-right.svg";
import _ from "lodash";

const ClassificationGroupingView = (props) => {
  const [hoveredItem, setHoveredItem] = useState("");
  const {
    tagsdata,
    // individualdatafetch,
    onItemSelect,
    classificationdata,
    selectedItem,
    handleCreateShow,
    handleEditShow,
    handleDeleteClassificationShow,
    setDescriptionheader,
    setindividualdatafetch,
    sethandleclassiSelect,
    handleClassificationSelect,
  } = props;

  // useEffect(() => {
  //   // onItemSelect(classificationdata[0]?.classificationName);
  //   console.log("Itemmm>", classificationdata)
  //   onItemSelect("default");
  // }, []);
  useEffect(() => {
    let selectedclassi = classificationdata[0]?.classificationName;
    const tabledata = _.filter(
      tagsdata,
      (m) => m.classificationName === selectedclassi
    );

    const descriptiondata = _.filter(
      classificationdata,
      (m) => m.classificationName === selectedclassi
    );

    setDescriptionheader(descriptiondata);
    setindividualdatafetch(tabledata);
    console.log("selectedclassi : ", selectedclassi);
    console.log("selectedItem : ", selectedItem);
    if (selectedItem && selectedItem !== "default") {
      sethandleclassiSelect(selectedItem);
      handleClassificationSelect(selectedItem);
    } else {
      sethandleclassiSelect(selectedclassi);
    }
  }, [tagsdata]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 style={{ fontSize: "16px" }}>Available Classifications</h4>
        <Button
          className="classification enabled_btn"
          onClick={handleCreateShow}
          style={{ cursor: "pointer", fontSize: "14px" }}
        >
          {" "}
          Add Classification
        </Button>
      </div>
      <ul
        className="list-group py-3"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "0",
          paddingLeft: "0",
        }}
      >
        {classificationdata &&
          classificationdata.map((classi, idx) => (
            <div key={idx}>
              {" "}
              <li
                className={
                  classi.classificationName === selectedItem
                    ? "list-group-item active"
                    : idx === 0 && selectedItem?.length === 0
                    ? "list-group-item active"
                    : "list-group-item"
                }
                onClick={() => onItemSelect(classi.classificationName)}
                onMouseEnter={() => setHoveredItem(classi.classificationName)}
                onMouseLeave={() => setHoveredItem("")}
                active
              >
                <div className="class__grp">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      padding: "0 7px 0 0",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      {classi.classificationName}
                    </p>
                    <p className="dataFetchLength">
                      {
                        tagsdata.filter(
                          (item) =>
                            item.classificationName ===
                            classi.classificationName
                        ).length
                      }
                    </p>
                  </div>
                  {(classi.classificationName === hoveredItem ||
                    classi.classificationName === selectedItem) && (
                    <div className="edit__classi">
                      <Button onClick={handleEditShow}>
                        <img src={editicon} alt="Edit Classification" />
                      </Button>
                      <Button onClick={handleDeleteClassificationShow}>
                        <img src={trashIcon} alt="Delete Classification" />
                      </Button>
                      <Button>
                        <img
                          src={arrowRight}
                          alt="Arrow Classification"
                          style={{
                            backgroundColor: "#f77039",
                            filter: "invert(100%)",
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </li>
            </div>
          ))}
      </ul>
    </>
  );
};

export default ClassificationGroupingView;
