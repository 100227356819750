import React from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "antd";
import Guide from "../Guide";
import ClassificationDesc from "../ClassificationList/ClassificationDesc";

const TagHeaderSection = (props) => {
  const {
    descriptionheader,
    individualdatafetch,
    handletagaddShow,
    handleDeleteClassificationShow,
    classsiselect,
  } = props;
  return (
    <>
      {" "}
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <ClassificationDesc
            descriptionheader={descriptionheader}
            individualdatafetch={individualdatafetch}
          />{" "}
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <div className="form__view">
            <div className="tags__btn py-3">
              <Guide />

              <Button className="enabled_btn" onClick={handletagaddShow}>
                Add Tag
              </Button>

              {/* {classsiselect?.length > 0 ? (
                <Button
                  className="deleted__btn"
                  onClick={handleDeleteClassificationShow}
                >
                  Delete Classification
                </Button>
              ) : (
                <Button disabled>Delete Classification</Button>
              )} */}
            </div>{" "}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TagHeaderSection;
