import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

function Banner() {
  const [showBanner, setShowBanner] = useState(true);

  return (
    showBanner && (
      <div
        style={{
          backgroundColor: "#E6F4FF",
          // backgroundColor: "#cfecf7",
          // backgroundColor: "#87CEFA",
          height: "2rem",
          padding: "0.3rem 0.7rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#1890ff",
          // borderBottom: "1px solid #708090",
          // boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
        }}
      >
        <p style={{ marginLeft: "auto", marginTop:"1rem" }}>
          <span> New Update : </span> Here is some new feature
        </p>
        <p
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => setShowBanner(false)}
        >
          <CloseIcon fontSize="small" sx={{ marginTop: "0.6rem" }} />
        </p>
      </div>
    )
  );
}

export default Banner;
