import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DATABOOK_API } from "../../../serverConfig";
import axios from "axios";
import { AuthContext } from "../../../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../../msalConfig";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function SampleData() {
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };
  const { datasetFQN } = useParams();
  // const PREVIEW_API = `${DATABOOK_API}/datasets/preview/datasets/${datasetFQN}`;
  const PREVIEW_API = `https://metainsights-api.gleafink.com:443/metainsights/datasets/preview/datasets/${datasetFQN}`;

  const [isFetching, setIsFetching] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  const getallpreviewdata = async (token) => {
    setIsFetching(true);
    axios
      .get(PREVIEW_API, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data;
        console.log("Preview::", maindata.body);
        setPreviewData(maindata.body);
        setIsFetching(false);
      })
      .catch((error) => {
        console.log("Sample data fetch error", error);
        setIsFetching(false);
      });
  };

  const generateElement = (key, value) => {
    return (
      <TableCell>
        {value == "" || null ? <p>Null</p> : <p>{value}</p>}
      </TableCell>
    );
  };

  function generateData(data) {
    const newData = Object.keys(data || {})?.reduce((result, currentKey) => {
      // if (
      //   typeof data[currentKey] === "string" ||
      //   data[currentKey] instanceof String
      // ) {
      const elementToPush = generateElement(currentKey, data[currentKey]);
      result.push(elementToPush);
      // } else {
      //   const nested = generateData(data[currentKey]);
      //   result.push(...nested);
      // }
      return result;
    }, []);
    return newData;
  }

  useEffect(() => {
    const fetchData = async () => {
      (async () => await getToken())();
      if (token) {
        await getallpreviewdata(token);
      }
      if (error) {
        try {
          const rtoken = (await instance.acquireTokenRedirect(request))
            .accessToken;
          await getallpreviewdata(rtoken);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [getToken, token, datasetFQN]);

  return (
    <div style={{ height: "56vh" }}>
      {isFetching ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {previewData.length === 0 && (
            <Card
              sx={{ width: "100%", height: 100, cursor: "pointer" }}
              // variant="outlined"
              // raised={true}
            >
              <CardActionArea>
                <CardContent>
                  {/* <Typography variant="h5" component="div">
                    {telemetryCountData}
                  </Typography> */}
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Sample data is not available.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          {previewData.length !== 0 && (
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                marginBottom: "2rem",
                maxHeight: "56vh",
                overflow: "auto",
              }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead style={{ backgroundColor: "#E6F4FF" }}>
                  <TableRow>
                    {Object.keys(previewData[0] || {})?.map((name, index) => (
                      <TableCell key={index}>{name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewData?.map((prev, idx) => (
                    <TableRow
                      key={idx}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {generateData(prev)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </div>
  );
}

export default SampleData;
