import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import _ from "lodash";
import * as yup from "yup";

const EditClassificationForm = (props) => {
  const {
    handleEditClose,
    editshow,
    handleEditClassification,
    setclassidesc,
    descriptionheader,
    classsiselect,
    classidesc
  } = props;

  let onlydescription = _.map(descriptionheader, "description");
  const initialValuesformik = {
    classificationName: descriptionheader[0]?.classificationName,
    description: descriptionheader[0]?.description
  }

  const schema = yup.object().shape({
    description: yup.string().required(),
  });

  return (
    <>
      <Modal show={editshow} onHide={handleEditClose} centered>
        <Modal.Header className="title__header">
          <Modal.Title>
            <h4>Edit Classification</h4>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            // validationSchema={schema}
            onSubmit={(values, e) => {
              handleEditClassification(values, e);
            }}
            initialValues={initialValuesformik}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form className="form__view" onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="label__header__name">
                    <span>Classification Name</span>
                  </Form.Label>
                  <Form.Control
                    name="classificationName"
                    placeholder="classificationName"
                    readOnly
                    disabled
                    defaultValue={classsiselect}
                    autoFocus
                    value={values.classificationName}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="label__header__name">
                    <span>Description</span>
                  </Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={3}
                    // required
                    // type="invalid"
                    // isInvalid={!errors.description}
                    // defaultValue={onlydescription[0]}
                    value={values.description}
                    onChange={handleChange}
                  />
                </Form.Group>
                <div className="button_save_close">
                  <Button
                    variant="secondary pop__btn"
                    onClick={handleEditClose}
                  >
                    Close
                  </Button>
                  <Button type="submit" className="pop__btn enabled_btn">Save Changes</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditClassificationForm;
