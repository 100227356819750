import React, { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  Badge,
  Box,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import { Switch } from "antd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import genAIchip from "../../../assets/images/genAIchip.png";
import editicon from "../../../assets/images/pencil.svg";
import { AuthContext } from "../../../AuthContext";
import { protectedResources } from "../../../msalConfig";
import { DATABOOK_API } from "../../../serverConfig";
import DatasetFieldsEditModal from "../DatasetFieldsEditModal";

function Overview({
  isFetching,
  modaldata,
  certifiedetails,
  oneMonthUserVisitCount,
  getallcolumndata,
  updateCertificationDetails,
  toggleloading,
}) {
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const [updateDescToggle, setUpdateDescToggle] = useState(false);
  const [updateDescData, setUpdateDescData] = useState("");

  const [datasetModalShow, setDatasetModalFlag] = useState(false);
  const [fieldname, setFieldName] = useState("");
  const [datasetstag, setDatasetTags] = useState([]);
  const [fieldDefaultValues, setFieldDefaultValues] = useState([]);
  const [selectedfield, setSelectedField] = useState([]);
  const [securityclassification, setSecurityClassification] = useState([]);
  const [selectedclassification, setSelectedClassification] = useState([]);
  const [sensitivity, setSensitivity] = useState([]);
  const [selectedsensitivity, setSelectedSensitivity] = useState([]);
  const [sensitivityDefaultValues, setSensitivityDefaultValues] = useState([]);
  const [datasetOwner, setDatasetOwner] = useState([]);
  const [editDatasetOwnerFlag, setEditDatasetOwner] = useState(false);

  const [genAIOption, setGenAIOption] = useState(false);

  const fetchAllTags = (token) => {
    axios
      .get(`${DATABOOK_API}/tag-classification/tags`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let result = res.data?.tags.map((item) => {
          if (item.fqn !== null) {
            return { value: item.fqn, label: item.tagName };
          }
        });
        setDatasetTags(result);
      });
  };

  const savedescription = async (value) => {
    const inprogresstoast = toast.info(
      "Updating description details.Please wait..."
    );
    let requestbody = {
      description: value,
      id: modaldata.fqn,
    };

    (async () => await getToken())();
    if (token) {
      axios
        .put(`${DATABOOK_API}/datasets`, requestbody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setUpdateDescToggle(!updateDescToggle);
          getallcolumndata(token);
          toast.update(inprogresstoast, {
            render: "Dataset description updated successfully.",
            type: toast.TYPE.SUCCESS,
            autoClose: 6000,
            closeButton: true,
            isLoading: false,
          });
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            // toast.update(inprogresstoast, {
            //   render: error.response.data,
            //   type: toast.TYPE.ERROR,
            //   autoClose: 6000,
            //   closeButton: true,
            //   isLoading: false,
            // });
            // console.log(error)
            // setDescToggle(false);
          } else {
            // toast.update(inprogresstoast, {
            //   render:
            //     "Something went wrong while updating dataset description.",
            //   type: toast.TYPE.ERROR,
            //   autoClose: 6000,
            //   closeButton: true,
            //   isLoading: false,
            // });
          }
          console.log(error);
          setUpdateDescToggle(false);
        });
    }
  };

  const getSecurityClassification = async (token) => {
    axios
      .get(
        `${DATABOOK_API}/glossary-classification/terms/classification/SecurityClassification`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        let result = res.data?.glossaryTerms.map((item) => {
          if (item.fqn !== null) {
            return { value: item.fqn, label: item.termName };
          }
        });
        setSecurityClassification(result);
      });
  };

  const getSelectSensitivity = (token) => {
    try {
      const fetchCarouselPromises = modaldata.securityClassification?.map(
        (classification) =>
          axios.get(
            `${DATABOOK_API}/glossary-classification/terms/${classification}/terms`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          )
      );

      if (fetchCarouselPromises) {
        Promise.all(fetchCarouselPromises).then((values) => {
          let container = [];
          values.map((res) => {
            res.data.glossaryTerms.map((item) =>
              container.push({ value: item.fqn, label: item.termName })
            );
          });
          setSensitivity(container);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = (flag) => {
    setSelectedClassification([]);
    setSelectedSensitivity([]);
    // props.setModalFlag(flag);
    setDatasetModalFlag(flag);
    callapiwithtoken(getSelectSensitivity);
  };

  const validateFields = () => {
    return selectedclassification?.filter(
      (item) => item?.indexOf("confidential") > -1
    );
  };

  const editsaveDatasetFields = () => {
    if (fieldname === "securityClassification" || "sensitivity") {
      if (
        (validateFields()?.length > 0 && selectedsensitivity?.length === 0) ||
        (selectedclassification?.length === 0 &&
          selectedsensitivity?.length > 0)
      ) {
        toast.info("Security classificaton or sensitivity cannot be blank. ", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    }

    const inprogresstoast = toast.info(
      "Updating dataset fields. Please wait..."
    );
    let requestbody = "";
    if (fieldname === "owner") {
      requestbody = {
        id: modaldata?.fqn,
        owner: datasetOwner,
      };
    } else if (fieldname === "tags") {
      requestbody = {
        id: modaldata?.fqn,
        tags: selectedfield,
      };
    } else if (fieldname === "securityClassification" || "sensitivity") {
      requestbody = {
        id: modaldata?.fqn,
        securityClassification: selectedclassification,
        sensitivity: selectedsensitivity,
      };
    } else if (fieldname === "description") {
      requestbody = {
        id: modaldata?.fqn,
        description: selectedfield,
      };
    }
    axios
      .put(`${DATABOOK_API}/datasets`, requestbody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        getallcolumndata(token);
        setDatasetModalFlag(false);
        toast.update(inprogresstoast, {
          render: "Dataset field updated successfully.",
          type: toast.TYPE.SUCCESS,
          autoClose: 6000,
          closeButton: true,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        // if (error.response.status == 401) {
        // toast.update(inprogresstoast, {
        //   render: error.response.data,
        //   type: toast.TYPE.ERROR,
        //   autoClose: 6000,
        //   closeButton: true,
        //   isLoading: false,
        // });
        closeModal();
        // }
      });
  };

  const setdefaultvalues = () => {
    let classification = modaldata?.securityClassification;
    let defaultClassification = [];
    if (classification) {
      classification?.map((item) => {
        defaultClassification.push(
          ...securityclassification.filter((x) => x.value === item)
        );
      });
      setFieldDefaultValues(defaultClassification);
    }

    setSelectedField(classification);
    setSelectedClassification(defaultClassification?.map((item) => item.value));
    if (modaldata?.sensitivity?.length > 0) {
      var defaultselectedSensitivity = sensitivity?.filter(function (el) {
        return modaldata.sensitivity?.indexOf(el.value) >= 0;
      });
      setSelectedSensitivity(
        defaultselectedSensitivity?.map((item) => item.value)
      );
      setSensitivityDefaultValues(defaultselectedSensitivity);
    }
  };

  const classificationType = (classification) => {
    let confidenceFlag = classification?.indexOf("confidential") > -1;
    let internalFlag = classification?.indexOf("internal") > -1;
    let publicFlag = classification?.indexOf("public") > -1;

    if (confidenceFlag) {
      return "sensitivity-confidential";
    } else if (internalFlag) {
      return "sensitivity-internal";
    } else if (publicFlag) {
      return "sensitivity-public";
    } else {
      return "bg-white";
    }
  };

  const showModal = (name) => {
    switch (name) {
      case "tags":
        setFieldDefaultValues(
          modaldata?.tags?.map((item) => ({
            label: item.tagName,
            value: item.fqn,
          }))
        );
        let tagselectedefault = [];
        modaldata?.tags?.map((item) => {
          tagselectedefault.push(
            ...datasetstag.filter((x) => x.value === item.fqn)
          );
        });
        setSelectedField(
          tagselectedefault.map((item) => ({
            tagName: item.label,
            fqn: item.value,
          }))
        );
        // props.setModalFlag(true);
        setDatasetModalFlag(true);
        setFieldName(name);
        break;
      case "securityClassification":
        setdefaultvalues();
        // props.setModalFlag(true);
        setDatasetModalFlag(true);
        setFieldName(name);
        break;

      case "sensitivity":
        setdefaultvalues();
        // props.setModalFlag(true);
        setDatasetModalFlag(true);
        setFieldName(name);
        break;

      case "owner":
        setEditDatasetOwner(true);
        setDatasetOwner(modaldata.owner);
        // props.setModalFlag(true);
        setDatasetModalFlag(true);
        setFieldName(name);
        break;

      case "description":
        setFieldDefaultValues(modaldata.description);
        setSelectedField(modaldata.description);
        setDatasetModalFlag(true);
        setFieldName(name);
        break;

      default:
        break;
    }
  };

  const getSensitivityForClassification = (selectedSecurity) => {
    let allterms = [];

    selectedSecurity.map((classification) => {
      axios
        .get(
          `${DATABOOK_API}/glossary-classification/terms/${classification}/terms`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          allterms = [...allterms, ...res.data.glossaryTerms];
          let result = allterms.map((item) => {
            if (item.fqn !== null) {
              return { value: item.fqn, label: item.termName };
            }
          });
          setSelectedSensitivity([]);
          setSensitivity(result);
        });
    });
  };

  const editDatasetOwnerHandler = (e) => {
    let ownerlist = e.target.value.split(",");
    setDatasetOwner(ownerlist);
  };

  const getPropagateTags = async (token) => {
    axios
      .get(
        `${DATABOOK_API}/lineage/propagate/tags_classification/${modaldata.fqn}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        // console.log("propagate", res.data)
        // let result = res.data?.glossaryTerms.map((item) => {
        //   if (item.fqn !== null) {
        //     return { value: item.fqn, label: item.termName };
        //   }
        // });
        // setSecurityClassification(result);
        callapiwithtoken(getallcolumndata);
      })
      .catch((error) => {
        console.log("Propagate Tags Error", error);
      });
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    callapiwithtoken(fetchAllTags);
    callapiwithtoken(getSecurityClassification);
    callapiwithtoken(getSelectSensitivity);
  }, [instance, modaldata]);

  return (
    <div style={{ height: "56vh" }}>
      {isFetching ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {!updateDescToggle && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                margin: "0.4rem 1rem",
                gap: "0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <h3 style={{ fontSize: "18px", margin: "0" }}>Description: </h3>
                <img
                  src={editicon}
                  alt="Edit Description"
                  onClick={() => {
                    setUpdateDescToggle(true);
                  }}
                  style={{ cursor: "pointer", height: "36px", width: "36px" }}
                />
                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.8rem",
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <p
                          style={{
                            margin: "0",
                            fontSize: "14px",
                          }}
                        >
                          Propagate Tags
                        </p>
                      </Tooltip>
                    }
                  >
                    <Badge color="error" variant="dot">
                      <Button
                        style={{
                          minWidth: "32px",
                          margin: "0",
                          textTransform: "none",
                          backgroundColor: "#1890ff",
                        }}
                        variant="contained"
                        size="small"
                      >
                        <AltRouteIcon
                          // onClick={() => setGenAIOption(!genAIOption)}
                          onClick={() => callapiwithtoken(getPropagateTags)}
                        />
                      </Button>
                    </Badge>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <p
                          style={{
                            margin: "0",
                            fontSize: "14px",
                          }}
                        >
                          Suggested Descriptions
                        </p>
                      </Tooltip>
                    }
                  >
                    <Badge color="error" variant="dot">
                      <img
                        src={genAIchip}
                        alt="genAIchip image"
                        style={{
                          cursor: "pointer",
                          height: "32px",
                          width: "32px",
                          backgroundColor: "#E6F4FF",
                          borderRadius: "8px",
                          margin: "0",
                        }}
                        onClick={() => setGenAIOption(!genAIOption)}
                      />
                    </Badge>
                  </OverlayTrigger>
                  {genAIOption && (
                    <>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "12px",
                          border: "2px solid #E6F4FF",
                          // backgroundColor: "#E6F4FF",
                          color: "#1890ff",
                          borderRadius: "8px",
                          padding: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <CheckIcon
                          style={{
                            margin: "0 0.4rem",
                            height: "1rem",
                            width: "1rem",
                          }}
                        />
                        Accept all
                      </p>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "12px",
                          border: "2px solid #E6F4FF",
                          // backgroundColor: "#E6F4FF",
                          color: "#1890ff",
                          borderRadius: "8px",
                          padding: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon
                          style={{
                            margin: "0 0.4rem",
                            height: "1rem",
                            width: "1rem",
                          }}
                        />
                        Reject all
                      </p>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "12px",
                          border: "2px solid #E6F4FF",
                          // backgroundColor: "#E6F4FF",
                          color: "#1890ff",
                          borderRadius: "8px",
                          padding: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => setGenAIOption(false)}
                      >
                        <ExitToAppIcon
                          style={{
                            margin: "0 0.4rem",
                            height: "1rem",
                            width: "1rem",
                          }}
                        />
                        Close
                      </p>
                    </>
                  )}
                </div>
              </div>
              <p
                style={{
                  margin: "0",
                  fontSize: "14px",
                  // border: "1px solid #d3d3d3",
                  padding: "0.4rem 0.4rem",
                  width: "96%",
                  textAlign: "left",
                }}
              >
                {modaldata?.description || "No description available"}
              </p>
            </div>
          )}
          {updateDescToggle && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                margin: "0.4rem 1rem",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <h3 style={{ fontSize: "18px", margin: "0" }}>
                  Edit Description:{" "}
                </h3>
                <CheckIcon
                  style={
                    updateDescData !== ""
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                  onClick={() => {
                    updateDescData !== "" && savedescription(updateDescData);
                  }}
                />
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setUpdateDescToggle(false);
                  }}
                />
              </div>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={2}
                defaultValue={modaldata?.description && modaldata?.description}
                style={{ margin: "0", fontSize: "14px", width: "96%" }}
                onChange={(e) => {
                  setUpdateDescData(e.target.value);
                }}
              />
            </div>
          )}

          <TableContainer
            component={Paper}
            sx={{ maxWidth: "100%", maxHeight: "46vh" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead style={{ backgroundColor: "#E6F4FF" }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={"Organisation"}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Organisation
                  </TableCell>
                  <TableCell>
                    {modaldata?.orgModel?.map((org) => (
                      <p style={{ margin: "0 auto", fontSize: "14px" }}>
                        {org}
                      </p>
                    ))}
                  </TableCell>
                </TableRow>
                {modaldata.type !== "datacube" && (
                  <TableRow key={"Last Modified"}>
                    <TableCell component="th" scope="row">
                      Last Modified
                    </TableCell>
                    <TableCell>
                      {modaldata?.certification?.certificationDate || "N/A"}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={"Domain"}>
                  <TableCell component="th" scope="row">
                    Domain
                  </TableCell>
                  <TableCell>{modaldata?.domains || "N/A"}</TableCell>
                </TableRow>
                <TableRow key={"Accessed in the last month"}>
                  <TableCell component="th" scope="row">
                    Accessed in the last month
                  </TableCell>
                  <TableCell>{oneMonthUserVisitCount || "N/A"}</TableCell>
                </TableRow>
                <TableRow key={"Data Owner"}>
                  <TableCell component="th" scope="row">
                    Data Owner
                  </TableCell>
                  <TableCell style={{ display: "flex", gap: "0.8rem" }}>
                    {modaldata?.owner ? (
                      modaldata?.owner?.map((own, idx) => (
                        <p
                          key={idx}
                          style={{ margin: "0", fontSize: "14px" }}
                          className="dhc-header-message-classi m-1"
                        >
                          {/* {idx + 1}. */}
                          {own}
                          {/* {idx + 1 !== modaldata?.owner?.length && ","} */}
                        </p>
                      ))
                    ) : (
                      <p style={{ margin: "0", fontSize: "14px" }}>N/A</p>
                    )}
                    <img
                      src={editicon}
                      alt="Edit Classification"
                      onClick={() => {
                        showModal("owner");
                      }}
                      style={{
                        cursor: "pointer",
                        height: "36px",
                        width: "36px",
                        // margin: "-8px",
                        // padding: "-8px",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={"Classification and Sensitivity"}>
                  <TableCell component="th" scope="row">
                    Classification and Sensitivity
                  </TableCell>
                  <TableCell style={{ display: "flex", gap: "0.8rem" }}>
                    {modaldata.securityClassification?.map((classi, index) => (
                      <p
                        className={
                          "dhc-header-message-classi m-1 " +
                          classificationType(classi)
                        }
                      >
                        {classi?.replace("securityclassification.", "")}
                      </p>
                    )) || <p style={{ margin: "0", fontSize: "14px" }}>N/A</p>}
                    {modaldata.sensitivity?.map((classi, index) => (
                      <p key={index} className="dhc-header-message-classi m-1">
                        {classi?.includes(
                          "securityclassification.confidential."
                        ) &&
                          classi?.replace(
                            "securityclassification.confidential.",
                            ""
                          )}
                        {classi?.includes("securityclassification.internal.") &&
                          classi?.replace(
                            "securityclassification.internal.",
                            ""
                          )}
                        {classi?.includes("securityclassification.public.") &&
                          classi?.replace("securityclassification.public.", "")}
                      </p>
                    ))}
                    <img
                      src={editicon}
                      alt="Edit Classification"
                      onClick={() => {
                        showModal("securityClassification");
                      }}
                      style={{
                        cursor: "pointer",
                        height: "36px",
                        width: "36px",
                        // margin: "-8px",
                        // padding: "-8px",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={"Certify"}>
                  <TableCell component="th" scope="row">
                    Certify
                  </TableCell>
                  <TableCell>
                    <Switch
                      size="small"
                      style={{
                        // padding: "1px",
                        top: 0,
                        backgroundColor: certifiedetails?.certifiedFlag
                          ? "green"
                          : "",
                      }}
                      // checkedChildren={
                      // <CheckIcon
                      //   fontSize="small"
                      //   color="error"
                      //   style={{ marginTop: "-8px" }}
                      // />
                      // }
                      // unCheckedChildren={
                      // <CloseIcon
                      //   fontSize="small"
                      //   color="error"
                      //   style={{ marginTop: "-8px" }}
                      // />
                      // }
                      checked={certifiedetails?.certifiedFlag}
                      onChange={updateCertificationDetails}
                      loading={toggleloading}
                    />
                  </TableCell>
                </TableRow>
                {certifiedetails?.certifiedFlag && (
                  <TableRow key={"Certified by"}>
                    <TableCell component="th" scope="row">
                      Certified by
                    </TableCell>
                    <TableCell>
                      {certifiedetails?.certifiedBy || "N/A"}
                    </TableCell>
                  </TableRow>
                )}
                {certifiedetails?.certifiedFlag && (
                  <TableRow key={"Certified date"}>
                    <TableCell component="th" scope="row">
                      Certified date
                    </TableCell>
                    <TableCell>
                      {certifiedetails?.certificationDate || "N/A"}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={"Tags"}>
                  <TableCell component="th" scope="row">
                    Tags
                  </TableCell>
                  <TableCell style={{ display: "flex", gap: "0.8rem" }}>
                    {modaldata.tags && modaldata.tags.toString()?.length > 0 ? (
                      modaldata.tags.map((tag, idx) => (
                        <p
                          className="dhc-header-message-classi"
                          style={{ margin: "0", fontSize: "14px" }}
                        >
                          {tag.tagName.toString()}
                        </p>
                      ))
                    ) : (
                      <p style={{ margin: "0", fontSize: "14px" }}>N/A</p>
                    )}
                    <img
                      src={editicon}
                      alt="Edit Tags"
                      onClick={() => {
                        showModal("tags");
                      }}
                      style={{
                        cursor: "pointer",
                        height: "36px",
                        width: "36px",
                        // margin: "-8px",
                        // padding: "-8px",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={"Glossary Terms"}>
                  <TableCell component="th" scope="row">
                    Glossary Terms
                  </TableCell>
                  <TableCell>N/A</TableCell>
                </TableRow>
                <TableRow key={"Schema Rows"}>
                  <TableCell component="th" scope="row">
                    Schema Rows
                  </TableCell>
                  <TableCell>{modaldata?.columns?.length || "N/A"}</TableCell>
                </TableRow>
                <TableRow key={"Platform"}>
                  <TableCell component="th" scope="row">
                    Platform
                  </TableCell>
                  <TableCell style={{ display: "flex", gap: "0.8rem" }}>
                    {modaldata?.platform?.map((plat) => (
                      <p style={{ margin: "0", fontSize: "14px" }}>{plat}</p>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow key={"Product"}>
                  <TableCell component="th" scope="row">
                    Product
                  </TableCell>
                  <TableCell>{modaldata.product || "N/A"}</TableCell>
                </TableRow>
                {modaldata.type !== "datacube" && (
                  <TableRow key={"Primary Keys"}>
                    <TableCell component="th" scope="row">
                      Primary Keys
                    </TableCell>
                    <TableCell>{"N/A"}</TableCell>
                  </TableRow>
                )}
                {modaldata.type !== "datacube" && (
                  <TableRow key={"Partition Keys"}>
                    <TableCell component="th" scope="row">
                      Partition Keys
                    </TableCell>
                    <TableCell>{"N/A"}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <DatasetFieldsEditModal
        show={datasetModalShow}
        name={fieldname}
        datasetstag={datasetstag}
        detaildata={modaldata}
        setSelectedField={setSelectedField}
        editsaveDatasetFields={editsaveDatasetFields}
        fieldDefaultValues={fieldDefaultValues}
        securityclassification={securityclassification}
        setSelectedClassification={setSelectedClassification}
        getSensitivityForClassification={getSensitivityForClassification}
        sensitivity={sensitivity}
        setSelectedSensitivity={setSelectedSensitivity}
        selectedsensitivity={selectedsensitivity}
        sensitivityDefaultValues={sensitivityDefaultValues}
        closeModel={closeModal}
        editDatasetOwnerFlag={editDatasetOwnerFlag}
        datasetOwner={datasetOwner}
        editDatasetOwnerHandler={editDatasetOwnerHandler}
      />
    </div>
  );
}

export default Overview;
