import React, { useContext, useEffect, useState } from "react"
import { Link, NavLink, useNavigate } from "react-router-dom"
import axios from "axios"
import { MARTBUILDER_API } from "../../serverConfig"
// import { BsPatchCheckFill, BsThreeDotsVertical } from "react-icons/bs";
import MoreVertIcon from "@mui/icons-material/MoreVert"
import VerifiedIcon from "@mui/icons-material/Verified"
import { Box, Button, CircularProgress } from "@mui/material"
import "./MyConnections.css"
import mysql from "../../assets/images/DatabaseIcons/mysql.svg"
import dreamio from "../../assets/images/DatabaseIcons/dreamio.svg"
import postgre from "../../assets/images/DatabaseIcons/postgre.svg"
import azureDataLake from "../../assets/images/DatabaseIcons/AzureDataLake.webp"
import clickHouse from "../../assets/images/DatabaseIcons/Clickhouse.svg"
import microsoftSQL from "../../assets/images/DatabaseIcons/MicrosoftSQL.svg"
import oracle from "../../assets/images/DatabaseIcons/Oracle.svg"
import redshift from "../../assets/images/DatabaseIcons/Redshift.svg"
import salesForce from "../../assets/images/DatabaseIcons/Salesforce.png"
import snowflake from "../../assets/images/DatabaseIcons/snowflake.svg"
import SFTP from "../../assets/images/DatabaseIcons/SFTC.png"
import { AuthContext } from "../../AuthContext"
import { useMsal } from "@azure/msal-react"
import { protectedResources } from "../../msalConfig"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Dropdown from "react-bootstrap/Dropdown"
import DeleteCrawlModal from "../../components/NewComponents/DeleteCrawlModal"
import BreadcrumbMUI from "../../components/NewComponents/BreadcrumbMUI"

const MyConnections = () => {
  const { dcerror, dctoken, getDatacraftToken, getToken, token } =
    useContext(AuthContext)
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const request = {
    scopes: protectedResources.datacraft.scopes,
    account: account,
  }

  const navigate = useNavigate()

  const [connLoading, setConnLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [modaldelete, setModalDelete] = useState(false)
  const [connectionDeleteId, setConnectionDeleteId] = useState("")

  const fetchDataSource = async (getToken) => {
    console.log(getToken, "getToken")
    setConnLoading(true)
    let username = account.username
    const ourRequest = axios.CancelToken.source()
    try {
      let response = await axios.get(
        // this one is removed as refed from
        // `${MARTBUILDER_API}/datasources/user/${username}`,
        `${MARTBUILDER_API}/datasources/`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${getToken}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      )
      // response.data.map((item) => {
      // console.log("all Connection ::::", item.createdBy);
      // });
      // const filteredResult = response.data.filter(
      //   (item) => item.product?.createdBy === account.username
      // );
      console.log(response.data, "response.data")
      setDataSource(response.data)
      setConnLoading(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
      setConnLoading(false)
    }
    return () => {
      ourRequest.cancel()
    }
  }

  const callapiwithtoken = async (callback) => {
    ;(async () => await getToken())()
    if (token) {
      await callback(token)
    }
    if (dcerror) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken
        await callback(rtoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    callapiwithtoken(fetchDataSource)
    // fetchDataSource();
  }, [token, dcerror])

  // const calculateDays = (date) => {
  //   console.log("date : ", date)
  //   let d = "2024-06-25";
  //   var today = new Date();
  //   var date_to_reply = new Date(d);
  //   var timeinmilisec = date_to_reply.getTime() - today.getTime();
  //   console.log(Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)));
  //   return Math.abs(Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)));
  // }
  const calculateDays = (d1, d2) => {
    var ndays
    var tv1 = d1?.valueOf() // msec since 1970
    var tv2 = d2?.valueOf()

    ndays = (tv2 - tv1) / 1000 / 86400
    ndays = Math.round(ndays - 0.5)
    return ndays
  }

  // Helper function to truncate text
  const truncateText = (text, wordLimit) => {
    if (!text) return ""
    const words = text.split(" ")
    if (words.length <= wordLimit) return text
    return words.slice(0, wordLimit).join(" ") + ""
  }
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
      {/* <span className="threedots" /> */}
      {/* <BsThreeDotsVertical size={20} /> */}
      <MoreVertIcon />
    </a>
  ))

  const deleteDatasourceConnection = async (connectionID) => {
    setConnLoading(true)
    setModalDelete(false)
    const ourRequest = axios.CancelToken.source()
    try {
      await axios
        .delete(
          MARTBUILDER_API + "/datasources/" + connectionID?.connectionId,
          {
            headers: {
              // Define your custom headers here
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          }
        )
        .then((res) => {
          console.log(res.data)
          setConnLoading(false)
          callapiwithtoken(fetchDataSource)
        })
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
      setConnLoading(false)
    }
    return () => {
      ourRequest.cancel()
    }
  }

  return (
    <>
      <BreadcrumbMUI last="My Connections" />
      <div style={{ padding: "0 1.4rem" }}>
        <div className="myConPageHeader">
          <h3>My Connections</h3>
          <Button
            style={{
              margin: "0",
              textTransform: "none",
              backgroundColor: "#1890ff",
            }}
            variant="contained"
            // size="small"
            onClick={() => {
              navigate("/createDataSources")
            }}
          >
            + New Data Connection
          </Button>
        </div>
        <DeleteCrawlModal
          crawl={connectionDeleteId}
          open={modaldelete}
          onClose={() => setModalDelete(!modaldelete)}
          handleDeleteCrawl={deleteDatasourceConnection}
        />
        {connLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "#d3d3d3",
            }}
          >
            <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
          </Box>
        ) : (
          <>
            {dataSource.length === 0 && (
              <div className="no-match">
                <h4>There are no Connections yet!</h4>
                <div style={{ margin: "0 8px" }}>
                  <h4>
                    Please click <Link to="/createDataSources">here</Link> to
                    create a new connection.
                  </h4>
                </div>
              </div>
            )}
            <div className="myConPageContent">
              {dataSource?.map((data) => (
                <>
                  {/* {console.log("Data : ", data)} */}
                  <NavLink
                    to={"/connectiondetail/" + data.connectionFqn}
                    state={{
                      connectionName: data.connectionName,
                      createdAt: data.createdAt,
                      datasourceType: data.datasourceType,
                      connectionFqn: data.connectionFqn,
                      description: data.description,
                    }}
                    // className="d-inline-flex px-1"
                    // className="g-col-5 g-col-md-5"
                  >
                    <div className="MyConsingleCard">
                      <div className="myConPageHeader">
                        <p className="techName">{data?.product?.name}</p>
                        <div className="imageText">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip">
                                {data.datasourceType}
                              </Tooltip>
                            }
                            // popperConfig={{ testObj: "BI Product" }}
                          >
                            <span>
                              {data.datasourceType === "Dremio" && (
                                <img
                                  src={dreamio}
                                  alt="dremio"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "MicrosoftSQL" && (
                                <img
                                  src={microsoftSQL}
                                  alt="microsoftSQL"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "AzureDataLake" && (
                                <img
                                  src={azureDataLake}
                                  alt="azureDataLake"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "MySQL" && (
                                <img
                                  src={mysql}
                                  alt="mysql"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "PostgreSQL" && (
                                <img
                                  src={postgre}
                                  alt="postgre"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "SFTP" && (
                                <img
                                  src={SFTP}
                                  alt="SFTP"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "ClickHouse" && (
                                <img
                                  src={clickHouse}
                                  alt="clickHouse"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "Oracle" && (
                                <img
                                  src={oracle}
                                  alt="oracle"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "SalesForce" && (
                                <img
                                  src={salesForce}
                                  alt="salesForce"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "Redshift" && (
                                <img
                                  src={redshift}
                                  alt="redshift"
                                  className="techLogo"
                                />
                              )}
                              {data.datasourceType === "Snowflake" && (
                                <img
                                  src={snowflake}
                                  alt="snowflake"
                                  className="techLogo"
                                />
                              )}
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip1">
                                Connection tested !
                              </Tooltip>
                            }
                            // popperConfig={{ testObj: "BI Product" }}
                          >
                            <span className="px-1">
                              {/* <BsPatchCheckFill size={20} fill="#007fff" /> */}
                              <VerifiedIcon color="primary" />
                            </span>
                          </OverlayTrigger>
                          {/* <BsThreeDotsVertical size={14} /> */}
                          {/* <MoreVertIcon /> */}
                          <Dropdown className="mt-0 ">
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                              {/* <Dropdown.Header>Options</Dropdown.Header> */}
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(
                                    "/createDataSources/" + data.connectionId,
                                    { state: { actionType: "EDIT" } }
                                  )
                                }
                              >
                                Edit
                              </Dropdown.Item>
                              {/* <Dropdown.Item onClick={() => deleteDatasourceConnection(data.connectionId)
                              }>Delete</Dropdown.Item> */}
                              <Dropdown.Item
                                onClick={() => {
                                  setModalDelete(true)
                                  setConnectionDeleteId(data)
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div style={{ marginBottom: "14px" }}>
                        <p className="myConName">
                          {data.connectionName || "Name N/A"}
                        </p>
                        {/* <p className="myConName"> {truncateText(data.connectionName, 2) || "Name N/A"}</p> */}

                        <p className="myConTimeDetails">
                          {/* Created at:s {data?.createdAt?.slice(0, 10)} */}
                          Created{" "}
                          {calculateDays(data?.createdAt?.slice(0, 10)) ===
                          0 ? (
                            <span>today</span>
                          ) : (
                            <span>
                              {" "}
                              {calculateDays(
                                new Date(data?.createdAt?.slice(0, 10)),
                                new Date()
                              )}{" "}
                              days ago
                            </span>
                          )}
                        </p>
                        <p className="myConTimeDetails">
                          Created by: {data.createdBy}
                        </p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                          style={{
                            fontSize: "12px",
                            color: "#000000",
                            padding: "0.2rem",
                          }}
                        >
                          <span>Business Units: </span>
                          {data?.product?.orgModel
                            ?.map((a) => a.name)
                            .toString() || "N/A"}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#000000",
                            padding: "0.2rem",
                          }}
                        >
                          <span>Domains: </span>
                          {data.product?.business
                            ?.map((a) => a.name)
                            .toString() || "N/A"}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#000000",
                            padding: "0.2rem",
                          }}
                        >
                          <span>Sub Domains: </span>
                          {data?.product?.platform
                            ?.map((a) => a.name)
                            .toString() || "N/A"}
                        </p>
                      </div>
                      {/* <NavLink
                      to={"/connectiondetail/" + data.connectionFqn}
                      state={{
                        connectionName: data.connectionName,
                        createdAt: data.createdAt,
                        datasourceType: data.datasourceType,
                        connectionFqn: data.connectionFqn,
                        description: data.description,
                      }}
                    > */}
                      <div className="myConActionContainer">
                        <div
                          className="myConActionBox"
                          style={{ opacity: "1" }}
                        >
                          <p className="myConCrawlTrue">
                            {data?.crawlCount > 0 ? data?.crawlCount : 0}
                          </p>
                          <p>Data Crawl</p>
                        </div>
                      </div>
                      {/* </NavLink> */}
                    </div>
                  </NavLink>
                </>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MyConnections
