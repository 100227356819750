import React, { useState, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { Table } from "antd";
import editicon from "../../../assets/images/pencil.svg";
import deleteicon from "../../../assets/images/trash.svg";
import addicon from "../../../assets/images/plus.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { Delete, Edit, Add } from "@mui/icons-material";

const GlossaryTable = (props) => {
  const { terms, handleEditTerm, handleDelTerm, handleAddChild } = props;

  const [tableData, setTableData] = useState([]);

  const columns = useMemo(() => [
    {
      accessorKey: "term",
      header: "Term",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: "classificationName",
      header: "Classification",
      size: 140,
    },
  ]);

  useEffect(() => {
    createTableData();
    // data();
  }, [terms]);

  const createTableData = () => {
    const actualData = [];
    const stackdupl = [...terms];

    while (stackdupl.length > 0) {
      const item = stackdupl.shift();
      const { fqn, classificationName, termName, description } = item;

      const row = {
        key: fqn || termName,
        term: termName,
        classificationName,
        termName,
        description,
        fqn,
        children: [],
      };

      const parentFqn = fqn ? fqn.split(".").slice(0, -1).join(".") : null;

      //This is to form the parent-child relation

      const findRowByKey = (data, key) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return data[i];
          }
          const nestedRow = findRowByKey(data[i].children, key);
          if (nestedRow) {
            return nestedRow;
          }
        }
        return null;
      };

      const parentRow = findRowByKey(actualData, parentFqn);
      if (parentRow) {
        parentRow.children.push(row);
      } else {
        actualData.push(row);
      }
    }

    // Where there is empty children array remove that else antd table behaviour - where it sees children
    //property it gives you expand and colapse icon
    const removeEmptyChildren = (data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children.length === 0) {
          delete data[i].children;
        } else {
          removeEmptyChildren(data[i].children);
        }
      }
    };

    removeEmptyChildren(actualData);
    setTableData(actualData);
  };

  // const data = () => {
  //   const actualData = [];
  //   const stackdupl = [...terms];

  //   while (stackdupl.length > 0) {
  //     const item = stackdupl.shift();
  //     const { fqn, classificationName, termName, description } = item;

  //     if (item?.children) {
  //       var row = {
  //         key: fqn || termName,
  //         term: termName,
  //         classificationName,
  //         termName,
  //         description,
  //         fqn,
  //         subRows: [
  //           {
  //             key: item?.children?.fqn || item?.children?.termName,
  //             term: item?.children?.termName,
  //             classificationName: item?.children?.classificationName,
  //             termName: item?.children?.termName,
  //             description: item?.children?.description,
  //             fqn: item?.children?.fqn,
  //           },
  //         ],
  //       };
  //     } else {
  //       var row = {
  //         key: fqn || termName,
  //         term: termName,
  //         classificationName,
  //         termName,
  //         description,
  //         fqn,
  //       };
  //     }

  //     actualData.push(row);
  //   }
  //   setTableData(actualData);
  // };

  console.log("tableData", tableData);

  return (
    <div className="py-3">
      <Table
        dataSource={tableData}
        columns={[
          {
            title: "Term",
            dataIndex: "term",
            key: "term",
            render: (termname) => <span>{termname}</span>,
          },

          // {
          //   title: "Classification",
          //   dataIndex: "classificationName",
          //   key: "classificationName",
          // },
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
          },

          {
            title: "Actions",
            dataIndex: "",
            width: "18%",
            key: "action",
            render: (record) => {
              return (
                <>
                  <div className="action__icon">
                    <Button
                      onClick={() =>
                        handleAddChild(record?.fqn, record?.classificationName)
                      }
                    >
                      <img src={addicon} alt="Add Child Tag" />
                    </Button>
                    <Button
                      onClick={() =>
                        handleEditTerm(
                          record?.fqn,
                          record?.description,
                          record?.termName
                        )
                      }
                    >
                      <img src={editicon} alt="Edit Child Tag" />
                    </Button>
                    <Button
                      onClick={() =>
                        handleDelTerm(record?.fqn, record?.termName)
                      }
                    >
                      <img src={deleteicon} alt="Delete Child Tag" />
                    </Button>{" "}
                  </div>
                </>
              );
            },
          },
        ]}
        pagination={true}
      />

      {/* <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              // align: "center",
              sx: { paddingLeft: "3.6rem", backgroundColor: "#42b0d5" },
            },
            muiTableBodyCellProps: {
              // align: "center",
              sx: { paddingLeft: "2.8rem" },
            },
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "#42b0d5",
            paddingLeft: "2.8rem",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            paddingLeft: "2.8rem",
          },
        }}
        initialState={{
          density: "compact",
          pagination: { pageSize: 10, pageIndex: 0 },
        }}
        columns={columns}
        data={tableData}
        positionActionsColumn="last"
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        enableExpanding={true}
        enableExpandAll={false}
        renderRowActions={({ row }) => {
          const fqndata = row?.original.fqn;
          const decdata = row?.original.description;
          const termName = row?.original.termName;
          const classificationName = row?.original.classificationName;
          return (
            <Box sx={{ display: "flex" }}>
              <Tooltip arrow placement="left" title="Info">
                <IconButton
                  onClick={() => handleAddChild(fqndata, classificationName)}
                >
                  <Add />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Info">
                <IconButton
                  onClick={() => handleEditTerm(fqndata, decdata, termName)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={() => handleDelTerm(fqndata, termName)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }}
      /> */}
    </div>
  );
};
export default GlossaryTable;
