import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  // Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import Select from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import { userRequestMartbuilder } from "../../serverConfig";
import axios from "axios";
import "../../components/NewComponents/MetaDataCrawl/addNewData/AddNewMetaDataCrawlData.css";
import "../MyConnections/NewDataSourceConnection.scss";
import { useNavigate } from "react-router-dom";
import { useValueDCT } from "../../context/ContextProviderDCT";
import { AuthContext } from "../../AuthContext";
import Autocomplete from "@mui/material/Autocomplete";
import AzureDataLakeForm from "../../components/NewComponents/MetaDataCrawl/azureDataLakeForm/AzureDataLakeForm";
import TableDataFetching from "./TableDataFetching";
import Switch from "@mui/material/Switch";
import { Delete } from "@mui/icons-material";
import BackdropLoader from "../../components/NewComponents/Atoms/BackdropLoader";
import useWindowDimensions from "../../components/NewComponents/Atoms/useWindowDimensions";
import DeleteModal from "../../components/NewComponents/Atoms/DeleteModal";
import crawlIcon from "../../assets/images/crawler.png";
import CustomConfirmModal from "../../components/NewComponents/Atoms/CustomConfirmModal";
import CircularLoader from "../../components/NewComponents/Atoms/CircularLoader";
import WarningPopUp from "../../components/NewComponents/Atoms/WarningPopUp";
import { toast } from "react-toastify";
import BreadcrumbMUI from "../../components/NewComponents/BreadcrumbMUI";

const EditMetaDataCrawl = () => {
  const [editMetaData, setEditMetaData] = useState([]);
  const [dataSourceName, setDataSourceName] = useState(null);
  const [dataSourceList, setDataSourceList] = useState([]);

  //for product drop down
  const [preSelectedProductName, setPreSelectedProductName] = useState(null);
  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const { getToken, token, error } = useContext(AuthContext);
  //below three for handling the radio button
  const [isSchemaSelected, setIsSchemaSelected] = useState(false);
  const [isTableFilterSelected, setIsTableFilterSelected] = useState(false);
  const [isAutoCrawlSelected, setIsAutoCrawlSelected] = useState(false);
  const [isDelimiterSelected, setIsDelimiterSelected] = useState(false);

  // screen size
  const { height, width } = useWindowDimensions();

  // schema
  const [inputValue, setInputValue] = useState("");
  const [inputVealue, setInputVealue] = useState("");
  const [schemaInclusivePattern, setSchemaInclusivePattern] = useState([]);
  const [schemaExclusivePattern, setSchemaExclusivePattern] = useState([]);
  //tableSchema
  const [inputTableInclusiveValue, setInputTableInclusiveValue] = useState("");
  const [inputTableExclusiveValue, setInputTableExclusiveValue] = useState("");
  const [tableInclusivePatterns, setTableInclusivePatterns] = useState([]);
  const [tableExclusivePattern, setTableExclusivePattern] = useState([]);
  //for handling case if the user select AzureDataLake
  const [AzureDataLake, setAzureDataLake] = useState(true);
  //for handling case if the user select SFTP
  const [SFTP, setSFTP] = useState(true);
  //for payload handling
  const [customPayloadAzure, setCustomPayloadAzure] = useState(false);
  const [customPayloadSFTP, setCustomPayloadSFTP] = useState(false);
  //for disabling the input fields
  const [disableField, setDisableField] = useState(true);

  // Search function for dropdown
  const [searchText, setSearchText] = useState();
  //for handling the delimiterCharacter checkbox
  const [delimiterCharacter, setDelimiterCharacter] = useState(false);

  //handle delete notification modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //handle delete notification modal
  const [openRunStatusModal, setOpenRunStatusModal] = useState(false);
  const [loadingRunStatus, setLoadingRunStatus] = useState(false);
  const [openRunStatusPopUp, setOpenRunStatusPopUp] = useState(false);

  //below code is for manually testing the blinker
  //  const [manualTestStatus, setManualTestStatus] = useState({
  //   status: "COMPLETED", // Initial status for manual testing  NOT_STARTED RUNNING COMPLETED
  //   tasksCompleted: 0, // Initial task count for manual testing
  // });
  //usestate to handle blinker
  const [runningStatus, setRunningStatus] = useState({});
  // const [runningStatus, setRunningStatus] = useState(manualTestStatus);

  //fetching values from context
  const {
    state: {
      editMetaDataCrawlId: contextEditMetaDataCrawlId,
      editMetaDataCrawlFqn: contextEditMetaDataCrawlFqn,
      backdropLoading,
    },
    dispatch,
  } = useValueDCT();

  const storedToken = localStorage.getItem("token"); // This token is not working so I am using "token"
  // Using local state to manage IDs, falling back to local storage if context is not set
  const [editMetaDataCrawlId, setEditMetaDataCrawlId] = useState(
    contextEditMetaDataCrawlId ||
      localStorage.getItem("SET_EDIT_METADATACRAWL_ID")
  );
  const [editMetaDataCrawlFqn, setEditMetaDataCrawlFqn] = useState(
    contextEditMetaDataCrawlFqn ||
      localStorage.getItem("SET_EDIT_METADATACRAWL_FQN")
  );

  const dataSourceOptions = useMemo(
    () =>
      dataSourceList?.map((ds) => ({
        label: ds.connectionFqn,
        value: ds.connectionFqn,
      })),
    [dataSourceList]
  );

  const productOptions = useMemo(
    () =>
      productList.map((product) => ({
        label: product.name,
        value: product.id,
      })),
    [productList]
  );

  const handleSchemaRadioChange = () => {
    setIsSchemaSelected(!isSchemaSelected);
  };

  const handleTableFilterRadioChange = () => {
    setIsTableFilterSelected(!isTableFilterSelected);
  };

  const handleAutoCrawlRadioChange = () => {
    setIsAutoCrawlSelected(!isAutoCrawlSelected);
  };

  const handleDelimiterRadioChange = () => {
    setIsDelimiterSelected(!isDelimiterSelected);
  };

  //handling the input fields
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSchemaExclusiveChange = (e) => {
    setInputVealue(e.target.value);
  };

  const handleTableInclusiveChange = (e) => {
    setInputTableInclusiveValue(e.target.value);
  };

  const handleTableExclusiveChange = (e) => {
    setInputTableExclusiveValue(e.target.value);
  };

  //array handling
  const handleAddToDataArray = () => {
    if (inputValue.trim() !== "") {
      setSchemaInclusivePattern([...schemaInclusivePattern, inputValue]);
      setInputValue("");
    }
  };

  const schemaExclusive = () => {
    if (inputVealue.trim() !== "") {
      setSchemaExclusivePattern([...schemaExclusivePattern, inputVealue]);
      setInputVealue("");
    }
  };

  const tableInclusive = () => {
    if (inputTableInclusiveValue.trim() !== "") {
      setTableInclusivePatterns([
        ...tableInclusivePatterns,
        inputTableInclusiveValue,
      ]);
      setInputTableInclusiveValue("");
    }
  };

  const tableExclusive = () => {
    if (inputTableExclusiveValue.trim() !== "") {
      setTableExclusivePattern([
        ...tableExclusivePattern,
        inputTableExclusiveValue,
      ]);
      setInputTableExclusiveValue("");
    }
  };

  const navigate = useNavigate();
  const handleSubmit = () => {
    console.log(selectedProductId, "selectedProductId");
    if (!selectedProductId) {
      alert("Please select a product.");
      return;
    }
    metaDataCrawlPutReq();
    setSchemaInclusivePattern([]);
    setSchemaExclusivePattern([]);
    setTableInclusivePatterns([]);
    setTableExclusivePattern([]);
    navigate("/manageMetadatacrawls");
  };

  // Define state for the payload
  const [crawlPayload, setCrawlPayload] = useState({
    cronSchedule: "",
    datasourceConnectionFqn: "",
    name: "",
    product: {
      id: "",
    },
    fqn: "",
    id: editMetaDataCrawlId,
    isAutoCrawl: false,
    schemaExclusiveFilterPatterns: "",
    schemaInclusiveFilterPatterns: "",
    tableExclusiveFilterPatterns: "",
    tableInclusiveFilterPatterns: "",
  });

  const [azureData, setAzureData] = useState({
    adlsContainerName: "",
    adlsDatasetPath: "",
    adlsDatasetSchemaName: "",
    adlsDoesPathContainTables: true,
    adlsFileFormat: "",
    adlsStorageAccountName: "",
  });

  const [SFTPData, setSFTPData] = useState({
    sftpFileDirectory: "",
    csvDelimiter: "",
  });

  const azurePayload = {
    ...azureData,
    name: crawlPayload.name,
    product: {
      id: selectedProductId,
    },
    fqn: crawlPayload.fqn,
    datasourceConnectionFqn: dataSourceName,
    id: crawlPayload.id,
    isAutoCrawl: isAutoCrawlSelected,
    cronSchedule: crawlPayload.cronSchedule,
  };

  const sftpPayload = {
    ...SFTPData,
    name: crawlPayload.name,
    product: {
      id: selectedProductId,
    },
    fqn: crawlPayload.fqn,
    datasourceConnectionFqn: dataSourceName,
    id: crawlPayload.id,
    isAutoCrawl: isAutoCrawlSelected,
    isCSVDelimiter: isDelimiterSelected,
    cronSchedule: crawlPayload.cronSchedule,
  };

  const regularPayload = {
    cronSchedule: crawlPayload.cronSchedule,
    datasourceConnectionFqn: dataSourceName,
    name: crawlPayload.name,
    product: {
      id: selectedProductId,
    },
    fqn: crawlPayload.fqn,
    id: editMetaDataCrawlId,
    isAutoCrawl: isAutoCrawlSelected,
    schemaInclusiveFilterPatterns: schemaInclusivePattern,
    schemaExclusiveFilterPatterns: schemaExclusivePattern,
    tableInclusiveFilterPatterns: tableInclusivePatterns,
    tableExclusiveFilterPatterns: tableExclusivePattern,
  };

  //for fetching running status
  useEffect(() => {
    let intervalId;

    const fetchRunningStatus = async () => {
      if (runningStatus.status === "COMPLETED") {
        clearInterval(intervalId);
        return;
      }
      try {
        const response = await userRequestMartbuilder.get(
          `/metadatacrawl/taskStatus/${editMetaDataCrawlFqn}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response?.data, "<==== RunningStatus");
        setRunningStatus(response?.data);
        // console.log(manualTestStatus, "<==== manualRunningStatus")
        // console.log(runningStatus, "<==== runningStatus")
        // setRunningStatus(manualTestStatus);
        if (response?.data.status === "COMPLETED") {
          // if (manualTestStatus.status === "COMPLETED") {
          clearInterval(intervalId);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchRunningStatus();
    if (runningStatus.status !== "COMPLETED") {
      intervalId = setInterval(fetchRunningStatus, 5000);
    }

    return () => clearInterval(intervalId);
  }, [editMetaDataCrawlFqn, runningStatus.status, token]);
  // }, [manualTestStatus]);

  //previous code (not using for setInterval)
  //  const fetchingRunningStatus = async (editMetaDataCrawlFqn) => {
  //   const ourRequest = axios.CancelToken.source();
  //   try {
  //     let response = await userRequestMartbuilder.get(`/metadatacrawl/taskStatus/${editMetaDataCrawlFqn}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //         "Content-Type": "application/json",
  //       },
  //       CancelToken: ourRequest.token,
  //     });
  //     setRunningStatus(response?.data);
  //   } catch (err) {
  //     if (axios.isCancel(err)) {
  //       return;
  //     } else {
  //       console.error(err);
  //     }
  //   }
  //   return () => {
  //     ourRequest.cancel();
  //   };
  // };

  //for productlist fetching
  const fetchProductTableData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      // console.log(response.data, "<==== setProductList")
      setProductList(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //for hitting the put API
  const metaDataCrawlPutReq = async () => {
    const ourRequest = axios.CancelToken.source();
    let payload = regularPayload;

    const selectedDataSource = dataSourceList.find(
      (ds) => ds.connectionFqn === dataSourceName
    );

    if (selectedDataSource) {
      if (selectedDataSource.datasourceType === "AzureDataLake") {
        payload = azurePayload;
      } else if (selectedDataSource.datasourceType === "SFTP") {
        payload = sftpPayload;
      }
    }

    try {
      let response = await userRequestMartbuilder.put(
        `/metadatacrawl/configs/${editMetaDataCrawlFqn}`,
        payload,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      if (response.status === 200) {
        alert("Data Submitted Successfully");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  //below code for datasource fetching
  const fetchDataSources = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/datasources/`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      // console.log(response?.data, "<<===setDataSourceList" );
      setDataSourceList(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  //for fetching the editMetadataCrawl
  const editDataSource = async (editMetaDataCrawlFqn) => {
    dispatch({ type: "START_BACKDROP_LOADING" });
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/metadatacrawl/configs/${editMetaDataCrawlFqn}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );

      setEditMetaData(response?.data);
      setSelectedProductId(response?.data?.product?.id || null);
      console.log("existing value", response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    } finally {
      dispatch({ type: "END_BACKDROP_LOADING" });
    }
  };

  useEffect(() => {
    // (async () => await getToken())();
    // fetchingRunningStatus(editMetaDataCrawlFqn);
    fetchProductTableData();
    fetchDataSources();
    if (editMetaDataCrawlId) {
      editDataSource(editMetaDataCrawlFqn);
    }
    if (editMetaData.datasourceConnectionFqn) {
      setDataSourceName(editMetaData.datasourceConnectionFqn);
      setPreSelectedProductName(editMetaData?.product?.name);
      setSchemaInclusivePattern(editMetaData.schemaInclusiveFilterPatterns);
      setSchemaExclusivePattern(editMetaData.schemaExclusiveFilterPatterns);
      setTableInclusivePatterns(editMetaData.tableInclusiveFilterPatterns);
      setTableExclusivePattern(editMetaData.tableExclusiveFilterPatterns);
      setIsDelimiterSelected(editMetaData.isCSVDelimiter || false);

      setSelectedProductId(editMetaData?.product?.id || "");

      setCrawlPayload({
        ...crawlPayload,
        cronSchedule: editMetaData.cronSchedule,
        name: editMetaData.name,
        product: {
          id: selectedProductId,
        },
        fqn: editMetaData.fqn,
      });
      setSFTPData({
        sftpFileDirectory: editMetaData.sftpFileDirectory || "",
        csvDelimiter: editMetaData.csvDelimiter || "",
      });
      setAzureData({
        adlsContainerName: editMetaData.adlsContainerName || "",
        adlsDatasetPath: editMetaData.adlsDatasetPath || "",
        adlsDatasetSchemaName: editMetaData.adlsDatasetSchemaName || "",
        adlsDoesPathContainTables: editMetaData.adlsDoesPathContainTables || "",
        adlsFileFormat: editMetaData.adlsFileFormat || "",
        adlsStorageAccountName: editMetaData.adlsStorageAccountName || "",
        sftpFileDirectory: editMetaData.sftpFileDirectory || "",
        csvDelimiter: editMetaData.csvDelimiter || "",
      });

      //making the checkbox selected
      if (
        editMetaData.schemaInclusiveFilterPatterns.length > 0 ||
        editMetaData.schemaExclusiveFilterPatterns.length > 0
      ) {
        setIsSchemaSelected(true);
      }

      if (
        editMetaData.tableInclusiveFilterPatterns.length > 0 ||
        editMetaData.tableExclusiveFilterPatterns.length > 0
      ) {
        setIsTableFilterSelected(true);
      }

      if (editMetaData?.cronSchedule) {
        setIsAutoCrawlSelected(true);
      }
    }
  }, [editMetaDataCrawlId, editMetaData.datasourceConnectionFqn]);

  //delete the item from array
  const handleDeleteFromSchemaInclusive = (index) => {
    const updatedPatterns = schemaInclusivePattern.filter(
      (_, i) => i !== index
    );
    setSchemaInclusivePattern(updatedPatterns);
  };

  const handleDeleteFromSchemaExclusive = (index) => {
    const updatedPatterns = schemaExclusivePattern.filter(
      (_, i) => i !== index
    );
    setSchemaExclusivePattern(updatedPatterns);
  };

  const handleDeleteFromTableInclusive = (index) => {
    const updatedPatterns = tableInclusivePatterns.filter(
      (_, i) => i !== index
    );
    setTableInclusivePatterns(updatedPatterns);
  };

  const handleDeleteFromTableExclusive = (index) => {
    const updatedPatterns = tableExclusivePattern.filter((_, i) => i !== index);
    setTableExclusivePattern(updatedPatterns);
  };

  useEffect(() => {
    if (
      dataSourceList.length > 0 &&
      editMetaData.datasourceConnectionFqn &&
      productList.length > 0
    ) {
      // productList.length>0 <= added later
      const selectedDataSource = dataSourceList.find(
        (ds) => ds.connectionFqn === editMetaData.datasourceConnectionFqn
      );

      const selectedProduct = productList.find(
        (ds) => ds.name === editMetaData?.product?.name
      );

      if (selectedDataSource) {
        setDataSourceName(selectedDataSource.connectionFqn);
        setPreSelectedProductName(selectedProduct?.name);

        if (selectedDataSource.datasourceType === "AzureDataLake") {
          setAzureDataLake(false);
          setCustomPayloadAzure(true);
          setCustomPayloadSFTP(false);
        } else {
          setAzureDataLake(true);
        }

        if (selectedDataSource.datasourceType === "SFTP") {
          setSFTP(false);
          setCustomPayloadSFTP(true);
          setCustomPayloadAzure(false);
        } else {
          setSFTP(true);
        }
      }
    }
  }, [dataSourceList, productList, editMetaData.datasourceConnectionFqn]);

  //function for toggle disable input fields
  const handleDisableInput = () => {
    setDisableField(!disableField);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  if (backdropLoading) {
    return <BackdropLoader message={"Please wait..."} />;
  }

  const handleDeleteModalToggle = (editMetaDataCrawlId) => {
    console.log(editMetaDataCrawlId);
    setOpenDeleteModal(!openDeleteModal);
    // setRowData(row);
  };

  // DELETE API call for deleting the task from the metadata crawl table
  const handleDeleteRow = async (editMetaDataCrawlId) => {
    console.log(editMetaDataCrawlId, "<==editMetaDataCrawlId API");
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.delete(
        `/metadatacrawl/configs/${editMetaDataCrawlId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      const { status, data } = response;
      if (status === 200) {
        // alert("Task deleted successfully");
        notify(`Crawl with ID: ${editMetaDataCrawlId} deleted successfully`);
        setOpenRunStatusPopUp(true);
        // deleteTrigger()
      } else {
        // alert("Error");
        notify("Error occured while deleting the crawl");
        setOpenRunStatusPopUp(true);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    setTimeout(() => navigate("/manageMetadatacrawls/"), 4000);
  };

  //for runstatus fetching
  const fetchMetadataCrawlRunStatus = async (editMetaDataCrawlFqn) => {
    //console.log(editMetaDataCrawlFqn)
    setLoadingRunStatus(true);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/metadatacrawl/run/${editMetaDataCrawlFqn}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response?.data)
      const { status, data } = response;
      if (status === 200 || status === 202 || status === 201) {
        notify(response?.data);
        setOpenRunStatusPopUp(true);
      } else {
        notifyError("Error occured");
        setOpenRunStatusPopUp(true);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    } finally {
      setLoadingRunStatus(false);
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const handleRunStatusModalToggle = () => {
    setOpenRunStatusModal(!openRunStatusModal);
  };

  const notify = (customText) => {
    toast.success(customText, {
      position: "top-right",
      autoClose: 2200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const notifyError = (customText) => {
    toast.error(customText, {
      position: "top-right",
      autoClose: 2200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const getBlinkerClass = () => {
    switch (runningStatus.status) {
      case "NOT_STARTED":
        return "round-blinker not-started";
      case "RUNNING":
        return "round-blinker running";
      case "COMPLETED":
        return "round-blinker completed";
      default:
        return "round-blinker";
    }
  };

  return (
    <>
      <BreadcrumbMUI
        path1={"/manageMetadatacrawls"}
        name1={"Manage Metadata"}
        last={"Edit"}
      />
      <div
        style={{
          // paddingTop: "80px",
          // textAlign: "center",
          // width: "1200px",
          width: "96%",
          // marginLeft: "200px",
          marginLeft: "30px",
        }}
      >
        <h2 style={{ textAlign: "left" }}>Edit Metadata-Crawl</h2>
        <div
          style={{
            // margin: "1rem",
            width: "100%",
            padding: "10px 30px",
            // background: "#ffffff",
            backgroundColor: "#E6F4FF",
            border: "1px solid #CFCFCF",
          }}
        >
          <div style={{ textAlign: "right", marginBottom: "20px" }}>
            {loadingRunStatus ? (
              <>
                <div>
                  Fetching data please wait... <CircularLoader />
                </div>
              </>
            ) : (
              <div
                style={{
                  border: "0px solid red",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignContent: "center",
                }}
              >
                <div className={getBlinkerClass()}>
                  <span className="tooltip-text">
                    Tasks Completed: {runningStatus.tasksCompleted || 0}
                  </span>
                </div>
                {runningStatus.status !== "RUNNING" && (
                  <button
                    onClick={() => handleRunStatusModalToggle()}
                    style={{
                      border: "1px solid grey",
                      padding: "3px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    <span className="crawlImage" style={{ marginRight: "5px" }}>
                      Crawl Now
                    </span>
                    <img
                      src={crawlIcon}
                      alt="crawlIcon"
                      style={{
                        width: "20px",
                      }}
                    />
                  </button>
                )}

                <Tooltip arrow placement="top" title="Click to Edit">
                  <Switch
                    {...label}
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                    onClick={handleDisableInput}
                  />
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteModalToggle(editMetaDataCrawlId)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>

          <div
            style={
              width < 1440
                ? { display: "flex", flexDirection: "column" }
                : { display: "flex" }
            }
          >
            <div
              className="arrayItem"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                // variant="subtitle1"
                className="labelStyle"
                style={{
                //   color: "black !important",
                //   fontWeight: 550,
                  marginRight: "110px",
                  minWidth: "200px",
                  textAlign: "left",
                }}
              >
                Crawl Name
              </Typography>
              <TextField
                disabled
                sx={{ width: "450px" }}
                size="small"
                margin="dense"
                name="name"
                placeholder="Crawl Name"
                value={crawlPayload.name}
                onChange={(e) =>
                  setCrawlPayload({
                    ...crawlPayload,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                textAlign: "center",
                width: "900px",
              }}
            >
              <Typography
                className="labelStyle"
                style={
                  width < 1440
                    ? {
                        // color: "black !important",
                        // fontWeight: "550",
                        marginRight: "108px",
                        minWidth: "200px",
                        textAlign: "left",
                      }
                    : {
                        // color: "black !important",
                        // fontWeight: 550,
                        marginLeft: "18px",
                        marginRight: "28px",
                      }
                }
              >
                Product
              </Typography>
              <FormControl sx={{ minWidth: 220 }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={productOptions}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    productOptions.find(
                      (option) => option.value === selectedProductId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedProductId(newValue ? newValue.value : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product Name"
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 3 },
                      }}
                    />
                  )}
                  disabled={disableField}
                />
              </FormControl>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
              width: "760px",
              marginTop: "15px",
            }}
          >
            <Typography
              className="labelStyle"
              // style={{
              //   color: "black !important",
              //   fontWeight: 550,
              //   marginRight: "1px",
              // }}
            >
              Choose Datasource Connection
            </Typography>
            <FormControl sx={{ minWidth: 450 }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dataSourceOptions}
                value={
                  dataSourceOptions.find(
                    (option) => option.label === dataSourceName
                  ) || null
                } // Ensure it's never undefined
                onChange={(event, newValue) => {
                  // Ensure newValue is handled correctly
                  // console.log(newValue, "New value");
                  if (newValue) {
                    setDataSourceName(newValue.label);
                    // Additional logic if needed
                  } else {
                    setDataSourceName(null); // Reset or handle deselection
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Datasource"
                    InputProps={{ ...params.InputProps, style: { padding: 3 } }}
                  />
                )}
                disabled={true}
              />
            </FormControl>
          </div>

          <div style={{}}>
            {SFTP && (
              <>
                {/* <div style={{border:"1px solid red"}} > */}
                <div
                  style={{
                    display: !AzureDataLake ? "none" : "block",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      textAlign: "center",
                      width: "460px",
                      margin: "20px 0px",
                    }}
                  >
                    <Typography
                      className="labelStyle"
                      // style={{
                      //   color: "black !important",
                      //   fontWeight: 550,
                      //   marginRight: "45px",
                      // }}
                    >
                      Schema Filter Pattern
                    </Typography>
                    <input
                      style={{ width: "260px" }}
                      type="checkbox"
                      id="schema"
                      name="radioGroup"
                      onChange={handleSchemaRadioChange}
                      checked={isSchemaSelected}
                      disabled={disableField}
                    />
                  </div>

                  {isSchemaSelected && (
                    <div>
                      <div
                        className="arrayItem"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className="labelStyle"
                          style={{
                          //   fontWeight: 550,
                            marginRight: "110px",
                            minWidth: "200px",
                          }}
                        >
                          Schema Inclusive Pattern
                        </Typography>
                        <TextField
                          sx={{
                            minWidth: "220px",
                          }}
                          size="small"
                          margin="dense"
                          name="schemaInclusivePattern"
                          // placeholder="Comma-Separated"
                          value={inputValue}
                          onChange={handleInputChange}
                          disabled={disableField}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddToDataArray}
                          style={{
                            margin: "0 10px",
                          }}
                          disabled={disableField}
                        >
                          Add
                        </Button>
                        {schemaInclusivePattern.length > 0 && (
                          <div
                            className="enteredText"
                            style={{
                              width: "450px",
                              height: "max-content",
                              marginBottom: "10px",
                            }}
                          >
                            <ul>
                              {schemaInclusivePattern.map((data, index) => (
                                <li key={index}>
                                  {data}
                                  <span
                                    className="cancel"
                                    onClick={() =>
                                      handleDeleteFromSchemaInclusive(index)
                                    }
                                  >
                                    x
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="arrayItem"
                      >
                        <Typography
                          className="labelStyle"
                          style={{
                            // fontWeight: 550,
                            marginRight: "105px",
                            minWidth: "210px",
                          }}
                        >
                          Schema Exclusive Pattern
                        </Typography>
                        <TextField
                          sx={{ width: "220px" }}
                          size="small"
                          margin="dense"
                          name="schemaExclusivePattern"
                          // placeholder="Comma-Separated"
                          value={inputVealue}
                          onChange={handleSchemaExclusiveChange}
                          disabled={disableField}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={schemaExclusive}
                          style={{
                            margin: "0 10px",
                          }}
                          disabled={disableField}
                        >
                          Add
                        </Button>
                        {schemaExclusivePattern.length > 0 && (
                          <div
                            className="enteredText"
                            style={{
                              width: "450px",
                              height: "max-content",
                              marginBottom: "10px",
                            }}
                          >
                            <ul>
                              {schemaExclusivePattern.map((data, index) => (
                                <li key={index}>
                                  {data}{" "}
                                  <span
                                    className="cancel"
                                    onClick={() =>
                                      handleDeleteFromSchemaExclusive(index)
                                    }
                                  >
                                    x
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      textAlign: "center",
                      width: "900px",
                      margin: "20px 0px",
                    }}
                  >
                    <Typography
                      className="labelStyle"
                      style={{
                        // color: "black !important",
                        // fontWeight: 550,
                        marginRight: "48px",
                      }}
                    >
                      Table Filter Pattern
                    </Typography>
                    <input
                      style={{ width: "260px" }}
                      type="checkbox"
                      id="tableFilter"
                      name="radioGroup"
                      onChange={handleTableFilterRadioChange}
                      checked={isTableFilterSelected}
                      disabled={disableField}
                    />
                  </div>

                  {isTableFilterSelected && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="arrayItem"
                      >
                        <Typography
                          className="labelStyle"
                          style={{ marginRight: "130px" }}
                        >
                          Table Inclusive Pattern
                        </Typography>
                        <TextField
                          sx={{ width: "220px" }}
                          size="small"
                          margin="dense"
                          name="tableInclusivePattern"
                          // placeholder="Comma-Separated"
                          value={inputTableInclusiveValue}
                          onChange={handleTableInclusiveChange}
                          disabled={disableField}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={tableInclusive}
                          style={{
                            margin: "0 10px",
                          }}
                          disabled={disableField}
                        >
                          Add
                        </Button>
                        {tableInclusivePatterns.length > 0 && (
                          <div
                            className="enteredText"
                            style={{
                              width: "450px",
                              height: "max-content",
                              marginBottom: "10px",
                            }}
                          >
                            <ul>
                              {tableInclusivePatterns.map((data, index) => (
                                <li key={index}>
                                  {data}{" "}
                                  <span
                                    className="cancel"
                                    onClick={() =>
                                      handleDeleteFromTableInclusive(index)
                                    }
                                  >
                                    x
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="arrayItem"
                      >
                        <Typography
                          className="labelStyle"
                          style={{ marginRight: "130px" }}
                        >
                          Table Exclusive Pattern
                        </Typography>
                        <TextField
                          sx={{ width: "220px" }}
                          size="small"
                          margin="dense"
                          name="tableExclusivePattern"
                          // placeholder="Comma-Separated"
                          value={inputTableExclusiveValue}
                          onChange={handleTableExclusiveChange}
                          disabled={disableField}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={tableExclusive}
                          style={{
                            margin: "0 10px",
                          }}
                          disabled={disableField}
                        >
                          Add
                        </Button>
                        {tableExclusivePattern.length > 0 && (
                          <div
                            className="enteredText"
                            style={{ width: "450px", height: "max-content" }}
                          >
                            <div>
                              <ul>
                                {tableExclusivePattern.map((data, index) => (
                                  <li key={index}>
                                    {data}{" "}
                                    <span
                                      className="cancel"
                                      onClick={() =>
                                        handleDeleteFromTableExclusive(index)
                                      }
                                    >
                                      x
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {!SFTP && (
              <div
                className="arrayItem"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  // variant="subtitle1"
                  className="labelStyle"
                  // style={{
                  //   color: "black !important",
                  //   fontWeight: 550,
                  //   marginRight: "110px",
                  //   minWidth: "200px",
                  //   textAlign: "left",
                  // }}
                >
                  SFTP File Directory
                </Typography>
                <TextField
                  sx={{ width: "220px" }}
                  size="small"
                  margin="dense"
                  name="sftpFileDirectory"
                  placeholder="SFTP File Directory"
                  value={SFTPData.sftpFileDirectory}
                  onChange={(e) =>
                    setSFTPData({
                      ...SFTPData,
                      sftpFileDirectory: e.target.value,
                    })
                  }
                  disabled={disableField}
                />
              </div>
            )}

            {!SFTP && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  width: "900px",
                  marginTop: "20px",
                }}
              >
                <Typography
                  className="labelStyle"
                  // style={{
                  //   color: "black !important",
                  //   fontWeight: 550,
                  //   marginRight: "80px",
                  // }}
                >
                  Custom Delimiter
                </Typography>
                <input
                  style={{ width: "232px" }}
                  type="checkbox"
                  id="autoCrawl"
                  name="radioGroup"
                  onChange={handleDelimiterRadioChange}
                  disabled={disableField}
                  checked={isDelimiterSelected}
                />
              </div>
            )}

            {isDelimiterSelected && (
              <div>
                <div
                  className="arrayItem"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    // variant="subtitle1"
                    className="labelStyle"
                    // style={{
                    //   fontWeight: 550,
                    //   marginRight: "120px",
                    //   minWidth: "200px",
                    // }}
                  >
                    Delimiter Character
                  </Typography>
                  <TextField
                    sx={{ width: "220px" }}
                    size="small"
                    margin="dense"
                    name="cronScheduleField"
                    placeholder=""
                    value={SFTPData.csvDelimiter}
                    onChange={(e) =>
                      setSFTPData({
                        ...SFTPData,
                        csvDelimiter: e.target.value,
                      })
                    }
                    disabled={disableField}
                  />
                </div>
              </div>
            )}

            {!AzureDataLake && (
              <AzureDataLakeForm
                azureData={azureData}
                setAzureData={setAzureData}
                disableFieldProp={disableField}
                // storageAccountName={azureData.adlsStorageAccountName}
                // containerName={azureData.adlsContainerName}
                // datasetPath={azureData.adlsDatasetPath}
                // fileFormat={azureData.adlsFileFormat}
                // DoesPathContainTables={azureData.adlsDoesPathContainTables}
                // DatasetSchemaName={azureData.adlsDatasetSchemaName}
              />
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                textAlign: "center",
                width: "900px",
                marginTop: "20px",
              }}
            >
              <Typography
                className="labelStyle"
                style={{
                  // color: "black !important",
                  // fontWeight: 550,
                  marginRight: "96px",
                }}
              >
                Is Auto Crawl
              </Typography>
              <input
                style={{ width: "260px" }}
                type="checkbox"
                id="autoCrawl"
                name="radioGroup"
                checked={isAutoCrawlSelected}
                onChange={handleAutoCrawlRadioChange}
                disabled={disableField}
              />
            </div>

            {isAutoCrawlSelected && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    className="labelStyle"
                    style={{ marginRight: "155px" }}
                  >
                    Cron Schedule Field
                  </Typography>
                  <TextField
                    sx={{ width: "220px" }}
                    size="small"
                    margin="dense"
                    name="cronScheduleField"
                    placeholder=""
                    disabled={disableField}
                    value={crawlPayload.cronSchedule}
                    onChange={(e) =>
                      setCrawlPayload({
                        ...crawlPayload,
                        cronSchedule: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              // textAlign: "left",
              marginTop: "30px",
              // paddingLeft: "180px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <button
              className="rounded-md job-btn btn"
              onClick={handleSubmit}
              disabled={disableField}
            >
              Schedule Crawl
            </button> */}
            <button
              style={{ marginLeft: "20px" }}
              className="rounded-md job-btn btn"
              onClick={handleSubmit}
              disabled={disableField}
            >
              Submit Config
            </button>
          </div>
          <hr style={{ margin: "60px 0px" }} />
          {editMetaDataCrawlFqn && editMetaData && (
            <TableDataFetching
              jobFqn={editMetaDataCrawlFqn}
              singleRowFetchData={editMetaData}
              isAutoCrawlSelected={isAutoCrawlSelected}
              storedToken={token}
            />
          )}
        </div>
        <DeleteModal
          handleDeleteRow={handleDeleteRow}
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          row={editMetaDataCrawlId}
        />
        <CustomConfirmModal
          // handleRunStatusApiCall={fetchMetadataCrawlRunStatus}
          handleRunStatusApiCall={() =>
            fetchMetadataCrawlRunStatus(editMetaDataCrawlFqn)
          }
          open={openRunStatusModal}
          onClose={() => setOpenRunStatusModal(false)}
          textToDisplay={"Are you sure you want to take this action?"}
        />
        {openRunStatusPopUp && <WarningPopUp />}
      </div>
    </>
  );
};

export default EditMetaDataCrawl;
