const reducerDCT = (state, action) => {
  switch (action.type) {
    case "UPDATE_FILTER":
      return { ...state, currentFilter: action.payload }
    case "START_CIRCULAR_LOADING":
      return { ...state, circularLoading: true }
    case "END_CIRCULAR_LOADING":
      return { ...state, circularLoading: false }
    case "START_BACKDROP_LOADING":
      return { ...state, backdropLoading: true }
    case "END_BACKDROP_LOADING":
      return { ...state, backdropLoading: false }
    case "SHOW_EDA":
      return { ...state, isEDAVisible: true }
    case "DISABLE_EDA":
      return { ...state, isEDAVisible: false }
    //centerailize job edit part
    case "SET_JOB_DETAILS":
      return { ...state, jobDetails: action.payload }
    case "CLEAR_JOB_DETAILS":
      return { ...state, jobDetails: null }
    case "SHOW_INGESTION_JOB_FORM":
      return { ...state, showIngestionJobForm: true }
    case "HIDE_INGESTION_JOB_FORM":
      return { ...state, showIngestionJobForm: false }
    case "SET_CREATE_JOB":
      return { ...state, showCreateJob: action.payload }
    case "SET_ACTIVE_DATASOURCE_TABLE":
      return { ...state, dataSourceTable: action.payload }
    case "SET_DATASET_DETAILS":
      return { ...state, datasetDetails: action.payload }
    case "CLEAR_DATASET_DETAILS":
      return { ...state, datasetDetails: null }
    case "SET_DATASOURCES":
      return { ...state, dataSources: action.payload }
    case "CLEAR_DATASOURCES":
      return { ...state, dataSources: null }
    case "SHOW_FOLDERTREE":
      return { ...state, showFolderTree: true }
    case "HIDE_FOLDERTREE":
      return { ...state, showFolderTree: false }
    case "SET_DESTINATION_SELECT_BATCH_I":
      return { ...state, destinationConnectionBatch: action.payload }
    case "SET_SOURCE_SELECT_BATCH_I":
      return { ...state, sourceConnectionBatch: action.payload }
    case "SET_SCHEDULE_SELECT_BATCH_I":
      return { ...state, schedulerInfoBatch: action.payload }
    case "SET_DATABOOK_TOKEN":
      return { ...state, dataBookToken: action.payload }
    case "SET_CONNECTION_ID":
      return { ...state, connectionId: action.payload }
    case "SET_AVAILABLE_ID":
      return { ...state, availableJobId: action.payload }
    case "SET_EDIT_METADATACRAWL_ID":
      return { ...state, editMetaDataCrawlId: action.payload }
    case "SET_EDIT_METADATACRAWL_FQN":
      return { ...state, editMetaDataCrawlFqn: action.payload }

    case "MAERSK_MODAL_DATA":
      return { ...state, maerskModalTableData: action.payload }
    case "BUSINESS_DATA":
      return { ...state, businessData: action.payload }
    case "DOMAIN_DATA":
      return { ...state, domainData: action.payload }
    case "PRODUCT_DATA":
      return { ...state, productData: action.payload }
    case "MART_BUILDER_EDIT_JOB_ID":
      return { ...state, martBuilderEditJobId: action.payload }
    case "BATCH_INGESTION_EDIT_JOB_ID":
      return { ...state, batchIngestionEditJobId: action.payload }
    case "SET_SOURCE_CONNECTION_NAME":
      return { ...state, sourceConnectionName: action.payload }
    case "CLEAR_SOURCE_CONNECTION_NAME":
      return { ...state, sourceConnectionName: null }
    case "SET_SOURCE_CONNECTION_FQN":
      return { ...state, sourceConnectionFqn: action.payload }
    case "SHOW_EDIT_BUTTON":
      return { ...state, toggleEditButton: true }
    case "HIDE_EDIT_BUTTON":
      return { ...state, toggleEditButton: false }
    case "SHOW_BATCH_NEXT_BUTTON":
      return { ...state, toggleNextButton: true }
    case "HIDE_BATCH_NEXT_BUTTON":
      return { ...state, toggleNextButton: false }
    case "TRANSFORMATION_CATALOG":
      return {
        ...state,
        transformationCatalog: [
          ...state.transformationCatalog,
          action.payload,
        ],
      }

    // case "START_INGESTION_FORM_LOADING":
    //   return { ...state, batchFormLoading: true }
    // case "STOP_INGESTION_FORM_LOADING":
    //   return { ...state, batchFormLoading: false }
    default:
      throw new Error("No Matched Action!")
  }
}

export default reducerDCT
