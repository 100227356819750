import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Tags.css";
import "./Tags.scss";
import { Row, Col, Container } from "react-bootstrap";
import TagHeaderSection from "./Tags/TagHeaderSection";
import CreateClassificationForm from "./ClassificationList/ClassificationForms/CreateClassificationForm";
import EditClassificationForm from "./ClassificationList/ClassificationForms/EditClassificationForm";
import DeleteClassificationForm from "./ClassificationList/ClassificationForms/DeleteClassificationForm";
import TagDataFetchMethods from "./Tags/TagDataFetchMethods";
import ClassificationGroupingView from "./ClassificationList/ClassificationGroupingView";
import { Box, CircularProgress } from "@mui/material";

const AllTagsClassificationView = (props) => {
  const navigate = useNavigate();
  const [addtagshow, setaddtagShow] = useState(false);
  const handletagaddClose = () => setaddtagShow(false);
  const handletagaddShow = () => setaddtagShow(true);
  const {
    tagsdata,
    classificationdata,
    istagsFetching,
    isclassificationFetching,
    handleCreateShow,
    createshow,
    handleCreateClose,
    handleSubmitCreateclassification,
    classificationName,
    description,
    setClassificationName,
    setDescription,
    classsiselect,
    individualdatafetch,
    descriptionheader,
    handleClassificationSelect,
    editshow,
    handleEditShow,
    handleEditClose,
    handleEditClassification,
    setclassidesc,
    deleteclassificationshow,
    handleDeleteClassificationClose,
    handleDeleteClassificationShow,
    handleDeleteClassi,
    settagsData,
    setDescriptionheader,
    setindividualdatafetch,
    sethandleclassiSelect,
    classidesc,
    getalltags
  } = props;

  // console.log("Test>", window.location.pathname)

  return (
    <>
      {/* <Container fluid className="tags__container p-5">
       <div className="TagsPageHeader">
         <h3>Manage Classisfication and Glossary</h3>
       </div>
       <Row> */}
      <>
        {/* <div className="TagContainerTop">
            <p
              className={
                window.location.pathname === "/tagsclassifcation" &&
                "TagContainerTopActivep"
              }
              onClick={() => navigate("/tagsclassifcation")}
            >
              Classification
            </p>
            <p
              className={
                window.location.pathname === "/databook/glossary" &&
                "TagContainerTopActivep"
              }
              onClick={() => navigate("/databook/glossary")}
            >
              Glossary
            </p>
          </div> */}
        {isclassificationFetching ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              color: "#d3d3d3",
            }}
          >
            <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
          </Box>
        ) : (
          // <div className="TagContainer">
          <>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={3}
              xl={3}
              style={{ padding: "1rem", borderRight: "1px solid #dfdfdf", width: "25%" }}
            >
              <div className="list__items classification__view py-3">
                <ClassificationGroupingView
                  tagsdata={tagsdata}
                  onItemSelect={handleClassificationSelect}
                  // individualdatafetch={individualdatafetch}
                  classificationdata={classificationdata}
                  selectedItem={classsiselect}
                  handleCreateShow={handleCreateShow}
                  handleEditShow={handleEditShow}
                  handleDeleteClassificationShow={
                    handleDeleteClassificationShow
                  }
                  setDescriptionheader={setDescriptionheader}
                  setindividualdatafetch={setindividualdatafetch}
                  sethandleclassiSelect={sethandleclassiSelect}
                  handleClassificationSelect={handleClassificationSelect}
                />
              </div>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={8}
              lg={9}
              xl={9}
              style={{
                padding: "1rem",
                // border: "1px solid #dfdfdf",
                borderLeft: "none",
                // backgroundColor: "#f4f5f7",
                backgroundColor: "#E6F4FF",
                width: "75%",
                display:"flex",
                flexDirection:"column"
                // flex:"0 0 auto"
              }}
            >
              <TagHeaderSection
                descriptionheader={descriptionheader}
                individualdatafetch={individualdatafetch}
                handletagaddShow={handletagaddShow}
                handleDeleteClassificationShow={
                  handleDeleteClassificationShow
                }
                classsiselect={classsiselect}
              />
              <TagDataFetchMethods
                classsiselect={classsiselect}
                tagsdata={tagsdata}
                addtagshow={addtagshow}
                handletagaddClose={handletagaddClose}
                individualdatafetch={individualdatafetch}
                istagsFetching={istagsFetching}
                settagsData={settagsData}
                classificationdata={classificationdata}
                setaddtagShow={setaddtagShow}
                getalltags={getalltags}
                handleClassificationSelect={handleClassificationSelect}
              />
            </Col>
          </>
          // </div> 
        )}
      </>
      {/* </Row>{" "} */}
      <CreateClassificationForm
        createshow={createshow}
        handleCreateClose={handleCreateClose}
        handleSubmitCreateclassification={handleSubmitCreateclassification}
        classificationName={classificationName}
        description={description}
        setClassificationName={setClassificationName}
        setDescription={setDescription}
      />
      <EditClassificationForm
        handleEditClose={handleEditClose}
        editshow={editshow}
        handleEditClassification={handleEditClassification}
        descriptionheader={descriptionheader}
        setclassidesc={setclassidesc}
        classsiselect={classsiselect}
        classidesc={classidesc}
      />
      <DeleteClassificationForm
        handleDeleteClassificationClose={handleDeleteClassificationClose}
        deleteclassificationshow={deleteclassificationshow}
        handleDeleteClassi={handleDeleteClassi}
        classsiselect={classsiselect}
      />
      {/* </Container> */}
    </>
  );
};

export default AllTagsClassificationView;
