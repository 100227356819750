import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
// import yup from "yup";
import * as yup from 'yup';
import _ from "lodash";

const EditGlossary = (props) => {
  const {
    handleEditClose,
    editshow,
    handleEditGlossary,
    descriptionheader,
    setglossarydesc,
    glossaryselect,
  } = props;

  let onlydescription = _.map(descriptionheader, "description");

  const schema = yup.object().shape({
    description: yup.string().required(),
  });

  return (
    <>
      <Modal show={editshow} onHide={handleEditClose} centered>
        <Modal.Header className="title__header">
          <Modal.Title>
            <h4>Edit Glossary</h4>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={console.log}
            initialValues={{
              description: "",
            }}
          >
            {({ errors }) => (
              <Form className="form__view" onSubmit={handleEditGlossary}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="label__header__name">
                    <span>Glossary Name</span>
                  </Form.Label>
                  <Form.Control
                    type="glossaryname"
                    placeholder="Glossary Name"
                    readOnly
                    disabled
                    defaultValue={glossaryselect}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="label__header__name">
                    <span>Description</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    type="invalid"
                    isInvalid={!!errors.description}
                    defaultValue={onlydescription[0]}
                    onChange={(e) => setglossarydesc(e.target.value)}
                  />
                </Form.Group>
                <div className="button_save_close">
                  <Button
                    variant="secondary pop__btn"
                    onClick={handleEditClose}
                  >
                    Close
                  </Button>
                  <Button type="submit" className="pop__btn enabled_btn">
                    Save Changes
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditGlossary;
