import React, { useState, useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../../../AuthContext";
import { protectedResources } from "../../../msalConfig";
import { DATABOOK_API } from "../../../serverConfig";
import AllGlossaryViews from "./AllGlossaryViews";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import _ from "lodash";

const GlossaryDataFetchMethods = () => {
  const [glossary, setglossary] = useState([]);
  const [glossaryfetch, setIsglossaryFetching] = useState(false);
  const [terms, setterms] = useState([]);
  const [termsfetch, setIstermsfetching] = useState(false);
  const [classificationName, setClassificationName] = useState("");
  const [description, setDescription] = useState("");
  const [glossarydesc, setglossarydesc] = useState("");
  const [glossaryselect, sethandleglossarySelect] = useState([]);
  const [individualdatafetch, setindividualdatafetch] = useState([]);
  const [descriptionheader, setDescriptionheader] = useState([]);
  const [createshow, setCreateShow] = useState(false);
  const handleCreateClose = () => setCreateShow(false);
  const handleCreateShow = () => setCreateShow(true);
  const [editshow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);
  const [deleteglossaryshow, setDeleteglossaryShow] = useState(false);
  const handleDeleteGlossaryClose = () => setDeleteglossaryShow(false);
  const handleDeleteGlossaryShow = () => setDeleteglossaryShow(true);

  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  //Getting glossary name on selection//
  const handleGlossarySelect = (selectedglossary) => {
    const tabledata = _.filter(
      terms,
      (m) => m.classificationName === selectedglossary
    );

    const descriptiondata = _.filter(
      glossary,
      (m) => m.classificationName === selectedglossary
    );

    setDescriptionheader(descriptiondata);
    setindividualdatafetch(tabledata);
    sethandleglossarySelect(selectedglossary);
  };

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.title,
      type: alert.type,
      icon: alert.icon,
      text: alert.text,
      customClass: {
        text: "sw_classi__text",
      },
      confirmButtonColor: "#42b0d5",
      allowOutsideClick: false,
    }).then(function () {
      window.location = "/tagsclassifcation";
      // window.location = "/glossary";
    });
  };

  //API CALL

  const GLOSSARYLISTCALL = `${DATABOOK_API}/glossary-classification`;
  const TERMSLISTCALL = `${DATABOOK_API}/glossary-classification/terms`;
  const CREATEGLOSSARYCALL = `${DATABOOK_API}/glossary-classification?classificationName=${classificationName}&description=${description}`;
  const EDITGLOSSARYCALL = `${DATABOOK_API}/glossary-classification/${glossaryselect}?description=${glossarydesc}`;
  const DELETELOSSARYCALL = `${DATABOOK_API}/glossary-classification/${glossaryselect}`;

  const getallterms = (token) => {
    setIstermsfetching(true);
    axios
      .get(TERMSLISTCALL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data;
        let termdata = maindata.glossaryTerms;
        setterms(termdata);
        setIstermsfetching(false);
      });
  };

  //Getting classification name on selection//

  const getallglossary = (token) => {
    setIsglossaryFetching(true);
    axios
      .get(GLOSSARYLISTCALL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data;
        let glossarydata = maindata.glossaryClassifications;
        setglossary(glossarydata);
        setIsglossaryFetching(false);
      });
  };

  const tokenfetch = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //Creating  Glossary//

  const glossarycreation = (token) => {
    axios
      .post(CREATEGLOSSARYCALL, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          sweetAlertHandler({
            text: "Glossary is Created!",
            type: "success",
            icon: "success",
          });
        } else {
          sweetAlertHandler({
            title: "Error while fetching the data. ",
            icon: "info",
          });
        }
      });
  };

  const handleSubmitCreateglossary = (e) => {
    e.preventDefault();
    tokenfetch(glossarycreation);
    setCreateShow(false);
  };

  //Edit Glossary//

  const glossaryedit = (token) => {
    axios
      .put(EDITGLOSSARYCALL, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 200) {
          sweetAlertHandler({
            text: `"${glossaryselect}" Glossary, Description is Updated!`,
            type: "success",
            icon: "success",
          });
        } else {
          sweetAlertHandler({
            title: "Error while fetching the data. ",
            icon: "info",
          });
        }
      });
  };

  const handleEditGlossary = (e) => {
    e.preventDefault();
    tokenfetch(glossaryedit);
    setEditShow(false);
  };

  //Delete Glossary

  const glossarydelete = (token) => {
    axios
      .delete(DELETELOSSARYCALL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 200) {
          sweetAlertHandler({
            text: `"${glossaryselect}" Glossary is deleted!`,
            type: "success",
            icon: "success",
          });
          const glossarydelete = _.filter(
            glossary,
            (m) => m.classificationName !== glossaryselect
          );
          setglossary(glossarydelete);
        } else {
          sweetAlertHandler({
            title: "Error while fetching the data. ",
            icon: "info",
          });
        }
      });
  };
  const handleDeleteglossary = (e) => {
    e.preventDefault();
    tokenfetch(glossarydelete);
    setDeleteglossaryShow(false);
  };

  useEffect(() => {
    const fetchglossaryitems = async () => {
      (async () => await getToken())();
      if (token) {
        getallglossary(token);
        getallterms(token);
      }
      if (error) {
        try {
          const rtoken = (await instance.acquireTokenRedirect(request))
            .accessToken;
          getallglossary(rtoken);
          getallterms(rtoken);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchglossaryitems();
  }, [getToken, token]);

  return (
    <>
      <AllGlossaryViews
        glossary={glossary}
        glossaryfetch={glossaryfetch}
        classificationName={classificationName}
        setClassificationName={setClassificationName}
        description={description}
        setDescription={setDescription}
        glossarydesc={glossarydesc}
        setglossarydesc={setglossarydesc}
        glossaryselect={glossaryselect}
        individualdatafetch={individualdatafetch}
        descriptionheader={descriptionheader}
        createshow={createshow}
        handleCreateClose={handleCreateClose}
        handleCreateShow={handleCreateShow}
        editshow={editshow}
        handleEditClose={handleEditClose}
        handleEditShow={handleEditShow}
        deleteglossaryshow={deleteglossaryshow}
        handleDeleteGlossaryClose={handleDeleteGlossaryClose}
        handleDeleteGlossaryShow={handleDeleteGlossaryShow}
        handleGlossarySelect={handleGlossarySelect}
        handleSubmitCreateglossary={handleSubmitCreateglossary}
        handleEditGlossary={handleEditGlossary}
        handleDeleteglossary={handleDeleteglossary}
        terms={terms}
        setterms={setterms}
        getallterms={getallterms}
        setDescriptionheader={setDescriptionheader}
        setindividualdatafetch={setindividualdatafetch}
        sethandleglossarySelect={sethandleglossarySelect}
      />
    </>
  );
};

export default GlossaryDataFetchMethods;
