import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
// import yup from "yup";
import * as yup from 'yup';

const CreateGlossary = (props) => {
  const {
    createshow,
    handleCreateClose,
    handleSubmitCreateglossary,
    classificationName,
    description,
    setClassificationName,
    setDescription,
  } = props;

  const schema = yup.object().shape({
    glossaryname: yup.string().required(),
    description: yup.string().required(),
  });
  return (
    <Modal show={createshow} onHide={handleCreateClose} centered>
      <Modal.Header className="title__header">
        <Modal.Title>
          <h4>Add Glossary</h4>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={console.log}
          initialValues={{
            glossaryname: "",
            description: "",
          }}
        >
          {({ errors }) => (
            <Form className="form__view" onSubmit={handleSubmitCreateglossary}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label__header__name__required">
                  <span>Glossary Name</span>
                </Form.Label>
                <Form.Control
                  placeholder="Glossary Name"
                  type="invalid"
                  required
                  value={classificationName}
                  isInvalid={!!errors.classiname}
                  name="classiname"
                  onChange={(e) => setClassificationName(e.target.value)}
                  autoFocus
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.classiname}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="label__header__name">
                  <span>Description</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="invalid"
                  rows={3}
                  value={description}
                  name="description"
                  isInvalid={!!errors.description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="button_save_close">
                <Button
                  variant="secondary pop__btn"
                  onClick={handleCreateClose}
                >
                  Close
                </Button>
                <Button type="submit" className="pop__btn enabled_btn">
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateGlossary;
