import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

const EditTerm = (props) => {
  const {
    handleSubmiteditterm,
    handleEditTermClose,
    edittermshow,
    termdata,
    termdesc,
    setTermiddesc,
    edittername,
  } = props;

  const schema = yup.object().shape({
    description: yup.string().required(),
  });

  return (
    <Modal show={edittermshow} onHide={handleEditTermClose} centered>
      <Modal.Header className="title__header">
        <Modal.Title>
          <h4>Edit Term</h4>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={console.log}
          initialValues={{
            description: "",
          }}
        >
          {({ errors }) => (
            <Form className="form__view" onSubmit={handleSubmiteditterm}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label__header__name">
                  <span>Term Name</span>
                </Form.Label>
                <Form.Control
                  placeholder="termname"
                  readOnly
                  disabled
                  // defaultValue={termdata}
                  defaultValue={edittername}
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="label__header__name">
                  <span>Description</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  isInvalid={!!errors.description}
                  defaultValue={termdesc}
                  onChange={(e) => setTermiddesc(e.target.value)}
                />
              </Form.Group>
              <div className="button_save_close">
                <Button
                  variant="secondary pop__btn"
                  onClick={handleEditTermClose}
                >
                  Close
                </Button>
                <Button type="submit" className="pop__btn enabled_btn">
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EditTerm;
