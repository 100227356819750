import React, { useState, useContext, useEffect } from "react";
import "../components/NewComponents/BulkDataUploadDownload/BulkDataUploadDownload.css";
import { AuthContext } from "../AuthContext";
import { protectedResources } from "../msalConfig";
import { DATABOOK_API } from "../serverConfig";
import { useMsal } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import { Transfer } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Select } from "antd";
import axios from "axios";
import _ from "lodash";
import DownloadTemplate from "../components/NewComponents/BulkDataUploadDownload/DownloadTemplate";
import UploadTemplate from "../components/NewComponents/BulkDataUploadDownload/UploadTemplate";
import BreadcrumbMUI from "../components/NewComponents/BreadcrumbMUI";
// import CommonBreadcrumb from "../../CommonBreadcrumb/CommonBreadcrumb";

const BulkView = () => {
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };
  const [stepper, setStepper] = useState(1);
  const [data, setData] = useState([]);
  const [schema, setSchema] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [filename, setFileName] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [isSchemaCleared, setIsSchemaCleared] = useState(false);

  const getdatasets = async (token) => {
    await axios(DATABOOK_API + "/bulk/sources", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        const maindata = res.data;
        let schemadata = _.map(maindata, (schema) => {
          return {
            value: schema?.length > 0 ? schema : "",
            label: schema?.length > 0 ? schema : "",
          };
        });

        setSchema(schemadata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (data) => {
    if (!data || data?.length === 0) {
      setData([]);
      setDataset([]);
      setIsSchemaCleared(true);
    } else {
      setFileName(data);
      let reqdata = JSON.stringify({
        schemas: [data],
        pageIndex: 0,
        pageSize: 1000,
        criteria: "dataset-name",
      });
      setFetching(true);
      axios
        .post(`${DATABOOK_API}/search`, reqdata, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          let maindata = res.data.results;
          let fqndata = [];
          _.map(maindata, (fqnd) => {
            return fqndata.push({
              key: fqnd.fqn,
              value: fqnd.fqn,
            });
          });
          setData(fqndata);
          setIsSchemaCleared(false);
          setFetching(false);
        });
    }
  };

  const handleDatasetChange = (value) => {
    setDataset(value);
  };

  const fetchData = async () => {
    // (async () => await getToken())();
    if (token) {
      await getdatasets(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await getdatasets(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getToken();
    if (token) {
      fetchData();
    }
  }, [getToken, token]);

  const antIcon = <LoadingOutlined spin />;

  return (
    <div style={{ textAlign: "left" }}>
      <BreadcrumbMUI last={"Bulk Upload Business Glossary"} />
      <Container
        fluid
        className="p30"
        style={{ height: "100%", marginBottom: "6rem" }}
      >
        {/* <Container
        className="pt-5 mt-5 py-3"
        style={{ height: "100%", marginBottom: "6rem" }}
      > */}
        <h3>Bulk Upload Business Glossary</h3>
        <div className="BUBGStepperContainer">
          <div className="BUBGStepper" onClick={() => setStepper(1)}>
            <p
              className={
                stepper === 1 ? "BUBGStepperNum Active" : "BUBGStepperNum"
              }
              style={{ margin: "0" }}
            >
              1
            </p>
            <p style={{ margin: "0" }}>Download Template</p>
          </div>
          <div className="BUBGStepper">
            <p style={{ fontSize: "24px", color: "#d3d3d3", margin: "0" }}>
              --------
            </p>
          </div>
          <div className="BUBGStepper" onClick={() => setStepper(2)}>
            <p
              className={
                stepper === 2 ? "BUBGStepperNum Active" : "BUBGStepperNum"
              }
              style={{ margin: "0" }}
            >
              2
            </p>
            <p style={{ margin: "0" }}>Upload Glossary</p>
          </div>
        </div>

        {stepper === 1 && (
          <div
            className="BUBGContentContainer"
            style={{ backgroundColor: "#e6f4ff" }}
          >
            <h4
              className="sub__header__bulk margin__sub__bulk"
              style={{ marginBottom: "0.5rem", marginTop: "0" }}
            >
              {" "}
              Choose schema (to filter datasets selection)
            </h4>
            <div className="py-3">
              <Select
                // mode="multiple"
                allowClear
                onChange={handleChange}
                className="select__multiple"
                options={schema}
                showSearch
                placeholder="Select schema..."
                maxTagCount="responsive"
                filterSort={(val1, val2) =>
                  (val1?.label ?? "")
                    .toLowerCase()
                    .localeCompare((val2?.label ?? "").toLowerCase())
                }
              />
            </div>

            <div className="py-3 biz-dom-wrap">
              <h4
                style={{ marginBottom: "0.5rem", marginTop: "0" }}
                className="sub__header__bulk"
              >
                Choose Datasets
              </h4>
              <div className="py-3">
                {fetching ? (
                  <div className="spin__outlined">
                    <Spin indicator={antIcon} />
                  </div>
                ) : (
                  <>
                    {filename?.length > 0 || isSchemaCleared ? (
                      <Transfer
                        dataSource={data}
                        targetKeys={dataset}
                        onChange={handleDatasetChange}
                        render={(item) => item.value}
                        oneWay
                        pagination
                        showSearch={schema?.length > 0}
                      />
                    ) : (
                      <Transfer oneWay pagination />
                    )}
                  </>
                )}
              </div>

              <div className="py-3" onClick={() => setStepper(2)}>
                {" "}
                <DownloadTemplate datasetid={dataset} filename={filename} />
              </div>
            </div>
          </div>
        )}

        {stepper === 2 && (
          <div
            className="BUBGContentContainer"
            style={{ backgroundColor: "#e6f4ff" }}
          >
            <div className="py-4">
              <h4
                className="sub__header__bulk"
                style={{ marginBottom: "0.5rem", marginTop: "0" }}
              >
                Please download the dataset template and update the CSV file,
                before proceeding with the upload!
              </h4>
              <h4
                className="sub__header__bulk margin__sub__bulk"
                style={{ marginBottom: "0.5rem", marginTop: "0" }}
              >
                Upload Glossary Template
              </h4>
              <UploadTemplate />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default BulkView;
