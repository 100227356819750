import React from "react";
// import certified_logo from "../../assets/images/certifiedLogo.png";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ReusableCertifiedIcon = ({ certifieddata }) => {

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip">
            <span> Certified by : {certifieddata?.certifiedBy}</span>
          </Tooltip>
        }
      >
        <span className="px-2">
          {/* <img
            alt="#"
            src={certified_logo}
            className="certified-logo-size"
          /> */}
          <WorkspacePremiumIcon className="certified-logo-size" style={{width: "1.2em", height:"1.2em"}} />
        </span>
      </OverlayTrigger>
    </>
  );
};

export default ReusableCertifiedIcon;
