import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Box } from '@mui/material';

const CronInfoModal = ({ open, onClose }) => {
  const examples = [ 
    {
      description: 'At 00:05 in August.',
      cron: ['5', '0', '*', '8', '*'],
      labels: ['minute', 'hour', 'day (month)', 'month', 'day (week)']
    },
    {
      description: 'At 14:15 on day-of-month 1.',
      cron: ['15', '14', '1', '*', '*'],
      labels: ['minute', 'hour', 'day (month)', 'month', 'day (week)']
    },
    {
      description: 'At 00:05 on Tuesday in September.',
      cron: ['5', '0', '*', '9', '2'],
      labels: ['minute', 'hour', 'day (month)', 'month', 'day (week)']
    }
  ];

  // Map to rearrange the cron array and labels to the desired order
  const rearrangeOrder = (cron, labels) => {
    const order = [2, 3, 4, 1, 0]; // day (month), month, day (week), hour, minute
    return {
      cron: order.map(i => cron[i]),
      labels: order.map(i => labels[i])
    };
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{"Cron Schedule Example"}</DialogTitle>
      <DialogContent>
        {examples.map((example, index) => {
          const rearranged = rearrangeOrder(example.cron, example.labels);
          return (
            <Box key={index} mb={3}>
              <Typography variant="h6" align="center">{example.description}</Typography>
              <Box mt={2}>
                <Grid container spacing={2} justifyContent="center">
                  {rearranged.cron.map((value, idx) => (
                    <Grid item key={idx}>
                      <Box
                        border={1}
                        borderColor="grey.500"
                        borderRadius={2}
                        p={1}
                        textAlign="center"
                      >
                        <Typography variant="h4">{value}</Typography>
                        <Typography variant="body2">{rearranged.labels[idx]}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CronInfoModal;
