import React, { useState, useContext } from "react";
import { DATABOOK_API } from "../../../serverConfig";
import { AuthContext } from "../../../AuthContext";
import { protectedResources } from "../../../msalConfig";
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const UploadTemplate = () => {
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.title,
      html: alert.html,
      type: alert.type,
      icon: alert.icon,
      width: "auto",
      customClass: {
        title: "sw__title",
        htmlContainer: "sw__html",
        confirmButton: "sw__btn",
      },
      allowOutsideClick: false,
    }).then(function () {
      window.location.href = "/bulkupload";
    });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const UPLOADFILE = `${DATABOOK_API}/bulk/template/upload`;

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    (async () => await getToken())();

    // const inprogresstoast = toast.info(
    //   <span className="toast__msg">Uploading file Please wait...</span>
    // );

    if (token) {
      await axios
        .post(UPLOADFILE, formData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          sweetAlertHandler({
            title: "File uploaded successfully",
            // html: errorDetails,
            icon: "success",
          });
          // toast.update(inprogresstoast, {
          //   render: sweetAlertHandler({
          //     title: (
          //       <span className="pop__msg">File uploaded successfully</span>
          //     ),
          //     type: "success",
          //     icon: "success",
          //   }),
          //   autoClose: 1000,
          // });
        })
        .catch((error) => {
          let errormsg = error.response.data;
          let errformat = errormsg.replaceAll(".", "<br>");
          const splitIndex = errformat.indexOf("::");
          const onlyheader = errformat.slice(0, splitIndex + 2);
          const errorDetails = errformat.slice(splitIndex + 2);
          const formattedTitle = `<b>${onlyheader}</b>`;

          sweetAlertHandler({
            title: formattedTitle,
            html: errorDetails,
            icon: "error",
          });
        });
    }
  };

  return (
    <div className="py-3 upload__temp mt-3">
      <input type="file" onChange={handleFileChange} className="input__file" />
      <br />
      {selectedFile ? (
        <Button
          onClick={handleUpload}
          className="bulk__btn enabled_btn"
          style={{ float: "left", margin: "1.4rem 30rem" }}
        >
          Upload Template
        </Button>
      ) : (
        <Button
          className="bulk__btn disabled_btn"
          disabled
          style={{ float: "left", margin: "1.4rem 30rem" }}
        >
          Upload Template
        </Button>
      )}
      <br />
    </div>
  );
};
export default UploadTemplate;
