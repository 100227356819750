import React from "react";
import { Modal, Form, Button } from "react-bootstrap";

const DeleteGlossary = (props) => {
  const {
    handleDeleteGlossaryClose,
    deleteglossaryshow,
    handleDeleteglossary,
    glossaryselect,
  } = props;
  return (
    <>
      <Modal
        show={deleteglossaryshow}
        onHide={handleDeleteGlossaryClose}
        centered
      >
        <Modal.Header className="del__msg">
          {" "}
          <Modal.Title>
            <span>
              {" "}
              Are you sure you want to delete glossary <b>"{glossaryselect}"</b>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form__view" onSubmit={handleDeleteglossary}>
            <div className="button_save_close">
              <Button
                variant="secondary pop__btn"
                onClick={handleDeleteGlossaryClose}
              >
                Close
              </Button>
              <Button type="submit" className="pop__btn enabled_btn">
                Yes
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>{" "}
    </>
  );
};

export default DeleteGlossary;
