import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import "./MetaDataCrawlInfoModal.css"

function MetaDataCrawlInfoModal({ data, boolean, onOpen }) {

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                MetaData-Crawl Details
              </Typography>
              <div style={{margin:"35px"}}>
              {data && (
                <div style={{ marginTop: "30px" }}>
                  <div>
                    <label htmlFor="">Connection Fqn</label>
                    <br />
                    <input
                    className="inputField"
                      type="text"
                      placeholder={data.datasourceConnectionFqn}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor="">Cron Schedule </label>
                    <br />
                    <input
                     className="inputField"
                      type="text"
                      placeholder={data.cronSchedule}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor="">Schema Inclusive Filter Patterns</label>
                    <br />
                    <input
                     className="inputField"
                      type="text"
                      placeholder={data.schemaInclusiveFilterPatterns.join(', ')}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="">Schema Exclusive Filter Patterns</label>
                    <br />
                    <input
                     className="inputField"
                      type="text"
                      placeholder={data.schemaExclusiveFilterPatterns.join(', ')}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="">Table Inclusive Filter Patterns</label>
                    <br />
                    <input
                     className="inputField"
                      type="text"
                      placeholder={data.tableInclusiveFilterPatterns
                        .join(', ')}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="">Table Exclusive Filter Patterns</label>
                    <br />
                    <input
                     className="inputField"
                      type="text"
                      placeholder={data.tableExclusiveFilterPatterns.join(', ')}
                      disabled
                    />
                  </div>
                </div>
              )}
              
              </div>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MetaDataCrawlInfoModal;
