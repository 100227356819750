import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  TextField,
} from "@mui/material";
import { DATABOOK_API } from "../../serverConfig";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const DDSchemaDescEditModal = ({ open, onClose, getallcolumndata, token }) => {
  const [updatedDesc, setUpdatedDesc] = useState("");

  const columnDescFielsdSave = (token) => {
    // if (selectedrow?.columnid !== "description") {
    //   if (validateFields() &&
    //     rsSelectedSensitivity?.length === 0) {
    //     toast.info("Security classificaton or sensitivity cannot be blank. ", {
    //       position: "top-right",
    //       autoClose: 6000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     return false;
    //   }
    // }

    // handleDataTypeForEmptyValue(selectedrow?.columnid);
    // const inprogresstoast = toast.info("Updating description. Please wait...");
    let requestbody = "";
    // if (selectedrow?.columnid === "description") {
    requestbody = {
      id: open.dataset_id,
      columns: [
        {
          name: open.name,
          description: updatedDesc,
        },
      ],
    };
    // } else {
    //   requestbody = {
    //     id: selectedrow?.rowdata.dataset_id,
    //     columns: [
    //       {
    //         name: open?.name,
    //         securityClassification: rsSelectedClassification?.value === undefined ? "" : rsSelectedClassification?.value,
    //         sensitivity: rsSelectedSensitivity?.map((item) => item.value)
    //       },
    //     ],
    //   }
    // }

    axios
      .put(`${DATABOOK_API}/datasets/dataset-columns`, requestbody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        getallcolumndata(token);
        // toast.update(inprogresstoast, {
        //   render: "Column field updated successfully.",
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 6000,
        //   closeButton: true,
        //   isLoading: false
        // });
        onClose();
      })
      .catch((error) => {
        if (error.response.status == 401) {
          // toast.update(inprogresstoast, {
          //   render: error.response.data,
          //   type: toast.TYPE.ERROR,
          //   autoClose: 6000,
          //   closeButton: true,
          //   isLoading: false
          // });
          onClose();
        } else {
          // toast.update(inprogresstoast, {
          //   render: "Some went wrong while updating columns field.",
          //   type: toast.TYPE.ERROR,
          //   autoClose: 6000,
          //   closeButton: true,
          //   isLoading: false
          // });
        }
      });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        {`Description for "${open.name}"`}
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <TextField
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={4}
            defaultValue={
              open?.description && open?.description
            }
            style={{ margin: "0", fontSize: "14px", width: "100%" }}
            onChange={(e) => {
              setUpdatedDesc(e.target.value);
            }}
          />
          {/* <p style={{ fontSize: "1rem" }}>
            <span>{open.name}</span> - Unified In-House Data and Analytics Platform
          </p> */}
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button
          onClick={() => {
            columnDescFielsdSave(token);
          }}
        >
          Edit
        </Button>
        <Button onClick={onClose}>Close</Button> */}
        <CheckIcon
          color="primary"
          style={
            updatedDesc !== ""
              ? { cursor: "pointer", margin:"0.4rem 1rem" }
              : { cursor: "not-allowed", margin:"0.4rem 1rem" }
          }
          onClick={() => {
            updatedDesc !== "" && columnDescFielsdSave(token);
          }}
        />
        <CloseIcon
          color="action"
          style={{ cursor: "pointer", margin:"0.4rem 1rem" }}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DDSchemaDescEditModal;
