import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
 
const CreateTagForm = (props) => {
  const {
    addtagshow,
    handletagaddClose,
    handleSubmitcreatetag,
    classsiselect,
    setTagName,
    tagName,
    descriptiontags,
    setDescriptiontags,
    slclassi,
    handleChange,
  } = props;
 
  let classu = [{ val: classsiselect, label: classsiselect }];
 
  let defaultdata =
    classsiselect?.length > 0 ? classu : [{ val: "default", label: "default" }];
 
  const schema = yup.object().shape({
    tagname: yup.string().required(),
    description: yup.string().required(),
  });
 
  const handleCloseClassification = ()=>{
    setTagName("");
    setDescriptiontags("");
    handletagaddClose();
  }
 
  return (
    <>
      <Modal show={addtagshow} onHide={handleCloseClassification} centered style={{border: "1px solid red"}}>
        <Modal.Header className="title__header">
          <Modal.Title>
            <h4>Add Tag</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={console.log}
            initialValues={{
              tagname: "",
              description: "",
            }}
          >
            {({ errors }) => (
              <Form className="form__view" onSubmit={handleSubmitcreatetag}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  {" "}
                  <Form.Label className="label__header__name">
                    <span>Classification Name</span>
                  </Form.Label>
                  <Select
                    onChange={handleChange}
                    className="tag__select"
                    options={slclassi}
                    defaultValue={defaultdata}
                    // placeholder="Select classification..."
                    isDisabled={true}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="label__header__name__required">
                    <span>Tag Name</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Tag"
                    autoFocus
                    value={tagName}
                    required
                    type="invalid"
                    isInvalid={!!errors.tagname}
                    onChange={(e) => setTagName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="label__header__name">
                    <span>Description</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={descriptiontags}
                    type="invalid"
                    isInvalid={!!errors.description}
                    onChange={(e) => setDescriptiontags(e.target.value)}
                  />
                </Form.Group>
 
                <div className="button_save_close">
                  <Button
                    variant="secondary pop__btn"
                    onClick={handleCloseClassification}
                  >
                    Close
                  </Button>
                  <Button type="submit" className="pop__btn enabled_btn">
                    Save Changes
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>

    // <Dialog
    //   open={addtagshow}
    //   onClose={handleCloseClassification}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   fullWidth
    //   maxWidth="md"
    // >
    //   <DialogTitle id="alert-dialog-title">
    //     {"Add Tag"}
    //   </DialogTitle>
    //   <DialogContent>
    //     <Box mt={2}>
    //       {/* <Typography variant="h6" align="center">
    //         Maestro - Unified In-House Data and Analytics Platform
    //       </Typography> */}
    //       <p style={{ fontSize: "1rem" }}>
    //         <span>{CLASSI_TAG_GUIDE_CONST.CLASSI_HEADER}</span></p>
    //       <p style={{ fontSize: "1rem" }}>
    //         <span>Classification Steps</span> -<br />
    //         <ul>
    //         {CLASSI_TAG_GUIDE_CONST.CLASSI_STEPS.map((classi, idx) => (
    //           <li key={idx}>
    //             <span>{classi.list}</span>
    //           </li>
    //         ))}
    //       </ul>
    //       </p>
    //       <p style={{ fontSize: "1rem" }}>
    //         <span>Tags Steps</span> -<br />
    //         <ul>
    //         {CLASSI_TAG_GUIDE_CONST.TAG_STEPS.map((tag, idx) => (
    //           <li key={idx}>
    //             <span>{tag.list}</span>
    //           </li>
    //         ))}
    //       </ul>
    //       </p>
    //     </Box>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button onClick={onClose} color="primary">
    //       Close
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
};
 
export default CreateTagForm;