import React from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"
import moment from "moment"
import { Box, Paper } from "@mui/material"

const DynamicLineChart = ({ qualityData, w, h }) => {
  const data = qualityData?.metrics
    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map(({ metricValue, createdAt }) => ({
      score: Number(metricValue).toFixed(2),
      date: moment(createdAt).format("DD/MM/YYYY"),
    }))

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        my: "40px",
        color: "gray",
      }}
    >
      <Paper elevation={1}>
        {/* <ResponsiveContainer width="100%" height="100%"> */}
        <LineChart
          width={w}
          height={h}
          data={data}
          margin={{
            top: 22,
            right: 50,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" fontSize={12} />
          <YAxis fontSize={12} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="score"
            stroke="#38bdf8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
        {/* </ResponsiveContainer> */}
      </Paper>
    </Box>
  )
}

export default DynamicLineChart
