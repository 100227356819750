import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";

const NewDatasourceConnModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {"Cannot Find Your Product?"}
      </DialogTitle>
      <DialogContent>
        <Box mt={1}>
          {/* <Typography variant="h6" align="center">
            Maestro - Unified In-House Data and Analytics Platform
          </Typography> */}
          <p style={{ fontSize: "1rem" }}>
            Please contact our support team to create and add your product to
            the list.
          </p>
          <p style={{ fontSize: "1rem" }}>
            <span>Support Team Link</span> -{" "}
            <a href="javascript:void(0)">MetaInsights | Microsoft Teams</a>
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewDatasourceConnModal;
