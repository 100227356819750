import React, { useState, useContext } from "react";
import { DATABOOK_API } from "../../../../serverConfig";
import { protectedResources } from "../../../../msalConfig";
import { AuthContext } from "../../../../AuthContext";
import { useMsal } from "@azure/msal-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import _ from "lodash";
import CreateTerm from "./TermForms/CreateTerm";
import GlossaryTable from "../GlossaryTable";
import EditTerm from "./TermForms/EditTerm";
import DeleteTerm from "./TermForms/DeleteTerm";
import CreateChildTerm from "./TermForms/CreateChildTerm";

const TermDataFetchMethods = (props) => {
  const {
    glossaryselect,
    createtagshow,
    handleCreatetagClose,
    terms,
    individualdatafetch,
    setterms,
    getallterms,
    settagCreateShow,
  } = props;

  const [edittermshow, setedittermShow] = useState(false);
  const handleEditTermClose = () => setedittermShow(false);
  const handleEditTermShow = () => setedittermShow(true);
  const [deltermshow, setdeltermShow] = useState(false);
  const handleDelTermClose = () => setdeltermShow(false);
  const handleDelTermShow = () => setdeltermShow(true);
  const [termdata, setTermdata] = useState("");
  const [termdesc, settermDesc] = useState("");
  const [termiddesc, setTermiddesc] = useState("");
  const [description, setDescription] = useState("");
  const [parentterm, setparentterm] = useState("");
  const [termname, settermname] = useState("");
  const [tagiddel, setTagiddel] = useState("");
  const [createchildtermshow, setchildtermshow] = useState(false);
  // const handleCreateChildTermClose = () => setchildtermshow(false);
  const handleCreateChildTermShow = () => setchildtermshow(true);
  const [childdescription, setchildDescription] = useState("");
  const [termnamechild, settermnamechild] = useState("");
  const [childtermdata, setchildtermdata] = useState("");
  const [gloss, setGloss] = useState("");
  const [edittername, setedittermname] = useState("");
  const [deltermname, setdeltername] = useState("");

  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const handleCreateChildTermClose = () => {
    setchildDescription("");
    settermnamechild("");
    setchildtermshow(false);
  }
  
  //API CALLS
  const CREATETERMAPI = `${DATABOOK_API}/glossary-classification/terms`;
  const EDITTERMAPI = `${DATABOOK_API}/glossary-classification/terms/${termdata}?description=${termiddesc}`;
  const DELETETERMAPI = `${DATABOOK_API}/glossary-classification/terms/${tagiddel}`;

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.title,
      type: alert.type,
      icon: alert.icon,
      text: alert.text,
      customClass: {
        text: "sw_classi__text",
      },
      confirmButtonColor: "#42b0d5",
      allowOutsideClick: false,
    }).then(function () {
      window.location = "/glossary";
      // window.location = "/tagsclassifcation";
    });
  };

  //TOKEN CALL

  const tokenfetch = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //CREATE TERM

  const createterm = (token) => {
    let reqdata = JSON.stringify({
      classificationName: glossaryselect,
      description: description,
      parentTermFqn: parentterm,
      parentTypeClassification: parentterm?.length > 0 ? false : true,
      termName: termname,
    });

    axios
      .post(CREATETERMAPI, reqdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          // sweetAlertHandler({
          //   text: `Term is created in "${glossaryselect}" Glossary`,
          //   type: "success",
          //   icon: "success",
          // });
          getallterms(token)
        } else {
          sweetAlertHandler({
            title: "Error while fetching the data. ",
            icon: "info",
          });
        }
      });
  };

  const handleCreateTerm = (e) => {
    e.preventDefault();
    tokenfetch(createterm);
    settagCreateShow(false);
  };

  const createchildterm = (token) => {
    let reqchilddata = JSON.stringify({
      classificationName: glossaryselect?.length > 0 ? glossaryselect : gloss,
      description: childdescription,
      parentTermFqn: childtermdata,
      parentTypeClassification: childtermdata?.length > 0 ? false : true,
      termName: termnamechild,
    });

    axios
      .post(CREATETERMAPI, reqchilddata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          // sweetAlertHandler({
          //   text: `Term is created in "${gloss}" Glossary`,
          //   icon: "success",
          //   type: "success",
          // });
          getallterms(token)
        } else {
          sweetAlertHandler({
            title: "Error while fetching the data. ",
            icon: "info",
          });
        }
      });
  };

  const handleCreateChildTerm = (e) => {
    e.preventDefault();
    tokenfetch(createchildterm);
    setchildtermshow(false);
  };

  const handleAddChild = (fqn, classification) => {
    handleCreateChildTermShow();
    setchildtermdata(fqn);
    setGloss(classification);
  };

  //EDIT TERM

  const termeditedit = (token) => {
    axios
      .put(EDITTERMAPI, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 201) {
          // sweetAlertHandler({
          //   text: `${edittername} Term is Updated! `,
          //   icon: "success",
          //   type: "success",
          // });
          getallterms(token)
        } else {
          sweetAlertHandler({
            title: "Error while fetching the data. ",
            icon: "info",
          });
        }
      });
  };

  const handleEditTerm = (fqn, descdata, term) => {
    handleEditTermShow();
    setTermdata(fqn);
    settermDesc(descdata);
    setedittermname(term);
  };

  const handleSubmiteditterm = (e) => {
    e.preventDefault();
    tokenfetch(termeditedit);
    setedittermShow(false);
  };

  //DELETE TERM

  const handleDeleteTerm = (token) => {
    axios
      .delete(DELETETERMAPI, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })

      .then((response) => {
        if (response.status === 200) {
          // sweetAlertHandler({
          //   text: `"${deltermname}" Term is Deleted!`,
          //   icon: "success",
          //   type: "success",
          // });
          const deletedtag = _.filter(terms, (m) => m.fqn !== tagiddel);
          setterms(deletedtag);
          // getallterms(token)
        } else {
          sweetAlertHandler({
            title: "Error while fetching the data. ",
            icon: "info",
          });
        }
      });
  };

  const handleSubmitDeleteterms = (e) => {
    e.preventDefault();
    tokenfetch(handleDeleteTerm);
    setdeltermShow(false);
  };

  const handleDelTerm = (fqndata, termname) => {
    handleDelTermShow();
    setTagiddel(fqndata);
    setdeltername(termname);
  };

  return (
    <>
      <CreateTerm
        handleCreateTerm={handleCreateTerm}
        setDescription={setDescription}
        settermname={settermname}
        createtagshow={createtagshow}
        handleCreatetagClose={handleCreatetagClose}
        glossaryselect={glossaryselect}
        description={description}
        parentterm={parentterm}
        termname={termname}
      />
      <EditTerm
        handleSubmiteditterm={handleSubmiteditterm}
        handleEditTermClose={handleEditTermClose}
        edittermshow={edittermshow}
        termdata={termdata}
        settermDesc={settermDesc}
        termdesc={termdesc}
        setTermiddesc={setTermiddesc}
        edittername={edittername}
      />
      <DeleteTerm
        deltermshow={deltermshow}
        handleDelTermClose={handleDelTermClose}
        tagiddel={tagiddel}
        handleSubmitDeleteterms={handleSubmitDeleteterms}
      />
      <CreateChildTerm
        createchildtermshow={createchildtermshow}
        handleCreateChildTermClose={handleCreateChildTermClose}
        handleCreateChildTerm={handleCreateChildTerm}
        childdescription={childdescription}
        setchildDescription={setchildDescription}
        glossaryselect={glossaryselect}
        termnamechild={termnamechild}
        settermnamechild={settermnamechild}
        childtermdata={childtermdata}
        gloss={gloss}
      />
      {glossaryselect?.length > 0 ? (
        <GlossaryTable
          terms={individualdatafetch}
          handleEditTerm={handleEditTerm}
          handleDelTerm={handleDelTerm}
          handleAddChild={handleAddChild}
          childtermdata={childtermdata}
        />
      ) : (
        <GlossaryTable
          terms={terms}
          handleEditTerm={handleEditTerm}
          handleDelTerm={handleDelTerm}
          handleAddChild={handleAddChild}
        />
      )}
    </>
  );
};

export default TermDataFetchMethods;
