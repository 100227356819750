import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "antd";

export const GLOSSARY_TERM_GUIDE_CONST = {
  GLOSSARY_HEADER:
    " This page basically consists of two different sections Tag & Glossary by clicking on any Glossary the Description and Glossary Name with the number of terms associated will be seen above the Tabular View.",
  GLOSSARY_STEPS: [
    {
      list: " Create a Glossary by clicking on Create Glossary button in the left Glossary Name is mandatory.",
    },
    {
      list: "  All the Glossary will be listed on the left after creating it.",
    },
    {
      list: " Edit a Glossary by clicking on the icon next to the Glossary Name.",
    },
    {
      list: "For Deleting a glossary click on the Button Delete Glossary on top right corner.",
    },
  ],
  TERM_STEPS: [
    {
      list: "To create a Term,selecting a Glossary Name is mandatory Then click on Add Term button on the top right corner whereTerm Name & Description is mandatory.",
    },
    {
      list: "The terms will be listed on the table.",
    },
    {
      list: " For Edit & Delete of a Term need to Select the icons present in the Actions Column in the Table.",
    },
    {
      list: "You can also create sub terms for a particular term by selecting the add icon from each row. It creates a hierarchy.You can create as many hierarchy you need on the terms.",
    },
  ],
};

const GlossaryGuide = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="pop__btn enabled_btn1"
      >
        Info
      </Button>

      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title className="desc__title">
            Glossary & Terms Creation Guide
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="steps__desc">
          {" "}
          <p className="note__desc">{GLOSSARY_TERM_GUIDE_CONST.GLOSSARY_HEADER}</p>
          <br></br>
          <p className="note__desc">Glossary Steps</p>
          <ul>
            {GLOSSARY_TERM_GUIDE_CONST.GLOSSARY_STEPS.map((gloss, idx) => (
              <li key={idx}>
                <span>{gloss.list}</span>
              </li>
            ))}
          </ul>
          <p className="note__desc">Term Steps</p>
          <ul>
            {GLOSSARY_TERM_GUIDE_CONST.TERM_STEPS.map((term, idx) => (
              <li key={idx}>
                <span>{term.list}</span>
              </li>
            ))}
          </ul>
          <p className="note__desc">
            Note: Glossary Creation is mandatory to Create Terms
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GlossaryGuide;
