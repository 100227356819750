import axios from "axios";

export const GRAPHME_URI = `https://graph.microsoft.com/v1.0/me/photo/$value`;
export const ACCESSREQUEST_URL =
  "https://insights.gleafink.com/ie/admin/accessRequests";

// export const DATABOOK_API = "https://databook-api-preprod.maersk-digital.net/databook";
export const DATABOOK_API = "https://metainsights-api.gleafink.com/metainsights";

// export const DATABOOK_API = (process.env.NODE_ENV === "development" &&
//   process.env.REACT_APP_RUN_ENV === "development")
//   ? "https://databook-api-dev.maestro.maersk.com/databook"
//   : (process.env.NODE_ENV === "preprod" &&
//     process.env.REACT_APP_RUN_ENV === "preprod")
//     ? "https://databook-api-preprod.maersk-digital.net/databook"
//     : "https://databook-api-prod.maestro.maersk.com/databook";
// export const TELEMETRY_API = "https://telemetry-api-preprod.maersk-digital.net/databook/";
export const TELEMETRY_API = (process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_RUN_ENV === "development")
  ? "https://telemetry-api-dev.gleafink.com/metainsights/telemetry/"
  : (process.env.NODE_ENV === "preprod" &&
    process.env.REACT_APP_RUN_ENV === "preprod")
    ? "https://telemetry-api-preprod.gleafink.com/metainsights/telemetry/"
    : "https://telemetry-api-prod.gleafink.com/metainsights/telemetry/";
export const DATABOOK_GPT_URL = `https://chat-api.gleafink.com/`;
export const MARTBUILDER_API = "https://datasculpt-api.gleafink.com";

export const userRequestMartbuilder = axios.create({
  baseURL: MARTBUILDER_API,
});

export const YAMMER_URL = "https://web.gleafink.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNTAyMzkzNzk0NTYifQ/all";


