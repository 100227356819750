import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material"

const DeleteCrawlModal = ({ open, onClose, handleDeleteCrawl, crawl }) => {
  // console.log(crawl)
  const displayCrawlName = crawl?.name || crawl?.connectionName
  const handleConfirmDelete = async () => {
    // Call the handleDeleteCrawl function with the row prop
    await handleDeleteCrawl(crawl) // Make sure it's an asynchronous call if needed
    // Close the modal after deletion
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item: <span>{displayCrawlName || "Name N/A"}</span> ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteCrawlModal
