import React, { useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box, CircularProgress } from "@mui/material";

const Postgre = ({
  connectionData,
  onSubmitFun,
  testConnectionSuccess,
  submissionSuccess,
  showLoading,
  editConnectionCred,
  isEditFlag,
  updatedatasource,
}) => {
  const isMicrosoftSQL = connectionData.datasourceType === "MicrosoftSQL";
  const [authType, setAuthType] = useState("native");

  const [connectionCreds, setConnectionCreds] = useState({
    hostname: "",
    port: "",
    username: "",
    password: "",
    database: "",
    clientId: "",
    clientSecret: "",
    tenantId: "",
  });

  useEffect(() => {
    const { hostname, port, username, password, database } =
      editConnectionCred || {};
    if (isEditFlag) {
      setConnectionCreds({ hostname, port, username, password, database });
    }
  }, [editConnectionCred]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionCreds((prevCreds) => ({
      ...prevCreds,
      [name]: value,
    }));
  };

  const handleSubmit = (isTestConnection) => {
    let formData = {
      ...connectionData,
      connectionCreds: {
        hostname: connectionCreds.hostname,
        port: connectionCreds.port,
        database: connectionCreds.database,
      },
    };

    if (authType === "native") {
      formData.connectionCreds.username = connectionCreds.username;
      formData.connectionCreds.password = connectionCreds.password;
    } else {
      formData.connectionCreds.clientId = connectionCreds.clientId;
      formData.connectionCreds.clientSecret = connectionCreds.clientSecret;
      formData.connectionCreds.tenantId = connectionCreds.tenantId;
    }

    const isEmptyField = Object.values(formData.connectionCreds).some(
      (value) => value.trim() === ""
    );

    if (isEmptyField) {
      alert("Please fill all input fields.");
      return;
    }
    if (onSubmitFun) {
      console.log("Create datasource");
      onSubmitFun(formData, isTestConnection);
    }
  };

  const updateDS = (isTestConnection) => {
    let formData = {
      ...connectionData,
      connectionCreds: {
        hostname: connectionCreds.hostname,
        port: connectionCreds.port,
        database: connectionCreds.database,
      },
    };

    if (authType === "native") {
      formData.connectionCreds.username = connectionCreds.username;
      formData.connectionCreds.password = connectionCreds.password;
    } else {
      formData.connectionCreds.clientId = connectionCreds.clientId;
      formData.connectionCreds.clientSecret = connectionCreds.clientSecret;
      formData.connectionCreds.tenantId = connectionCreds.tenantId;
    }

    const isEmptyField = Object.values(formData.connectionCreds).some(
      (value) => value.trim() === ""
    );

    if (isEmptyField) {
      alert("Please fill all input fields.");
      return;
    }

    if (isEditFlag) {
      console.log("UPDATE datasource");
      updatedatasource(formData, isTestConnection);
    }
  };

  return (
    <div>
      {isMicrosoftSQL && (
        <div className="inputRadioBtn" style={{ border: "0px solid red" }}>
          <div className="inputWrapper">
            <input
              type="radio"
              id="native"
              name="authType"
              value="native"
              checked={authType === "native"}
              onChange={() => setAuthType("native")}
            />
            <label htmlFor="native">Native Connection</label>
            <input
              type="radio"
              id="azure"
              name="authType"
              value="azure"
              checked={authType === "azure"}
              onChange={() => setAuthType("azure")}
            />
            <label htmlFor="azure">Azure Identity</label>
          </div>
        </div>
      )}

      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="hostname">
            Host Name <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="hostname"
            name="hostname"
            placeholder="Enter Host Name"
            value={connectionCreds.hostname}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="port">
            Port Number <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="port"
            name="port"
            placeholder="Enter Port Number"
            onChange={handleChange}
            value={connectionCreds.port}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>
      <div className="individualRow">
        <div className="inputWrapper">
          <label className="labelStyle" htmlFor="database">
            Database <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="database"
            name="database"
            placeholder="Enter Database Name"
            onChange={handleChange}
            value={connectionCreds.database}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>

      {authType === "azure" && (
        <div className="individualRow">
          <div className="inputWrapper">
            <label className="labelStyle" htmlFor="clientId">
              Client Id <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              id="clientId"
              name="clientId"
              placeholder="Enter Client Id"
              onChange={handleChange}
              value={connectionCreds.clientId}
              style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
            />
          </div>
        </div>
      )}

      {/* <div className="individualRow">
        <div className="">
          <label className="labelStyle" htmlFor="username">User Name</label>
          <br />
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Enter User Name"
            onChange={handleChange}
            value={connectionCreds.username}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="passwordWrapper">
          <div>
            <label className="labelStyle" htmlFor="password">Password</label>
            <br />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter Password"
              onChange={handleChange}
              value={connectionCreds.password}
              style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="valign_bottom">
        <button
          className="job-btn btn nar-wid1"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button>
        </div>
      </div> */}

      <div className="individualRow" style={{ border: "0px solid red" }}>
        {authType === "native" ? (
          <>
            <div>
              <label className="labelStyle" htmlFor="username">
                Username <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter Username"
                onChange={handleChange}
                value={connectionCreds.username}
                style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
              />
            </div>
            <div className="passwordWrapper">
              <div>
                <label className="labelStyle" htmlFor="password">
                  Password <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  onChange={handleChange}
                  value={connectionCreds.password}
                  style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                />
              </div>
            </div>

            {showLoading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  color: "#d3d3d3",
                }}
              >
                <CircularProgress color="inherit" sx={{ margin: "10% auto" }} />
              </Box>
            ) : (
              <div className="valign_bottom">
                <button
                  className="job-btn btn nar-wid1"
                  onClick={() => handleSubmit(true)}
                  disabled={testConnectionSuccess}
                  // disabled={!testConnectionSuccess || submissionSuccess}
                >
                  Check Connection
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="">
              <div>
                <label className="labelStyle" htmlFor="clientSecret">
                  Client Secret <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="clientSecret"
                  name="clientSecret"
                  placeholder="Enter Client Secret"
                  onChange={handleChange}
                  value={connectionCreds.clientSecret}
                  style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
                />
              </div>
            </div>

            <div>
              <label className="labelStyle" htmlFor="tenantId">
                Tenant Id <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="text"
                id="tenantId"
                name="tenantId"
                placeholder="Enter Tenant Id"
                onChange={handleChange}
                value={connectionCreds.tenantId}
                style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
              />
            </div>

            {/* {authType === "native" && */}
            <div className="valign_bottom">
              <button
                className="job-btn btn nar-wid1"
                onClick={() => handleSubmit(true)}
                disabled={testConnectionSuccess}
                // disabled={!testConnectionSuccess || submissionSuccess}
              >
                Check Connection
              </button>
            </div>
            {/* } */}
          </>
        )}
      </div>

      {submissionSuccess && (
        <div className="individualRow">
          {/* <p id="successPara"> */}
          <p id="successPara" style={{ paddingLeft: "60%" }}>
            <VerifiedIcon color="primary" />
            Success! Connection to the Database is established.
          </p>
        </div>
      )}

      <hr></hr>

      <div className="individualRow" style={{ marginTop: "20px" }}>
        {authType !== "azure" && testConnectionSuccess && isEditFlag ? (
          <button
            className="job-btn btn"
            type="submit"
            // onSubmit={handleSubmit}
            onClick={() => updateDS(false)}
            disabled={authType === "azure" ? "" : !testConnectionSuccess}
            // disabled={testConnectionSuccess || submissionSuccess}
          >
            Update
          </button>
        ) : (
          <button
            className="job-btn btn"
            type="submit"
            // onSubmit={handleSubmit}
            onClick={() => handleSubmit(false)}
            disabled={authType === "azure" ? "" : !testConnectionSuccess}
            // disabled={testConnectionSuccess || submissionSuccess}
          >
            Submit
          </button>
        )}

        {/* <button
          className="job-btn btn nar-wid"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button> */}
      </div>
    </div>
  );
};

export default Postgre;
