import React from "react"
import { Cell, Pie, PieChart } from "recharts"

export default function PieGraph({ data }) {
  let COLORS = ["#ff9203", "#eeeeee"]
  if (data[0].value < 70) {
    COLORS[0] = "#d20000" // Change color to red
  } else if (data[0].value >= 70 && data[0].value <= 90) {
    COLORS[0] = "#ff9203" // Change color to amber
  } else {
    COLORS[0] = "#238d05" // Change color to green
  }

  return (
    <PieChart width={300} height={150}>
      <Pie
        data={data}
        cx={122}
        cy={60}
        innerRadius={35}
        outerRadius={45}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <text
        x={125}
        y={65}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: "20px", fontWeight: "bold" }}
      >
        {data[0].value}
      </text>
    </PieChart>
  )
}
