import { useState, useContext } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material"
import axios from "axios"
import { userRequestMartbuilder } from "../../../../serverConfig"
import { AuthContext } from "../../../../AuthContext"

function MIModal({ boolean, onOpen, tableType,fetchDomainTableData}) {

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext)

  //api call for adding new data
  const AddMaerskModalData = async () => {
    const ourRequest = axios.CancelToken.source()
    try {
      let response = await userRequestMartbuilder.post(`/orgmodel`, values, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      })
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
    }
    return () => {
      ourRequest.cancel()
    }
  }

  const AddBusinessData = async () => {
    const ourRequest = axios.CancelToken.source()
    try {
      let response = await userRequestMartbuilder.post(`/business`, values, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      })
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
    }
    return () => {
      ourRequest.cancel()
    }
  }

  const AddDomainsData = async () => {
    const ourRequest = axios.CancelToken.source()
    try {
      let response = await userRequestMartbuilder.post(`/platform/createPlatform`, values, {
        headers: {
          // Define your custom headers here 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      })
      fetchDomainTableData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err);
        alert(`API request failed: ${err.response ? err.response.data : err.message}`);
      }
    }
    return () => {
      ourRequest.cancel()
    }
  }

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false)
  const [values, setValues] = useState({
    name: "",
    description: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    const updatedValues = { ...values, [name]: value }
    setValues(updatedValues)
  }

  const handleSubmit = () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true)
      setTimeout(() => {
        setShowEmptyFieldMessage(false)
      }, 2000) // Show the message for 2 seconds
      return
    }
    onOpen()
    switch (tableType) {
      case "MaerskModels":
        AddMaerskModalData(values)
        break
      case "Business":
        AddBusinessData(values)
        break
      case "Domain":
        AddDomainsData(values)
        break
      default:
        break
    }
  }

  return (
    <div style={{  }}>
      <Dialog open={boolean} onClose={onOpen} >
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Add New Data
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            fullWidth
          />
          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{justifyContent:"center"}}>
            <button
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p" 
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  )
}

export default MIModal
