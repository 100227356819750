import React from "react";
import Modal from 'react-bootstrap/Modal';
import { RxCross2 } from "react-icons/rx";
import { GiCheckMark } from "react-icons/gi";
import { Select } from 'antd';

const DatasetFieldsEditModal = (props) => {
    const { name, datasetstag, securityclassification, datasetOwner } = props;


    const handleChange = (value) => {
        switch (name) {
            case "tags":
                let output = [];
                value.map((item) => {
                    output.push(...datasetstag.filter(x => x.value === item));
                })
                props.setSelectedField(output.map((item) => ({ tagName: item.label, fqn: item.value })))
                break;
            case "securityClassification":
                props.setSelectedClassification([value]);
                props.getSensitivityForClassification([value]);
                break;
            case "sensitivity":
                props.setSelectedClassification([value]);
                props.getSensitivityForClassification([value]);
                break;
            case "description":
                props.setSelectedField(value);
                break;
            default:
                break;
        }

    };

    const handleSensitivity = (value) => {
        props.setSelectedSensitivity(value);
    }

    let editField = (<div></div>);
    switch (name) {

        case "tags":
            editField = (
                <div className="mb-3">
                    <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Dataset Tags</label>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        backdrop="static"
                        keyboard={false}
                        placeholder="Please select tags."
                        defaultValue={props.fieldDefaultValues}
                        onChange={handleChange}
                        options={datasetstag}
                        dropdownStyle={{ zIndex: 99999 }}
                    />
                </div>)
            break;
        case "securityClassification":
            editField = (
                <div>
                    <div className="mb-3">
                        <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Dataset Security Classification</label>
                        <Select
                            // mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            backdrop="static"
                            keyboard={false}
                            placeholder="Please select classification."
                            defaultValue={props.fieldDefaultValues}
                            onChange={handleChange}
                            options={securityclassification}
                            dropdownStyle={{ zIndex: 99999 }}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Dataset Sensitivity</label>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            backdrop="static"
                            keyboard={false}
                            value={props.selectedsensitivity}
                            placeholder="Please select sensitivity."
                            defaultValue={props.sensitivityDefaultValues}
                            onChange={handleSensitivity}
                            options={props.sensitivity}
                            dropdownStyle={{ zIndex: 99999 }}
                        />
                    </div>

                </div>
            )
            break;
        case "sensitivity":
            editField = (
                <div>
                    <div className="mb-3">
                        <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Dataset security classification</label>
                        <Select
                            // mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            backdrop="static"
                            keyboard={false}
                            placeholder="Please select classification."
                            defaultValue={props.fieldDefaultValues}
                            onChange={handleChange}
                            options={securityclassification}
                            dropdownStyle={{ zIndex: 99999 }}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Dataset Sensitivity</label>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            backdrop="static"
                            keyboard={false}
                            value={props.selectedsensitivity}
                            placeholder="Please select sensitivity."
                            defaultValue={props.sensitivityDefaultValues}
                            onChange={handleSensitivity}
                            options={props.sensitivity}
                            dropdownStyle={{ zIndex: 99999 }}
                        />
                    </div>

                </div>
            )
            break;

        case "owner":
            editField = (
                <div>
                    <div className="mb-3">
                        <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Dataset Owner <span className="owner-note" style={{color:"red"}}>( Note : Use comma for multiple owners. )</span></label>
                        <textarea className="form-control" defaultValue={datasetOwner?.toString()} id="exampleFormControlTextarea1" rows="2" onChange={props.editDatasetOwnerHandler}></textarea>
                    </div>
                </div>
            )
            break;
        case "description":
            editField = (
                <div className="mb-3">
                    <label htmlFor="descID" className="form-label fs-6 maersk-text-reg">Dataset Description</label>
                    <textarea className="form-control" defaultValue={props.fieldDefaultValues} id="exampleFormControlTextarea1" rows="4" onChange={(e) => handleChange(e.target.value)}></textarea>
                </div>
            )
            break;
        default:
            editField = (<div></div>)
    }

    return (
        <>
            <div>
                <Modal
                    size="lg"
                    show={props?.show}
                    onHide={() => props.closeModel(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-modal-sizes-title-lg"
                    backdrop="static"
                    keyboard={false}

                >
                    <Modal.Body>
                        <div>
                            {editField}
                        </div>
                        <div className="">
                            <div className=" text-end">
                                <button type="button" className="model-save mx-3 shadow-sm  rounded" onClick={() => props.closeModel(false)}>
                                    <RxCross2 />
                                </button>
                                <button type="submit" className="model-save  shadow-sm  rounded" onClick={props.editsaveDatasetFields} >
                                    <GiCheckMark />
                                </button>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}



export default DatasetFieldsEditModal;
