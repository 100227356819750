import React, { useState } from "react";

const SalesForce = ({
  connectionData,
  onSubmitFun,
  testConnectionSuccess,
  submissionSuccess,
}) => {
  const [connectionCreds, setConnectionCreds] = useState({
    sfdc_password: "",
    sfdc_security_token: "",
    sf_username: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionCreds((prevCreds) => ({
      ...prevCreds,
      [name]: value,
    }));
  };

  const handleSubmit = (isTestConnection) => {
    const formData = {
      ...connectionData,
      connectionCreds,
    };
    if (onSubmitFun) {
      onSubmitFun(formData, isTestConnection);
    }
  };

  return (
    <div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="sfdc_password">
            SFDC Password <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="sfdc_password"
            name="sfdc_password"
            placeholder="Enter Host Name"
            value={connectionData.sfdc_password}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div>
          <label className="labelStyle" htmlFor="sfdc_security_token">
            SFDC Security Token <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="sfdc_security_token"
            name="sfdc_security_token"
            placeholder="Enter SFDC Security Token"
            onChange={handleChange}
            value={connectionData.sfdc_security_token}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
      </div>
      <div className="individualRow">
        <div className="inputHostName">
          <label className="labelStyle" htmlFor="sf_username">
            SF Username <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <input
            type="text"
            id="sf_username"
            name="sf_username"
            placeholder="Enter Host Name"
            value={connectionData.sf_username}
            onChange={handleChange}
            style={{ border: "1px solid #d3d3d3", borderRadius: "5px" }}
          />
        </div>
        <div className="valign_bottom">
          <button
            className="job-btn btn nar-wid1"
            onClick={() => handleSubmit(true)}
            disabled={testConnectionSuccess}
            // disabled={!testConnectionSuccess || submissionSuccess}
          >
            Check Connection
          </button>
        </div>
      </div>
      <hr></hr>

      <div className="individualRow" style={{ marginTop: "20px" }}>
        <button
          className="job-btn btn"
          type="submit"
          // onSubmit={handleSubmit}
          onClick={() => handleSubmit(false)}
          disabled={!testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Submit
        </button>
        {/* <button
          className="job-btn btn nar-wid"
          onClick={() => handleSubmit(true)}
          disabled={testConnectionSuccess}
          // disabled={!testConnectionSuccess || submissionSuccess}
        >
          Check Connection
        </button> */}
      </div>
    </div>
  );
};

export default SalesForce;
