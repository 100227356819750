import React, { useEffect, useState } from "react";
import { Button } from "antd";
import editicon from "../../../../assets/images/pencil.svg";
import trashIcon from "../../../../assets/images/trash.svg";
import arrowRight from "../../../../assets/images/arrow-right.svg";
import _ from "lodash";

const GlossaryGroupingView = (props) => {
  const [hoveredItem, setHoveredItem] = useState("");
  const {
    // individualdatafetch,
    terms,
    onItemSelect,
    glossary,
    selectedItem,
    handleCreateShow,
    handleEditShow,
    handleDeleteGlossaryShow,
    setDescriptionheader,
    setindividualdatafetch,
    sethandleglossarySelect,
  } = props;

  //  console.log("Glossary", glossary)
  // useEffect(() => {
  //   onItemSelect(glossary[0]?.classificationName);
  //   // console.log("Itemmm>", classificationdata)
  //   // onItemSelect("default");
  // }, []);

  useEffect(() => {
    let selectedglossary = glossary[0]?.classificationName;
    const tabledata = _.filter(
      terms,
      (m) => m.classificationName === selectedglossary
    );

    const descriptiondata = _.filter(
      glossary,
      (m) => m.classificationName === selectedglossary
    );

    setDescriptionheader(descriptiondata);
    setindividualdatafetch(tabledata);
    sethandleglossarySelect(selectedglossary);
  }, [terms]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 style={{ fontSize: "16px" }}>Available Glossary</h4>
        <Button
          className="classification enabled_btn"
          onClick={handleCreateShow}
          style={{ cursor: "pointer", fontSize: "14px" }}
        >
          {" "}
          Add Glossary
        </Button>
      </div>
      <ul
        className="list-group py-3"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "0",
          paddingLeft: "0",
        }}
      >
        {glossary &&
          glossary.map((gloss, idx) => (
            <div key={idx}>
              {" "}
              <li
                className={
                  gloss.classificationName === selectedItem
                    ? "list-group-item active"
                    : idx === 0 && selectedItem?.length === 0
                    ? "list-group-item active"
                    : "list-group-item"
                }
                onClick={() => onItemSelect(gloss.classificationName)}
                onMouseEnter={() => setHoveredItem(gloss.classificationName)}
                onMouseLeave={() => setHoveredItem("")}
              >
                <div className="class__grp">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      padding: "0 7px 0 0",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      {gloss.classificationName}
                    </p>
                    <p className="dataFetchLength">
                      {
                        terms.filter(
                          (item) =>
                            item.classificationName === gloss.classificationName
                        ).length
                      }
                    </p>
                  </div>
                  {(gloss.classificationName === selectedItem ||
                    gloss.classificationName === hoveredItem) && (
                    <div className="edit__classi">
                      <Button onClick={handleEditShow}>
                        <img src={editicon} alt="Edit Classification" />
                      </Button>
                      <Button onClick={handleDeleteGlossaryShow}>
                        <img src={trashIcon} alt="Delete Classification" />
                      </Button>
                      <Button>
                        <img
                          src={arrowRight}
                          alt="Arrow Classification"
                          style={{
                            backgroundColor: "#f77039",
                            filter: "invert(100%)",
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </li>
            </div>
          ))}
      </ul>
    </>
  );
};

export default GlossaryGroupingView;
